@import "../mixins/media";
@import "../mixins/clearfix";

/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/

#news {
  margin-bottom: 120px;
  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
  .ec-widthRole {
    margin: 0 auto;
    padding: 80px 0;
    @media (max-width: 767px) {
      padding: 40px 0;
    }
  }
  .ec-section__titleEn {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
  .ec-newsRole {
    &__newsDescription {
      border-top: 1px solid #5C5C67;
      border-bottom: 1px solid #5C5C67;
      color: #5C5C67;
      font-family: 'Koburina-Gothic-w3', sans-serif;
    }
  }
  .ec-topNotice {
    display: flex;
    margin-bottom: 56px;
    padding: 0 24px;
    @media (max-width: 767px) {
      flex-direction: column;
      padding: 0 12px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &__date {
      font-family: "Fahkwang", sans-serif;
      font-size: 15px;
      line-height: 32px;
      text-transform: lowercase;
      width: 180px;
      letter-spacing: 0.12em;
      flex-shrink: 0;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 8px;
      }
    }
    &__titleWrap {
      padding-left: 120px;
      @media (max-width: 767px) {
        padding-left: 0;
      }
    }
    &__link {
      color: #5C5C67;
      &:hover {
        text-decoration: none;
        opacity: 0.6;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        -ms-transition: 0.2s;
      }
    }
    &__title {
      font-size: 18px;
      line-height: 32px;
      color: #5C5C67;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
  .ec-viewmore {
    margin-top: 64px;
    @media (max-width: 767px) {
      margin-top: 32px;
    }
  }
}
/* 旧デザインのスタイル */
/*
.ec-newsRole {
  padding: 40px 0 0;

  @include media_desktop {
    padding: 60px 0 0;
  }

  & &__news {

    box-sizing: border-box;

    @include media_desktop {
      border: 16px solid #F8F8F8;
      padding: 20px 30px;
    }
  }
  & &__newsItem {
    width: 100%;

    &:not(:last-of-type){
      border-bottom: 1px solid #ccc;
    }

    &:last-of-type {
      margin-bottom: 20px;

      @include media_desktop {
        margin-bottom: 0;
      }
    }


    @include media_desktop {

      padding: 20px 0;
    }
  }
  & &__newsHeading {
    cursor: pointer;

    @include media_desktop {
      display: flex;
    }

  }
  & &__newsDate {
    display: block;
    margin:  15px 0 5px;
    font-size: 12px;
    color: black;

    @include media_desktop {
      display: inline-block;
      margin: 0;
      min-width: 120px;
      font-size: 14px;
    }

  }
  & &__newsColumn {
    display: flex;

    @include media_desktop {
      display: inline-flex;
      min-width: calc(100% - 120px);
    }
  }

  & &__newsTitle {
    display: inline-block;
    margin-bottom: 10px;
    width: 90%;
    font-size: 14px;
    font-weight: bold;
    color: #7D7D7D;
    line-height: 1.6;

    @include media_desktop {
      margin-bottom: 0;
      line-height: 1.8;
    }

  }
  & &__newsClose {
    display: inline-block;
    width: 10%;
    position: relative;

  }
  & &__newsCloseBtn {
    display: inline-block;
    margin-left: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    background: black;
    cursor: pointer;
    position: absolute;
    right: 5px;
  }
  & &__newsDescription {
    display: none;
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;

    @include media_desktop {
      margin: 20px 0 0;
      line-height: 1.8;
    }

    a {
      color: #0092C4;
    }
  }
  &__newsItem.is_active &__newsDescription{
    margin: 0 0 10px;

    @include media_desktop {
      margin: 20px 0 0;
    }
  }
  &__newsItem.is_active &__newsCloseBtn i {
    display: inline-block;
    transform: rotateX(180deg) translateY(2px);

  }

}
*/
