@import "../mixins/media";
/*
最近チェックした商品のスタイルです。

*/
#checkedItems.ec-ranking {
  .ec-ranking__list {
    justify-content: initial;
  }
  .ec-rank__listChild {
    flex: none;
    width: 17.6%;
  }
}
