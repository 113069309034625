@charset "UTF-8";
@import url("../../../../../node_modules/normalize.css/normalize.css");
/*
テキスト

Styleguide 1.0
*/
/*
ボタン

Styleguide 2.0
*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fahkwang:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.ec-infoBtn {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #E5E5E5 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF;
  opacity: 1;
  color: #5C5C67;
  border-radius: 47px;
  font-size: 16px;
  height: 92px;
  box-sizing: border-box;
  padding: 0 36px;
}
.ec-infoBtn:hover {
  color: #5C5C67;
  opacity: 0.8;
  text-decoration: none;
}

.ec-infoBtn__text {
  text-align: center;
  letter-spacing: 0.2em;
}
.ec-infoBtn__text .ec-infoBtn__textSm {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 16px;
}
.ec-infoBtn__text .ec-infoBtn__textLg {
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 24px;
}

.ec-counselingBtn {
  width: 416px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  right: 30px;
  bottom: 15px;
  padding: 0 36px;
}
.ec-counselingBtn .ec-counselingText {
  text-align: center;
  letter-spacing: 0.2em;
}
@media (max-width: 600px) {
  .ec-counselingBtn .ec-counselingText {
    margin-right: 20px;
  }
}
.ec-counselingBtn .ec-counselingText span {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 16px;
}
.ec-counselingBtn .ec-counselingText p {
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 24px;
}
.ec-counselingBtn:hover {
  opacity: 0.8;
  text-decoration: none;
}
.ec-counselingBtn:hover .ec-counselingText {
  text-decoration: none;
}
.ec-counselingBtn .ec-counselingPlus {
  width: 11px;
  height: 22px;
}
@media (max-width: 767px) {
  .ec-counselingBtn {
    bottom: 10px;
    height: 68px;
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .ec-counselingBtn {
    left: 0;
    width: 100%;
    justify-content: center;
  }
}

.ec-viewmore {
  border-bottom: 1px solid #5C5C67;
  width: 189px;
  padding-bottom: 17px;
  text-align: right;
  float: right;
}
.ec-viewmore:hover {
  opacity: 0.8;
}
@media (max-width: 767px) {
  .ec-viewmore {
    width: 130px;
    right: 16px;
    bottom: 18px;
    padding-bottom: 8px;
  }
}
.ec-viewmore a, .ec-viewmore div {
  color: #5C5C67;
  font-family: "BonvenoCF-Light", sans-serif;
  display: block;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 0.12em;
}
@media (max-width: 767px) {
  .ec-viewmore a, .ec-viewmore div {
    font-size: 16px;
  }
}
.ec-viewmore a:hover, .ec-viewmore div:hover {
  opacity: 0.8;
  text-decoration: none;
}

/*
テキスト

*/
.ec-section__titleEn {
  color: #5C5C67;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 34px;
  letter-spacing: 0.12em;
}
@media (max-width: 767px) {
  .ec-section__titleEn {
    font-size: 24px;
  }
}

/*
フォーム

Styleguide 3.0
*/
/*
アイコン

Styleguide 4.0
*/
/*
グリッド

Styleguide 5.0
*/
/*
ログイン

Styleguide 6.0
*/
/*
商品

Styleguide 7.0
*/
/*
お知らせ

Styleguide 8.0
*/
/*
マイページ

Styleguide 9.0
*/
/*
共通コンポーネント

Styleguide 11.0
*/
.ec-widthRole {
  max-width: 1000px !important;
}

.ec-widthRole.ec-headerNaviRole,
.ec-widthRole.ec-headerRole,
.ec-widthRole.ec-categoryNaviRole {
  padding-left: 0;
  padding-right: 0;
}

.ec-section__title {
  color: #000;
  margin-bottom: 36px;
}

.ec-section__titleJa {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 13px;
  line-height: 28px;
}

.ec-product__unstress {
  color: #54BEB3;
}

.ec-product__illustrious {
  color: #A7A8A9;
}

.ec-product__biophyto {
  color: #618F2E;
}

.ec-product__foreveryoung {
  color: #F7B05B;
}

.ec-product__rosedemer {
  color: #162C7D;
}

.ec-product__muse {
  color: #D76EA8;
}

.ec-product__comodex {
  color: #842331;
}

.ec-product__theraskin {
  color: #38829B;
}

.ec-product__chateu {
  color: #7E2D4F;
}

.ec-product__silk {
  color: #B29E97;
}

.ec-product__satin {
  color: #686868;
}

.ec-product__clinical {
  color: #488A9B;
}

.ec-breadcrumbsRole {
  background: #000;
  color: #fff;
  width: 100%;
  height: 33px;
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.ec-breadcrumbsRole .ec-widthRole {
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 767px) {
  .ec-breadcrumbsRole .ec-widthRole {
    padding: 0 20px;
  }
}
.ec-breadcrumbsRole .ec-separatorRole {
  margin: 0 16px;
}
.ec-breadcrumbsRole a {
  color: #fff;
}

.ec-category2Role__categoryTitle {
  width: 100% !important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1.8vw;
  font-weight: 300;
  margin: 0;
  color: #FFFFFF;
  font-family: "BonvenoCF-Light", sans-serif;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background: rgba(0, 0, 0, 0.4);
  transform: none !important;
}

.ec-cartRole__progress {
  padding-top: 48px;
}
@media (max-width: 767px) {
  .ec-cartRole__progress {
    padding-top: 24px;
  }
}

input[type=checkbox].ec-checkboxRole {
  display: none;
}

label.ec-labelRole {
  font-weight: 400;
  position: relative;
  padding: 2px 0 2px 25px;
  cursor: pointer;
  white-space: nowrap;
}
label.ec-labelRole::before, label.ec-labelRole::after {
  position: absolute;
  content: "";
  top: 50%;
}
label.ec-labelRole::before {
  width: 16px;
  height: 16px;
  left: 0;
  border: 1px solid #9B9B9B;
  background: #fff;
  margin-top: -8px;
}
label.ec-labelRole::after {
  position: absolute;
  width: 5px;
  height: 9px;
  top: 50%;
  left: 10px;
  border-right: 2px solid #9B9B9B;
  border-bottom: 2px solid #9B9B9B;
  content: "";
  margin-top: -6px;
  opacity: 0;
  transform: rotate(45deg);
}

input[type=checkbox].ec-checkboxRole:checked + .ec-labelRole::after {
  opacity: 1;
}

select.ec-selectRole {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 16px;
  color: #000;
  border: 1px solid #9B9B9B;
  width: 320px;
  height: 46px;
  padding-left: 24px;
  padding-right: 17px;
  -webkit-appearance: none;
  appearance: none;
  background: #fff url("/html/template/default/assets/img/common/select_arrow.svg") no-repeat right 17px center;
  cursor: pointer;
}
@media (max-width: 767px) {
  select.ec-selectRole {
    width: 100%;
  }
}

img.slide-arrow {
  position: absolute;
  width: 5px;
  height: 10px;
}

.ec-blockTopBtn.pagetop {
  background: transparent;
  width: 60px;
  height: 60px;
}
@media only screen and (min-width: 768px) {
  .ec-blockTopBtn.pagetop {
    bottom: 128px;
  }
}
@media (max-width: 767px) {
  .ec-blockTopBtn.pagetop {
    width: 38px;
    height: 38px;
    margin-right: 8px;
    bottom: 93px;
  }
}
.ec-blockTopBtn.pagetop .ec-gotop {
  width: 100%;
  height: 100%;
}
.ec-blockTopBtn.pagetop .ec-gotop:hover {
  opacity: 0.8;
}

/*
トップページ

Styleguide 12.0
*/
/*
商品詳細

Styleguide 14.0
*/
/*
レコメンド

Styleguide 14.0
*/
#recommend.ec-ranking {
  padding: 56px 0 56px;
  background: #DEDEDE;
}

/*
フォーム

Styleguide 19.0
*/
/*
404ページ

Styleguide 20.0
*/
/*
退会手続き

Styleguide 21.0
*/
/*
会員情報編集完了

Styleguide 22.0
*/
body {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  color: #525263;
  transition: z-index 0ms 5.28455ms;
  background: #f6f6f6;
  margin: 0;
}

a {
  text-decoration: none;
}

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0;
}

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.1
*/
/*
見出し

商品紹介等で利用される、一般的な見出しのスタイルです。

ex [商品詳細ページ　商品見出し部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-headingTitle マトリョーシカ

Styleguide 1.1.1
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263;
}

/*
ページヘッダ

各種ページで用いられるページヘッダのデザインです。

Styleguide 1.1.2
*/
.ec-pageHeader {
  margin: 0 auto;
}
.ec-pageHeader h1 {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 22px;
  padding: 40px 0;
  border-bottom: 1px solid #000000;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-pageHeader h1 {
    font-size: 28px;
    padding: 55px 0;
  }
}
.ec-pageHeader p {
  margin-top: 10px;
  font-size: 12px;
  font-weight: normal;
  font-family: KoburiGothic, sans-serif;
  letter-spacing: 0.1em;
}
@media only screen and (min-width: 768px) {
  .ec-pageHeader p {
    margin-top: 20px;
    font-size: 13px;
  }
}

/*
サブ見出し

利用規約など、文字主体のページで用いられるサブ見出しです。

ex [利用規約ページ サブ見出し部分](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-heading 第1条 (会員)

Styleguide 1.1.3
*/
.ec-heading {
  margin: 24px 0;
}

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

ex [プライバシーポリシー サブ見出し部分](http://demo3.ec-cube.net/help/privacy)

Markup:
.ec-heading-bold 個人情報の定義

Styleguide 1.1.4
*/
.ec-heading-bold {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-heading-bold {
    font-size: 18px;
  }
}

/*
背景付き見出し

マイページ注文履歴等で用いられる背景付きの見出しです。

ex [ご注文履歴詳細　背景付き見出し部分](http://demo3.ec-cube.net/mypage/history/1063)

Markup:
.ec-rectHeading
  h2 配送情報
.ec-rectHeading
  h2 お支払について

Styleguide 1.1.5
*/
.ec-rectHeading h1, .ec-rectHeading h2, .ec-rectHeading h3,
.ec-rectHeading h4, .ec-rectHeading h5, .ec-rectHeading h6 {
  background: #F3F3F3;
  padding: 8px 12px;
  font-size: 20px;
  font-weight: bold;
}

/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

ex [注文完了 ログイン後、カートに商品を入れ注文完了まで行う](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました

Styleguide 1.1.6
*/
.ec-reportHeading {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-reportHeading {
    border-top: 0;
    font-size: 32px;
  }
}
.ec-reportHeading h1, .ec-reportHeading h2, .ec-reportHeading h3,
.ec-reportHeading h4, .ec-reportHeading h5, .ec-reportHeading h6, .ec-reportHeading p {
  font-weight: bold;
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .ec-reportHeading h1, .ec-reportHeading h2, .ec-reportHeading h3,
.ec-reportHeading h4, .ec-reportHeading h5, .ec-reportHeading h6, .ec-reportHeading p {
    font-size: 32px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@font-face {
  font-family: "RyuminPr5-Light";
  src: url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Light.woff2") format("woff2"), url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Light.woff") format("woff"), url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Light.otf") format("opentype");
}
@font-face {
  font-family: "RyuminPr5-Medium";
  src: url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Medium.woff2") format("woff2"), url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Medium.woff") format("woff"), url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Koburina-Gothic-w3";
  src: url("/html/template/default/assets/fonts/Koburina-Gothic-Std.woff2") format("woff2"), url("/html/template/default/assets/fonts/Koburina-Gothic-Std.woff") format("woff"), url("/html/template/default/assets/fonts/Koburina-Gothic-Std.otf"), format("opentype");
}
@font-face {
  font-family: "BonvenoCF-Light";
  src: url("/html/template/default/assets/fonts/BonvenoCF-Light.otf") format("opentype");
}
@font-face {
  font-family: "Bebas Neue";
  src: url("/html/template/default/assets/fonts/BebasNeue-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Fahkwang-bold";
  src: url("/html/template/default/assets/fonts/Fahkwang-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Ryumin;
  font-weight: 400;
  src: url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Light.otf") format("opentype"), url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Light.woff") format("woff");
}
@font-face {
  font-family: Ryumin;
  font-weight: 500;
  src: url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Medium.otf") format("opentype"), url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Medium.woff") format("woff");
}
@font-face {
  font-family: Ryumin2;
  font-weight: 400;
  src: url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Light_2.otf") format("opentype"), url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Light_2.woff") format("woff");
}
@font-face {
  font-family: Ryumin2;
  font-weight: 500;
  src: url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Medium_2.otf") format("opentype"), url("/html/template/default/assets/fonts/A-OTF-RyuminPr5-Medium_2.woff") format("woff");
}
@font-face {
  font-family: BonvenoCF;
  font-weight: 400;
  src: url("/html/template/default/assets/fonts/BonvenoCF-Light.otf") format("opentype"), url("/html/template/default/assets/fonts/BonvenoCF-Light.woff") format("woff");
}
@font-face {
  font-family: KoburiGothic;
  font-weight: 400;
  src: url("/html/template/default/assets/fonts/KoburiGothicStdW3.otf") format("opentype"), url("/html/template/default/assets/fonts/KoburiGothicStdW3.woff") format("woff");
}
@font-face {
  font-family: KoburiGothic2;
  font-weight: 400;
  src: url("/html/template/default/assets/fonts/KoburiGothicStdW3_2.otf") format("opentype"), url("/html/template/default/assets/fonts/KoburiGothicStdW3_2.woff") format("woff");
}
/*
文字装飾

文字装飾をするためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.2
*/
/*
テキストリンク

テキストリンクのスタイルです。

Markup:
a(href="#").ec-link さくらのクラウド

Styleguide 1.2.1
*/
.ec-link {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}
.ec-link:hover {
  color: #33A8D0;
  text-decoration: none;
}

/*
テキスト（太字）

テキストを太くするためのスタイルです。

Markup:
p.ec-font-bold この季節にぴったりな商品をご用意しました

Styleguide 1.2.2
*/
.ec-font-bold {
  font-weight: bold;
}

/*
テキスト（グレー）

テキストをグレーにするためのスタイルです。

Markup:
p.ec-color-grey 青色が美しい職人が仕上げた吹きガラス

Styleguide 1.2.3
*/
.ec-color-grey {
  color: #9a947e;
}

/*
テキスト（赤）

テキストを赤にするためのスタイルです。

Markup:
p.ec-color-red ¥ 2,728 税込
p.ec-color-accent ¥ 2,728 税込

Styleguide 1.2.4
*/
.ec-color-red {
  color: #DE5D50;
}

.ec-color-accent {
  color: #DE5D50;
}

/*
フォントサイズ

フォントサイズを指定するためのスタイルです。

Markup:
.ec-font-size-1 さわやかな日差しが過ごしやすい季節
.ec-font-size-2 さわやかな日差しが過ごしやすい季節
.ec-font-size-3 さわやかな日差しが過ごしやすい季節
.ec-font-size-4 さわやかな日差しが過ごしやすい季節
.ec-font-size-5 さわやかな日差しが過ごしやすい季節
.ec-font-size-6 さわやかな日差しが過ごしやすい季節


Styleguide 1.2.5
*/
.ec-font-size-1 {
  font-size: 12px;
}

.ec-font-size-2 {
  font-size: 14px;
}

.ec-font-size-3 {
  font-size: 16px;
}

.ec-font-size-4 {
  font-size: 20px;
}

.ec-font-size-5 {
  font-size: 32px;
}

.ec-font-size-6 {
  font-size: 40px;
}

/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。

Markup:
p.ec-text-ac さわやかな日差しが過ごしやすい季節

Styleguide 1.2.6
*/
.ec-text-ac {
  text-align: center;
}

/*
価格テキスト

価格を表示するテキストです。

価格文字にスペースを取るほか、税込み等の表示を小さくする効果もあります。

spanを用いたインライン要素として利用します。

Markup:
div(style="color:#DE5D50;font-size:28px")
    span.ec-price
      span.ec-price__unit ¥
      span.ec-price__price 1,280
      span.ec-price__tax 税込

Styleguide 1.2.7
*/
.ec-price .ec-price__unit {
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__unit {
    font-size: 1em;
  }
}
.ec-price .ec-price__price {
  display: inline-block;
  padding: 0 0.3em;
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__price {
    font-size: 1em;
  }
}
.ec-price .ec-price__tax {
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__tax {
    font-size: 0.57em;
  }
}

/*
テキストの位置

テキストや、入れ子にしたインライン要素を
「左揃え」「中央揃え」「右揃え」に設定することができます。

Markup:
h3 左揃え
p.text-left
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 中央揃え
p.text-center
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 右揃え
p.text-right
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?

Styleguide 1.2.8
*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。

ex [注文完了 （ログイン後、カートに商品を入れ注文完了まで行う）](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました
p.ec-reportDescription
      | ただいま、ご注文の確認メールをお送りさせていただきました。
      br
      | 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
      br
      | 今後ともご愛顧賜りますようよろしくお願い申し上げます。


Styleguide 1.2.9
*/
.ec-reportDescription {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
}

/*
テキスト下部のスペース

テキストの下に余白を追加することができます。 .ec-para-normalで16pxの余白をつけることができます。

Markup:
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。

Styleguide 1.2.10
*/
.ec-para-normal {
  margin-bottom: 16px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
リスト

シンプルなリストを構成するためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions, .ec-definitions--soft {
  margin: 5px 0;
  display: block;
}
.ec-definitions dt, .ec-definitions--soft dt, .ec-definitions dd, .ec-definitions--soft dd {
  display: inline-block;
  margin: 0;
}
.ec-definitions dt, .ec-definitions--soft dt {
  font-weight: bold;
}

.ec-definitions--soft dt {
  font-weight: normal;
}

/*
下線つき定義リスト

線が添えられた定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　下線つき定義リスト](http://demo3.ec-cube.net/help/about)

Markup:
.ec-borderedDefs
  dl
    dt 店名
    dd EC-CUBE3 DEMO SHOP
  dl
    dt 会社名
    dd EC-CUBE3
  dl
    dt 所在地
    dd 〒550 - 0001

Styleguide 1.3.2
*/
.ec-borderedDefs {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin-bottom: 16px;
}
.ec-borderedDefs dl {
  display: flex;
  border-bottom: 1px dotted #ccc;
  margin: 0;
  padding: 10px 0 0;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dl {
    flex-wrap: nowrap;
    padding: 15px 0 4px;
  }
}
.ec-borderedDefs dt, .ec-borderedDefs dd {
  padding: 0;
}
.ec-borderedDefs dt {
  font-weight: normal;
  width: 100%;
  padding-top: 0;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dt {
    padding-top: 14px;
    width: 30%;
  }
}
.ec-borderedDefs dd {
  padding: 0;
  width: 100%;
  line-height: 2.5;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dd {
    width: 70%;
    line-height: 3;
  }
}
.ec-borderedDefs p {
  line-height: 1.4;
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;
}
.ec-list-chilled dt, .ec-list-chilled dd {
  display: table-cell;
  border-bottom: 1px dotted #ccc;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-list-chilled dt, .ec-list-chilled dd {
    padding: 16px 0;
  }
}
.ec-list-chilled dt {
  width: 30%;
}
.ec-list-chilled dd {
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-list-chilled dd {
    padding: 16px;
  }
}

/*
ボーダーリスト

線が添えられたリストを表示します。

ex [当サイトについて　ボーダーリスト](http://demo3.ec-cube.net/help/about)

Markup:
ul.ec-borderedList
  li: p lorem
  li: p lorem
  li: p lorem


Styleguide 1.3.3
*/
.ec-borderedList {
  width: 100%;
  border-top: 0;
  list-style: none;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-borderedList {
    border-top: 1px dotted #ccc;
  }
}
.ec-borderedList li {
  border-bottom: 1px dotted #ccc;
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;
}
.ec-list-chilled dt, .ec-list-chilled dd {
  display: table-cell;
  border-bottom: 1px dotted #ccc;
  padding: 16px 0;
}
.ec-list-chilled dt {
  width: 30%;
}
.ec-list-chilled dd {
  padding: 16px;
}

/*
ボタンサイズ

ボタンサイズを変更するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #525263;
  background-color: #F5F7F8;
  border-color: #ccc;
}
.ec-inlineBtn:focus, .ec-inlineBtn.focus, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn:hover, .ec-inlineBtn:focus, .ec-inlineBtn.focus {
  color: #525263;
  text-decoration: none;
}
.ec-inlineBtn:active, .ec-inlineBtn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-inlineBtn.disabled, .ec-inlineBtn[disabled], fieldset[disabled] .ec-inlineBtn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-inlineBtn:focus, .ec-inlineBtn.focus {
  color: #525263;
  background-color: #d7dfe3;
  border-color: #8c8c8c;
}
.ec-inlineBtn:hover {
  color: #525263;
  background-color: #d7dfe3;
  border-color: #adadad;
}
.ec-inlineBtn:active, .ec-inlineBtn.active, .open > .ec-inlineBtn.dropdown-toggle {
  color: #525263;
  background-color: #d7dfe3;
  background-image: none;
  border-color: #adadad;
}
.ec-inlineBtn:active:hover, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:hover, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus, .open > .ec-inlineBtn.dropdown-toggle:hover, .open > .ec-inlineBtn.dropdown-toggle:focus, .open > .ec-inlineBtn.dropdown-toggle.focus {
  color: #525263;
  background-color: #c2ced4;
  border-color: #8c8c8c;
}
.ec-inlineBtn.disabled:hover, .ec-inlineBtn.disabled:focus, .ec-inlineBtn.disabled.focus, .ec-inlineBtn[disabled]:hover, .ec-inlineBtn[disabled]:focus, .ec-inlineBtn[disabled].focus, fieldset[disabled] .ec-inlineBtn:hover, fieldset[disabled] .ec-inlineBtn:focus, fieldset[disabled] .ec-inlineBtn.focus {
  background-color: #F5F7F8;
  border-color: #ccc;
}
.ec-inlineBtn .badge {
  color: #F5F7F8;
  background-color: #525263;
}
.ec-inlineBtn .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

.ec-inlineBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
}
.ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn--primary:hover, .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
  color: #525263;
  text-decoration: none;
}
.ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-inlineBtn--primary.disabled, .ec-inlineBtn--primary[disabled], fieldset[disabled] .ec-inlineBtn--primary {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
  color: #fff;
  background-color: #479393;
  border-color: #2e6060;
}
.ec-inlineBtn--primary:hover {
  color: #fff;
  background-color: #479393;
  border-color: #438d8d;
}
.ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active, .open > .ec-inlineBtn--primary.dropdown-toggle {
  color: #fff;
  background-color: #479393;
  background-image: none;
  border-color: #438d8d;
}
.ec-inlineBtn--primary:active:hover, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:hover, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus, .open > .ec-inlineBtn--primary.dropdown-toggle:hover, .open > .ec-inlineBtn--primary.dropdown-toggle:focus, .open > .ec-inlineBtn--primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #3b7b7b;
  border-color: #2e6060;
}
.ec-inlineBtn--primary.disabled:hover, .ec-inlineBtn--primary.disabled:focus, .ec-inlineBtn--primary.disabled.focus, .ec-inlineBtn--primary[disabled]:hover, .ec-inlineBtn--primary[disabled]:focus, .ec-inlineBtn--primary[disabled].focus, fieldset[disabled] .ec-inlineBtn--primary:hover, fieldset[disabled] .ec-inlineBtn--primary:focus, fieldset[disabled] .ec-inlineBtn--primary.focus {
  background-color: #5CB1B1;
  border-color: #5CB1B1;
}
.ec-inlineBtn--primary .badge {
  color: #5CB1B1;
  background-color: #fff;
}
.ec-inlineBtn--primary .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

.ec-inlineBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #DE5D50;
  border-color: #DE5D50;
}
.ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn--action:hover, .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
  color: #525263;
  text-decoration: none;
}
.ec-inlineBtn--action:active, .ec-inlineBtn--action.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-inlineBtn--action.disabled, .ec-inlineBtn--action[disabled], fieldset[disabled] .ec-inlineBtn--action {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
  color: #fff;
  background-color: #d33828;
  border-color: #93271c;
}
.ec-inlineBtn--action:hover {
  color: #fff;
  background-color: #d33828;
  border-color: #cb3526;
}
.ec-inlineBtn--action:active, .ec-inlineBtn--action.active, .open > .ec-inlineBtn--action.dropdown-toggle {
  color: #fff;
  background-color: #d33828;
  background-image: none;
  border-color: #cb3526;
}
.ec-inlineBtn--action:active:hover, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:hover, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus, .open > .ec-inlineBtn--action.dropdown-toggle:hover, .open > .ec-inlineBtn--action.dropdown-toggle:focus, .open > .ec-inlineBtn--action.dropdown-toggle.focus {
  color: #fff;
  background-color: #b53022;
  border-color: #93271c;
}
.ec-inlineBtn--action.disabled:hover, .ec-inlineBtn--action.disabled:focus, .ec-inlineBtn--action.disabled.focus, .ec-inlineBtn--action[disabled]:hover, .ec-inlineBtn--action[disabled]:focus, .ec-inlineBtn--action[disabled].focus, fieldset[disabled] .ec-inlineBtn--action:hover, fieldset[disabled] .ec-inlineBtn--action:focus, fieldset[disabled] .ec-inlineBtn--action.focus {
  background-color: #DE5D50;
  border-color: #DE5D50;
}
.ec-inlineBtn--action .badge {
  color: #DE5D50;
  background-color: #fff;
}
.ec-inlineBtn--action .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

.ec-inlineBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #525263;
  border-color: #525263;
}
.ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn--cancel:hover, .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
  color: #525263;
  text-decoration: none;
}
.ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-inlineBtn--cancel.disabled, .ec-inlineBtn--cancel[disabled], fieldset[disabled] .ec-inlineBtn--cancel {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
  color: #fff;
  background-color: #3b3b47;
  border-color: #18181d;
}
.ec-inlineBtn--cancel:hover {
  color: #fff;
  background-color: #3b3b47;
  border-color: #363642;
}
.ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active, .open > .ec-inlineBtn--cancel.dropdown-toggle {
  color: #fff;
  background-color: #3b3b47;
  background-image: none;
  border-color: #363642;
}
.ec-inlineBtn--cancel:active:hover, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:hover, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus, .open > .ec-inlineBtn--cancel.dropdown-toggle:hover, .open > .ec-inlineBtn--cancel.dropdown-toggle:focus, .open > .ec-inlineBtn--cancel.dropdown-toggle.focus {
  color: #fff;
  background-color: #2b2b34;
  border-color: #18181d;
}
.ec-inlineBtn--cancel.disabled:hover, .ec-inlineBtn--cancel.disabled:focus, .ec-inlineBtn--cancel.disabled.focus, .ec-inlineBtn--cancel[disabled]:hover, .ec-inlineBtn--cancel[disabled]:focus, .ec-inlineBtn--cancel[disabled].focus, fieldset[disabled] .ec-inlineBtn--cancel:hover, fieldset[disabled] .ec-inlineBtn--cancel:focus, fieldset[disabled] .ec-inlineBtn--cancel.focus {
  background-color: #525263;
  border-color: #525263;
}
.ec-inlineBtn--cancel .badge {
  color: #525263;
  background-color: #fff;
}
.ec-inlineBtn--cancel .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #525263;
  background-color: #F5F7F8;
  border-color: #ccc;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn:focus, .ec-blockBtn.focus, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn:hover, .ec-blockBtn:focus, .ec-blockBtn.focus {
  color: #525263;
  text-decoration: none;
}
.ec-blockBtn:active, .ec-blockBtn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-blockBtn.disabled, .ec-blockBtn[disabled], fieldset[disabled] .ec-blockBtn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-blockBtn:focus, .ec-blockBtn.focus {
  color: #525263;
  background-color: #d7dfe3;
  border-color: #8c8c8c;
}
.ec-blockBtn:hover {
  color: #525263;
  background-color: #d7dfe3;
  border-color: #adadad;
}
.ec-blockBtn:active, .ec-blockBtn.active, .open > .ec-blockBtn.dropdown-toggle {
  color: #525263;
  background-color: #d7dfe3;
  background-image: none;
  border-color: #adadad;
}
.ec-blockBtn:active:hover, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:hover, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus, .open > .ec-blockBtn.dropdown-toggle:hover, .open > .ec-blockBtn.dropdown-toggle:focus, .open > .ec-blockBtn.dropdown-toggle.focus {
  color: #525263;
  background-color: #c2ced4;
  border-color: #8c8c8c;
}
.ec-blockBtn.disabled:hover, .ec-blockBtn.disabled:focus, .ec-blockBtn.disabled.focus, .ec-blockBtn[disabled]:hover, .ec-blockBtn[disabled]:focus, .ec-blockBtn[disabled].focus, fieldset[disabled] .ec-blockBtn:hover, fieldset[disabled] .ec-blockBtn:focus, fieldset[disabled] .ec-blockBtn.focus {
  background-color: #F5F7F8;
  border-color: #ccc;
}
.ec-blockBtn .badge {
  color: #F5F7F8;
  background-color: #525263;
}
.ec-blockBtn .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

.ec-blockBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn--primary:hover, .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
  color: #525263;
  text-decoration: none;
}
.ec-blockBtn--primary:active, .ec-blockBtn--primary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-blockBtn--primary.disabled, .ec-blockBtn--primary[disabled], fieldset[disabled] .ec-blockBtn--primary {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
  color: #fff;
  background-color: #479393;
  border-color: #2e6060;
}
.ec-blockBtn--primary:hover {
  color: #fff;
  background-color: #479393;
  border-color: #438d8d;
}
.ec-blockBtn--primary:active, .ec-blockBtn--primary.active, .open > .ec-blockBtn--primary.dropdown-toggle {
  color: #fff;
  background-color: #479393;
  background-image: none;
  border-color: #438d8d;
}
.ec-blockBtn--primary:active:hover, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:hover, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus, .open > .ec-blockBtn--primary.dropdown-toggle:hover, .open > .ec-blockBtn--primary.dropdown-toggle:focus, .open > .ec-blockBtn--primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #3b7b7b;
  border-color: #2e6060;
}
.ec-blockBtn--primary.disabled:hover, .ec-blockBtn--primary.disabled:focus, .ec-blockBtn--primary.disabled.focus, .ec-blockBtn--primary[disabled]:hover, .ec-blockBtn--primary[disabled]:focus, .ec-blockBtn--primary[disabled].focus, fieldset[disabled] .ec-blockBtn--primary:hover, fieldset[disabled] .ec-blockBtn--primary:focus, fieldset[disabled] .ec-blockBtn--primary.focus {
  background-color: #5CB1B1;
  border-color: #5CB1B1;
}
.ec-blockBtn--primary .badge {
  color: #5CB1B1;
  background-color: #fff;
}
.ec-blockBtn--primary .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

.ec-blockBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #DE5D50;
  border-color: #DE5D50;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--action:focus, .ec-blockBtn--action.focus, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn--action:hover, .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
  color: #525263;
  text-decoration: none;
}
.ec-blockBtn--action:active, .ec-blockBtn--action.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-blockBtn--action.disabled, .ec-blockBtn--action[disabled], fieldset[disabled] .ec-blockBtn--action {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
  color: #fff;
  background-color: #d33828;
  border-color: #93271c;
}
.ec-blockBtn--action:hover {
  color: #fff;
  background-color: #d33828;
  border-color: #cb3526;
}
.ec-blockBtn--action:active, .ec-blockBtn--action.active, .open > .ec-blockBtn--action.dropdown-toggle {
  color: #fff;
  background-color: #d33828;
  background-image: none;
  border-color: #cb3526;
}
.ec-blockBtn--action:active:hover, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:hover, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus, .open > .ec-blockBtn--action.dropdown-toggle:hover, .open > .ec-blockBtn--action.dropdown-toggle:focus, .open > .ec-blockBtn--action.dropdown-toggle.focus {
  color: #fff;
  background-color: #b53022;
  border-color: #93271c;
}
.ec-blockBtn--action.disabled:hover, .ec-blockBtn--action.disabled:focus, .ec-blockBtn--action.disabled.focus, .ec-blockBtn--action[disabled]:hover, .ec-blockBtn--action[disabled]:focus, .ec-blockBtn--action[disabled].focus, fieldset[disabled] .ec-blockBtn--action:hover, fieldset[disabled] .ec-blockBtn--action:focus, fieldset[disabled] .ec-blockBtn--action.focus {
  background-color: #DE5D50;
  border-color: #DE5D50;
}
.ec-blockBtn--action .badge {
  color: #DE5D50;
  background-color: #fff;
}
.ec-blockBtn--action .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

.ec-blockBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #525263;
  border-color: #525263;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn--cancel:hover, .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
  color: #525263;
  text-decoration: none;
}
.ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-blockBtn--cancel.disabled, .ec-blockBtn--cancel[disabled], fieldset[disabled] .ec-blockBtn--cancel {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
  color: #fff;
  background-color: #3b3b47;
  border-color: #18181d;
}
.ec-blockBtn--cancel:hover {
  color: #fff;
  background-color: #3b3b47;
  border-color: #363642;
}
.ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active, .open > .ec-blockBtn--cancel.dropdown-toggle {
  color: #fff;
  background-color: #3b3b47;
  background-image: none;
  border-color: #363642;
}
.ec-blockBtn--cancel:active:hover, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:hover, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus, .open > .ec-blockBtn--cancel.dropdown-toggle:hover, .open > .ec-blockBtn--cancel.dropdown-toggle:focus, .open > .ec-blockBtn--cancel.dropdown-toggle.focus {
  color: #fff;
  background-color: #2b2b34;
  border-color: #18181d;
}
.ec-blockBtn--cancel.disabled:hover, .ec-blockBtn--cancel.disabled:focus, .ec-blockBtn--cancel.disabled.focus, .ec-blockBtn--cancel[disabled]:hover, .ec-blockBtn--cancel[disabled]:focus, .ec-blockBtn--cancel[disabled].focus, fieldset[disabled] .ec-blockBtn--cancel:hover, fieldset[disabled] .ec-blockBtn--cancel:focus, fieldset[disabled] .ec-blockBtn--cancel.focus {
  background-color: #525263;
  border-color: #525263;
}
.ec-blockBtn--cancel .badge {
  color: #525263;
  background-color: #fff;
}
.ec-blockBtn--cancel .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

/*
アイコンボタン

SVGアイコンを用いたアイコンボタンです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 2.2
*/
/*
アイコンボタン

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)

Markup:
a.ec-closeBtn
  .ec-icon
    img(src='/moc/icon/cross.svg', alt='close')

Styleguide 2.2.1
*/
.ec-closeBtn {
  cursor: pointer;
}
.ec-closeBtn .ec-icon img {
  display: inline-block;
  margin-right: 5px;
  width: 1em;
  height: 1em;
  position: relative;
  top: -1px;
  vertical-align: middle;
}

/*
アイコンボタン(○)

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)



ex [お届け先編集画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/delivery)

Markup:
a.ec-closeBtn--circle
  .ec-icon
    img(src='/moc/icon/cross-white.svg', alt='close')

Styleguide 2.2.2
*/
.ec-closeBtn--circle {
  display: block;
  border: 0 none;
  padding: 0;
  margin: 0;
  text-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  background: #B8BEC4;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
.ec-closeBtn--circle .ec-icon img {
  display: block;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.3
*/
/*
ページトップボタン

ページトップボタンを表示します

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
.ec-blockTopBtn

Styleguide 2.3.1
*/
.ec-blockTopBtn {
  display: none;
  position: fixed;
  width: 120px;
  height: 40px;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  background-color: #9da3a9;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
@media only screen and (min-width: 768px) {
  .ec-blockTopBtn {
    right: 30px;
    bottom: 30px;
  }
}
.ec-blockTopBtn.pagetop .ec-gotop {
  position: relative;
  top: -3px;
}
@media only screen and (min-width: 768px) {
  .ec-blockTopBtn.pagetop .ec-gotop {
    position: unset;
    top: unset;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type=search], .ec-birth input[type=search], .ec-select input[type=search], .ec-telInput input[type=search], .ec-zipInput input[type=search], .ec-numberInput input[type=search], .ec-halfInput input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-input input[type=radio], .ec-birth input[type=radio], .ec-select input[type=radio], .ec-telInput input[type=radio], .ec-zipInput input[type=radio], .ec-numberInput input[type=radio], .ec-halfInput input[type=radio],
.ec-input input[type=checkbox],
.ec-birth input[type=checkbox],
.ec-select input[type=checkbox],
.ec-telInput input[type=checkbox],
.ec-zipInput input[type=checkbox],
.ec-numberInput input[type=checkbox],
.ec-halfInput input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}
.ec-input input[type=file], .ec-birth input[type=file], .ec-select input[type=file], .ec-telInput input[type=file], .ec-zipInput input[type=file], .ec-numberInput input[type=file], .ec-halfInput input[type=file] {
  display: block;
}
.ec-input input[type=range], .ec-birth input[type=range], .ec-select input[type=range], .ec-telInput input[type=range], .ec-zipInput input[type=range], .ec-numberInput input[type=range], .ec-halfInput input[type=range] {
  display: block;
  width: 100%;
}
.ec-input select[multiple], .ec-birth select[multiple], .ec-select select[multiple], .ec-telInput select[multiple], .ec-zipInput select[multiple], .ec-numberInput select[multiple], .ec-halfInput select[multiple],
.ec-input select[size],
.ec-birth select[size],
.ec-select select[size],
.ec-telInput select[size],
.ec-zipInput select[size],
.ec-numberInput select[size],
.ec-halfInput select[size] {
  height: auto;
}
.ec-input input[type=file]:focus, .ec-birth input[type=file]:focus, .ec-select input[type=file]:focus, .ec-telInput input[type=file]:focus, .ec-zipInput input[type=file]:focus, .ec-numberInput input[type=file]:focus, .ec-halfInput input[type=file]:focus,
.ec-input input[type=radio]:focus,
.ec-birth input[type=radio]:focus,
.ec-select input[type=radio]:focus,
.ec-telInput input[type=radio]:focus,
.ec-zipInput input[type=radio]:focus,
.ec-numberInput input[type=radio]:focus,
.ec-halfInput input[type=radio]:focus,
.ec-input input[type=checkbox]:focus,
.ec-birth input[type=checkbox]:focus,
.ec-select input[type=checkbox]:focus,
.ec-telInput input[type=checkbox]:focus,
.ec-zipInput input[type=checkbox]:focus,
.ec-numberInput input[type=checkbox]:focus,
.ec-halfInput input[type=checkbox]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
.ec-input input:focus, .ec-birth input:focus, .ec-select input:focus, .ec-telInput input:focus, .ec-zipInput input:focus, .ec-numberInput input:focus, .ec-halfInput input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ec-input input::-moz-placeholder, .ec-birth input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-halfInput input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder {
  color: #999;
}
.ec-input input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder {
  color: #999;
}
.ec-input input::-ms-expand, .ec-birth input::-ms-expand, .ec-select input::-ms-expand, .ec-telInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-halfInput input::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input input[disabled], .ec-birth input[disabled], .ec-select input[disabled], .ec-telInput input[disabled], .ec-zipInput input[disabled], .ec-numberInput input[disabled], .ec-halfInput input[disabled], .ec-input input[readonly], .ec-birth input[readonly], .ec-select input[readonly], .ec-telInput input[readonly], .ec-zipInput input[readonly], .ec-numberInput input[readonly], .ec-halfInput input[readonly], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-birth input, fieldset[disabled] .ec-select input, fieldset[disabled] .ec-telInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-halfInput input {
  background-color: #eeeeee;
  opacity: 1;
}
.ec-input input[disabled], .ec-birth input[disabled], .ec-select input[disabled], .ec-telInput input[disabled], .ec-zipInput input[disabled], .ec-numberInput input[disabled], .ec-halfInput input[disabled], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-birth input, fieldset[disabled] .ec-select input, fieldset[disabled] .ec-telInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-halfInput input {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
    font-size: 14px;
  }
}
.ec-input select, .ec-birth select, .ec-select select, .ec-telInput select, .ec-zipInput select, .ec-numberInput select, .ec-halfInput select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
.ec-input select:focus, .ec-birth select:focus, .ec-select select:focus, .ec-telInput select:focus, .ec-zipInput select:focus, .ec-numberInput select:focus, .ec-halfInput select:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ec-input select::-moz-placeholder, .ec-birth select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-telInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-halfInput select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder {
  color: #999;
}
.ec-input select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder {
  color: #999;
}
.ec-input select::-ms-expand, .ec-birth select::-ms-expand, .ec-select select::-ms-expand, .ec-telInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-halfInput select::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input select[disabled], .ec-birth select[disabled], .ec-select select[disabled], .ec-telInput select[disabled], .ec-zipInput select[disabled], .ec-numberInput select[disabled], .ec-halfInput select[disabled], .ec-input select[readonly], .ec-birth select[readonly], .ec-select select[readonly], .ec-telInput select[readonly], .ec-zipInput select[readonly], .ec-numberInput select[readonly], .ec-halfInput select[readonly], fieldset[disabled] .ec-input select, fieldset[disabled] .ec-birth select, fieldset[disabled] .ec-select select, fieldset[disabled] .ec-telInput select, fieldset[disabled] .ec-zipInput select, fieldset[disabled] .ec-numberInput select, fieldset[disabled] .ec-halfInput select {
  background-color: #eeeeee;
  opacity: 1;
}
.ec-input select[disabled], .ec-birth select[disabled], .ec-select select[disabled], .ec-telInput select[disabled], .ec-zipInput select[disabled], .ec-numberInput select[disabled], .ec-halfInput select[disabled], fieldset[disabled] .ec-input select, fieldset[disabled] .ec-birth select, fieldset[disabled] .ec-select select, fieldset[disabled] .ec-telInput select, fieldset[disabled] .ec-zipInput select, fieldset[disabled] .ec-numberInput select, fieldset[disabled] .ec-halfInput select {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input select, .ec-birth select, .ec-select select, .ec-telInput select, .ec-zipInput select, .ec-numberInput select, .ec-halfInput select {
    font-size: 14px;
  }
}
.ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
.ec-input textarea:focus, .ec-birth textarea:focus, .ec-select textarea:focus, .ec-telInput textarea:focus, .ec-zipInput textarea:focus, .ec-numberInput textarea:focus, .ec-halfInput textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ec-input textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder {
  color: #999;
}
.ec-input textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder {
  color: #999;
}
.ec-input textarea::-ms-expand, .ec-birth textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-halfInput textarea::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input textarea[disabled], .ec-birth textarea[disabled], .ec-select textarea[disabled], .ec-telInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-halfInput textarea[disabled], .ec-input textarea[readonly], .ec-birth textarea[readonly], .ec-select textarea[readonly], .ec-telInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-halfInput textarea[readonly], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-birth textarea, fieldset[disabled] .ec-select textarea, fieldset[disabled] .ec-telInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-halfInput textarea {
  background-color: #eeeeee;
  opacity: 1;
}
.ec-input textarea[disabled], .ec-birth textarea[disabled], .ec-select textarea[disabled], .ec-telInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-halfInput textarea[disabled], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-birth textarea, fieldset[disabled] .ec-select textarea, fieldset[disabled] .ec-telInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-halfInput textarea {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
    font-size: 14px;
  }
}
.ec-input input:focus, .ec-birth input:focus, .ec-select input:focus, .ec-telInput input:focus, .ec-zipInput input:focus, .ec-numberInput input:focus, .ec-halfInput input:focus, .ec-input textarea:focus, .ec-birth textarea:focus, .ec-select textarea:focus, .ec-telInput textarea:focus, .ec-zipInput textarea:focus, .ec-numberInput textarea:focus, .ec-halfInput textarea:focus {
  box-shadow: none;
  border-color: #3c8dbc;
}
.ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
  height: 40px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
    margin-bottom: 16px;
  }
}
.ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
  height: auto;
  min-height: 100px;
}
.ec-input p, .ec-birth p, .ec-select p, .ec-telInput p, .ec-zipInput p, .ec-numberInput p, .ec-halfInput p {
  line-height: 1.4;
}
.ec-input .ec-errorMessage, .ec-birth .ec-errorMessage, .ec-select .ec-errorMessage, .ec-telInput .ec-errorMessage, .ec-zipInput .ec-errorMessage, .ec-numberInput .ec-errorMessage, .ec-halfInput .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50;
}

.error.ec-input input, .error.ec-birth input, .error.ec-select input, .error.ec-telInput input, .error.ec-zipInput input, .error.ec-numberInput input, .error.ec-halfInput input, .error.ec-input select, .error.ec-birth select, .error.ec-select select, .error.ec-telInput select, .error.ec-zipInput select, .error.ec-numberInput select, .error.ec-halfInput select, .error.ec-input textarea, .error.ec-birth textarea, .error.ec-select textarea, .error.ec-telInput textarea, .error.ec-zipInput textarea, .error.ec-numberInput textarea, .error.ec-halfInput textarea {
  margin-bottom: 5px;
  border-color: #CF3F34;
  background: #FDF1F0;
}

.ec-checkbox .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50;
}

.error.ec-checkbox input, .error.ec-checkbox label {
  border-color: #CF3F34;
  background: #FDF1F0;
}

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type=text] {
  display: inline-block;
  width: 47%;
  margin-left: 2%;
}
@media only screen and (min-width: 768px) {
  .ec-halfInput input[type=text] {
    margin-left: 15px;
    width: 45%;
  }
}
.ec-halfInput input[type=text]:first-child {
  margin-left: 0;
}

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type=number] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right;
}

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block;
}
.ec-zipInput input {
  display: inline-block;
  text-align: left;
  width: auto;
  max-width: 8em;
  font-size: 16px;
}
.ec-zipInput span {
  display: inline-block;
  padding: 0 5px 0 3px;
  margin-left: 5px;
}

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0;
}
.ec-zipInputHelp .ec-zipInputHelp__icon {
  display: inline-block;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background: #525263;
  border-radius: 50%;
  font-size: 13px;
  position: relative;
  top: -6px;
}
.ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon img {
  width: 1em;
  height: 1em;
  position: relative;
  left: 3px;
  top: 3px;
}
.ec-zipInputHelp span {
  margin-left: 8px;
  display: inline-block;
  color: #0092C4;
  vertical-align: 3px;
}

.ec-zipAuto {
  margin-bottom: 16px;
}
.ec-zipAuto .ec-inlineBtn {
  font-weight: normal;
}

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 10em;
  text-align: left;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type=search], .ec-birth input[type=search], .ec-select input[type=search], .ec-halfInput input[type=search], .ec-numberInput input[type=search], .ec-zipInput input[type=search], .ec-telInput input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-input input[type=radio], .ec-birth input[type=radio], .ec-select input[type=radio], .ec-halfInput input[type=radio], .ec-numberInput input[type=radio], .ec-zipInput input[type=radio], .ec-telInput input[type=radio],
.ec-input input[type=checkbox],
.ec-birth input[type=checkbox],
.ec-select input[type=checkbox],
.ec-halfInput input[type=checkbox],
.ec-numberInput input[type=checkbox],
.ec-zipInput input[type=checkbox],
.ec-telInput input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}
.ec-input input[type=file], .ec-birth input[type=file], .ec-select input[type=file], .ec-halfInput input[type=file], .ec-numberInput input[type=file], .ec-zipInput input[type=file], .ec-telInput input[type=file] {
  display: block;
}
.ec-input input[type=range], .ec-birth input[type=range], .ec-select input[type=range], .ec-halfInput input[type=range], .ec-numberInput input[type=range], .ec-zipInput input[type=range], .ec-telInput input[type=range] {
  display: block;
  width: 100%;
}
.ec-input select[multiple], .ec-birth select[multiple], .ec-select select[multiple], .ec-halfInput select[multiple], .ec-numberInput select[multiple], .ec-zipInput select[multiple], .ec-telInput select[multiple],
.ec-input select[size],
.ec-birth select[size],
.ec-select select[size],
.ec-halfInput select[size],
.ec-numberInput select[size],
.ec-zipInput select[size],
.ec-telInput select[size] {
  height: auto;
}
.ec-input input[type=file]:focus, .ec-birth input[type=file]:focus, .ec-select input[type=file]:focus, .ec-halfInput input[type=file]:focus, .ec-numberInput input[type=file]:focus, .ec-zipInput input[type=file]:focus, .ec-telInput input[type=file]:focus,
.ec-input input[type=radio]:focus,
.ec-birth input[type=radio]:focus,
.ec-select input[type=radio]:focus,
.ec-halfInput input[type=radio]:focus,
.ec-numberInput input[type=radio]:focus,
.ec-zipInput input[type=radio]:focus,
.ec-telInput input[type=radio]:focus,
.ec-input input[type=checkbox]:focus,
.ec-birth input[type=checkbox]:focus,
.ec-select input[type=checkbox]:focus,
.ec-halfInput input[type=checkbox]:focus,
.ec-numberInput input[type=checkbox]:focus,
.ec-zipInput input[type=checkbox]:focus,
.ec-telInput input[type=checkbox]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
.ec-input input:focus, .ec-birth input:focus, .ec-select input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ec-input input::-moz-placeholder, .ec-birth input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder {
  color: #999;
}
.ec-input input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder {
  color: #999;
}
.ec-input input::-ms-expand, .ec-birth input::-ms-expand, .ec-select input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input input[disabled], .ec-birth input[disabled], .ec-select input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-input input[readonly], .ec-birth input[readonly], .ec-select input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-birth input, fieldset[disabled] .ec-select input, fieldset[disabled] .ec-halfInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-telInput input {
  background-color: #eeeeee;
  opacity: 1;
}
.ec-input input[disabled], .ec-birth input[disabled], .ec-select input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-birth input, fieldset[disabled] .ec-select input, fieldset[disabled] .ec-halfInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-telInput input {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
    font-size: 14px;
  }
}
.ec-input select, .ec-birth select, .ec-select select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
.ec-input select:focus, .ec-birth select:focus, .ec-select select:focus, .ec-halfInput select:focus, .ec-numberInput select:focus, .ec-zipInput select:focus, .ec-telInput select:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ec-input select::-moz-placeholder, .ec-birth select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-halfInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-telInput select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder {
  color: #999;
}
.ec-input select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder {
  color: #999;
}
.ec-input select::-ms-expand, .ec-birth select::-ms-expand, .ec-select select::-ms-expand, .ec-halfInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-telInput select::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input select[disabled], .ec-birth select[disabled], .ec-select select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-input select[readonly], .ec-birth select[readonly], .ec-select select[readonly], .ec-halfInput select[readonly], .ec-numberInput select[readonly], .ec-zipInput select[readonly], .ec-telInput select[readonly], fieldset[disabled] .ec-input select, fieldset[disabled] .ec-birth select, fieldset[disabled] .ec-select select, fieldset[disabled] .ec-halfInput select, fieldset[disabled] .ec-numberInput select, fieldset[disabled] .ec-zipInput select, fieldset[disabled] .ec-telInput select {
  background-color: #eeeeee;
  opacity: 1;
}
.ec-input select[disabled], .ec-birth select[disabled], .ec-select select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], fieldset[disabled] .ec-input select, fieldset[disabled] .ec-birth select, fieldset[disabled] .ec-select select, fieldset[disabled] .ec-halfInput select, fieldset[disabled] .ec-numberInput select, fieldset[disabled] .ec-zipInput select, fieldset[disabled] .ec-telInput select {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input select, .ec-birth select, .ec-select select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select {
    font-size: 14px;
  }
}
.ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
.ec-input textarea:focus, .ec-birth textarea:focus, .ec-select textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ec-input textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder {
  color: #999;
}
.ec-input textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder {
  color: #999;
}
.ec-input textarea::-ms-expand, .ec-birth textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input textarea[disabled], .ec-birth textarea[disabled], .ec-select textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-input textarea[readonly], .ec-birth textarea[readonly], .ec-select textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-birth textarea, fieldset[disabled] .ec-select textarea, fieldset[disabled] .ec-halfInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-telInput textarea {
  background-color: #eeeeee;
  opacity: 1;
}
.ec-input textarea[disabled], .ec-birth textarea[disabled], .ec-select textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-birth textarea, fieldset[disabled] .ec-select textarea, fieldset[disabled] .ec-halfInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-telInput textarea {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea {
    font-size: 14px;
  }
}
.ec-input input:focus, .ec-birth input:focus, .ec-select input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-input textarea:focus, .ec-birth textarea:focus, .ec-select textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus {
  box-shadow: none;
  border-color: #3c8dbc;
}
.ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
  height: 40px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
    margin-bottom: 16px;
  }
}
.ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea {
  height: auto;
  min-height: 100px;
}
.ec-input p, .ec-birth p, .ec-select p, .ec-halfInput p, .ec-numberInput p, .ec-zipInput p, .ec-telInput p {
  line-height: 1.4;
}
.ec-input .ec-errorMessage, .ec-birth .ec-errorMessage, .ec-select .ec-errorMessage, .ec-halfInput .ec-errorMessage, .ec-numberInput .ec-errorMessage, .ec-zipInput .ec-errorMessage, .ec-telInput .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50;
}

.error.ec-input input, .error.ec-birth input, .error.ec-select input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .error.ec-input select, .error.ec-birth select, .error.ec-select select, .error.ec-halfInput select, .error.ec-numberInput select, .error.ec-zipInput select, .error.ec-telInput select, .error.ec-input textarea, .error.ec-birth textarea, .error.ec-select textarea, .error.ec-halfInput textarea, .error.ec-numberInput textarea, .error.ec-zipInput textarea, .error.ec-telInput textarea {
  margin-bottom: 5px;
  border-color: #CF3F34;
  background: #FDF1F0;
}

.ec-checkbox .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50;
}

.error.ec-checkbox input, .error.ec-checkbox label {
  border-color: #CF3F34;
  background: #FDF1F0;
}

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type=text] {
  display: inline-block;
  width: 47%;
  margin-left: 2%;
}
@media only screen and (min-width: 768px) {
  .ec-halfInput input[type=text] {
    margin-left: 15px;
    width: 45%;
  }
}
.ec-halfInput input[type=text]:first-child {
  margin-left: 0;
}

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type=number] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right;
}

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block;
}
.ec-zipInput input {
  display: inline-block;
  text-align: left;
  width: auto;
  max-width: 8em;
  font-size: 16px;
}
.ec-zipInput span {
  display: inline-block;
  padding: 0 5px 0 3px;
  margin-left: 5px;
}

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0;
}
.ec-zipInputHelp .ec-zipInputHelp__icon {
  display: inline-block;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background: #525263;
  border-radius: 50%;
  font-size: 13px;
  position: relative;
  top: -6px;
}
.ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon img {
  width: 1em;
  height: 1em;
  position: relative;
  left: 3px;
  top: 3px;
}
.ec-zipInputHelp span {
  margin-left: 8px;
  display: inline-block;
  color: #0092C4;
  vertical-align: 3px;
}

.ec-zipAuto {
  margin-bottom: 16px;
}
.ec-zipAuto .ec-inlineBtn {
  font-weight: normal;
}

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 10em;
  text-align: left;
}

/*
フォーム部品(その他)

フォーム部品でテキストの入力以外の動作要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 3.2
*/
/*
ラジオ（水平）

水平に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　性別選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-radio
  label
    input(type="radio")
    span 男性
  label
    input(type="radio")
    span 女性

Styleguide 3.2.2
*/
.ec-radio label {
  margin-right: 20px;
}
.ec-radio input {
  margin-right: 10px;
  margin-bottom: 10px;
}
.ec-radio span {
  font-weight: normal;
}

/*
ラジオ(垂直)

垂直に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [購入画面 お支払方法](http://demo3.ec-cube.net/shopping)

Markup:
.ec-blockRadio
  label
    input(type="radio")
    span 郵便振替
  label
    input(type="radio")
    span 現金書留
  label
    input(type="radio")
    span 銀行振込
  label
    input(type="radio")
    span 代金引換

Styleguide 3.2.3
*/
.ec-blockRadio label {
  display: block;
}
.ec-blockRadio span {
  padding-left: 10px;
  font-weight: normal;
}

/*
セレクトボックス

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　都道府県選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-select
  select
    option 都道府県を選択
    option 北海道
    option 青森県
    option 岩手県
    option ...
.ec-select
  select
    option 選択して下さい
    option 公務員
    option コンサルタント
    option コンピュータ関連技術職
    option コンピュータ関連以外の技術職
    option ...

Styleguide 3.2.4
*/
.ec-selects {
  margin-bottom: 20px;
  border-bottom: 1px dotted #ccc;
}

.ec-select {
  margin-bottom: 16px;
}
.ec-select select {
  display: inline-block;
  width: auto;
  max-width: 100%;
  background-color: #f8f8f8;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}
.ec-select select:focus {
  box-shadow: none;
}
.ec-select label {
  margin-right: 10px;
  font-weight: bold;
}
.ec-select label:nth-child(3) {
  margin-left: 10px;
  font-weight: bold;
}

.ec-select__delivery {
  display: block;
  margin-right: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-select__delivery {
    display: inline-block;
  }
}

.ec-select__time {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-select__time {
    display: inline-block;
  }
}

/*
生年月日選択

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　生年月日選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-birth
  select
    option ----
    option 1960
    option 1961
    option 1962
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...

Styleguide 3.2.5
*/
.ec-birth select {
  display: inline-block;
  width: auto;
  margin: 0 0 10px;
  background-color: #f8f8f8;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}
.ec-birth select:focus {
  box-shadow: none;
}
@media only screen and (min-width: 768px) {
  .ec-birth select {
    margin: 0 8px 10px;
  }
}
.ec-birth span {
  margin-left: 5px;
}

/*
チェックボックス （水平）

水平に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　利用規約](http://demo3.ec-cube.net/entry)

Markup:
.ec-checkbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.6
*/
.ec-checkbox label {
  display: inline-block;
}
.ec-checkbox input {
  margin-bottom: 10px;
}
.ec-checkbox span {
  font-weight: normal;
}

/*
チェックボックス (垂直)

垂直に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

Markup:
.ec-blockCheckbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.7
*/
.ec-blockCheckbox label {
  display: block;
}
.ec-blockCheckbox span {
  font-weight: normal;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォームラベル

フォームのラベルに関する要素を定義します。

sg-wrapper:
<div class="ec-registerRole">
  <div class="ec-off1Grid">
    <div class="ec-off1Grid__cell">
      <div class="ec-borderedDefs">
        <sg-wrapper-content/>
      </div>
    </div>
  </div>
</div>

Styleguide 3.3
*/
/*
ラベル

フォーム要素で利用するラベル要素です。

ex [お問い合わせページ　ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.1
*/
.ec-label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 5px;
}

/*
必須ラベル

必須文字を表示するラベル要素です。

ex [お問い合わせページ　必須ラベル部分](http://demo3.ec-cube.net/contact)


Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
        span.ec-required 必須
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.2
*/
.ec-required {
  display: inline-block;
  margin-left: 0.8em;
  vertical-align: 2px;
  color: #DE5D50;
  font-size: 12px;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-required {
    margin-left: 1em;
  }
}

/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.1
*/
/*
2分割グリッド

画面 ２分割の　グリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。

Markup:
.ec-grid2
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1.1
*/
.ec-grid2 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid2 {
    display: flex;
  }
}
.ec-grid2 .ec-grid2__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid2 .ec-grid2__cell {
    width: 50%;
  }
}
.ec-grid2 .ec-grid2__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid2 .ec-grid2__cell2 {
    width: 100%;
  }
}
/*
3分割グリッド

画面　３分割の　グリッドです。


Markup:
.ec-grid3
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell

Styleguide 5.1.2
*/
.ec-grid3 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid3 {
    display: flex;
  }
}
.ec-grid3 .ec-grid3__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell {
    width: 33.3333333333%;
  }
}
.ec-grid3 .ec-grid3__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell2 {
    width: 66.6666666667%;
  }
}
.ec-grid3 .ec-grid3__cell3 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell3 {
    width: 100%;
  }
}
/*
4分割グリッド

画面　４分割の　グリッドです。


Markup:
.ec-grid4
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell

Styleguide 5.1.3
*/
.ec-grid4 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid4 {
    display: flex;
  }
}
.ec-grid4 .ec-grid4__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid4 .ec-grid4__cell {
    width: 25%;
  }
}
/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。


Markup:
.ec-grid6
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
.ec-grid6
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
.ec-grid6
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3

Styleguide 5.1.4
*/
.ec-grid6 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid6 {
    display: flex;
  }
}
.ec-grid6 .ec-grid6__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell {
    width: 16.6666666667%;
  }
}
.ec-grid6 .ec-grid6__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell2 {
    width: 33.3333333333%;
  }
}
.ec-grid6 .ec-grid6__cell3 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell3 {
    width: 50%;
  }
}
/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の１０グリッドです

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off1Grid
  .ec-off1Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.5
*/
.ec-off1Grid {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off1Grid {
    display: block;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off1Grid {
    display: flex;
  }
}
.ec-off1Grid .ec-off1Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off1Grid .ec-off1Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 8.3333333333%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off1Grid .ec-off1Grid__cell {
    width: 83.3333333333%;
  }
}
/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の８グリッドです


Markup:
.ec-off2Grid
  .ec-off2Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.6
*/
.ec-off2Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off2Grid {
    display: flex;
  }
}
.ec-off2Grid .ec-off2Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off2Grid .ec-off2Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 16.6666666667%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off2Grid .ec-off2Grid__cell {
    width: 66.6666666667%;
  }
}
/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の６グリッドです


Markup:
.ec-off3Grid
  .ec-off3Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.7
*/
.ec-off3Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off3Grid {
    display: flex;
  }
}
.ec-off3Grid .ec-off3Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off3Grid .ec-off3Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 25%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off3Grid .ec-off3Grid__cell {
    width: 50%;
  }
}
/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の４グリッドです


Markup:
.ec-off4Grid
  .ec-off4Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.8
*/
.ec-off4Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off4Grid {
    display: flex;
  }
}
.ec-off4Grid .ec-off4Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off4Grid .ec-off4Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 33.3333333333%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off4Grid .ec-off4Grid__cell {
    width: 33.3333333333%;
  }
}
/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 5.1.9
*/
/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--left
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start;
}

/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--right
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end;
}

/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--center
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。

Styleguide 5.2
*/
/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [注文履歴 ログイン後→注文履歴ボタンを押下](http://demo3.ec-cube.net/mypage)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
}
.ec-imageGrid .ec-imageGrid__img {
  display: table-cell;
  padding: 10px;
  width: 100px;
}
@media only screen and (min-width: 768px) {
  .ec-imageGrid .ec-imageGrid__img {
    padding: 10px;
    width: 130px;
  }
}
.ec-imageGrid .ec-imageGrid__img img {
  width: 100%;
}
.ec-imageGrid .ec-imageGrid__content {
  vertical-align: middle;
  display: table-cell;
}
.ec-imageGrid .ec-imageGrid__content span {
  margin-left: 10px;
}
.ec-imageGrid .ec-imageGrid__content p {
  margin-bottom: 0;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/
/*
ログインフォーム

ログインフォームを表示します。

ex [ログイン画面](http://demo3.ec-cube.net/mypage/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login


Styleguide 6.1.1
*/
.ec-login {
  margin: 48px 0 0;
  padding: 30px 13% 20px;
  height: auto;
  background: #F3F4F4;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .ec-login {
    margin: 64px 16px 0;
    padding: 30px 13% 60px;
  }
}
.ec-login .ec-login__icon {
  text-align: center;
}
.ec-login .ec-icon {
  margin-bottom: 10px;
}
.ec-login .ec-icon img {
  width: 90px;
  height: 90px;
  display: inline-block;
}
.ec-login .ec-login__input {
  margin-bottom: 40px;
}
.ec-login .ec-login__input .ec-checkbox span {
  margin-left: 5px;
  font-weight: normal;
}
.ec-login .ec-login__actions {
  color: #fff;
}
.ec-login .ec-login__actions a {
  color: inherit;
  text-decoration: none;
}
.ec-login .ec-login__actions a:hover {
  text-decoration: none;
}
.ec-login .ec-login__link {
  margin-top: 5px;
  margin-left: 0;
}
@media only screen and (min-width: 768px) {
  .ec-login .ec-login__link {
    margin-left: 20px;
  }
}
.ec-login .ec-errorMessage {
  color: #DE5D50;
  margin-bottom: 20px;
}

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

ex [ゲスト購入画面](http://demo3.ec-cube.net/shopping/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  margin: 0;
  padding: 13%;
  height: auto;
  box-sizing: border-box;
  background: #F3F4F4;
}
@media only screen and (min-width: 768px) {
  .ec-guest {
    height: 100%;
    margin: 0 16px;
  }
}
.ec-guest .ec-guest__inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.ec-guest .ec-guest__inner p {
  margin-bottom: 16px;
}
.ec-guest .ec-guest__actions {
  display: block;
  vertical-align: middle;
  text-align: center;
  color: #fff;
}
.ec-guest .ec-guest__actions a {
  color: inherit;
  text-decoration: none;
}
.ec-guest .ec-guest__actions a:hover {
  text-decoration: none;
}
.ec-guest .ec-guest__icon {
  font-size: 70px;
  text-align: center;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品掲載

トップページに商品掲載するスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.1
*/
/*
商品アイテム（商品紹介B）

３項目横並びの商品アイテムを表示します。
必要に応じて商品詳細や、キャッチコピーなどを添えることが出来ます。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayB

Styleguide 7.1.1
*/
.ec-displayB {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .ec-displayB {
    flex-direction: row;
  }
}
.ec-displayB .ec-displayB__cell {
  width: 100%;
  margin-bottom: 16px;
}
.ec-displayB .ec-displayB__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayB .ec-displayB__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayB .ec-displayB__cell {
    width: 31.4466%;
    margin-bottom: 0;
  }
}
.ec-displayB .ec-displayB__cell:hover {
  text-decoration: none;
}
.ec-displayB .ec-displayB__cell:hover img {
  opacity: 0.8;
}
.ec-displayB .ec-displayB__cell:hover a {
  text-decoration: none;
}
.ec-displayB .ec-displayB__img {
  margin-bottom: 15px;
}
.ec-displayB .ec-displayB__catch {
  margin-bottom: 15px;
  text-decoration: none;
  font-weight: bold;
  color: #9a947e;
}
.ec-displayB .ec-displayB__comment {
  margin-bottom: 14px;
  text-decoration: none;
  color: #525263;
  font-size: 14px;
}
.ec-displayB .ec-displayB__link {
  text-decoration: none;
  font-weight: bold;
  color: #9a947e;
}

/*
商品アイテム（商品紹介C）

４項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayC
p hoge

Styleguide 7.1.2
*/
.ec-displayC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
}
.ec-displayC .ec-displayC__cell {
  width: 47%;
}
.ec-displayC .ec-displayC__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayC .ec-displayC__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayC .ec-displayC__cell {
    width: 22.8775%;
  }
}
.ec-displayC .ec-displayC__cell:hover a {
  text-decoration: none;
}
.ec-displayC .ec-displayC__cell:hover img {
  opacity: 0.8;
}
.ec-displayC .ec-displayC__img {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.ec-displayC .ec-displayC__catch {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #9a947e;
}
.ec-displayC .ec-displayC__title {
  display: block;
  width: 100%;
  color: #525263;
}
.ec-displayC .ec-displayC__price {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #525263;
}
.ec-displayC .ec-displayC__price--sp {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #DE5D50;
}

/*
商品アイテム（商品紹介D）

６項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayD

Styleguide 7.1.3
*/
.ec-displayD {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}
@media only screen and (min-width: 768px) {
  .ec-displayD {
    box-sizing: border-box;
    flex-wrap: nowrap;
  }
}
.ec-displayD .ec-displayD__cell {
  width: 30%;
  margin-bottom: 8px;
}
.ec-displayD .ec-displayD__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayD .ec-displayD__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayD .ec-displayD__cell {
    width: 14.3083%;
    margin-bottom: 16px;
  }
}
.ec-displayD .ec-displayD__cell:hover {
  text-decoration: none;
}
.ec-displayD .ec-displayD__cell:hover img {
  opacity: 0.8;
}
.ec-displayD .ec-displayD__img {
  display: block;
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/
/*
トピックパス

検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-topicpath {
  letter-spacing: -0.4em;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px dotted #ccc;
  padding: 10px;
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  color: #0092C4;
}
@media only screen and (min-width: 768px) {
  .ec-topicpath {
    padding: 30px 0 10px;
    border: 0;
    font-size: 16px;
  }
}
.ec-topicpath .ec-topicpath__item a {
  color: inherit;
  text-decoration: none;
}
.ec-topicpath .ec-topicpath__item a:hover {
  text-decoration: none;
}
.ec-topicpath .ec-topicpath__divider {
  color: #000;
}
.ec-topicpath .ec-topicpath__item, .ec-topicpath .ec-topicpath__divider, .ec-topicpath .ec-topicpath__item--active {
  display: inline-block;
  min-width: 16px;
  text-align: center;
  position: relative;
  letter-spacing: normal;
}
.ec-topicpath .ec-topicpath__item--active {
  font-weight: bold;
}
.ec-topicpath .ec-topicpath__item--active a {
  color: inherit;
  text-decoration: none;
}
.ec-topicpath .ec-topicpath__item--active a:hover {
  text-decoration: none;
}

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager {
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 1em 0;
  text-align: center;
}
.ec-pager .ec-pager__item, .ec-pager .ec-pager__item--active {
  display: inline-block;
  min-width: 29px;
  padding: 0 3px 0 2px;
  text-align: center;
  position: relative;
}
.ec-pager .ec-pager__item a, .ec-pager .ec-pager__item--active a {
  color: inherit;
  text-decoration: none;
}
.ec-pager .ec-pager__item a:hover, .ec-pager .ec-pager__item--active a:hover {
  text-decoration: none;
}
.ec-pager .ec-pager__item a, .ec-pager .ec-pager__item--active a {
  color: inherit;
  display: block;
  line-height: 1.8;
  padding: 5px 1em;
  text-decoration: none;
}
.ec-pager .ec-pager__item a:hover, .ec-pager .ec-pager__item--active a:hover {
  color: inherit;
}
.ec-pager .ec-pager__item--active {
  background: #F3F3F3;
}
.ec-pager .ec-pager__item:hover {
  background: #F3F3F3;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/
/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress {
  margin: 0 auto;
  padding: 8px 0 16px;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 600px;
  list-style: none;
}
@media only screen and (min-width: 768px) {
  .ec-progress {
    margin-bottom: 30px;
    padding: 0;
  }
}
.ec-progress .ec-progress__item {
  display: table-cell;
  position: relative;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  z-index: 10;
}
.ec-progress .ec-progress__item:after {
  content: "";
  position: absolute;
  display: block;
  background: #525263;
  width: 100%;
  height: 0.25em;
  top: 1.25em;
  left: 50%;
  margin-left: 1.5em\9 ;
  z-index: -1;
}
.ec-progress .ec-progress__item:last-child:after {
  display: none;
}
.ec-progress .ec-progress__number {
  line-height: 30px;
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  font-size: 12px;
  background: #525263;
  color: #fff;
  top: 0;
  left: 18px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
}
@media only screen and (min-width: 768px) {
  .ec-progress .ec-progress__number {
    line-height: 42px;
    width: 42px;
    height: 42px;
    font-size: 20px;
  }
}
.ec-progress .ec-progress__label {
  font-size: 12px;
}
.ec-progress .is-complete .ec-progress__number {
  background: #5CB1B1;
}
.ec-progress .is-complete .ec-progress__label {
  color: #5CB1B1;
}

/*
カートナビゲーション

カートナビゲーションを表示します。　カートに追加された商品の個数も表示します。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
@media only screen and (min-width: 768px) {
  .ec-cartNaviWrap {
    position: relative;
  }
}

.ec-cartNavi {
  display: inline-block;
  padding: 10px 0 0 20px;
  width: auto;
  color: black;
  background: transparent;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi {
    display: flex;
    justify-content: space-between;
    border-radius: 99999px;
    box-sizing: border-box;
    padding: 12px 17px 10px;
    width: auto;
    min-width: 140px;
    height: 44px;
    white-space: nowrap;
    cursor: pointer;
    background: #F8F8F8;
  }
}
.ec-cartNavi .ec-cartNavi__icon {
  display: inline-block;
  font-size: 20px;
  display: inline-block;
  opacity: 1;
  visibility: visible;
  animation: fadeIn 200ms linear 0s;
  position: relative;
}
.ec-cartNavi .ec-cartNavi__badge {
  display: inline-block;
  border-radius: 99999px;
  box-sizing: border-box;
  padding: 5px;
  height: 17px;
  font-size: 10px;
  line-height: 0.7;
  vertical-align: top;
  color: #fff;
  text-align: left;
  white-space: nowrap;
  background-color: #DE5D50;
  position: absolute;
  left: 60%;
  top: -10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi .ec-cartNavi__badge {
    display: inline-block;
    min-width: 17px;
    position: relative;
    left: 0;
    top: 0;
  }
}
.ec-cartNavi .ec-cartNavi__price {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi .ec-cartNavi__price {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
  }
}

.ec-cartNavi.is-active .ec-cartNavi__icon:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.ec-cartNavi.is-active .ec-cartNavi__badge {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi.is-active .ec-cartNavi__badge {
    display: none;
  }
}

/*
カートナビゲーションのポップアップ(商品詳細)

カートナビゲーションのポップアップを表示します。カートに追加された商品の詳細が表示されます。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='close')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 20;
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .ec-cartNaviIsset {
    margin-top: 10px;
    min-width: 256px;
    max-width: 256px;
  }
  .ec-cartNaviIsset::before {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 10px 8.5px;
    border-color: transparent transparent #f8f8f8 transparent;
    position: absolute;
    top: -9px;
  }
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 16px;
  padding-bottom: 32px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
  content: " ";
  display: table;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
  clear: both;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
  float: left;
  width: 45%;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartImage img {
  width: 100%;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
  float: right;
  width: 55%;
  padding-left: 16px;
  text-align: left;
  box-sizing: border-box;
}
.ec-cartNaviIsset .ec-cartNaviIsset__action .ec-blockBtn--action {
  color: #fff;
  margin-bottom: 8px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentTitle {
  margin-bottom: 8px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice {
  font-weight: bold;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentTax {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  margin-left: 2px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentNumber {
  font-size: 14px;
}

.ec-cartNaviIsset.is-active {
  display: block;
}

/*
カートナビゲーションのポップアップ(商品なし)

カートナビゲーションのポップアップを表示します。商品が登録されていない場合の表示です。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='cart')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/
.ec-cartNaviNull {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .ec-cartNaviNull {
    margin-top: 10px;
    min-width: 256px;
    max-width: 256px;
  }
  .ec-cartNaviNull::before {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 10px 8.5px;
    border-color: transparent transparent #f8f8f8 transparent;
    position: absolute;
    top: -9px;
    right: 0;
  }
}
.ec-cartNaviNull .ec-cartNaviNull__message {
  border: 1px solid #D9D9D9;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #F99;
}
.ec-cartNaviNull .ec-cartNaviNull__message p {
  margin: 0;
}

.ec-cartNaviNull.is-active {
  display: block;
}

/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox {
  background: #F3F3F3;
  padding: 16px;
  margin-bottom: 16px;
}
.ec-totalBox .ec-totalBox__spec {
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 8px;
}
.ec-totalBox .ec-totalBox__spec dt {
  font-weight: normal;
  text-align: left;
}
.ec-totalBox .ec-totalBox__spec dd {
  text-align: right;
}
.ec-totalBox .ec-totalBox__spec .ec-totalBox .ec-totalBox__spec__specTotal {
  color: #DE5D50;
}
.ec-totalBox .ec-totalBox__total {
  border-top: 1px dotted #ccc;
  padding: 8px 0;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}
.ec-totalBox .ec-totalBox__paymentTotal {
  padding: 8px 0;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}
.ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__price,
.ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__taxLabel {
  color: #DE5D50;
}
.ec-totalBox .ec-totalBox__price {
  margin-left: 16px;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-totalBox .ec-totalBox__price {
    font-size: 24px;
  }
}
.ec-totalBox .ec-totalBox__taxLabel {
  margin-left: 8px;
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  .ec-totalBox .ec-totalBox__taxLabel {
    font-size: 14px;
  }
}
.ec-totalBox .ec-totalBox__taxRate {
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 8px;
  font-size: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-totalBox .ec-totalBox__taxRate {
    font-size: 12px;
  }
}
.ec-totalBox .ec-totalBox__taxRate dt {
  font-weight: normal;
  text-align: left;
  margin-right: 8px;
}
.ec-totalBox .ec-totalBox__taxRate dt::before {
  content: "[ ";
}
.ec-totalBox .ec-totalBox__taxRate dd {
  text-align: right;
}
.ec-totalBox .ec-totalBox__taxRate dd::after {
  content: " ]";
}
.ec-totalBox .ec-totalBox__pointBlock {
  padding: 18px 20px 10px;
  margin-bottom: 10px;
  background: #fff;
}
.ec-totalBox .ec-totalBox__btn {
  color: #fff;
}
.ec-totalBox .ec-totalBox__btn a {
  color: inherit;
  text-decoration: none;
}
.ec-totalBox .ec-totalBox__btn a:hover {
  text-decoration: none;
}
.ec-totalBox .ec-totalBox__btn .ec-blockBtn--action {
  font-size: 16px;
  font-weight: bold;
}
.ec-totalBox .ec-totalBox__btn .ec-blockBtn--cancel {
  margin-top: 8px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お知らせ

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/
/*
新着情報

新着情報の掲載をします。

ex [トップページ　新着情報部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-news

Styleguide 8.1.1
*/
.ec-news {
  margin-bottom: 16px;
  background: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-news {
    margin-right: 3%;
  }
}
@media only screen and (min-width: 768px) {
  .ec-news {
    margin-bottom: 32px;
  }
}
.ec-news .ec-news__title {
  font-weight: bold;
  padding: 8px;
  font-size: 16px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-news .ec-news__title {
    padding: 16px;
    text-align: left;
    font-size: 24px;
  }
}
.ec-news .ec-news__items {
  padding: 0;
  list-style: none;
  border-top: 1px dotted #ccc;
}

/*
折りたたみ項目

折りたたみ項目を掲載します。

ex [トップページ　折りたたみ項目部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+b.ec-news
        +e.title 新着情報
        +e.UL.items
            +e.LI.item
                +b.ec-newsline.is_active
                    +e.info
                        +e.date 2016/09/29
                        +e.comment サイトオープンしました
                        +e.close
                            a.ec-closeBtn--circle
                                span.ec-closeBtn--circle__icon
                                    .ec-icon
                                        img(src='/moc/icon/angle-down-white.svg', alt='')
                    +e.description 一人暮らしからオフィスなどさまざまなシーンで あなたの生活をサポートするグッズをご家庭へお届けします！

Styleguide 8.1.2
*/
.ec-newsline {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 16px;
}
.ec-newsline .ec-newsline__info {
  width: 100%;
  padding: 16px 0;
}
.ec-newsline .ec-newsline__info:after {
  content: " ";
  display: table;
}
.ec-newsline .ec-newsline__info:after {
  clear: both;
}
.ec-newsline .ec-newsline__date {
  display: inline-block;
  margin-right: 10px;
  float: left;
}
.ec-newsline .ec-newsline__comment {
  display: inline-block;
  float: left;
}
.ec-newsline .ec-newsline__close {
  float: right;
  display: inline-block;
  text-align: right;
}
.ec-newsline .ec-newsline__close .ec-closeBtn--circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}
.ec-newsline .ec-newsline__description {
  width: 100%;
  height: 0;
  transition: all 0.2s ease-out;
}
.ec-newsline.is_active .ec-newsline__description {
  height: auto;
  transition: all 0.2s ease-out;
  padding-bottom: 16px;
}
.ec-newsline.is_active .ec-icon img {
  transform: rotateX(180deg);
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/
/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole .ec-navlistRole__navlist {
  display: flex;
  flex-wrap: wrap;
  border-color: #D0D0D0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  margin-bottom: 32px;
  padding: 0;
  list-style: none;
}
.ec-navlistRole .ec-navlistRole__navlist a {
  color: inherit;
  text-decoration: none;
}
.ec-navlistRole .ec-navlistRole__navlist a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-navlistRole .ec-navlistRole__navlist {
    flex-wrap: nowrap;
  }
}
.ec-navlistRole .ec-navlistRole__item {
  display: flex;
  width: 50%;
  border-color: #D0D0D0;
  border-style: solid;
  border-width: 0 1px 1px 0;
  text-align: center;
  font-weight: bold;
}
.ec-navlistRole .ec-navlistRole__item:hover {
  background: #f5f7f8;
}
.ec-navlistRole .ec-navlistRole__item a {
  margin: auto;
  padding: 16px 10px;
  width: 100%;
  display: inline-block;
}
.ec-navlistRole .active a {
  color: #DE5D50;
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/
/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  border-bottom: 1px dotted #ccc;
}
.ec-welcomeMsg:after {
  content: " ";
  display: table;
}
.ec-welcomeMsg:after {
  clear: both;
}
.ec-welcomeMsg textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-welcomeMsg img {
  max-width: 100%;
}
.ec-welcomeMsg html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-welcomeMsg *,
.ec-welcomeMsg *::before,
.ec-welcomeMsg *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-welcomeMsg img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-welcomeMsg {
    padding-left: 26px;
    padding-right: 26px;
  }
}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole .ec-favoriteRole__header {
  margin-bottom: 16px;
}
.ec-favoriteRole .ec-favoriteRole__itemList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.ec-favoriteRole .ec-favoriteRole__itemList a {
  color: inherit;
  text-decoration: none;
}
.ec-favoriteRole .ec-favoriteRole__itemList a:hover {
  text-decoration: none;
}
.ec-favoriteRole .ec-favoriteRole__item {
  margin-bottom: 8px;
  width: 47.5%;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}
.ec-favoriteRole .ec-favoriteRole__item-image {
  margin-bottom: 10px;
  text-align: center;
}
.ec-favoriteRole .ec-favoriteRole__item img {
  width: auto;
  max-height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-favoriteRole .ec-favoriteRole__item {
    width: 25%;
  }
}
.ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle {
  position: absolute;
  right: 10px;
  top: 10px;
}
.ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle .ec-icon img {
  width: 1em;
  height: 1em;
}
.ec-favoriteRole .ec-favoriteRole__itemThumb {
  display: block;
  height: auto;
  margin-bottom: 8px;
}
.ec-favoriteRole .ec-favoriteRole__itemTitle {
  margin-bottom: 2px;
}
.ec-favoriteRole .ec-favoriteRole__itemPrice {
  font-weight: bold;
  margin-bottom: 0;
}

/*
余白

マイページフッター上の余白です。

Styleguide 9.1.4
*/
.ec-layoutRole__footer {
  margin-top: 64px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-role:after {
  content: " ";
  display: table;
}
.ec-role:after {
  clear: both;
}
.ec-role textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-role img {
  max-width: 100%;
}
.ec-role html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-role *,
.ec-role *::before,
.ec-role *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-role img {
  width: 100%;
}

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
}
.ec-mypageRole:after {
  content: " ";
  display: table;
}
.ec-mypageRole:after {
  clear: both;
}
.ec-mypageRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-mypageRole img {
  max-width: 100%;
}
.ec-mypageRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-mypageRole *,
.ec-mypageRole *::before,
.ec-mypageRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-mypageRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-mypageRole {
    padding-left: 26px;
    padding-right: 26px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-mypageRole .ec-pageHeader h1 {
    margin: 10px 0 48px;
    padding: 8px 0 18px;
  }
}
.ec-mypageRole .ec-editRole {
  margin: 64px auto 0;
}
.ec-mypageRole .ec-RegisterRole__actions {
  margin-top: 48px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: #fff;
}
.ec-layoutRole .ec-layoutRole__contentTop {
  padding: 0;
}
.ec-layoutRole .ec-layoutRole__contents {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-wrap: nowrap;
}
.ec-layoutRole .ec-layoutRole__main {
  width: 100%;
}
.ec-layoutRole .ec-layoutRole__mainWithColumn {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__mainWithColumn {
    width: 75%;
  }
}
.ec-layoutRole .ec-layoutRole__mainBetweenColumn {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
    width: 50%;
  }
}
.ec-layoutRole .ec-layoutRole__left, .ec-layoutRole .ec-layoutRole__right {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__left, .ec-layoutRole .ec-layoutRole__right {
    display: block;
    width: 25%;
  }
}
.ec-layoutRole .ec-layoutRole__header {
  background-color: #fff;
  overflow: visible;
}
@media (max-width: 767px) {
  .ec-layoutRole .ec-layoutRole__header {
    height: 15%;
    min-height: 52px;
  }
}

.ec-headerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  padding-top: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
}
.ec-headerRole:after {
  content: " ";
  display: table;
}
.ec-headerRole:after {
  clear: both;
}
.ec-headerRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-headerRole img {
  max-width: 100%;
}
.ec-headerRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-headerRole *,
.ec-headerRole *::before,
.ec-headerRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-headerRole img {
  width: 100%;
}
.ec-headerRole:after {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerRole:after {
    content: " ";
    display: table;
  }
  .ec-headerRole:after {
    clear: both;
  }
}
.ec-headerRole::before {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerRole {
    width: 100%;
  }
  .ec-headerRole:after {
    content: " ";
    display: table;
  }
  .ec-headerRole:after {
    clear: both;
  }
}
.ec-headerRole .ec-headerRole__title {
  width: 100%;
}
.ec-headerRole .ec-headerRole__navSP {
  display: block;
  position: absolute;
  top: 15px;
  width: 27%;
  right: 0;
  text-align: right;
}
@media only screen and (min-width: 768px) {
  .ec-headerRole .ec-headerRole__navSP {
    display: none;
  }
}
@media (max-width: 767px) {
  .ec-headerRole .ec-headerRole__navSP {
    display: block;
    position: absolute;
    top: -19px;
  }
}

.ec-widthRole.ec-headerRole {
  display: none;
}
@media (max-width: 767px) {
  .ec-widthRole.ec-headerRole {
    display: block;
    position: absolute;
    left: 32.5%;
    top: 10px;
    width: 35%;
    padding-top: 0;
    z-index: 3;
  }
}

.ec-headerNaviRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 97%;
  padding-top: 3.6%;
  min-height: 52px;
  z-index: 2;
}
.ec-headerNaviRole:after {
  content: " ";
  display: table;
}
.ec-headerNaviRole:after {
  clear: both;
}
.ec-headerNaviRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-headerNaviRole img {
  max-width: 100%;
}
.ec-headerNaviRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-headerNaviRole *,
.ec-headerNaviRole *::before,
.ec-headerNaviRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-headerNaviRole img {
  width: 100%;
}
@media (max-width: 980px) {
  .ec-headerNaviRole {
    width: 100%;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__search {
  margin-top: 0px !important;
  margin-left: 18px;
}
@media (max-width: 1000px) {
  .ec-headerNaviRole .ec-headerNaviRole__search {
    margin-left: 14px;
  }
}
@media (max-width: 800px) {
  .ec-headerNaviRole .ec-headerNaviRole__search {
    margin-left: 10px;
  }
}
.ec-headerNaviRole .fa-bars {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ec-headerNaviRole .ec-headerNaviRole__inner {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 52px;
}
@media (max-width: 767px) {
  .ec-headerNaviRole .ec-headerNaviRole__inner {
    justify-content: space-between;
    position: absolute;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__left {
  width: auto;
}
.ec-headerNaviRole .ec-headerNaviRole__search {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerNaviRole .ec-headerNaviRole__search {
    display: inline-block;
    margin-top: 10px;
    position: relative;
  }
  .ec-headerNaviRole .ec-headerNaviRole__search a {
    color: inherit;
    text-decoration: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__search a:hover {
    text-decoration: none;
  }
}
.ec-headerNaviRole__searchIcon {
  display: inline-block;
  width: 16px;
  height: 16px;
}
@media (max-width: 1000px) {
  .ec-headerNaviRole__searchIcon {
    width: 14px;
    height: 14px;
  }
}
@media (max-width: 767px) {
  .ec-headerNaviRole__searchIcon {
    display: none;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__navSP {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-headerNaviRole .ec-headerNaviRole__navSP {
    display: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__navSP a {
    color: inherit;
    text-decoration: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__navSP a:hover {
    text-decoration: none;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__right {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 767px) {
  .ec-headerNaviRole .ec-headerNaviRole__right {
    margin-right: 20px;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__nav {
  display: inline-block;
  z-index: 1;
  margin-right: 0;
}
.ec-headerNaviRole .ec-headerNaviRole__nav a {
  color: inherit;
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__nav a:hover {
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__cart {
  display: inline-block;
}
.ec-headerNaviRole .ec-headerNaviRole__cart a {
  color: inherit;
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__cart a:hover {
  text-decoration: none;
}

.ec-headerNavSP {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  top: 5px;
  padding: 6px 10px 10px 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: transparent;
  position: fixed;
  top: 7px;
  left: 10px;
  z-index: 1000;
}
.ec-headerNavSP .fas {
  vertical-align: top;
}
@media only screen and (min-width: 768px) {
  .ec-headerNavSP {
    display: none;
  }
}

.ec-headerNavSP.is-active {
  display: none;
}

#fixed_nav {
  z-index: 1;
}
#fixed_nav .ec-headerNav {
  display: none;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
}
.ec-headerTitle textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-headerTitle img {
  max-width: 100%;
}
.ec-headerTitle html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-headerTitle *,
.ec-headerTitle *::before,
.ec-headerTitle *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-headerTitle img {
  width: 100%;
}
.ec-headerTitle .ec-headerTitle__title {
  text-align: center;
}
.ec-headerTitle .ec-headerTitle__title h1 {
  margin: 0;
  padding: 0;
}
.ec-headerTitle .ec-headerTitle__title a {
  display: inline-block;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerTitle .ec-headerTitle__title a {
    font-size: 40px;
  }
}
.ec-headerTitle .ec-headerTitle__title a:hover {
  opacity: 0.8;
}
@media (max-width: 767px) {
  .ec-headerTitle .ec-headerTitle__img {
    font-size: inherit !important;
  }
}
@media (max-width: 980px) {
  .ec-headerTitle .ec-headerTitle__img img {
    width: 171px;
    height: 30px;
  }
}
@media (max-width: 550px) {
  .ec-headerTitle .ec-headerTitle__img img {
    width: 140px;
    height: 25px;
  }
}
.ec-headerTitle .ec-headerTitle__subtitle {
  font-size: 10px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-headerTitle .ec-headerTitle__subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.ec-headerTitle .ec-headerTitle__subtitle a {
  display: inline-block;
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  display: flex;
  align-items: center;
  text-align: right;
  line-height: 1.2;
}
.ec-headerNav .ec-headerNav__item {
  margin-left: 0;
  display: inline-block;
  font-size: 28px;
}
.ec-headerNav .ec-headerNav__itemIcon {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerNav .ec-headerNav__itemIcon {
    margin-right: 0;
    font-size: 20px;
  }
}
.ec-headerNav .ec-headerNav__itemLink {
  display: none;
  margin-right: 5px;
  font-size: 14px;
  vertical-align: middle;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerNav .ec-headerNav__itemLink {
    display: inline-block;
  }
}

.ec-originalNav__item {
  margin-right: 18px;
}
@media (max-width: 1000px) {
  .ec-originalNav__item {
    margin-right: 14px;
  }
}
@media (max-width: 800px) {
  .ec-originalNav__item {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .ec-originalNav__item {
    margin-right: 12px;
  }
}
.ec-originalNav__item > a > .ec-headerNavIcom__item {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
@media (max-width: 1000px) {
  .ec-originalNav__item > a > .ec-headerNavIcom__item {
    width: 14px;
    height: 14px;
  }
}

.ec-cartNaviWrap .ec-cartNavi {
  justify-content: normal;
  border-radius: 0;
  background: transparent;
  padding: 0;
  min-width: initial;
  height: auto;
  cursor: pointer;
}
.ec-cartNaviWrap .ec-cartNavi img {
  width: 16px;
  height: 16px;
  transform: scale(1.1);
  object-fit: contain;
}
@media (max-width: 1000px) {
  .ec-cartNaviWrap .ec-cartNavi img {
    width: 14px;
    height: 14px;
  }
}
@media (max-width: 767px) {
  .ec-cartNaviWrap .ec-cartNavi img {
    margin-bottom: 3px;
  }
}

.ec-headerNav > .ec-headerNav__item.ec-originalNav__item {
  font-size: initial;
}

.ec-headerNav__item.ec-originalNav__item {
  cursor: pointer;
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch {
  display: none;
}
.ec-headerSearch:after {
  content: " ";
  display: table;
}
.ec-headerSearch:after {
  clear: both;
}
@media (max-width: 767px) {
  .ec-headerSearch {
    display: block;
  }
}
.ec-headerSearch .ec-headerSearch__category {
  float: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__category {
    float: left;
    width: 43%;
  }
}
.ec-headerSearch .ec-headerSearch__category .ec-select {
  overflow: hidden;
  width: 100%;
  margin: 0;
  text-align: center;
}
.ec-headerSearch .ec-headerSearch__category .ec-select select {
  width: 100%;
  cursor: pointer;
  padding: 8px 24px 8px 8px;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  appearance: none;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__category .ec-select select {
    max-width: 165px;
    height: 36px;
  }
}
.ec-headerSearch .ec-headerSearch__category .ec-select select option {
  color: #000;
}
.ec-headerSearch .ec-headerSearch__category .ec-select select::-ms-expand {
  display: none;
}
.ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
  position: relative;
  border: 0;
  background: #000;
  color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
    border-top-right-radius: inherit;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
}
.ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search::before {
  position: absolute;
  top: 0.8em;
  right: 0.4em;
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  pointer-events: none;
}
.ec-headerSearch .ec-headerSearch__keyword {
  position: relative;
  color: #525263;
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  border-radius: 50px;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__keyword {
    float: none;
    width: 100%;
  }
}
.ec-headerSearch .ec-headerSearch__keyword input[type=search] {
  width: 100%;
  height: 34px;
  font-size: 16px;
  border: 0 none;
  padding: 0.5em 50px 0.5em 1em;
  box-shadow: none;
  background: none;
  box-sizing: border-box;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__keyword input[type=search] {
    font-size: 12px;
  }
}
.ec-headerSearch .ec-headerSearch__keyword input[type=search]::placeholder {
  color: #5C5C67;
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 14px;
}
.ec-headerSearch .ec-headerSearch__keyword .ec-icon {
  width: 22px;
  height: 22px;
}
.ec-headerSearch .ec-headerSearch__keyword .ec-headerNavIcom__item {
  width: 16px;
  height: 16px;
  margin-top: 4px;
}
@media (max-width: 767px) {
  .ec-headerSearch .ec-headerSearch__keyword .ec-headerNavIcom__item {
    margin-top: 0;
  }
}
.ec-headerSearch .ec-headerSearch__keywordBtn {
  border: 0;
  background: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-55%);
  display: block;
  white-space: nowrap;
  z-index: 1;
}
@media (max-width: 767px) {
  .ec-headerSearch .ec-headerSearch__keywordBtn {
    top: calc(50% + 3px);
  }
}

.ec-headerSearch.is-active {
  display: block;
  position: absolute;
  right: -16px;
  width: 240px;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .ec-headerSearch.is-active {
    position: initial;
    margin-top: 0;
  }
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  background-color: #fff;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: none;
}
.ec-categoryNaviRole:after {
  content: " ";
  display: table;
}
.ec-categoryNaviRole:after {
  clear: both;
}
.ec-categoryNaviRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-categoryNaviRole img {
  max-width: 100%;
}
.ec-categoryNaviRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-categoryNaviRole *,
.ec-categoryNaviRole *::before,
.ec-categoryNaviRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-categoryNaviRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-categoryNaviRole {
    display: block;
    width: 100%;
  }
  .ec-categoryNaviRole a {
    color: inherit;
    text-decoration: none;
  }
  .ec-categoryNaviRole a:hover {
    text-decoration: none;
  }
}

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.ec-itemNav__nav {
  display: flex;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  text-align: center;
  vertical-align: bottom;
}
@media (max-width: 767px) {
  .ec-itemNav__nav {
    display: none;
  }
}

.ec-itemNav__nav li {
  margin: 0;
  padding: 0;
  width: auto;
  text-align: center;
  position: relative;
}
.ec-itemNav__nav li:hover {
  background: transparent;
}

.ec-itemNav__nav li:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #5C5C67;
  position: absolute;
  top: 4px;
  right: 0;
}

.ec-itemNav__nav li a {
  margin: 0;
  padding: 14px 12px;
  height: auto;
  color: #5C5C67;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  text-align: center;
  background: #fff;
  white-space: nowrap;
  font-family: "BonvenoCF-Light", sans-serif;
}
.ec-itemNav__nav li a:hover {
  text-decoration: underline;
  opacity: 0.8;
}
@media (max-width: 1200px) {
  .ec-itemNav__nav li a {
    padding: 14px 10px;
    font-size: 14px;
  }
}
@media (max-width: 1000px) {
  .ec-itemNav__nav li a {
    padding: 14px 8px;
    font-size: 12px;
  }
}

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 100%;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul {
    display: block;
    z-index: 100;
    position: absolute;
  }
}

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: 0.3s;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li {
    overflow: hidden;
    height: 0;
  }
}

.ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #E8E8E8;
  padding: 16px 22px 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  background: black;
}

.ec-itemNav__nav > li:hover > a {
  background: #fff;
}

.ec-itemNav__nav > li:hover li:hover > a {
  background: #333;
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav > li:hover > ul > li {
    overflow: visible;
    height: auto;
  }
}

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto;
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li ul:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px;
  }
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li:hover > ul > li {
    overflow: visible;
    height: auto;
    width: auto;
  }
}

.ec-itemNav__nav li ul li ul li a {
  background: #7D7D7D;
}

.ec-itemNav__nav li:hover ul li ul li a:hover {
  background: #333;
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/
.ec-drawerRole {
  overflow-y: scroll;
  background: black;
  width: 260px;
  height: 100vh;
  transform: translateX(-300px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: z-index 0ms 1ms;
}
@media only screen and (min-width: 768px) {
  .ec-drawerRole {
    display: none;
  }
}
.ec-drawerRole .ec-headerSearchArea {
  padding: 20px 10px;
  width: 100%;
  background: #F8F8F8;
}
.ec-drawerRole .ec-headerSearch {
  padding: 16px 8px 26px;
  background: #EBEBEB;
  color: #636378;
}
.ec-drawerRole .ec-headerSearch select {
  width: 100% !important;
}
.ec-drawerRole .ec-headerCategoryArea .ec-headerCategoryArea__heading {
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 1em 10px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  background: #F8F8F8;
}
.ec-drawerRole .ec-headerCategoryArea p {
  margin-top: 0;
  margin-bottom: 0;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li a {
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #5C5C67;
  font-weight: normal;
  background: #f8f8f8;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #ccc;
  padding-left: 20px;
  font-weight: normal;
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover > a {
  background: #f8f8f8;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover li:hover > a {
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li a {
  padding-left: 40px;
  color: black;
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li:hover ul li ul li a:hover {
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li ul li a {
  padding-left: 60px;
  font-weight: normal;
}

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 270px;
  z-index: 1000;
}
.ec-drawerRoleClose .fas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 768px) {
  .ec-drawerRoleClose {
    display: none;
  }
}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all 0.3s;
  z-index: 100000;
}
@media only screen and (min-width: 768px) {
  .ec-drawerRole.is_active {
    display: none;
  }
}

.ec-drawerRoleClose.is_active {
  display: inline-block;
  transition: all 0.3s;
}
@media only screen and (min-width: 768px) {
  .ec-drawerRoleClose.is_active {
    display: none;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all 0.3s;
  visibility: hidden;
}
@media only screen and (min-width: 768px) {
  .ec-overlayRole {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;
}
@media only screen and (min-width: 768px) {
  .have_curtain .ec-overlayRole {
    display: none;
  }
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/
.ec-itemNavAccordion {
  display: none;
}

.ec-maintenanceAlert {
  background: steelblue;
  height: 5rem;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  z-index: 9999;
  display: flex;
  font-weight: bold;
}
.ec-maintenanceAlert > * {
  margin: auto;
}
.ec-maintenanceAlert .ec-maintenanceAlert__icon {
  display: inline-block;
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  color: #fff;
  fill: #fff;
  vertical-align: top;
}
.ec-maintenanceAlert + * {
  margin-top: 5rem;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/* フォント */
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  background: rgba(94, 103, 112, 0.15);
  font-family: "RyuminPr5-Light", sans-serif;
  letter-spacing: 0.1em;
  position: relative;
}
@media (max-width: 1120px) {
  .ec-footerRole {
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  .ec-footerRole {
    padding-bottom: 55px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-footerRole {
    padding-top: 104px;
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}
.ec-footerRole__pcInline {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-footerRole__pcInline {
    display: inline;
  }
}
.ec-footerRole__spInline {
  display: inline;
}
@media only screen and (min-width: 768px) {
  .ec-footerRole__spInline {
    display: none;
  }
}
.ec-footerRole .ec-footerRole__innerTop, .ec-footerRole .ec-footerRole__innerBottom {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .ec-footerRole .ec-footerRole__innerTop, .ec-footerRole .ec-footerRole__innerBottom {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1130px;
  }
  .ec-footerRole .ec-footerRole__innerTop:after, .ec-footerRole .ec-footerRole__innerBottom:after {
    content: " ";
    display: table;
  }
  .ec-footerRole .ec-footerRole__innerTop:after, .ec-footerRole .ec-footerRole__innerBottom:after {
    clear: both;
  }
  .ec-footerRole .ec-footerRole__innerTop textarea, .ec-footerRole .ec-footerRole__innerBottom textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif;
  }
  .ec-footerRole .ec-footerRole__innerTop img, .ec-footerRole .ec-footerRole__innerBottom img {
    max-width: 100%;
  }
  .ec-footerRole .ec-footerRole__innerTop html, .ec-footerRole .ec-footerRole__innerBottom html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .ec-footerRole .ec-footerRole__innerTop *,
.ec-footerRole .ec-footerRole__innerTop *::before,
.ec-footerRole .ec-footerRole__innerTop *::after, .ec-footerRole .ec-footerRole__innerBottom *,
.ec-footerRole .ec-footerRole__innerBottom *::before,
.ec-footerRole .ec-footerRole__innerBottom *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  .ec-footerRole .ec-footerRole__innerTop img, .ec-footerRole .ec-footerRole__innerBottom img {
    width: 100%;
  }
}
.ec-footerRole .ec-footerRole__innerTop {
  min-height: 344px;
  margin-top: 80px !important;
}
@media (max-width: 1120px) {
  .ec-footerRole .ec-footerRole__innerTop {
    margin-top: 40px !important;
  }
}
@media (max-width: 767px) {
  .ec-footerRole .ec-footerRole__innerTop {
    margin-top: 18px !important;
  }
}

/*
フッターメイン

フッタープロジェクトで使用するメイン部分(ロゴなど)です。
*/
.ec-footerMain {
  width: 162px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  right: 20px;
  bottom: 0;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain {
    width: 228px;
  }
}
@media (max-width: 1120px) {
  .ec-footerMain {
    margin: 80px auto 0;
    position: unset;
  }
}
@media (max-width: 767px) {
  .ec-footerMain {
    margin: 40px auto 0;
  }
}
.ec-footerMain__element:nth-of-type(2) {
  margin: 10px 0 0 0;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__element:nth-of-type(2) {
    margin: 7px 0 0 0;
  }
}
.ec-footerMain__element:nth-of-type(3) {
  margin: 15px 0 0 19px;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__element:nth-of-type(3) {
    margin: 20px 0 0 0;
  }
}
.ec-footerMain__h1 {
  margin: 0;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__h1 {
    text-align: left;
  }
}
.ec-footerMain__titleImg {
  width: 228px;
  height: 40px;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__titleImg {
    width: 100%;
    height: 4.2vw;
  }
}
.ec-footerMain__titleImg:hover {
  opacity: 0.6;
}
.ec-footerMain__link {
  position: relative;
  color: #5C5C67;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-footerMain__link, .ec-footerMain__link:hover {
  text-decoration: none;
  color: #5C5C67;
}
.ec-footerMain__link:hover {
  opacity: 0.6;
}
.ec-footerMain__tel:before {
  top: 4px;
}
.ec-footerMain__telImg, .ec-footerMain__contactImg {
  position: absolute;
}
.ec-footerMain__telImg {
  width: 25px !important;
  height: 25px;
  left: 0;
  top: 9px;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__telImg {
    width: 32px !important;
    height: 32px;
  }
}
.ec-footerMain__tel, .ec-footerMain__time, .ec-footerMain__contact {
  padding-left: 35px;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__tel, .ec-footerMain__time, .ec-footerMain__contact {
    padding-left: 48px;
  }
}
.ec-footerMain__tel {
  font-family: "Bebas Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__tel {
    font-size: 26px;
  }
}
.ec-footerMain__time {
  font-size: 7px;
  position: relative;
  top: -5px;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__time {
    font-size: 8px;
  }
}
.ec-footerMain__contact {
  font-size: 11px;
  padding-top: 5px;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__contact {
    font-size: 15px;
    padding-top: 7px;
  }
}
.ec-footerMain__contactImg {
  width: 25px !important;
  height: 25px;
  left: 0;
  top: 0;
}
@media only screen and (min-width: 768px) {
  .ec-footerMain__contactImg {
    width: 32px !important;
    height: 32px;
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
@media (max-width: 1120px) {
  .ec-footerNaviWrapper {
    margin-top: 60px;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .ec-footerNaviWrapper {
    padding: 0 20px;
    margin-top: 40px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-footerNaviWrapper {
    padding: 0 0 0 300px;
  }
}

.ec-footerNavi1,
.ec-footerNavi2 {
  color: #5C5C67;
  list-style: none;
  text-align: center;
}

.ec-footerNavi1__link,
.ec-footerNavi2__link {
  display: block;
}

.ec-footerNavi1__link a,
.ec-footerNavi2__link a {
  display: inline;
  text-decoration: none;
  color: #5C5C67;
}

.ec-footerNavi1__link a:hover,
.ec-footerNavi2__link a:hover {
  opacity: 0.6;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}

.ec-footerNavi1 {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
@media (max-width: 1120px) {
  .ec-footerNavi1 {
    max-width: 765px;
    margin: 80px auto 0;
  }
}
@media (max-width: 767px) {
  .ec-footerNavi1 {
    width: 100%;
    margin: 40px auto 0;
  }
}
.ec-footerNavi1__element {
  text-align: left;
  position: relative;
  float: left;
}
@media (max-width: 1120px) {
  .ec-footerNavi1__element {
    width: 25%;
  }
}
@media (max-width: 767px) {
  .ec-footerNavi1__element {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi1__element {
    width: 190px;
    position: static;
    padding-right: 10px;
  }
}
.ec-footerNavi1__element:nth-last-of-type(1) {
  padding-right: 0;
}
.ec-footerNavi1__index {
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 12px 0;
  border-bottom: 1px solid #5C5C67;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi1__index {
    border-bottom: unset;
    letter-spacing: 0.2em;
    padding: 0;
  }
}
.ec-footerNavi1__linkList {
  list-style: none;
  padding: 18px 0;
  border-bottom: 1px solid #5C5C67;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi1__linkList {
    border-bottom: unset;
    display: block !important;
    padding: 28px 0 0 0;
  }
}
.ec-footerNavi1__link {
  font-size: 12px;
  line-height: 2em;
}
.ec-footerNavi1__link.products {
  font-family: "BonvenoCF-Light", sans-serif;
}
.ec-footerNavi1__introducedInfo {
  color: #5C5C67;
  font-size: 12px;
  display: block;
  width: 100%;
  border: unset;
  background-color: #DEDEDE;
  padding: 11px 10px;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi1__introducedInfo {
    border: 1px solid #5C5C67;
    background-color: unset;
    padding: 8px 10px;
  }
}
.ec-footerNavi1__introducedInfo:hover {
  color: #5C5C67;
  background-color: #b4b4b4;
  opacity: 0.6;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi1__introducedInfo:hover {
    background-color: #CCCCCC;
  }
}
.ec-footerNavi1__introducedInfo, .ec-footerNavi1__introducedInfo:hover {
  text-decoration: none;
}
.ec-footerNavi1__plusImgSp {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 15px;
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi1__plusImgSp {
    display: none;
    position: static;
  }
}
.ec-footerNavi1__plusImg {
  width: 12px !important;
  height: 12px;
  margin: 0 0 2px 5px;
  position: absolute;
  top: 13px;
  right: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi1__plusImg {
    position: static;
  }
}

.ec-footerNavi2 {
  list-style: none;
  padding-left: 0;
  text-align: left;
  margin-top: 35px;
  /*
  &__element {
    width: 100%;
    margin-top: 65px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    letter-spacing: 0.05em;
    @include media_desktop {
      width: auto;
      margin-top: 25px;
      display: block;
      justify-content: unset;
      flex-wrap: unset;
      letter-spacing: 0.1em;
    }
  }
  &__linkList {
    list-style: none;
    padding-left: 0;
  }
  &__linkIcon {
    float: left;
  }
  &__linkIcon:hover {
    opacity: .6;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
  &__linkIcon:not(:nth-last-of-type(1)) {
    padding-right: 15px;
  }
  .instagram {
    width: 20px;
    height: 20px;
  }
  .twitter {
    width: 20px;
    height: 16px;
  }
  .line {
    width: 20px;
    height: 19px;
  }
  .youtube {
    width: 22px;
    height: 16px;
  }
  */
}
@media (max-width: 1120px) {
  .ec-footerNavi2 {
    position: unset;
    width: 100%;
    max-width: 765px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
  }
}
@media (max-width: 767px) {
  .ec-footerNavi2 {
    margin: 40px auto 0;
  }
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2 {
    width: 200px;
    display: block;
    flex-flow: unset;
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 20px;
  }
}
.ec-footerNavi2__link {
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 9px;
  line-height: 1.8em;
  letter-spacing: 0.05em;
  position: relative;
  margin-top: 22px;
}
@media (max-width: 1120px) {
  .ec-footerNavi2__link {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .ec-footerNavi2__link {
    padding-left: 32px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2__link {
    width: auto;
    font-size: 12px;
    line-height: 2.5em;
    letter-spacing: 0.1em;
    margin-top: 13px;
    padding-left: 40px;
  }
}
.ec-footerNavi2__link:nth-of-type(-n+1) {
  margin-top: 0;
}
@media (max-width: 1120px) {
  .ec-footerNavi2__link:nth-of-type(2) {
    margin-top: 0;
  }
}
.ec-footerNavi2__link:nth-of-type(2n) {
  margin-left: 4%;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2__link:nth-of-type(2n) {
    margin-left: 0;
  }
}
.ec-footerNavi2__linkImg {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.ec-footerNavi2 .mypage {
  width: 23px !important;
  height: 23px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2 .mypage {
    width: 27px !important;
    height: 27px;
  }
}
.ec-footerNavi2 .registration {
  width: 13px !important;
  height: 16px;
  left: 3px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2 .registration {
    width: 15px !important;
    height: 18px;
    left: 0;
  }
}
.ec-footerNavi2 .memberPage {
  width: 14px !important;
  height: 22px;
  left: 5px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2 .memberPage {
    width: 17px !important;
    height: 26px;
    left: 4px;
  }
}
.ec-footerNavi2 .onlineShop {
  width: 23px !important;
  height: 22px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2 .onlineShop {
    width: 28px !important;
    height: 26px;
  }
}
.ec-footerNavi2 .favorite {
  width: 20px !important;
  height: 18px;
  left: 2px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2 .favorite {
    width: 24px !important;
    height: 21px;
  }
}
.ec-footerNavi2 .instagram {
  width: 18px !important;
  height: 18px;
  left: 3px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi2 .instagram {
    width: 22px !important;
    height: 22px;
    left: 2px;
  }
}

/*
ミニメニュー＆フッタータイトル

フッタープロジェクトで使用するミニメニュー＆タイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerRole__innerBottom {
  margin-top: 30px !important;
  padding-bottom: 40px;
}
@media only screen and (min-width: 768px) {
  .ec-footerRole__innerBottom {
    margin-top: 55px !important;
    padding-bottom: 25px;
  }
}

.ec-footerNaviSmall {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  float: unset;
}
@media only screen and (min-width: 768px) {
  .ec-footerNaviSmall {
    float: right;
  }
}
.ec-footerNaviSmall__link {
  float: unset;
  padding: 0;
  position: relative;
  text-align: center;
  margin-top: 5px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNaviSmall__link {
    float: left;
    padding: 0 16px;
    text-align: left;
    margin-top: 0;
  }
}
.ec-footerNaviSmall__link:nth-of-type(1) {
  padding-left: 0;
  margin-top: 0;
}
.ec-footerNaviSmall__link:nth-last-of-type(1) {
  padding-right: 0;
}
.ec-footerNaviSmall__link:not(:last-of-type):after {
  content: "";
  width: 1px;
  height: 12px;
  background-color: #9B9B9B;
  position: unset;
  right: 0;
  top: 4px;
}
@media only screen and (min-width: 768px) {
  .ec-footerNaviSmall__link:not(:last-of-type):after {
    position: absolute;
  }
}
.ec-footerNaviSmall__link a {
  display: inline;
  font-size: 12px;
  text-decoration: none;
  color: #9B9B9B;
}
@media only screen and (min-width: 768px) {
  .ec-footerNaviSmall__link a {
    display: block;
  }
}
.ec-footerNaviSmall__link a:hover {
  opacity: 0.6;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}

.ec-footerTitle {
  float: unset;
  margin-top: 50px;
}
@media only screen and (min-width: 768px) {
  .ec-footerTitle {
    float: left;
    margin-top: 0;
  }
}
.ec-footerTitle .ec-footerTitle__copyright {
  font-family: "Lato", sans-serif;
  font-size: 9px;
  color: #3B4043;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-footerTitle .ec-footerTitle__copyright {
    text-align: left;
  }
}

#spmenu.ec-drawerRole {
  font-family: Lato, sans-serif;
  letter-spacing: 0.84px;
  color: #000;
  background: #fff;
}
#spmenu.ec-drawerRole .ec-headerSearch {
  background: #fff;
  width: calc(100% - 40px);
}
#spmenu.ec-drawerRole .ec-headerCategoryArea__heading {
  border-top: none;
  border-bottom: none;
  background: #fff;
  padding: 0;
  position: relative;
}
#spmenu.ec-drawerRole .ec-headerCategoryArea__heading .ec-drawerRoleClose {
  position: absolute;
  top: -68px;
  right: 6px;
  left: initial;
  display: block;
}
#spmenu.ec-drawerRole .ec-itemNav {
  margin-bottom: 13px;
}
#spmenu.ec-drawerRole .ec-itemNav__title {
  font-size: 15px;
  font-weight: bold;
  font-family: "BonvenoCF-Light", sans-serif;
  color: #5C5C67;
  background: #fff;
  text-align: left;
  padding: 0 0 13px 20px;
}
#spmenu.ec-drawerRole .ec-itemNav__title a {
  color: #5C5C67;
}
#spmenu.ec-drawerRole .ec-itemNav__title a:hover {
  color: rgba(92, 92, 103, 0.8);
}
#spmenu.ec-drawerRole .ec-itemNav__nav {
  display: block;
  letter-spacing: 0.84px;
  margin: auto;
  text-align: left;
}
#spmenu.ec-drawerRole .ec-itemNav__nav li {
  padding: 0 0 13px 20px;
  text-align: left;
  position: initial;
}
#spmenu.ec-drawerRole .ec-itemNav__nav li a {
  border-bottom: none;
  background: #fff;
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #5C5C67;
  padding: 0;
  text-align: left;
}
#spmenu.ec-drawerRole .ec-itemNav__nav li a:hover {
  color: rgba(92, 92, 103, 0.8);
}
#spmenu.ec-drawerRole .ec-itemNav__products li a {
  font-family: "BonvenoCF-Light", sans-serif;
}
#spmenu.ec-drawerRole .ec-itemNav__nav li:not(:last-child)::after {
  content: none;
}
#spmenu.ec-drawerRole .ec-headerLink__terms {
  background-color: #fff;
  display: block;
  margin-bottom: 31px;
}
#spmenu.ec-drawerRole .ec-headerLink__terms div {
  padding: 0 0 11px 20px;
}
#spmenu.ec-drawerRole .ec-headerLink__terms div a {
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 12px;
  color: #9B9B9B;
}
#spmenu.ec-drawerRole .ec-headerLink__list {
  border-top: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
#spmenu.ec-drawerRole .ec-headerLink__item {
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 9px;
  line-height: 1.8em;
  letter-spacing: 0.05em;
  font-weight: normal;
  color: #5C5C67;
  display: flex;
  align-items: center;
  padding: 0 0 14px 20px;
  border-bottom: none;
  background: #fff;
  text-align: left;
}
#spmenu.ec-drawerRole .ec-headerLink__item:hover {
  opacity: 0.8;
  text-decoration: none;
}
#spmenu.ec-drawerRole .ec-headerLink__icon {
  margin-right: 10px;
}
#spmenu.ec-drawerRole .ec-headerLink__icon img {
  width: 22px;
  height: 23px;
}
#spmenu.ec-drawerRole .ec-headerLink__cart {
  order: 1;
}
#spmenu.ec-drawerRole .ec-headerLink__favorite {
  order: 2;
}
#spmenu.ec-drawerRole .ec-headerLink__mypage {
  order: 3;
}
#spmenu.ec-drawerRole .ec-headerLink__forSalon {
  order: 4;
}
#spmenu.ec-drawerRole .ec-headerLink__instagram {
  order: 5;
}
#spmenu.ec-drawerRole .ec-headerLink__storeInstagram {
  order: 6;
}
#spmenu.ec-drawerRole .ec-btnRole__salon {
  margin-bottom: 35px;
}
#spmenu.ec-drawerRole .ec-btnRole__salon a {
  color: #5C5C67;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 12px;
  background-color: #DEDEDE;
  width: 88%;
  height: 38px;
  margin: 0 auto;
  border: none;
  padding: 0 16px;
}
#spmenu.ec-drawerRole .ec-btnRole__salon a:hover {
  opacity: 0.8;
  text-decoration: none;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  margin-bottom: 24px;
}
.ec-sliderRole:after {
  content: " ";
  display: table;
}
.ec-sliderRole:after {
  clear: both;
}
.ec-sliderRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-sliderRole img {
  max-width: 100%;
}
.ec-sliderRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-sliderRole *,
.ec-sliderRole *::before,
.ec-sliderRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-sliderRole img {
  width: 100%;
}
.ec-sliderRole ul {
  padding: 0;
  list-style: none;
}

.ec-sliderItemRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  margin-bottom: 24px;
}
.ec-sliderItemRole:after {
  content: " ";
  display: table;
}
.ec-sliderItemRole:after {
  clear: both;
}
.ec-sliderItemRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-sliderItemRole img {
  max-width: 100%;
}
.ec-sliderItemRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-sliderItemRole *,
.ec-sliderItemRole *::before,
.ec-sliderItemRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-sliderItemRole img {
  width: 100%;
}
.ec-sliderItemRole ul {
  padding: 0;
  list-style: none;
}
.ec-sliderItemRole .item_nav {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-sliderItemRole .item_nav {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}
.ec-sliderItemRole .slideThumb {
  margin-bottom: 25px;
  width: 33%;
  opacity: 0.8;
  cursor: pointer;
}
.ec-sliderItemRole .slideThumb:focus {
  outline: none;
}
.ec-sliderItemRole .slideThumb:hover {
  opacity: 1;
}
.ec-sliderItemRole .slideThumb img {
  width: 80%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  /* Deprecated https://github.com/EC-CUBE/ec-cube/pull/4220 */
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole {
    flex-wrap: nowrap;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__image {
  display: block;
  margin-bottom: 40px;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__image {
    order: 2;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__intro {
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__intro {
    padding-right: 5%;
    order: 1;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
  margin-bottom: 0.8em;
  font-size: 16px;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
    margin-top: 45px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introTitle {
  margin-bottom: 0.8em;
  font-size: 24px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introTitle {
    margin-bottom: 1em;
    font-size: 26px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 2;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
    margin-bottom: 30px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introDescription {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 2;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introDescription {
    margin-bottom: 30px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
ボタン

トップページで使用されているボタンのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.3
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

Markup:
.ec-inlineBtn--top more

Styleguide 12.3.1
*/
.ec-inlineBtn--top {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  background-color: black;
  border-color: black;
}
.ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus, .ec-inlineBtn--top:active:focus, .ec-inlineBtn--top:active.focus, .ec-inlineBtn--top.active:focus, .ec-inlineBtn--top.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn--top:hover, .ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus {
  color: #525263;
  text-decoration: none;
}
.ec-inlineBtn--top:active, .ec-inlineBtn--top.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-inlineBtn--top.disabled, .ec-inlineBtn--top[disabled], fieldset[disabled] .ec-inlineBtn--top {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus {
  color: white;
  background-color: black;
  border-color: black;
}
.ec-inlineBtn--top:hover {
  color: white;
  background-color: black;
  border-color: black;
}
.ec-inlineBtn--top:active, .ec-inlineBtn--top.active, .open > .ec-inlineBtn--top.dropdown-toggle {
  color: white;
  background-color: black;
  background-image: none;
  border-color: black;
}
.ec-inlineBtn--top:active:hover, .ec-inlineBtn--top:active:focus, .ec-inlineBtn--top:active.focus, .ec-inlineBtn--top.active:hover, .ec-inlineBtn--top.active:focus, .ec-inlineBtn--top.active.focus, .open > .ec-inlineBtn--top.dropdown-toggle:hover, .open > .ec-inlineBtn--top.dropdown-toggle:focus, .open > .ec-inlineBtn--top.dropdown-toggle.focus {
  color: white;
  background-color: black;
  border-color: black;
}
.ec-inlineBtn--top.disabled:hover, .ec-inlineBtn--top.disabled:focus, .ec-inlineBtn--top.disabled.focus, .ec-inlineBtn--top[disabled]:hover, .ec-inlineBtn--top[disabled]:focus, .ec-inlineBtn--top[disabled].focus, fieldset[disabled] .ec-inlineBtn--top:hover, fieldset[disabled] .ec-inlineBtn--top:focus, fieldset[disabled] .ec-inlineBtn--top.focus {
  background-color: black;
  border-color: black;
}
.ec-inlineBtn--top .badge {
  color: black;
  background-color: white;
}
.ec-inlineBtn--top .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

/*
ロングボタン（全幅）

ロングタイプのボタンです。

Markup:
.ec-blockBtn--top 商品一覧へ

Styleguide 2.1.2
*/
.ec-blockBtn--top {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  background-color: black;
  border-color: black;
  display: block;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--top:focus, .ec-blockBtn--top.focus, .ec-blockBtn--top:active:focus, .ec-blockBtn--top:active.focus, .ec-blockBtn--top.active:focus, .ec-blockBtn--top.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn--top:hover, .ec-blockBtn--top:focus, .ec-blockBtn--top.focus {
  color: #525263;
  text-decoration: none;
}
.ec-blockBtn--top:active, .ec-blockBtn--top.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-blockBtn--top.disabled, .ec-blockBtn--top[disabled], fieldset[disabled] .ec-blockBtn--top {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-blockBtn--top:focus, .ec-blockBtn--top.focus {
  color: white;
  background-color: black;
  border-color: black;
}
.ec-blockBtn--top:hover {
  color: white;
  background-color: black;
  border-color: black;
}
.ec-blockBtn--top:active, .ec-blockBtn--top.active, .open > .ec-blockBtn--top.dropdown-toggle {
  color: white;
  background-color: black;
  background-image: none;
  border-color: black;
}
.ec-blockBtn--top:active:hover, .ec-blockBtn--top:active:focus, .ec-blockBtn--top:active.focus, .ec-blockBtn--top.active:hover, .ec-blockBtn--top.active:focus, .ec-blockBtn--top.active.focus, .open > .ec-blockBtn--top.dropdown-toggle:hover, .open > .ec-blockBtn--top.dropdown-toggle:focus, .open > .ec-blockBtn--top.dropdown-toggle.focus {
  color: white;
  background-color: black;
  border-color: black;
}
.ec-blockBtn--top.disabled:hover, .ec-blockBtn--top.disabled:focus, .ec-blockBtn--top.disabled.focus, .ec-blockBtn--top[disabled]:hover, .ec-blockBtn--top[disabled]:focus, .ec-blockBtn--top[disabled].focus, fieldset[disabled] .ec-blockBtn--top:hover, fieldset[disabled] .ec-blockBtn--top:focus, fieldset[disabled] .ec-blockBtn--top.focus {
  background-color: black;
  border-color: black;
}
.ec-blockBtn--top .badge {
  color: black;
  background-color: white;
}
.ec-blockBtn--top .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}
@media only screen and (min-width: 768px) {
  .ec-blockBtn--top {
    max-width: 260px;
  }
}

/*
見出し

トップページで使用されている見出しのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.4
*/
/*
横並び見出し

横並びの見出しです。

Markup:
.ec-secHeading
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.1
*/
.ec-secHeading {
  margin-bottom: 15px;
  color: black;
}
.ec-secHeading .ec-secHeading__en {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2em;
}
.ec-secHeading .ec-secHeading__line {
  display: inline-block;
  margin: 0 20px;
  width: 1px;
  height: 14px;
  background: black;
}
.ec-secHeading .ec-secHeading__ja {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15em;
  vertical-align: 2px;
}

/*
縦並び見出し

縦並びの見出しです。

Markup:
.ec-secHeading--tandem
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.2
*/
.ec-secHeading--tandem {
  margin-bottom: 15px;
  color: black;
  text-align: center;
}
.ec-secHeading--tandem .ec-secHeading__en {
  display: block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2em;
}
.ec-secHeading--tandem .ec-secHeading__line {
  display: block;
  margin: 13px auto;
  width: 20px;
  height: 1px;
  background: black;
}
.ec-secHeading--tandem .ec-secHeading__ja {
  display: block;
  margin-bottom: 30px;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15em;
  vertical-align: 2px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トピック（アイテム2列）

トップページで使用されているトピックのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.5.1
*/
.ec-topicRole {
  padding: 40px 0;
  background: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole {
    padding: 60px 0;
  }
}
.ec-topicRole .ec-topicRole__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__list {
    flex-wrap: nowrap;
  }
}
.ec-topicRole .ec-topicRole__listItem {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__listItem {
    width: 50%;
  }
  .ec-topicRole .ec-topicRole__listItem:not(:last-of-type) {
    margin-right: 30px;
  }
}
.ec-topicRole .ec-topicRole__listItemTitle {
  margin-top: 0.5em;
  font-size: 14px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__listItemTitle {
    margin-top: 1em;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/
.ec-newItemRole {
  padding: 40px 0;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole {
    padding: 60px 0;
  }
}
.ec-newItemRole .ec-newItemRole__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__list {
    flex-wrap: nowrap;
  }
}
.ec-newItemRole .ec-newItemRole__listItem {
  margin-bottom: 4%;
  width: 48%;
  height: auto;
}
.ec-newItemRole .ec-newItemRole__listItem:not(:first-child) a {
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItem {
    margin-bottom: 15px;
    width: 25%;
  }
  .ec-newItemRole .ec-newItemRole__listItem:not(:last-of-type) {
    margin-right: 30px;
  }
}
.ec-newItemRole .ec-newItemRole__listItem:nth-child(odd) {
  margin-right: 4%;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItem:nth-child(odd) {
    margin-right: 30px;
  }
}
.ec-newItemRole .ec-newItemRole__listItemHeading {
  margin-top: calc(45% - 20px);
}
.ec-newItemRole .ec-newItemRole__listItemTitle {
  margin: 8px 0;
  font-size: 14px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItemTitle {
    margin: 20px 0 10px;
  }
}
.ec-newItemRole .ec-newItemRole__listItemPrice {
  font-size: 12px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム4列）

カテゴリ部分で使用されている共通スタイルです。

Styleguide 12.7.1
*/
.ec-categoryRole .ec-widthRole {
  margin: 0 auto;
}
.ec-categoryRole__headlineTop {
  display: none;
}
.ec-categoryRole__exTextTop {
  display: none;
}
.ec-categoryRole .ec-section__titleEn {
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .ec-categoryRole .ec-section__titleEn {
    margin-bottom: 40px;
    letter-spacing: 0.18em;
  }
}
.ec-categoryRole__title h2 {
  font-size: 22px;
  font-family: "BonvenoCF-Light", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole__title h2 {
    font-size: 28px;
  }
}
.ec-categoryRole__title p {
  margin-top: 10px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Koburina-Gothic-w3", sans-serif;
  letter-spacing: 0.1em;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole__title p {
    font-size: 13px;
    margin-top: 20px;
  }
}
.ec-categoryRole__caption {
  display: none;
}
.ec-categoryRole__list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding-left: 0;
  margin: -2px 0 0 -2%;
}
.ec-categoryRole__btnWrap {
  text-align: center;
  margin: 189px auto 200px;
}
@media (max-width: 767px) {
  .ec-categoryRole__btnWrap {
    margin: 40px auto 100px;
  }
}
.ec-categoryRole__btn {
  display: inline-block;
  color: #fff;
  background: #5C5C67;
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 15px;
  padding: 21px 31px;
  letter-spacing: 0.12em;
}
.ec-categoryRole__btn:hover {
  opacity: 0.8;
  color: #fff;
  text-decoration: none;
}
@media (max-width: 767px) {
  .ec-categoryRole__btn {
    padding: 14px 21px;
  }
}
.ec-categoryRole__btn span {
  font-family: "BonvenoCF-Light", sans-serif;
}
.ec-categoryRole__element {
  color: #000000;
  width: 48%;
  margin: 25px 0 0 2%;
  overflow: hidden;
  padding-bottom: 25px;
  background-color: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole__element {
    width: 23%;
    margin: 40px 0 0 2%;
  }
}
.ec-categoryRole__element .ec-viewmore {
  display: none;
}
.ec-categoryRole__element:hover {
  opacity: 0.8;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-categoryRole__imgBox {
  width: 100%;
  height: 120px;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole__imgBox {
    height: 165px;
  }
}
.ec-categoryRole__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ec-categoryRole__categoryTitle {
  font-size: 16px;
  margin: 25px 0 0 0;
  margin-top: 25px;
  padding: 0 16px;
  font-family: "BonvenoCF-Light", sans-serif;
}
.ec-categoryRole__categoryTitleSmall {
  font-size: 0.8em;
  letter-spacing: 0.01em;
}
.ec-categoryRole__categoryTitleTail {
  margin-top: 8px;
  font-size: 13px;
  font-family: "Koburina-Gothic-w3", sans-serif;
}
.ec-categoryRole__headlineTop {
  display: none;
}
.ec-categoryRole__exTextTop {
  display: none;
}
.ec-categoryRole__asterisk {
  position: relative;
  top: 0.01em;
  padding: 0 0.1em;
  font-size: 0.9em;
}
.ec-categoryRole__asterisk span {
  font-size: 10px;
  vertical-align: bottom;
}
.ec-categoryRole .unstress {
  color: #54BEB3;
}
.ec-categoryRole .illustrious {
  color: #A7A8A9;
}
.ec-categoryRole .biophyto {
  color: #618F2E;
}
.ec-categoryRole .forever_young {
  color: #F7B05B;
}
.ec-categoryRole .rose_de_mer {
  color: #162C7D;
}
.ec-categoryRole .muse {
  color: #D76EA8;
}
.ec-categoryRole .comodex {
  color: #842331;
}
.ec-categoryRole .theraskin {
  color: #38829B;
}
.ec-categoryRole .chateau_de_beaute {
  color: #7E2D4F;
}
.ec-categoryRole .silk {
  color: #B29E97;
}
.ec-categoryRole .bio_satin_serum {
  color: #686868;
}
.ec-categoryRole .clinical {
  color: #488A9B;
}

/*
カテゴリ（アイテム4列・シンプルver.）

カテゴリ(シンプルver.)部分で使用されている共通スタイルです。

Styleguide 12.7.1
*/
.ec-category2Role {
  padding: 64px 0;
}
@media only screen and (min-width: 768px) {
  .ec-category2Role {
    padding: 80px 0;
  }
}
.ec-category2Role__wrapper {
  margin: 0 auto;
}
.ec-category2Role__title h2 {
  font-size: 22px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-category2Role__title h2 {
    font-size: 18px;
  }
}
.ec-category2Role__caption {
  display: none;
}
.ec-category2Role__list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding-left: 0;
  margin: 2px 0 0 -2%;
}
.ec-category2Role__element {
  color: #000000;
  width: 48%;
  height: 26.9vw;
  margin: 10px 0 0 2%;
  overflow: hidden;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .ec-category2Role__element {
    width: 23%;
    height: 10.2vw;
    margin: 25px 0 0 2%;
  }
}
.ec-category2Role__element .ec-viewmore {
  display: none;
}
.ec-category2Role__element:hover {
  opacity: 0.8;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-category2Role__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ec-category2Role__categoryTitle {
  width: 95%;
  text-align: center;
  font-size: 3.5vw;
  font-weight: 300;
  white-space: nowrap;
  margin: 0;
  color: #FFFFFF;
  font-family: "BonvenoCF-Light", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 1100px) {
  .ec-category2Role__categoryTitle {
    font-size: 18px;
  }
}
@media only screen and (min-width: 880px) {
  .ec-category2Role__categoryTitle {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-category2Role__categoryTitle {
    font-size: 14px;
  }
}
.ec-category2Role__headlineTop {
  display: none;
}
.ec-category2Role__exTextTop {
  display: none;
}

/*
カテゴリ TOPのスタイル

*/
#products.ec-productsTop .ec-categoryRole__wrap {
  width: 89%;
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__wrap {
    width: 100%;
  }
}
#products.ec-productsTop .ec-categoryRole__caption {
  display: block;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__caption {
    margin-bottom: 40px;
  }
}
#products.ec-productsTop .ec-categoryRole__caption p {
  font-size: 25px;
  line-height: 47px;
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__caption p {
    font-size: 14px;
    line-height: 32px;
  }
}
#products.ec-productsTop .ec-categoryRole__title h2 {
  color: #5C5C67;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 34px;
  letter-spacing: 0.12em;
  margin: 0 0 80px 0;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  #products.ec-productsTop .ec-categoryRole__title h2 {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__title h2 {
    font-size: 24px;
    margin: 0 0 40px 0;
    letter-spacing: 0.18em;
  }
}
#products.ec-productsTop .ec-categoryRole__categoryTitle {
  color: #5C5C67;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 34px;
  padding: 0;
  margin: 40px 0 56px 0;
  letter-spacing: 0.12em;
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__categoryTitle {
    font-size: 24px;
    margin: 20px 0 28px 0;
  }
}
#products.ec-productsTop .ec-categoryRole__categoryTitleTail {
  display: none;
}
#products.ec-productsTop .ec-categoryRole__list {
  justify-content: space-between;
  margin: 0;
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__list {
    flex-direction: column;
  }
}
#products.ec-productsTop .ec-categoryRole__element {
  color: #5C5C67;
  background: #fff;
  overflow: visible;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__element:not(:first-child) {
    padding-top: 35px;
  }
}
#products.ec-productsTop .ec-categoryRole__element:nth-child(even) {
  padding-top: 115px;
  align-items: flex-end;
}
#products.ec-productsTop .ec-categoryRole__element:nth-child(even) .ec-categoryRole__imgBox {
  align-self: flex-end;
}
@media only screen and (min-width: 768px) {
  #products.ec-productsTop .ec-categoryRole__element {
    width: 50%;
    margin: 40px 0 0 0;
  }
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__element {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
#products.ec-productsTop .ec-categoryRole .ec-viewmore {
  display: block;
  align-self: flex-end;
  margin-top: 32px;
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole .ec-viewmore {
    margin-top: 17px;
  }
}
#products.ec-productsTop .ec-categoryRole__headlineTop {
  display: block;
  color: #5C5C67;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__headlineTop {
    font-size: 15px;
    line-height: 29px;
    margin-bottom: 8px;
  }
}
#products.ec-productsTop .ec-categoryRole__exTextTop {
  display: block;
  color: #5C5C67;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  font-size: 12px;
  line-height: 24px;
}
@media only screen and (min-width: 768px) {
  #products.ec-productsTop .ec-categoryRole__imgBox {
    height: 388px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  #products.ec-productsTop .ec-categoryRole__imgBox {
    height: 194px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/
#news {
  margin-bottom: 120px;
}
@media (max-width: 767px) {
  #news {
    margin-bottom: 60px;
  }
}
#news .ec-widthRole {
  margin: 0 auto;
  padding: 80px 0;
}
@media (max-width: 767px) {
  #news .ec-widthRole {
    padding: 40px 0;
  }
}
#news .ec-section__titleEn {
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  #news .ec-section__titleEn {
    margin-bottom: 40px;
  }
}
#news .ec-newsRole__newsDescription {
  border-top: 1px solid #5C5C67;
  border-bottom: 1px solid #5C5C67;
  color: #5C5C67;
  font-family: "Koburina-Gothic-w3", sans-serif;
}
#news .ec-topNotice {
  display: flex;
  margin-bottom: 56px;
  padding: 0 24px;
}
@media (max-width: 767px) {
  #news .ec-topNotice {
    flex-direction: column;
    padding: 0 12px;
  }
}
#news .ec-topNotice:last-child {
  margin-bottom: 0;
}
#news .ec-topNotice__date {
  font-family: "Fahkwang", sans-serif;
  font-size: 15px;
  line-height: 32px;
  text-transform: lowercase;
  width: 180px;
  letter-spacing: 0.12em;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  #news .ec-topNotice__date {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 8px;
  }
}
#news .ec-topNotice__titleWrap {
  padding-left: 120px;
}
@media (max-width: 767px) {
  #news .ec-topNotice__titleWrap {
    padding-left: 0;
  }
}
#news .ec-topNotice__link {
  color: #5C5C67;
}
#news .ec-topNotice__link:hover {
  text-decoration: none;
  opacity: 0.6;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
#news .ec-topNotice__title {
  font-size: 18px;
  line-height: 32px;
  color: #5C5C67;
}
@media (max-width: 767px) {
  #news .ec-topNotice__title {
    font-size: 14px;
    line-height: 28px;
  }
}
#news .ec-viewmore {
  margin-top: 64px;
}
@media (max-width: 767px) {
  #news .ec-viewmore {
    margin-top: 32px;
  }
}

/* 旧デザインのスタイル */
/*
.ec-newsRole {
  padding: 40px 0 0;

  @include media_desktop {
    padding: 60px 0 0;
  }

  & &__news {

    box-sizing: border-box;

    @include media_desktop {
      border: 16px solid #F8F8F8;
      padding: 20px 30px;
    }
  }
  & &__newsItem {
    width: 100%;

    &:not(:last-of-type){
      border-bottom: 1px solid #ccc;
    }

    &:last-of-type {
      margin-bottom: 20px;

      @include media_desktop {
        margin-bottom: 0;
      }
    }


    @include media_desktop {

      padding: 20px 0;
    }
  }
  & &__newsHeading {
    cursor: pointer;

    @include media_desktop {
      display: flex;
    }

  }
  & &__newsDate {
    display: block;
    margin:  15px 0 5px;
    font-size: 12px;
    color: black;

    @include media_desktop {
      display: inline-block;
      margin: 0;
      min-width: 120px;
      font-size: 14px;
    }

  }
  & &__newsColumn {
    display: flex;

    @include media_desktop {
      display: inline-flex;
      min-width: calc(100% - 120px);
    }
  }

  & &__newsTitle {
    display: inline-block;
    margin-bottom: 10px;
    width: 90%;
    font-size: 14px;
    font-weight: bold;
    color: #7D7D7D;
    line-height: 1.6;

    @include media_desktop {
      margin-bottom: 0;
      line-height: 1.8;
    }

  }
  & &__newsClose {
    display: inline-block;
    width: 10%;
    position: relative;

  }
  & &__newsCloseBtn {
    display: inline-block;
    margin-left: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    background: black;
    cursor: pointer;
    position: absolute;
    right: 5px;
  }
  & &__newsDescription {
    display: none;
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;

    @include media_desktop {
      margin: 20px 0 0;
      line-height: 1.8;
    }

    a {
      color: #0092C4;
    }
  }
  &__newsItem.is_active &__newsDescription{
    margin: 0 0 10px;

    @include media_desktop {
      margin: 20px 0 0;
    }
  }
  &__newsItem.is_active &__newsCloseBtn i {
    display: inline-block;
    transform: rotateX(180deg) translateY(2px);

  }

}
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されているカレンダーのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.9.1
*/
/* 背景や文字の色調整 */
.ec-calendar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .ec-calendar {
    flex-direction: row;
    margin-left: -30px;
  }
}
.ec-calendar__month {
  border-collapse: collapse;
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .ec-calendar__month {
    margin-top: 0;
    margin-left: 30px;
  }
}
.ec-calendar__month th, .ec-calendar__month td {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.ec-calendar .ec-calendar__title {
  border: 0;
}
.ec-calendar .ec-calendar__sun {
  background: #F8F8F8;
  color: #DE5D50;
}
.ec-calendar .ec-calendar__mon, .ec-calendar .ec-calendar__tue, .ec-calendar .ec-calendar__wed, .ec-calendar .ec-calendar__thu, .ec-calendar .ec-calendar__fri {
  background: #F8F8F8;
  color: #333;
}
.ec-calendar .ec-calendar__sat {
  background: #F8F8F8;
  color: #5CB1B1;
}
.ec-calendar .ec-calendar__day {
  background: #FFF;
  color: #333;
}
.ec-calendar .ec-calendar__holiday {
  background: #FFF;
  color: #DE5D50 !important;
}
.ec-calendar .ec-calendar__today {
  color: #333;
  position: relative;
  z-index: 1;
}
.ec-calendar .ec-calendar__today::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #FFFDE7;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
バナー

トップページで使用されているバナー（サロンインフォメーションの箇所）のスタイルです。

*/
#banner.ec-banner {
  background: #fff;
  padding: 160px 0 200px 0;
}
@media (max-width: 767px) {
  #banner.ec-banner {
    padding: 64px 0;
  }
}
#banner.ec-banner .ec-widthRole {
  margin: 0 auto;
}
#banner.ec-banner .ec-titleWrap {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-titleWrap {
    margin-bottom: 40px;
    text-align: center;
  }
}
#banner.ec-banner .ec-bannerWrap {
  display: flex;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-bannerWrap {
    flex-direction: column;
  }
}
#banner.ec-banner .ec-banner {
  width: 50%;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-banner {
    width: 100%;
  }
}
#banner.ec-banner .ec-banner__img {
  position: relative;
}
#banner.ec-banner .ec-banner__title {
  position: absolute;
  color: #fff;
  font-family: "BonvenoCF-Light", sans-serif;
  writing-mode: vertical-lr;
  font-size: 25px;
  letter-spacing: 0.12em;
  margin: 16px;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-banner__title {
    font-size: 16px;
  }
}
#banner.ec-banner .ec-banner__margin {
  margin-right: 80px;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-banner__margin {
    margin-right: 0;
    margin-bottom: 40px;
  }
}
#banner.ec-banner .ec-banner__pcImg {
  display: block;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-banner__pcImg {
    display: none;
  }
}
#banner.ec-banner .ec-banner__spImg {
  display: none;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-banner__spImg {
    display: block;
  }
}
#banner.ec-banner .ec-viewmore__white {
  border-bottom: 1px solid #fff;
  width: 189px;
  padding-bottom: 17px;
  text-align: right;
  float: right;
  position: absolute;
  right: 32px;
  bottom: 34px;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-viewmore__white {
    width: 130px;
    right: 16px;
    bottom: 18px;
    padding-bottom: 8px;
  }
}
#banner.ec-banner .ec-viewmore__white a {
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-viewmore__white a {
    font-size: 16px;
  }
}
#banner.ec-banner .ec-viewmore__white:hover {
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
#banner.ec-banner .ec-banner__caption {
  margin-top: 32px;
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 18px;
  letter-spacing: 0.12em;
  color: #5C5C67;
}
@media (max-width: 767px) {
  #banner.ec-banner .ec-banner__caption {
    margin-top: 26px;
    font-size: 13px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
サロン

トップページで使用されている「サロントリートメントについて」のスタイルです。

*/
.ec-care {
  background: #fff;
}
.ec-care .ec-role .ec-widthRole {
  margin: 0 auto;
}
.ec-care .ec-salon__visual {
  position: relative;
  background: url("/html/template/default/assets/img/top/salon/salon_img.png") no-repeat center;
  background-size: cover;
  height: 708px;
  margin-bottom: 120px;
}
@media (max-width: 767px) {
  .ec-care .ec-salon__visual {
    background: url("/html/template/default/assets/img/top/salon/salon_img_sp.png") no-repeat left;
    min-height: 400px;
    margin-bottom: 60px;
  }
}
@media (max-width: 415px) {
  .ec-care .ec-salon__visual {
    background-size: cover;
    height: 400px;
  }
}
@media (max-width: 376px) {
  .ec-care .ec-salon__visual {
    background-size: contain;
  }
}
.ec-care .ec-salon__visualTitle {
  position: absolute;
  right: 87px;
  bottom: 204px;
  color: #fff;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 35px;
  line-height: 61px;
  letter-spacing: 0.12em;
  text-align: right;
}
@media (max-width: 767px) {
  .ec-care .ec-salon__visualTitle {
    bottom: 90px;
    right: 30px;
    font-size: 24px;
    line-height: 42px;
    letter-spacing: 0.25em;
  }
}
@media (max-width: 428px) {
  .ec-care .ec-salon__visualTitle {
    bottom: 85px;
    letter-spacing: 0.12em;
  }
}
.ec-care .ec-infoBtn {
  position: absolute;
  right: 87px;
  bottom: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 980px) {
  .ec-care .ec-infoBtn {
    padding: 0 30px;
  }
}
@media (max-width: 767px) {
  .ec-care .ec-infoBtn {
    right: 30px;
    bottom: 23px;
    height: 56px;
    padding: 0 16px;
  }
}
@media (max-width: 428px) {
  .ec-care .ec-infoBtn {
    height: 46px;
    padding: 0 20px;
  }
}
.ec-care .ec-infoBtn__textSm {
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 16px;
  margin-bottom: 4px;
  letter-spacing: 0.05em;
}
@media (max-width: 1200px) {
  .ec-care .ec-infoBtn__textSm {
    font-size: 14px;
  }
}
@media (max-width: 980px) {
  .ec-care .ec-infoBtn__textSm {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .ec-care .ec-infoBtn__textSm {
    font-size: 11px;
    margin-bottom: 2px;
    letter-spacing: 0.1em;
  }
}
@media (max-width: 428px) {
  .ec-care .ec-infoBtn__textSm {
    font-size: 8px;
    margin-bottom: 0;
    letter-spacing: 0;
  }
}
.ec-care .ec-infoBtn__textLg {
  font-family: "RyuminPr5-Medium", sans-serif;
  font-size: 28px;
}
@media (max-width: 1200px) {
  .ec-care .ec-infoBtn__textLg {
    font-size: 27px;
  }
}
@media (max-width: 980px) {
  .ec-care .ec-infoBtn__textLg {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .ec-care .ec-infoBtn__textLg {
    font-size: 18px;
  }
}
.ec-care .ec-salon {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  color: #5C5C67;
  letter-spacing: 0.12em;
}
.ec-care .ec-salon__titleWrap {
  display: flex;
  align-items: center;
  margin-bottom: 72px;
}
@media (max-width: 767px) {
  .ec-care .ec-salon__titleWrap {
    margin-bottom: 36px;
  }
}
.ec-care .ec-salon__title {
  font-size: 30px;
  line-height: 46px;
}
@media (max-width: 767px) {
  .ec-care .ec-salon__title {
    font-size: 19px;
    line-height: 26px;
  }
}
@media (max-width: 376px) {
  .ec-care .ec-salon__title {
    font-size: 17px;
    line-height: 23px;
  }
}
.ec-care .ec-salon__line {
  border-bottom: 1px solid #5C5C67;
  flex: 1;
  margin-left: 25px;
}
@media (max-width: 767px) {
  .ec-care .ec-salon__line {
    margin-left: 16px;
  }
}
.ec-care .ec-salon__content {
  font-size: 14px;
  line-height: 2.6em;
  width: 90%;
}
@media (max-width: 767px) {
  .ec-care .ec-salon__content {
    font-size: 14px;
    line-height: 30px;
    width: 100%;
  }
}
@media (max-width: 376px) {
  .ec-care .ec-salon__content {
    font-size: 12px;
    line-height: 28px;
  }
}
.ec-care .ec-salon__text {
  margin-bottom: 20px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
ABOUT

トップページで使用されているABOUTのスタイルです。

*/
/*
トピック

Styleguide 13.0
*/
#topic.ec-topicRole {
  background: #fff;
}
@media (min-width: 768px) {
  #topic.ec-topicRole {
    padding: 120px 0 200px 0;
  }
}
@media (max-width: 767px) {
  #topic.ec-topicRole {
    padding: 60px 0 100px 0;
  }
}
#topic.ec-topicRole .ec-widthRole {
  margin: 0 auto;
  width: 100%;
}
#topic.ec-topicRole .ec-role {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: initial;
}
#topic.ec-topicRole .ec-headingCaption__en {
  color: #5C5C67;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 32px;
  line-height: 54px;
  letter-spacing: 0.18em;
  margin-bottom: 82px;
  text-align: center;
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-headingCaption__en {
    font-size: 24px;
    line-height: 37px;
    white-space: nowrap;
    letter-spacing: 0.15em;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-headingCaption__en.fz23 {
    font-size: 24px;
    letter-spacing: 0.05em;
  }
}
@media (max-width: 400px) {
  #topic.ec-topicRole .ec-headingCaption__en.fz23 {
    font-size: 23px;
  }
}
@media (max-width: 375px) {
  #topic.ec-topicRole .ec-headingCaption__en.fz23 {
    font-size: 22px;
    letter-spacing: 0.04em;
  }
}
#topic.ec-topicRole .ec-topicRole__listItem {
  margin-bottom: 120px;
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-topicRole__listItem {
    margin-bottom: 48px;
  }
}
#topic.ec-topicRole .ec-topicRole__listItem:not(:last-of-type) {
  margin-right: 24px;
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-topicRole__listItem:not(:last-of-type) {
    margin-right: 6px;
  }
}
@media only screen and (min-width: 768px) {
  #topic.ec-topicRole .ec-topicRole__listItem {
    width: calc((100% - 24px) / 2);
  }
  #topic.ec-topicRole .ec-topicRole__listItem img {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-topicRole__listItem {
    width: calc((100% - 6px) / 2);
  }
  #topic.ec-topicRole .ec-topicRole__listItem img {
    width: 100%;
    margin-left: 0;
  }
}
#topic.ec-topicRole .ec-about {
  color: #000;
  height: auto;
  margin: 0 auto;
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-about {
    flex-direction: column-reverse;
  }
}
#topic.ec-topicRole .ec-about__img {
  width: 52%;
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-about__img {
    width: 100%;
  }
}
#topic.ec-topicRole .ec-about__img img {
  margin-left: -20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-about__img img {
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-about__description {
    margin: 0 auto 40px auto;
    width: 100%;
  }
}
#topic.ec-topicRole .ec-about__content {
  color: #5C5C67;
  font-size: 14px;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  margin-bottom: 36px;
  text-align: center;
}
#topic.ec-topicRole .ec-about__content p {
  line-height: 2.4em;
  letter-spacing: 0.12em;
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-about__content p {
    line-height: 2.3em;
  }
}
#topic.ec-topicRole .ec-annotation {
  color: #5C5C67;
  font-size: 12px;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 2em;
  letter-spacing: 0.12em;
  text-align: center;
  margin-bottom: 68px;
}
@media (max-width: 767px) {
  #topic.ec-topicRole .ec-annotation {
    margin-bottom: 40px;
  }
}

.ec-movieWrapper {
  display: flex;
  text-align: center;
  margin: 0 auto 129px auto;
  width: 81%;
}
@media (max-width: 767px) {
  .ec-movieWrapper {
    margin-bottom: 68px;
    width: 100%;
  }
}
.ec-movieWrapper .ec-ytWrap {
  width: 100%;
  margin: auto;
  flex-shrink: 0;
}
@media (max-width: 690px) {
  .ec-movieWrapper .ec-ytWrap {
    height: 59.4vw;
  }
}
.ec-movieWrapper .ec-ytBlock {
  position: relative;
}
.ec-movieWrapper .ec-videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 0;
  height: 0;
  width: 100%;
}
.ec-movieWrapper .ec-videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ec-movieWrapper .ec-videoWrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("/html/template/default/assets/img/top/movie_new.png");
  background-size: cover;
}
.ec-movieWrapper .ec-videoWrapper.pv::after {
  display: none;
}
.ec-movieWrapper #play {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  width: 12%;
}

.ec-movieText {
  margin-left: 9px;
}
@media (max-width: 767px) {
  .ec-movieText {
    margin-left: 4px;
  }
}

.ec-movieLine {
  height: 93px;
  margin-left: 0;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .ec-movieLine {
    height: 36px;
    margin-bottom: 4px;
  }
}

.ec-movieCaption {
  writing-mode: vertical-rl;
  font-size: 14px;
  font-family: "BonvenoCF-Light", sans-serif;
  letter-spacing: 0.12em;
  color: #5C5C67;
}
@media (max-width: 767px) {
  .ec-movieCaption {
    font-size: 7px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
MEDIA

トップページで使用されているMEDIAのスタイルです。

*/
/*
メディア

Styleguide 15.0
*/
@media (max-width: 1100px) {
  #media.ec-media .ec-role {
    padding-left: 50px;
    padding-right: 50px;
  }
}
#media.ec-media .ec-widthRole {
  margin: 0 auto;
}
#media.ec-media .ec-section__titleEn {
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  #media.ec-media .ec-section__titleEn {
    margin-bottom: 40px;
  }
}
#media.ec-media .ec-media__scroll {
  display: flex;
  flex-direction: column;
}
#media.ec-media .ec-media__scroll .ec-scroll__wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  #media.ec-media .ec-media__scroll .ec-scroll__wrap {
    order: 2;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 0;
  }
}
#media.ec-media .ec-media__scroll .ec-scroll__arrow {
  width: 5px;
  height: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: inline;
}
#media.ec-media .ec-media__scroll .ec-scroll__arrow:not(:last-child) {
  margin-right: 32px;
}
#media.ec-media .ec-media__list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
}
#media.ec-media .ec-media__list .slick-slide {
  margin: 0 12px;
}
@media (max-width: 767px) {
  #media.ec-media .ec-media__list .slick-slide {
    width: calc(50% - 7.5px);
  }
}
#media.ec-media .ec-media__list .slick-list {
  margin: 0 -12px;
}
#media.ec-media .ec-media__list img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 8px;
}
#media.ec-media .ec-media__list img.slide-arrow {
  position: absolute;
  height: 36px;
  width: 36px;
  top: calc(50% - 60px);
  cursor: pointer;
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  #media.ec-media .ec-media__list img.slide-arrow {
    height: 30px;
    width: 30px;
  }
}
@media (max-width: 870px) {
  #media.ec-media .ec-media__list img.slide-arrow {
    top: calc(50% - 70px);
  }
}
@media (max-width: 767px) {
  #media.ec-media .ec-media__list img.slide-arrow {
    top: calc(50% - 55px);
  }
}
@media (max-width: 600px) {
  #media.ec-media .ec-media__list img.slide-arrow {
    height: 24px;
    width: 24px;
    top: calc(50% - 70px);
  }
}
@media (max-width: 390px) {
  #media.ec-media .ec-media__list img.slide-arrow {
    top: calc(50% - 85px);
  }
}
@media (max-width: 375px) {
  #media.ec-media .ec-media__list img.slide-arrow {
    top: calc(50% - 95px);
  }
}
#media.ec-media .ec-media__list img.prev-arrow {
  left: -50px;
}
@media (max-width: 1100px) {
  #media.ec-media .ec-media__list img.prev-arrow {
    left: -36px;
  }
}
@media (max-width: 390px) {
  #media.ec-media .ec-media__list img.prev-arrow {
    left: -30px;
  }
}
#media.ec-media .ec-media__list img.next-arrow {
  right: -50px;
}
@media (max-width: 1100px) {
  #media.ec-media .ec-media__list img.next-arrow {
    right: -36px;
  }
}
@media (max-width: 390px) {
  #media.ec-media .ec-media__list img.next-arrow {
    right: -30px;
  }
}
#media.ec-media .ec-media__name {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  font-size: 13px;
  line-height: 28px;
  color: #5C5C67;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
SNSのスタイルです。

*/
#instagram.ec-instagram {
  padding: 200px 0;
}
@media (max-width: 767px) {
  #instagram.ec-instagram {
    padding: 100px 0;
  }
}
#instagram.ec-instagram .ec-widthRole {
  margin: 0 auto;
}
#instagram.ec-instagram .ec-section__titleEn {
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  #instagram.ec-instagram .ec-section__titleEn {
    margin-bottom: 40px;
  }
}
#instagram.ec-instagram .ec-instagram__list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  #instagram.ec-instagram .ec-instagram__list {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}
#instagram.ec-instagram .ec-instagram__list li {
  width: 19%;
}
@media (max-width: 767px) {
  #instagram.ec-instagram .ec-instagram__list li {
    width: calc(50% - 7.5px);
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  #instagram.ec-instagram .ec-instagram__list li:nth-of-type(odd) {
    margin-right: 11px;
  }
}
@media (min-width: 768px) {
  #instagram.ec-instagram .ec-instagram__list li:last-child {
    display: none;
  }
}
@media (max-width: 767px) {
  #instagram.ec-instagram .ec-instagram__list li:last-child {
    display: block;
  }
}
#instagram.ec-instagram .ec-instagram__list img {
  width: 100%;
  height: auto;
}
#instagram.ec-instagram .ec-instagram__linkWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#instagram.ec-instagram .ec-instagram__link {
  float: right;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-bottom: 15px;
}
#instagram.ec-instagram .ec-instagram__link a {
  color: #5C5C67;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 13px;
  white-space: nowrap;
}
@media (max-width: 767px) {
  #instagram.ec-instagram .ec-instagram__link a {
    font-size: 11px;
  }
}
#instagram.ec-instagram .ec-instagram__link a:hover {
  text-decoration: none;
  opacity: 0.8;
}
#instagram.ec-instagram .ec-instagram__link img {
  width: 93px;
  height: 1px;
  margin-right: 8px;
}
@media (max-width: 767px) {
  #instagram.ec-instagram .ec-instagram__link img {
    width: 46px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
ショップガイドのスタイルです。
対応twig: app/template/default/Block/bottomInfo.twig

*/
#shop_guide.ec-shopGuide {
  padding: 120px 0;
  background: #D7E1E4;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide {
    padding: 80px 0 64px 0;
  }
}
#shop_guide.ec-shopGuide .ec-widthRole {
  margin: 0 auto;
}
#shop_guide.ec-shopGuide .ec-section__titleEn {
  color: #fff;
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide .ec-section__titleEn {
    margin-bottom: 48px;
  }
}
@media (max-width: 375px) {
  #shop_guide.ec-shopGuide .ec-section__titleEn {
    white-space: nowrap;
  }
}
#shop_guide.ec-shopGuide .ec-shopGuide__list {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list {
    justify-content: space-evenly;
  }
}
#shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__detail {
  padding-bottom: 20px;
  width: 33.33%;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__detail {
    padding-bottom: 16px;
  }
}
@media (max-width: 600px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__detail {
    padding-bottom: 14px;
  }
}
@media (max-width: 428px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__detail {
    padding-bottom: 10px;
  }
}
#shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__secTitleEn {
  font-family: "BonvenoCF-Light", sans-serif;
  color: #5C5C67;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.12em;
  text-align: center;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__secTitleEn {
    margin-bottom: 12px;
    font-size: 9px;
  }
}
@media (max-width: 600px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__secTitleEn {
    margin-bottom: 10px;
    font-size: 7px;
    line-height: 1;
  }
}
#shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__secTitleJa {
  font-family: "Koburina-Gothic-w3", sans-serif;
  color: #5C5C67;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.12em;
  text-align: center;
  margin-bottom: 16px;
}
@media (max-width: 600px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-shopGuide__secTitleJa {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
}
#shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__delivery {
  width: 57px;
  height: auto;
  margin: 0 auto;
  display: block;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__delivery {
    width: 47px;
  }
}
@media (max-width: 600px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__delivery {
    width: 37px;
    height: 25px;
  }
}
#shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__payment {
  width: 51px;
  height: auto;
  margin: 0 auto;
  display: block;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__payment {
    width: 41px;
  }
}
@media (max-width: 600px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__payment {
    width: 31px;
    height: 25px;
  }
}
#shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__returns {
  width: 53px;
  height: auto;
  margin: 0 auto;
  display: block;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__returns {
    width: 43px;
  }
}
@media (max-width: 600px) {
  #shop_guide.ec-shopGuide .ec-shopGuide__list .ec-icon__returns {
    width: 33px;
    height: 25px;
    transform: scale(1.2);
  }
}
#shop_guide.ec-shopGuide .ec-viewmore {
  width: 120px;
  text-align: center;
  float: none;
  margin: 0 auto;
  display: block;
  padding-bottom: 8px;
}
@media (max-width: 600px) {
  #shop_guide.ec-shopGuide .ec-viewmore {
    padding-bottom: 6px;
  }
}
@media (max-width: 428px) {
  #shop_guide.ec-shopGuide .ec-viewmore {
    width: 110px;
    padding-bottom: 4px;
  }
}
#shop_guide.ec-shopGuide .ec-viewmore__wrap {
  margin-top: 24px;
}
@media (max-width: 767px) {
  #shop_guide.ec-shopGuide .ec-viewmore__wrap {
    margin-top: 16px;
  }
}
#shop_guide.ec-shopGuide .ec-viewmore a {
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 28px;
}
@media (max-width: 600px) {
  #shop_guide.ec-shopGuide .ec-viewmore a {
    font-size: 14px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
TOPページ　ファーストビューのスタイルです。

*/
#firstview {
  margin-left: 0;
  margin-right: 0;
  max-width: initial;
}

.ec-firstview {
  position: relative;
}
@media (min-width: 768px) {
  .ec-firstview .ec-firstview__imgPc {
    width: 100%;
    display: block;
  }
}
@media (max-width: 767px) {
  .ec-firstview .ec-firstview__imgPc {
    display: none;
  }
}
@media (min-width: 768px) {
  .ec-firstview .ec-firstview__imgSp {
    display: none;
  }
}
@media (max-width: 767px) {
  .ec-firstview .ec-firstview__imgSp {
    display: block;
    width: 100%;
    height: 90%;
    object-fit: cover;
    min-height: 340px;
  }
}
.ec-firstview .ec-firstview__captions {
  position: absolute;
  top: 10vh;
  left: 6vw;
}
@media (max-width: 1280px) {
  .ec-firstview .ec-firstview__captions {
    top: 8vh;
  }
}
@media (max-width: 1000px) {
  .ec-firstview .ec-firstview__captions {
    top: 6vh;
  }
}
@media (max-width: 767px) {
  .ec-firstview .ec-firstview__captions {
    top: 7vh;
  }
}
.ec-firstview .ec-firstview__subCaption {
  color: #fff;
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 1.5vw;
  letter-spacing: 0.12em;
  padding: 6px 14px;
  border: 1px solid #fff;
  margin-bottom: 2vw;
  display: inline-block;
}
@media (max-width: 767px) {
  .ec-firstview .ec-firstview__subCaption {
    padding: 4px 10px;
    font-size: 2.6vw;
    margin-bottom: 4vh;
  }
}
@media (max-width: 600px) {
  .ec-firstview .ec-firstview__subCaption {
    margin-bottom: 2.8vh;
  }
}
.ec-firstview .ec-firstview__captionXs {
  color: #fff;
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 15px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .ec-firstview .ec-firstview__captionXs {
    font-size: 14px;
    margin-bottom: 25px;
  }
}
.ec-firstview .ec-firstview__captionLg {
  color: #fff;
  font-family: "RyuminPr5-Light", sans-serif;
  font-size: 2.7vw;
  line-height: 1.4em;
  letter-spacing: 0.12em;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .ec-firstview .ec-firstview__captionLg {
    font-size: 3.7vw;
    margin-bottom: 1.2vh;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
TOPページ　ランキングのスタイルです。

*/
.ec-ranking {
  margin-bottom: 200px;
}
.ec-ranking .ec-widthRole {
  margin: 0 auto;
}
.ec-ranking .ec-section__titleEn {
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .ec-ranking .ec-section__titleEn {
    margin-bottom: 40px;
    letter-spacing: 0.2em;
  }
}
.ec-ranking .ec-ranking__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.ec-ranking .ec-ranking__list .slick-slide {
  margin: 0 6px;
}
@media (max-width: 767px) {
  .ec-ranking .ec-ranking__list .slick-slide {
    width: calc(50% - 7.5px);
  }
}
.ec-ranking .ec-ranking__list .slick-list {
  margin: 0 -6px;
}
.ec-ranking .ec-ranking__list img.slide-arrow {
  top: -20px;
  width: 5px;
  height: 10px;
}
@media (max-width: 767px) {
  .ec-ranking .ec-ranking__list img.slide-arrow {
    top: initial;
    bottom: -64px;
  }
}
.ec-ranking .ec-ranking__list img.prev-arrow {
  right: 37px;
}
@media (max-width: 767px) {
  .ec-ranking .ec-ranking__list img.prev-arrow {
    left: calc((100% - 37px) / 2);
  }
}
.ec-ranking .ec-ranking__list img.next-arrow {
  right: 0;
}
@media (max-width: 767px) {
  .ec-ranking .ec-ranking__list img.next-arrow {
    right: calc((100% - 37px) / 2);
  }
}
.ec-ranking .ec-rank__listChild {
  flex: 1;
  margin-right: 3%;
}
.ec-ranking .ec-rank__listChild:last-of-type {
  margin-right: 0;
}
@media (max-width: 1110px) {
  .ec-ranking .ec-rank__listChild {
    margin-right: 2%;
  }
}
.ec-ranking .ec-rank__numWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ec-ranking .ec-rank__num {
  font-family: "Fahkwang", sans-serif;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #5C5C67;
  margin-bottom: 16px;
}
.ec-ranking .ec-rank__num1 {
  color: #A8975D;
}
.ec-ranking .ec-rank__num2 {
  color: #989898;
}
.ec-ranking .ec-rank__num3 {
  color: #BB9A86;
}
.ec-ranking .ec-rank__icon {
  width: 14px;
  height: auto;
}
@media (max-width: 767px) {
  .ec-ranking .ec-rank__itemInner {
    width: 100%;
    overflow: hidden;
  }
}
.ec-ranking .ec-rank__itemImg {
  margin: 32px auto 25px auto;
  width: auto;
  aspect-ratio: 1/1.3;
  display: block;
  object-fit: cover;
}
@media (max-width: 767px) {
  .ec-ranking .ec-rank__itemImg {
    margin: 16px auto 13px auto;
    transform: scale(1.3);
  }
}
.ec-ranking .ec-rank__name {
  color: #5C5C67;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", sans-serif;
  font-size: 14px;
  line-height: 24px;
  height: 24px;
  letter-spacing: 0.12em;
  text-align: center;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .ec-ranking .ec-rank__name {
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.1em;
    margin-top: 13px;
    margin-bottom: 27px;
  }
}
.ec-ranking .ec-rank__price {
  color: #5C5C67;
  font-family: "Fahkwang", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.12em;
  text-align: center;
}
@media (max-width: 767px) {
  .ec-ranking .ec-rank__price {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1em;
    padding-top: 20px;
  }
}

/*
.slick-track {
  display: flex;
}

.slick-slide {
  height: auto!important;
}

.ec-ranking {
  background: #F2F2F2;
  padding: 56px 0 88px;
  .ec-widthRole {
    margin: 0 auto;
  }
  .ec-section__title {
    margin-bottom: 42px;
  }
  .ec-ranking__list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin: 0 0 0 -2%;
    @media (max-width: 767px) {
      flex-flow: unset;
      justify-content: space-between;
      margin: 0;
    }
    .ec-rank__listChild {
      width: 18%;
      margin: 0 0 0 2%;
      @media (max-width: 767px) {
        width: initial;
        margin: 0;
        padding: 0 7px;
      }
    }
    .ec-rank__item {
      background: #fff;
      width: 100%;
      height: 100%;
      padding: 8px 16px 32px 16px;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
      img {
        width: 82%;
        height: 136px;
        display: block;
        margin: 0 auto 32px;
        object-fit: contain;
        @media (max-width: 900px) and (min-width: 768px) {
          margin: 0 auto 20px;
        }
      }
    }
    .ec-rank__num {
      font-family: Lato, sans-serif;
      color: #000;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
  .ec-rank__name {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 13px;
  }
  .ec-product__brand {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .ec-rank__detail {
    display: flex;
    align-items: center;
  }
  .ec-rank__price {
    font-family: Lato, sans-serif;
    font-size: 15px;
    line-height: 28px;
  }
  .ec-rank__tax {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 12px;
    line-height: 28px;
  }
  .slick-list {
    @media (max-width: 767px) {
      margin: 0 -7.5px;
    }
  }
  .slick-slide {
    @media (max-width: 767px) {
    margin: 0 7.5px;
    }
  }
  img.slide-arrow {
    @media (max-width: 767px) {
      bottom: -24px;
      width: 5px;
      height: 10px;
    }
  }
  img.prev-arrow {
    @media (max-width: 767px) {
      left: calc((100% - 37px) / 2);
    }
  }
  img.next-arrow {
    @media (max-width: 767px) {
      right: calc((100% - 37px) / 2);
    }
  }
}
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
最近チェックした商品のスタイルです。

*/
#checkedItems.ec-ranking .ec-ranking__list {
  justify-content: initial;
}
#checkedItems.ec-ranking .ec-rank__listChild {
  flex: none;
  width: 17.6%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole {
  margin-bottom: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1130px;
  }
  .ec-searchnavRole:after {
    content: " ";
    display: table;
  }
  .ec-searchnavRole:after {
    clear: both;
  }
  .ec-searchnavRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif;
  }
  .ec-searchnavRole img {
    max-width: 100%;
  }
  .ec-searchnavRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .ec-searchnavRole *,
.ec-searchnavRole *::before,
.ec-searchnavRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  .ec-searchnavRole img {
    width: 100%;
  }
}
.ec-searchnavRole .ec-searchnavRole__infos {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  border-top: 0;
  margin-bottom: 16px;
  padding-top: 5px;
  flex-direction: column;
}
.ec-searchnavRole .ec-searchnavRole__infos:after {
  content: " ";
  display: table;
}
.ec-searchnavRole .ec-searchnavRole__infos:after {
  clear: both;
}
.ec-searchnavRole .ec-searchnavRole__infos textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-searchnavRole .ec-searchnavRole__infos img {
  max-width: 100%;
}
.ec-searchnavRole .ec-searchnavRole__infos html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-searchnavRole .ec-searchnavRole__infos *,
.ec-searchnavRole .ec-searchnavRole__infos *::before,
.ec-searchnavRole .ec-searchnavRole__infos *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-searchnavRole .ec-searchnavRole__infos img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__infos {
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid #ccc;
    padding-top: 16px;
    flex-direction: row;
  }
}
.ec-searchnavRole .ec-searchnavRole__counter {
  margin-bottom: 16px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__counter {
    margin-bottom: 0;
    width: 50%;
  }
}
.ec-searchnavRole .ec-searchnavRole__actions {
  text-align: right;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__actions {
    width: 50%;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-shelfRole:after {
  content: " ";
  display: table;
}
.ec-shelfRole:after {
  clear: both;
}
.ec-shelfRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-shelfRole img {
  max-width: 100%;
}
.ec-shelfRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-shelfRole *,
.ec-shelfRole *::before,
.ec-shelfRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-shelfRole img {
  width: 100%;
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.ec-shelfGrid a {
  color: inherit;
  text-decoration: none;
}
.ec-shelfGrid a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__item {
  margin-bottom: 36px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.ec-shelfGrid .ec-shelfGrid__item-image {
  margin-bottom: 10px;
  text-align: center;
}
.ec-shelfGrid .ec-shelfGrid__item img {
  width: auto;
  max-height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item {
    padding: 0 16px;
    width: 25%;
  }
}
.ec-shelfGrid .ec-shelfGrid__item .ec-productRole__btn {
  margin-top: auto;
  margin-bottom: 15px;
}
.ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
  padding-right: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
    padding: 0 16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
  padding-left: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
    padding: 0 16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__title {
  margin-bottom: 7px;
}
.ec-shelfGrid .ec-shelfGrid__plice {
  font-weight: bold;
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;
}
.ec-shelfGridCenter a {
  color: inherit;
  text-decoration: none;
}
.ec-shelfGridCenter a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item {
  margin-bottom: 36px;
  width: 50%;
}
.ec-shelfGridCenter .ec-shelfGridCenter__item-image {
  margin-bottom: 10px;
  text-align: center;
}
.ec-shelfGridCenter .ec-shelfGridCenter__item img {
  width: auto;
  max-height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item {
    padding: 0 16px;
    width: 25%;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item .ec-productRole__btn {
  margin-top: auto;
  padding-top: 1em;
}
.ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
  padding-right: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
    padding: 0 16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
  padding-left: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
    padding: 0 16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__title {
  margin-bottom: 7px;
}
.ec-shelfGridCenter .ec-shelfGridCenter__plice {
  font-weight: bold;
}

/*
商品一覧フッター

商品一覧 フッター に関する Project コンポーネントを定義します。

ex [商品一覧 ページャ部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.3.pager.pug
+ec-pagerRole

Styleguide 13.3

*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。

ex [商品一覧、商品詳細](http://demo3.ec-cube.net/products/list)

+ec-modal

Styleguide 13.4

*/
.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
}
.ec-modal.small {
  width: 30%;
}
.ec-modal.full {
  width: 100%;
  height: 100%;
}
.ec-modal .ec-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}
.ec-modal .ec-modal-wrap {
  position: relative;
  border-radius: 2px;
  border: 1px solid #333;
  background-color: #fff;
  width: 90%;
  margin: 20px;
  padding: 40px 5px;
}
@media only screen and (min-width: 768px) {
  .ec-modal .ec-modal-wrap {
    padding: 40px 10px;
    width: 50%;
    margin: 20px auto;
  }
}
.ec-modal .ec-modal-close {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 20px;
  height: 30px;
  width: 20px;
}
.ec-modal .ec-modal-close:hover {
  color: #4b5361;
}
.ec-modal .ec-modal-box {
  text-align: center;
}
.ec-modal .ec-role {
  margin-top: 20px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  font-family: "Koburina-Gothic-w3", sans-serif;
  letter-spacing: 0.1em;
}
.ec-productRole .ec-productTop {
  display: block;
  margin: 0 auto;
  padding-top: 55px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productTop {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-grid2__cell.ec-productCellImg {
    width: 416px;
    float: left;
  }
}
@media (max-width: 950px) {
  .ec-productRole .ec-grid2__cell.ec-productCellImg {
    width: 46%;
  }
}
@media (max-width: 767px) {
  .ec-productRole .ec-grid2__cell.ec-productCellImg {
    width: 100%;
    float: unset;
  }
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-grid2__cell.ec-productCellDetail {
    width: 464px;
    float: right;
  }
}
@media (max-width: 950px) {
  .ec-productRole .ec-grid2__cell.ec-productCellDetail {
    width: 51%;
  }
}
@media (max-width: 767px) {
  .ec-productRole .ec-grid2__cell.ec-productCellDetail {
    width: 100%;
    float: unset;
  }
}
.ec-productRole .ec-sliderItemRole .slideThumb {
  text-align: left;
}
.ec-productRole .ec-sliderItemRole .slideThumb:nth-of-type(3n+2) {
  text-align: center;
}
.ec-productRole .ec-sliderItemRole .slideThumb:nth-of-type(3n) {
  text-align: right;
}
.ec-productRole .ec-sliderItemRole .slideThumb img {
  width: 88%;
}
.ec-productRole .ec-productImgBox {
  padding: 0;
  margin-bottom: 0;
  width: 100%;
  position: relative;
}
.ec-productRole .ec-productRole__img {
  margin-right: 0;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__img {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.ec-productRole .ec-productRole__profile {
  margin-left: 0;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__profile {
    margin-left: 16px;
  }
}
.ec-productRole .ec-productRole__title .ec-headingTitle {
  font-size: 18px;
  margin-top: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__title .ec-headingTitle {
    font-size: 20px;
    margin-top: 18px;
  }
}
.ec-productRole .ec-productRole__title .ec-headingTitle .ec-headingTitleSmall {
  font-size: 14px;
  margin-top: 17px;
  font-family: "BonvenoCF-Light", sans-serif;
}
.ec-productRole .ec-productRole__tags {
  margin-top: 16px;
  padding: 0;
  padding-bottom: 16px;
}
.ec-productRole .ec-productRole__tag {
  display: inline-block;
  padding: 2px 5px;
  list-style: none;
  font-size: 80%;
  color: #525263;
  border: solid 1px #D7DADD;
  border-radius: 3px;
  background-color: #F5F7F8;
}
.ec-productRole .ec-productRole__priceRegular {
  padding-top: 14px;
}
.ec-productRole .ec-productRole__priceRegularTax {
  margin-left: 5px;
  font-size: 10px;
}
.ec-productRole .ec-productRole__price {
  color: #000000;
  font-size: 28px;
  padding: 0 0 10px 0;
  border-bottom: 0;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__price {
    padding: 14px 0;
  }
}
.ec-productRole .ec-price .ec-price__price {
  font-size: 22px;
  padding: 0;
  font-family: "Fahkwang", sans-serif;
  font-weight: normal;
  color: #5C5C67;
}
.ec-productRole .ec-price .ec-price__tax {
  color: #5C5C67;
  font-size: 15px;
}
.ec-productRole .ec-productRole__code {
  padding: 14px 0;
}
.ec-productRole .ec-productRole__code .product-code-default {
  font-family: "Fahkwang", sans-serif;
}
.ec-productRole .ec-productRole__category {
  padding: 0;
}
.ec-productRole .ec-productRole__category a {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 15px;
}
.ec-productRole .ec-productRole__category a:hover {
  opacity: 0.6;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-productRole .ec-productRole__category ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ec-productRole .ec-productRole__actions {
  padding: 14px 0;
}
.ec-productRole .ec-productRole__actions .ec-select select {
  height: 40px;
  max-width: 100%;
  min-width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__actions .ec-select select {
    min-width: 350px;
    max-width: 350px;
  }
}
.ec-productRole .ec-productRole__text {
  font-size: 14px;
  line-height: 1.6em;
  border: 1px solid rgba(92, 92, 103, 0.5);
  padding: 10px;
}
.ec-productRole .ec-productRole__btnWrapper {
  margin-top: 30px;
  position: relative;
}
.ec-productRole .ec-productRole__btn {
  width: 100%;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__btn {
    margin-bottom: 16px;
  }
}
.ec-productRole .ec-productRole__btnMain {
  float: unset;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__btnMain {
    float: left;
    width: calc(100% - 80px);
  }
}
.ec-productRole .ec-productRole__btnMain .ec-blockBtn--action {
  height: 62px;
}
.ec-productRole .ec-productRole__btnFav {
  float: unset;
  width: 62px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__btnFav {
    float: right;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.ec-productRole .ec-productRole__btnFav.pcFav {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__btnFav.pcFav {
    display: block;
  }
}
.ec-productRole .ec-productRole__btnFav.spFav {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__btnFav.spFav {
    display: none;
  }
}
.ec-productRole .ec-productRole__btn .ec-blockBtn--action {
  background-color: #000000;
  border-color: unset;
  font-size: 15px;
  font-weight: normal;
}
@media (max-width: 950px) {
  .ec-productRole .ec-productRole__btn .ec-blockBtn--action {
    font-size: 1.6vw;
  }
}
@media (max-width: 767px) {
  .ec-productRole .ec-productRole__btn .ec-blockBtn--action {
    font-size: 14px;
  }
}
.ec-productRole .ec-productRole__btn .ec-blockBtn--cancel {
  width: 62px;
  height: 62px;
  background-color: #EDEDED;
  border: unset;
}
.ec-productRole .ec-productRole__btn .ec-blockBtn--action:hover, .ec-productRole .ec-productRole__btn .ec-blockBtn--cancel:hover {
  opacity: 0.6;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-productRole .ec-productRole__btnFavImg {
  width: 22px;
  height: 18px;
}
.ec-productRole .ec-productRole__contact {
  width: 210px;
  display: block;
  padding: 0 0 16px 0;
  margin-top: 16px;
  font-size: 13px;
  color: #000000;
  border-bottom: 1px solid #000000;
  position: relative;
}
.ec-productRole .ec-productRole__contact:hover {
  opacity: 0.6;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-productRole .ec-productRole__contactImg {
  position: absolute;
  width: 12px;
  height: 12px;
  right: 0;
  top: 3px;
}
.ec-productRole .ec-productRole__description {
  margin-bottom: 16px;
}

/*
商品詳細ページの商品特徴部分(白枠で囲まれている部分)

商品特徴部分で使用されているクラスの定義です。

Styleguide 14.1
*/
.ec-productDetailBottom {
  margin: 40px auto 0;
  background-color: #F8F8F8;
  padding: 56px 0;
}
@media only screen and (min-width: 768px) {
  .ec-productDetailBottom {
    margin-top: 80px;
  }
}
.ec-productDetailBottom__wrapper {
  padding: 60px 7vw;
  margin: 0 auto;
  background-color: #FFFFFF;
}
@media only screen and (min-width: 768px) {
  .ec-productDetailBottom__wrapper {
    padding: 84px 7vw;
  }
}
.ec-productDetailBottom__detail {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0.1em;
}
.ec-productDetailBottom__title {
  text-align: left;
  font-weight: bold;
  padding-bottom: 25px;
  margin: 0;
  font-size: 14px;
  padding-bottom: 15px;
}
.ec-productDetailBottom__title:nth-of-type(n+2) {
  margin-top: 38px;
}
.ec-productDetailBottom__detailTitle {
  font-weight: 400;
  line-height: 1.5em;
  margin: 15px 0 0 0;
  font-size: 16px;
}
.ec-productDetailBottom__detailImg {
  width: auto !important;
  max-width: 100%;
  display: block;
  padding-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .ec-productDetailBottom__detailImg {
    padding-bottom: 25px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.ec-cartRole:after {
  content: " ";
  display: table;
}
.ec-cartRole:after {
  clear: both;
}
.ec-cartRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-cartRole img {
  max-width: 100%;
}
.ec-cartRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-cartRole *,
.ec-cartRole *::before,
.ec-cartRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-cartRole img {
  width: 100%;
}
.ec-cartRole::before {
  display: none;
}
.ec-cartRole .ec-cartRole__progress {
  width: 100%;
  text-align: center;
}
.ec-cartRole .ec-cartRole__error {
  width: 100%;
  text-align: center;
}
.ec-cartRole .ec-cartRole__error .ec-alert-warning {
  max-width: 80%;
  display: inline-block;
}
.ec-cartRole .ec-cartRole__totalText {
  margin-bottom: 0;
  padding: 16px 0 6px;
  width: 100%;
  text-align: center;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__totalText {
    margin-bottom: 30px;
    padding: 0;
  }
}
.ec-cartRole .ec-cartRole__cart {
  margin: 0;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__cart {
    margin: 0 10%;
  }
}
.ec-cartRole .ec-cartRole__actions {
  text-align: right;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__actions {
    width: 30%;
    margin-right: 10%;
  }
}
.ec-cartRole .ec-cartRole__total {
  padding: 15px 0 30px;
  font-weight: bold;
  font-size: 16px;
}
.ec-cartRole .ec-cartRole__totalAmount {
  margin-left: 30px;
  color: #de5d50;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__totalAmount {
    font-size: 24px;
  }
}
.ec-cartRole .ec-blockBtn--action {
  margin-bottom: 10px;
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-cartTable {
    border-top: none;
  }
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  width: 100%;
  background: #F4F3F0;
}
@media only screen and (min-width: 768px) {
  .ec-cartHeader {
    display: table-row;
  }
}
.ec-cartHeader .ec-cartHeader__label {
  display: table-cell;
  padding: 16px;
  text-align: center;
  background: #F4F3F0;
  overflow-x: hidden;
  font-weight: bold;
}

.ec-cartCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-cartCompleteRole:after {
  content: " ";
  display: table;
}
.ec-cartCompleteRole:after {
  clear: both;
}
.ec-cartCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-cartCompleteRole img {
  max-width: 100%;
}
.ec-cartCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-cartCompleteRole *,
.ec-cartCompleteRole *::before,
.ec-cartCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-cartCompleteRole img {
  width: 100%;
}

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/
.ec-cartRow {
  display: table-row;
}
.ec-cartRow .ec-cartRow__delColumn {
  border-bottom: 1px dotted #ccc;
  text-align: center;
  display: table-cell;
  width: 14%;
  vertical-align: middle;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__delColumn {
    width: 8.3333333%;
  }
}
.ec-cartRow .ec-cartRow__delColumn .ec-icon img {
  width: 1.5em;
  height: 1.5em;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__delColumn .ec-icon img {
    width: 1em;
    height: 1em;
  }
}
.ec-cartRow .ec-cartRow__contentColumn {
  border-bottom: 1px dotted #ccc;
  padding: 10px 0;
  display: table;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__contentColumn {
    display: table-cell;
  }
}
.ec-cartRow .ec-cartRow__img {
  display: table-cell;
  width: 40%;
  vertical-align: middle;
  padding-right: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__img {
    display: inline-block;
    min-width: 80px;
    max-width: 100px;
    padding-right: 0;
  }
}
.ec-cartRow .ec-cartRow__summary {
  display: table-cell;
  margin-left: 5px;
  font-weight: bold;
  vertical-align: middle;
  width: 46%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__summary {
    display: inline-block;
    width: calc(100% - 130px);
    margin-left: 20px;
    vertical-align: middle;
  }
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
  margin-bottom: 5px;
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
  display: block;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
    display: none;
  }
}
.ec-cartRow .ec-cartRow__amountColumn {
  display: table-cell;
  border-bottom: 1px dotted #ccc;
  vertical-align: middle;
  text-align: center;
  width: 20%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn {
    width: 16.66666667%;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
  display: none;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
    display: block;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
  display: block;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
    display: none;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
    display: block;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton {
  margin: 0 2px;
  display: inline-block;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background: #fff;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton .ec-cartRow__amountUpButton__icon img {
  display: block;
  margin-left: -0.4em;
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  top: 28%;
  left: 50%;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  margin: 0 2px;
  display: inline-block;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background: #fff;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton .ec-cartRow__amountDownButton__icon img, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled .ec-cartRow__amountDownButton__icon img {
  display: block;
  margin-left: -0.4em;
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  top: 28%;
  left: 50%;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default;
}
.ec-cartRow .ec-cartRow__subtotalColumn {
  display: none;
  border-bottom: 1px dotted #ccc;
  text-align: right;
  width: 16.66666667%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__subtotalColumn {
    display: table-cell;
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default;
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/
.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px;
}
.ec-alert-warning .ec-alert-warning__icon {
  display: inline-block;
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  color: #fff;
  fill: #fff;
  vertical-align: top;
}
.ec-alert-warning .ec-alert-warning__text {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
}

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
.ec-orderRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}
.ec-orderRole:after {
  content: " ";
  display: table;
}
.ec-orderRole:after {
  clear: both;
}
.ec-orderRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-orderRole img {
  max-width: 100%;
}
.ec-orderRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-orderRole *,
.ec-orderRole *::before,
.ec-orderRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-orderRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-orderRole {
    margin-top: 20px;
    flex-direction: row;
  }
}
.ec-orderRole .ec-inlineBtn {
  font-weight: normal;
}
.ec-orderRole .ec-orderRole__detail {
  padding: 0;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-orderRole .ec-orderRole__detail {
    padding: 0 16px;
    width: 66.66666%;
  }
}
.ec-orderRole .ec-orderRole__summary {
  width: 100%;
}
.ec-orderRole .ec-orderRole__summary .ec-inlineBtn {
  display: inline-block;
}
@media only screen and (min-width: 768px) {
  .ec-orderRole .ec-orderRole__summary {
    width: 33.33333%;
    padding: 0 16px;
  }
  .ec-orderRole .ec-orderRole__summary .ec-inlineBtn {
    display: none;
  }
}
.ec-orderRole .ec-borderedList {
  margin-bottom: 20px;
  border-top: 1px dotted #ccc;
}
@media only screen and (min-width: 768px) {
  .ec-orderRole .ec-borderedList {
    border-top: none;
  }
}

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  margin-bottom: 30px;
}
.ec-orderOrder .ec-orderOrder__items {
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc;
}

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  margin-bottom: 30px;
}
.ec-orderAccount p {
  margin-bottom: 0;
}
.ec-orderAccount:after {
  content: " ";
  display: table;
}
.ec-orderAccount:after {
  clear: both;
}
.ec-orderAccount .ec-orderAccount__change {
  display: inline-block;
  margin-left: 10px;
  float: right;
}
.ec-orderAccount .ec-orderAccount__account {
  margin-bottom: 16px;
}

/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery .ec-orderDelivery__title {
  padding: 16px 0 17px;
  font-weight: bold;
  font-size: 18px;
  position: relative;
}
.ec-orderDelivery .ec-orderDelivery__change {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}
.ec-orderDelivery .ec-orderDelivery__items {
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc;
}
.ec-orderDelivery .ec-orderDelivery__address {
  margin: 10px 0 18px;
}
.ec-orderDelivery .ec-orderDelivery__address p {
  margin: 0;
}
/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-orderConfirm {
    margin-bottom: 0;
  }
}
.ec-orderConfirm .ec-input textarea, .ec-orderConfirm .ec-halfInput textarea, .ec-orderConfirm .ec-numberInput textarea, .ec-orderConfirm .ec-zipInput textarea, .ec-orderConfirm .ec-telInput textarea, .ec-orderConfirm .ec-select textarea, .ec-orderConfirm .ec-birth textarea {
  height: 96px;
}

/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress {
  padding: 0 10px;
}
@media only screen and (min-width: 768px) {
  .ec-AddAddress {
    margin: 0 10%;
  }
}
.ec-AddAddress .ec-AddAddress__info {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
}
.ec-AddAddress .ec-AddAddress__add {
  border-top: 1px solid #f4f4f4;
  padding-top: 20px;
  margin-bottom: 20px;
}
.ec-AddAddress .ec-AddAddress__item {
  display: table;
  padding: 16px;
  background: #f4f4f4;
  margin-bottom: 16px;
}
.ec-AddAddress .ec-AddAddress__itemThumb {
  display: table-cell;
  min-width: 160px;
  width: 20%;
}
.ec-AddAddress .ec-AddAddress__itemThumb img {
  width: 100%;
}
.ec-AddAddress .ec-AddAddress__itemtContent {
  display: table-cell;
  vertical-align: middle;
  padding-left: 16px;
  font-size: 16px;
}
.ec-AddAddress .ec-AddAddress__itemtTitle {
  font-weight: bold;
  margin-bottom: 10px;
}
.ec-AddAddress .ec-AddAddress__itemtSize {
  margin-bottom: 10px;
}
.ec-AddAddress .ec-AddAddress__select {
  margin-bottom: 5px;
}
.ec-AddAddress .ec-AddAddress__selectAddress {
  display: inline-block;
}
.ec-AddAddress .ec-AddAddress__selectAddress label {
  font-size: 16px;
  font-weight: normal;
}
.ec-AddAddress .ec-AddAddress__selectAddress select {
  min-width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-AddAddress .ec-AddAddress__selectAddress select {
    min-width: 350px;
  }
}
.ec-AddAddress .ec-AddAddress__selectNumber {
  display: inline-block;
  margin-left: 30px;
}
.ec-AddAddress .ec-AddAddress__selectNumber label {
  font-size: 16px;
  font-weight: normal;
}
.ec-AddAddress .ec-AddAddress__selectNumber input {
  display: inline-block;
  margin-left: 10px;
  width: 80px;
}
.ec-AddAddress .ec-AddAddress__actions .ec-blockBtn--action {
  margin-bottom: 8px;
}
.ec-AddAddress .ec-AddAddress__new {
  margin-bottom: 20px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole .ec-historyRole__contents {
  padding-top: 1em;
  padding-bottom: 16px;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  color: #525263;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__contents {
    flex-direction: row;
  }
}
.ec-historyRole .ec-historyRole__header {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__header {
    width: 33.3333%;
  }
}
.ec-historyRole .ec-historyRole__detail {
  border-top: 1px dotted #ccc;
  width: 100%;
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid:nth-of-type(1) {
  border-top: none;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailTitle {
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: bold;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailPrice {
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__detail {
    width: 66.6666%;
    border-top: none;
  }
}

/*
注文履歴一覧 規格

マイページ 注文履歴内アイテムの規格を定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole-option

Styleguide 16.1.1
*/
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption {
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 0.5rem;
  font-size: 1.6rem;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption::after {
  display: inline-block;
  padding-left: 0.5rem;
  content: "/";
  font-weight: bold;
}

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader
p hofe

Styleguide 16.1.2
*/
.ec-historyListHeader .ec-historyListHeader__date {
  font-weight: bold;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-historyListHeader .ec-historyListHeader__date {
    font-weight: bold;
    font-size: 20px;
  }
}
.ec-historyListHeader .ec-historyListHeader__action {
  margin: 16px 0;
}
.ec-historyListHeader .ec-historyListHeader__action a {
  font-size: 12px;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-historyListHeader .ec-historyListHeader__action a {
    font-size: 14px;
  }
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailRole

Styleguide 16.2
*/
/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMail

Styleguide 16.2.5
*/
.ec-orderMails .ec-orderMails__item {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
}
.ec-orderMails .ec-orderMails__time {
  margin: 0;
}
.ec-orderMails .ec-orderMails__body {
  display: none;
}

/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴個別](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMailHistory

Styleguide 16.2.6
*/
.ec-orderMail {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 16px;
}
.ec-orderMail .ec-orderMail__time {
  margin: 0;
}
.ec-orderMail .ec-orderMail__body {
  display: none;
}
.ec-orderMail .ec-orderMail__time {
  margin-bottom: 4px;
}
.ec-orderMail .ec-orderMail__link {
  margin-bottom: 4px;
}
.ec-orderMail .ec-orderMail__link a {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}
.ec-orderMail .ec-orderMail__link a:hover {
  color: #33A8D0;
}
.ec-orderMail .ec-orderMail__close a {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}
.ec-orderMail .ec-orderMail__close a:hover {
  color: #33A8D0;
}

/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole .ec-addressRole__item {
  border-top: 1px dotted #ccc;
}
.ec-addressRole .ec-addressRole__actions {
  margin-top: 32px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #ccc;
}

.ec-addressList .ec-addressList__item {
  display: table;
  width: 100%;
  position: relative;
  border-bottom: 1px dotted #ccc;
}
.ec-addressList .ec-addressList__remove {
  vertical-align: middle;
  padding: 16px;
  text-align: center;
}
.ec-addressList .ec-addressList__remove .ec-icon img {
  width: 1em;
  height: 1em;
}
.ec-addressList .ec-addressList__address {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
  margin-right: 4em;
  width: 80%;
}
.ec-addressList .ec-addressList__action {
  position: relative;
  vertical-align: middle;
  text-align: right;
  top: 27px;
  padding-right: 10px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-forgotRole:after {
  content: " ";
  display: table;
}
.ec-forgotRole:after {
  clear: both;
}
.ec-forgotRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-forgotRole img {
  max-width: 100%;
}
.ec-forgotRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-forgotRole *,
.ec-forgotRole *::before,
.ec-forgotRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-forgotRole img {
  width: 100%;
}
.ec-forgotRole .ec-forgotRole__intro {
  font-size: 16px;
}
.ec-forgotRole .ec-forgotRole__form {
  margin: 48px auto 16px;
}
.ec-forgotRole .ec-borderedDefs {
  margin: 32px auto;
}

.ec-forgetCompleteRole {
  margin: 48px auto 0;
}
@media (max-width: 767px) {
  .ec-forgetCompleteRole {
    width: 90%;
  }
}

.ec-resetPassForm {
  margin-top: 48px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-registerRole:after {
  content: " ";
  display: table;
}
.ec-registerRole:after {
  clear: both;
}
.ec-registerRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-registerRole img {
  max-width: 100%;
}
.ec-registerRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-registerRole *,
.ec-registerRole *::before,
.ec-registerRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-registerRole img {
  width: 100%;
}
.ec-registerRole .ec-registerRole__actions {
  padding-top: 20px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-registerRole .ec-registerRole__actions {
    text-align: left;
  }
}
.ec-registerRole .ec-registerRole__actions p {
  margin-bottom: 16px;
}
.ec-registerRole .ec-blockBtn--action {
  margin-bottom: 16px;
}
.ec-registerRole .ec-confirmMessage {
  margin-bottom: 24px;
}

.ec-registerCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-registerCompleteRole:after {
  content: " ";
  display: table;
}
.ec-registerCompleteRole:after {
  clear: both;
}
.ec-registerCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-registerCompleteRole img {
  max-width: 100%;
}
.ec-registerCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-registerCompleteRole *,
.ec-registerCompleteRole *::before,
.ec-registerCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-registerCompleteRole img {
  width: 100%;
}

.ec-entryForm {
  padding-top: 48px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactFormRole

Styleguide 19.2

*/
.contact_page .ec-layoutRole__contents {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.8em;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  color: #5e6770;
  font-family: KoburiGothic, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
}
@media (max-width: 767px) {
  .contact_page .ec-layoutRole__contents {
    font-size: 14px;
  }
}
.contact_page .ec-layoutRole__contents a {
  outline: 0;
  text-decoration: none;
}
.contact_page .ec-layoutRole__contents ul {
  list-style: none;
}
.contact_page .ec-layoutRole__contents li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contact_page .ec-pageHeader {
  position: relative;
  margin: 0 80px 80px;
  padding: 0;
  height: 140px;
  background-color: #c8dade;
}
@media print, screen and (max-width: 1180px) {
  .contact_page .ec-pageHeader {
    margin: 0 50px 50px;
  }
}
@media print, screen and (max-width: 767px) {
  .contact_page .ec-pageHeader {
    margin: 0 0 50px;
  }
}
.contact_page .ec-pageHeader .ec-pageHeader__h1 {
  position: absolute;
  top: 0;
  right: 112px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  color: #ffffff;
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 34px;
  font-family: BonvenoCF, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1;
  border-bottom: none;
}
@media print, screen and (max-width: 1180px) {
  .contact_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 50px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media print, screen and (max-width: 767px) {
  .contact_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 30px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}

.ec-contactFormCommonRole__sp {
  display: none;
}
@media (max-width: 767px) {
  .ec-contactFormCommonRole__sp {
    display: block;
  }
}
.ec-contactFormCommonRole .ec-contactFormCommonRole__dNone {
  display: none;
}
.ec-contactFormCommonRole__main {
  margin: 0;
  padding: 0 80px;
}
@media print, screen and (max-width: 1180px) {
  .ec-contactFormCommonRole__main {
    padding: 0 50px;
  }
}
@media print, screen and (max-width: 767px) {
  .ec-contactFormCommonRole__main {
    padding: 0 30px;
  }
}
.ec-contactFormCommonRole .ec-off1Grid .ec-off1Grid__cell {
  width: 100%;
  max-width: 986px;
  margin: 0 auto;
}
.ec-contactFormCommonRole__flow {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  font-weight: bold;
  margin: 55px 0 0;
  padding: 0 0 24px;
  border-bottom: 1px solid #c8dade;
}
.ec-contactFormCommonRole__flowElement {
  width: 30%;
  height: auto;
  padding: 0;
  color: #a4c0c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
}
.ec-contactFormCommonRole__flowElement.active {
  color: #66a6af;
}
.ec-contactFormCommonRole__flowElement.active .ec-contactFormCommonRole__flowNumber {
  color: #fff;
  background: #66a6af;
}
.ec-contactFormCommonRole__flowNumber {
  background: rgba(200, 218, 222, 0.36);
  width: 26px;
  height: 26px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a4c0c4;
  margin-bottom: 4px;
  font-size: 15px;
}
.ec-contactFormCommonRole .ec-borderedDefs {
  border-top: unset;
  margin-top: 40px;
}
.ec-contactFormCommonRole .ec-borderedDefs dt {
  padding: 9px 0 6px;
}
.ec-contactFormCommonRole .ec-label {
  font-family: KoburiGothic, sans-serif;
  font-size: 18px;
}
@media (max-width: 767px) {
  .ec-contactFormCommonRole .ec-label {
    font-size: 17px;
  }
}
.ec-contactFormCommonRole dl {
  flex-wrap: unset !important;
  display: block;
  border-bottom: 1px dashed #a4c0c4;
  padding: 15px 20px 14px !important;
}
@media (max-width: 767px) {
  .ec-contactFormCommonRole dl {
    padding: 10px 20px 12px !important;
  }
}
.ec-contactFormCommonRole dt, .ec-contactFormCommonRole dd {
  width: 100% !important;
}
.ec-contactFormCommonRole__borderBottomNone {
  border-bottom: unset !important;
}
.ec-contactFormCommonRole .ec-required, .ec-contactFormCommonRole__any {
  font-size: 12px;
  color: #ffffff;
  padding: 2px 10px;
  font-style: normal;
  margin-left: 20px;
  display: inline-block;
  vertical-align: 2px;
}
.ec-contactFormCommonRole .ec-required {
  background: #66a6af;
}
.ec-contactFormCommonRole__any {
  background: rgba(200, 218, 222, 0.36);
  color: #a4c0c4;
}
.ec-contactFormCommonRole .ec-blockBtn--action,
.ec-contactFormCommonRole .ec-blockBtn--cancel {
  font-family: KoburiGothic, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  color: #5e6770;
  font-size: 18px;
  letter-spacing: 0.1em;
  line-height: 2;
  cursor: pointer;
  border: none;
  max-width: 235px;
  height: 63px;
  margin: 0 auto;
  font-weight: normal;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
  position: relative;
}
.ec-contactFormCommonRole .ec-blockBtn--action:before,
.ec-contactFormCommonRole .ec-blockBtn--cancel:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid;
  display: block;
  position: absolute;
}
.ec-contactFormCommonRole .ec-blockBtn--action:before {
  border-color: #5e6770 #5e6770 transparent transparent;
  transform: translate(0, -50%) rotate(45deg);
  -ms-transform: translate(0, -50%) rotate(45deg);
  -webkit-transform: translate(0, -50%) rotate(45deg);
  top: 50%;
  right: 50px;
}
.ec-contactFormCommonRole .ec-blockBtn--cancel:before {
  border-color: #FFF #FFF transparent transparent;
  transform: translate(0, -50%) rotate(225deg);
  -ms-transform: translate(0, -50%) rotate(225deg);
  -webkit-transform: translate(0, -50%) rotate(225deg);
  top: 50%;
  left: 50px;
}
.ec-contactFormCommonRole .ec-blockBtn--action {
  background-color: #c8dade;
}
.ec-contactFormCommonRole .ec-blockBtn--action:hover {
  opacity: 0.7;
}
.ec-contactFormCommonRole .ec-blockBtn--cancel {
  background-color: #5e6770;
  color: #FFF;
}
.ec-contactFormCommonRole .ec-blockBtn--cancel:hover {
  opacity: 0.7;
}

.ec-contactFormRole .ec-contactFormRole__actions {
  padding-top: 20px;
}
.ec-contactFormRole p {
  margin: 16px 0;
}
.ec-contactFormRole .ec-contactFormRole__explanation {
  margin: 0 0 30px;
  padding: 0;
  letter-spacing: 5px;
  font-weight: 500;
  font-size: 28px;
  font-family: KoburiGothic, sans-serif;
}
@media (max-width: 1180px) {
  .ec-contactFormRole .ec-contactFormRole__explanation {
    text-align: center;
    letter-spacing: 3px;
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .ec-contactFormRole .ec-contactFormRole__explanation {
    line-height: 1.8em;
  }
}
.ec-contactFormRole .ec-radio input[type=radio],
.ec-contactFormRole .ec-radio label,
.ec-contactFormRole .ec-checkbox input[type=checkbox],
.ec-contactFormRole .ec-checkbox label {
  cursor: pointer;
}
.ec-contactFormRole .ec-checkbox label,
.ec-contactFormRole .ec-radio label,
.ec-contactFormRole .ec-input [type=text],
.ec-contactFormRole .ec-halfInput [type=text],
.ec-contactFormRole .ec-numberInput [type=text],
.ec-contactFormRole .ec-zipInput [type=text],
.ec-contactFormRole .ec-telInput [type=text],
.ec-contactFormRole .ec-select [type=text],
.ec-contactFormRole .ec-birth [type=text],
.ec-contactFormRole .ec-input [type=tel],
.ec-contactFormRole .ec-halfInput [type=tel],
.ec-contactFormRole .ec-numberInput [type=tel],
.ec-contactFormRole .ec-zipInput [type=tel],
.ec-contactFormRole .ec-telInput [type=tel],
.ec-contactFormRole .ec-select [type=tel],
.ec-contactFormRole .ec-birth [type=tel],
.ec-contactFormRole .ec-input [type=email],
.ec-contactFormRole .ec-halfInput [type=email],
.ec-contactFormRole .ec-numberInput [type=email],
.ec-contactFormRole .ec-zipInput [type=email],
.ec-contactFormRole .ec-telInput [type=email],
.ec-contactFormRole .ec-select [type=email],
.ec-contactFormRole .ec-birth [type=email] {
  height: 50px;
}
.ec-contactFormRole .ec-checkbox label,
.ec-contactFormRole .ec-input [type=text],
.ec-contactFormRole .ec-halfInput [type=text],
.ec-contactFormRole .ec-numberInput [type=text],
.ec-contactFormRole .ec-zipInput [type=text],
.ec-contactFormRole .ec-telInput [type=text],
.ec-contactFormRole .ec-select [type=text],
.ec-contactFormRole .ec-birth [type=text],
.ec-contactFormRole .ec-input [type=tel],
.ec-contactFormRole .ec-halfInput [type=tel],
.ec-contactFormRole .ec-numberInput [type=tel],
.ec-contactFormRole .ec-zipInput [type=tel],
.ec-contactFormRole .ec-telInput [type=tel],
.ec-contactFormRole .ec-select [type=tel],
.ec-contactFormRole .ec-birth [type=tel],
.ec-contactFormRole .ec-input [type=email],
.ec-contactFormRole .ec-halfInput [type=email],
.ec-contactFormRole .ec-numberInput [type=email],
.ec-contactFormRole .ec-zipInput [type=email],
.ec-contactFormRole .ec-telInput [type=email],
.ec-contactFormRole .ec-select [type=email],
.ec-contactFormRole .ec-birth [type=email],
.ec-contactFormRole .ec-radio label,
.ec-contactFormRole .ec-select select,
.ec-contactFormRole .ec-input textarea,
.ec-contactFormRole .ec-halfInput textarea,
.ec-contactFormRole .ec-numberInput textarea,
.ec-contactFormRole .ec-zipInput textarea,
.ec-contactFormRole .ec-telInput textarea,
.ec-contactFormRole .ec-select textarea,
.ec-contactFormRole .ec-birth textarea {
  border: 1px solid #5e6770 !important;
  border-radius: unset;
}
.ec-contactFormRole .ec-input [type=text], .ec-contactFormRole .ec-halfInput [type=text], .ec-contactFormRole .ec-numberInput [type=text], .ec-contactFormRole .ec-zipInput [type=text], .ec-contactFormRole .ec-telInput [type=text], .ec-contactFormRole .ec-select [type=text], .ec-contactFormRole .ec-birth [type=text],
.ec-contactFormRole .ec-input [type=tel],
.ec-contactFormRole .ec-halfInput [type=tel],
.ec-contactFormRole .ec-numberInput [type=tel],
.ec-contactFormRole .ec-zipInput [type=tel],
.ec-contactFormRole .ec-telInput [type=tel],
.ec-contactFormRole .ec-select [type=tel],
.ec-contactFormRole .ec-birth [type=tel],
.ec-contactFormRole .ec-input [type=email],
.ec-contactFormRole .ec-halfInput [type=email],
.ec-contactFormRole .ec-numberInput [type=email],
.ec-contactFormRole .ec-zipInput [type=email],
.ec-contactFormRole .ec-telInput [type=email],
.ec-contactFormRole .ec-select [type=email],
.ec-contactFormRole .ec-birth [type=email],
.ec-contactFormRole .ec-input textarea,
.ec-contactFormRole .ec-halfInput textarea,
.ec-contactFormRole .ec-numberInput textarea,
.ec-contactFormRole .ec-zipInput textarea,
.ec-contactFormRole .ec-telInput textarea,
.ec-contactFormRole .ec-select textarea,
.ec-contactFormRole .ec-birth textarea {
  max-width: 680px;
  width: 100%;
  padding: 15px 20px;
  font-size: 15px;
  line-height: 1.8em;
}
@media (max-width: 767px) {
  .ec-contactFormRole .ec-input [type=text], .ec-contactFormRole .ec-halfInput [type=text], .ec-contactFormRole .ec-numberInput [type=text], .ec-contactFormRole .ec-zipInput [type=text], .ec-contactFormRole .ec-telInput [type=text], .ec-contactFormRole .ec-select [type=text], .ec-contactFormRole .ec-birth [type=text],
.ec-contactFormRole .ec-input [type=tel],
.ec-contactFormRole .ec-halfInput [type=tel],
.ec-contactFormRole .ec-numberInput [type=tel],
.ec-contactFormRole .ec-zipInput [type=tel],
.ec-contactFormRole .ec-telInput [type=tel],
.ec-contactFormRole .ec-select [type=tel],
.ec-contactFormRole .ec-birth [type=tel],
.ec-contactFormRole .ec-input [type=email],
.ec-contactFormRole .ec-halfInput [type=email],
.ec-contactFormRole .ec-numberInput [type=email],
.ec-contactFormRole .ec-zipInput [type=email],
.ec-contactFormRole .ec-telInput [type=email],
.ec-contactFormRole .ec-select [type=email],
.ec-contactFormRole .ec-birth [type=email],
.ec-contactFormRole .ec-input textarea,
.ec-contactFormRole .ec-halfInput textarea,
.ec-contactFormRole .ec-numberInput textarea,
.ec-contactFormRole .ec-zipInput textarea,
.ec-contactFormRole .ec-telInput textarea,
.ec-contactFormRole .ec-select textarea,
.ec-contactFormRole .ec-birth textarea {
    font-size: 14px;
  }
}
.ec-contactFormRole .ec-radio .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: bold;
  color: #DE5D50;
}
@media (max-width: 767px) {
  .ec-contactFormRole .ec-radio .ec-errorMessage {
    font-size: 14px;
  }
}
.ec-contactFormRole .ec-checkbox span,
.ec-contactFormRole .ec-radio span {
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-contactFormRole .ec-checkbox span,
.ec-contactFormRole .ec-radio span {
    font-size: 14px;
  }
}
.ec-contactFormRole .ec-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/html/template/default/assets/img/contact/contact_select_arrow01.png");
  background-repeat: no-repeat;
  background-color: unset;
  background-size: 12px 6px;
  background-position: right 20px center;
  max-width: 325px;
  width: 100%;
  height: 50px;
  padding: 13px 45px 13px 20px;
  font-size: 15px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .ec-contactFormRole .ec-select select {
    font-size: 14px;
  }
}
.ec-contactFormRole .ec-select.error select {
  background-color: #FDF1F0;
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide {
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide div {
  margin-left: 10px;
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide input[type=radio],
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide input[type=checkbox] {
  display: none;
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide input[type=radio]:checked + label,
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide input[type=checkbox]:checked + label {
  color: #5e6770;
  border: 1px solid #5e6770;
  background-color: #a4c0c4;
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide input[type=radio]:checked + label::after,
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide input[type=checkbox]:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  right: 24px;
  width: 7px;
  height: 14px;
  transform: rotate(40deg);
  border-bottom: 2px solid #5e6770;
  border-right: 2px solid #5e6770;
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide label {
  position: relative;
  border: 1px solid #c8dade;
  padding: 4px 60px 4px 20px;
  margin: 0 0 10px 0;
  min-width: 300px;
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide label {
    font-size: 14px;
    padding: 8px 60px 8px 20px;
  }
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide label:hover {
  background-color: #c8dade;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide label:hover span {
  color: #5e6770;
}
.ec-contactFormRole__radioAndCheckBox .ec-contactFormRole__sideBySide label:before {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  right: 18px;
  width: 20px;
  height: 20px;
  background: #fff;
  border: solid 1px #5e6770;
}
.ec-contactFormRole__radioAndCheckBox.error label {
  border: 1px solid #CF3F34;
  background-color: #FDF1F0;
}
.ec-contactFormRole__consultation {
  padding-bottom: 10px;
}
.ec-contactFormRole__consultation textarea {
  height: 450px;
}
@media (max-width: 767px) {
  .ec-contactFormRole__consultation textarea {
    height: 200px;
  }
}
.ec-contactFormRole .ec-contactFormRole__thinText {
  margin: 0 0 16px;
  color: #5e6770;
}
@media (max-width: 767px) {
  .ec-contactFormRole .ec-contactFormRole__thinText {
    margin: 10px 0 6px;
  }
}
.ec-contactFormRole__contactCaption {
  margin: 30px auto 60px;
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-contactFormRole__contactCaption {
    font-size: 14px;
  }
}
.ec-contactFormRole .ec-errorMessage {
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-contactFormRole .ec-errorMessage {
    font-size: 14px;
  }
}

.ec-contactFormConfirmRole .ec-contactFormConfirmRole__actions {
  padding-top: 20px;
}
.ec-contactFormConfirmRole .ec-contactFormConfirmRole__flow {
  margin: 0;
}
.ec-contactFormConfirmRole .ec-blockBtn--action {
  margin-bottom: 16px;
}
.ec-contactFormConfirmRole .ec-contactFormConfirmRole__explanation {
  margin-bottom: 24px;
  line-height: 1.9em;
}
.ec-contactFormConfirmRole dl {
  padding: 15px 20px 15px !important;
}
@media (max-width: 767px) {
  .ec-contactFormConfirmRole dl {
    padding: 10px 20px 10px !important;
  }
}
.ec-contactFormConfirmRole .ec-contactFormConfirmRole__dt {
  padding-top: 3px;
  line-height: 1.6em;
}
.ec-contactFormConfirmRole .ec-contactFormConfirmRole__dt .ec-required,
.ec-contactFormConfirmRole .ec-contactFormConfirmRole__dt .ec-contactFormConfirmRole__any {
  line-height: 1.42857143;
}
.ec-contactFormConfirmRole .ec-contactFormConfirmRole__dd {
  line-height: 1.9em;
  padding-bottom: 5px;
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-contactFormConfirmRole .ec-contactFormConfirmRole__dd {
    font-size: 14px;
  }
}
.ec-contactFormConfirmRole .ec-borderedDefs {
  margin-bottom: 62px;
}

.ec-contactFormCompleteRole .ec-contactFormCompleteRole__flow {
  margin: 0;
}
.ec-contactFormCompleteRole .ec-contactFormCompleteRole__h2 {
  line-height: 1.6em;
  font-size: 28px;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .ec-contactFormCompleteRole .ec-contactFormCompleteRole__h2 {
    font-size: 22px;
  }
}
.ec-contactFormCompleteRole .ec-reportHeading {
  margin: 0 0 30px;
  border-top: none;
}
.ec-contactFormCompleteRole .ec-reportDescription {
  font-size: 15px;
  line-height: 1.9em;
  margin-bottom: 62px;
}
@media (max-width: 767px) {
  .ec-contactFormCompleteRole .ec-reportDescription {
    font-size: 14px;
  }
}
.ec-contactFormCompleteRole .ec-blockBtn--action {
  padding: 13px 0;
}
.ec-contactFormCompleteRole .ec-blockBtn--action:before {
  right: 34px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お客様情報の入力

ログインせずゲストとして商品を購入する際の、お客様情報の入力 に関する Project コンポーネントを定義します。

ex [カートSTEP2 お客様情報の入力(ゲスト購入)](http://demo3.ec-cube.net/shopping/nonmember)

Markup:
include /assets/tmpl/elements/19.3.customer.pug
+ec-customerRole
hoge

Styleguide 19.3

*/
.ec-customerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-customerRole:after {
  content: " ";
  display: table;
}
.ec-customerRole:after {
  clear: both;
}
.ec-customerRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-customerRole img {
  max-width: 100%;
}
.ec-customerRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-customerRole *,
.ec-customerRole *::before,
.ec-customerRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-customerRole img {
  width: 100%;
}
.ec-customerRole .ec-customerRole__actions {
  padding-top: 20px;
}
.ec-customerRole .ec-blockBtn--action {
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-customerRole .ec-blockBtn--action {
    margin-bottom: 16px;
  }
}

.ec-contactConfirmRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactConfirmRole:after {
  content: " ";
  display: table;
}
.ec-contactConfirmRole:after {
  clear: both;
}
.ec-contactConfirmRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactConfirmRole img {
  max-width: 100%;
}
.ec-contactConfirmRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactConfirmRole *,
.ec-contactConfirmRole *::before,
.ec-contactConfirmRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactConfirmRole img {
  width: 100%;
}
.ec-contactConfirmRole .ec-contactConfirmRole__actions {
  padding-top: 20px;
}
.ec-contactConfirmRole .ec-blockBtn--action {
  margin-bottom: 16px;
}

.ec-contactCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactCompleteRole:after {
  content: " ";
  display: table;
}
.ec-contactCompleteRole:after {
  clear: both;
}
.ec-contactCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactCompleteRole img {
  max-width: 100%;
}
.ec-contactCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactCompleteRole *,
.ec-contactCompleteRole *::before,
.ec-contactCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactCompleteRole img {
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/*
404ページ

404 エラー画面で使用するページコンポーネントです。

ex [404エラー画面](http://demo3.ec-cube.net/404)

Markup:
include /assets/tmpl/elements/20.1.404.pug
+ec-404Role

Styleguide 20.1

*/
.ec-404Role {
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  text-align: center;
  box-sizing: border-box;
}
.ec-404Role textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-404Role img {
  max-width: 100%;
}
.ec-404Role html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-404Role *,
.ec-404Role *::before,
.ec-404Role *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-404Role img {
  width: 100%;
}
.ec-404Role .ec-404Role__icon img {
  width: 1em;
  height: 1em;
}
.ec-404Role .ec-404Role__title {
  font-weight: bold;
  font-size: 25px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
退会手続き

退会手続きで使用するページコンポーネントです。

ex [退会手続き](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawRole

Styleguide 21.1

*/
.ec-withdrawRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  text-align: center;
  padding: 0 16px;
}
.ec-withdrawRole:after {
  content: " ";
  display: table;
}
.ec-withdrawRole:after {
  clear: both;
}
.ec-withdrawRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-withdrawRole img {
  max-width: 100%;
}
.ec-withdrawRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-withdrawRole *,
.ec-withdrawRole *::before,
.ec-withdrawRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-withdrawRole img {
  width: 100%;
}
.ec-withdrawRole .ec-withdrawRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
.ec-withdrawRole .ec-withdrawRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}
.ec-withdrawRole .ec-icon img {
  width: 100px;
  height: 100px;
}

/*
退会手続き実行確認

退会手続き実行確認で使用するページコンポーネントです。

ex [退会手続き　退会手続きへボタン→押下](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawConfirm

Styleguide 21.1.2

*/
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__cancel {
  margin-bottom: 20px;
}
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}
.ec-withdrawConfirmRole .ec-icon img {
  width: 100px;
  height: 100px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.ec-userEditCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  text-align: center;
  padding: 0 16px;
}
.ec-userEditCompleteRole:after {
  content: " ";
  display: table;
}
.ec-userEditCompleteRole:after {
  clear: both;
}
.ec-userEditCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-userEditCompleteRole img {
  max-width: 100%;
}
.ec-userEditCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-userEditCompleteRole *,
.ec-userEditCompleteRole *::before,
.ec-userEditCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-userEditCompleteRole img {
  width: 100%;
}
.ec-userEditCompleteRole .ec-userEditCompleteRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
    font-size: 32px;
  }
}
.ec-userEditCompleteRole .ec-userEditCompleteRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/* フォント */
/*
カテゴリページ

カテゴリページのコンポーネントを定義します。

Styleguide 11.3
*/
.ec-categoryPageRole {
  font-family: "Koburina-Gothic-w3", sans-serif;
  letter-spacing: 0.1em;
}

/*
カテゴリ（アイテム4列）
カテゴリページver.

カテゴリページでのみ使用されているクラスの定義です。
(本体は_12.7.category.scss)

Styleguide 12.7.1
*/
.ec-categoryPageRole .ec-categoryRole {
  padding: 41px 0 65px;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageRole .ec-categoryRole {
    padding: 64px 0 80px;
  }
}
.ec-categoryPageRole .ec-categoryRole__listWrapper {
  margin: 0 auto;
}
.ec-categoryPageRole .ec-categoryRole__text {
  min-height: 66px;
  font-size: 13px;
  padding: 0 16px;
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageRole .ec-categoryRole__text {
    margin-top: 25px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カテゴリ詳細ページ

カテゴリ詳細ページのコンポーネントを定義します。

Styleguide 11.3
*/
.ec-categoryPageDetailRole {
  line-height: 2.1em;
  letter-spacing: 0.14em;
}

/*
カテゴリ詳細ページのWrapper部分(背景色が付いている部分)
カテゴリ詳細ページのWrapper部分で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailWrapper {
  margin: 0 calc(50% - 50vw);
  background-color: #F8F8F8;
  padding: 80px 0;
}

/*
カテゴリ詳細ページのメイン画像

カテゴリ詳細ページのメイン画像で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailMainImg {
  height: 39vw;
  margin: 0 calc(50% - 50vw);
  position: relative;
}
.ec-categoryPageDetailMainImg__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.ec-categoryPageDetailMainImg__imgText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 32px;
  font-weight: 300;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMainImg__imgText {
    font-size: 40px;
  }
}

/*
カテゴリ詳細ページのメイン部分

カテゴリ詳細ページのメイン部分で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailMain {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  margin: 0 auto;
  text-align: left;
  line-height: 1.8em;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMain {
    text-align: center;
  }
}
.ec-categoryPageDetailMain__title {
  margin: 0;
  font-family: "BonvenoCF-Light", sans-serif;
  text-align: left;
  font-size: 26px;
  letter-spacing: 0.1em;
  padding-bottom: 25px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMain__title {
    text-align: center;
    font-size: 32px;
    padding-bottom: 40px;
  }
}
.ec-categoryPageDetailMain__title:after {
  content: "";
  position: absolute;
  width: 32px;
  height: 4px;
  left: 0;
  bottom: 0;
  transform: unset;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMain__title:after {
    left: calc(50% - 16px);
    bottom: 0;
    transform: translate(0, -50%);
  }
}
.ec-categoryPageDetailMain__titleSub {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  font-size: 13px;
  margin-top: 12px;
  font-weight: 300;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMain__titleSub {
    font-size: 15px;
    margin-top: 20px;
    font-weight: 400;
  }
}
.ec-categoryPageDetailMain.unstress .ec-categoryPageDetailMain__title:after {
  background-color: #54BEB3;
}
.ec-categoryPageDetailMain.unstress .ec-categoryPageDetailMain__titleSub {
  color: #54BEB3;
}
.ec-categoryPageDetailMain.illustrious .ec-categoryPageDetailMain__title:after {
  background-color: #A7A8A9;
}
.ec-categoryPageDetailMain.illustrious .ec-categoryPageDetailMain__titleSub {
  color: #A7A8A9;
}
.ec-categoryPageDetailMain.biophyto .ec-categoryPageDetailMain__title:after {
  background-color: #618F2E;
}
.ec-categoryPageDetailMain.biophyto .ec-categoryPageDetailMain__titleSub {
  color: #618F2E;
}
.ec-categoryPageDetailMain.forever_young .ec-categoryPageDetailMain__title:after {
  background-color: #F7B05B;
}
.ec-categoryPageDetailMain.forever_young .ec-categoryPageDetailMain__titleSub {
  color: #F7B05B;
}
.ec-categoryPageDetailMain.rose_de_mer .ec-categoryPageDetailMain__title:after {
  background-color: #162C7D;
}
.ec-categoryPageDetailMain.rose_de_mer .ec-categoryPageDetailMain__titleSub {
  color: #162C7D;
}
.ec-categoryPageDetailMain.muse .ec-categoryPageDetailMain__title:after {
  background-color: #D76EA8;
}
.ec-categoryPageDetailMain.muse .ec-categoryPageDetailMain__titleSub {
  color: #D76EA8;
}
.ec-categoryPageDetailMain.comodex .ec-categoryPageDetailMain__title:after {
  background-color: #842331;
}
.ec-categoryPageDetailMain.comodex .ec-categoryPageDetailMain__titleSub {
  color: #842331;
}
.ec-categoryPageDetailMain.theraskin .ec-categoryPageDetailMain__title:after {
  background-color: #38829B;
}
.ec-categoryPageDetailMain.theraskin .ec-categoryPageDetailMain__titleSub {
  color: #38829B;
}
.ec-categoryPageDetailMain.chateau_de_beaute .ec-categoryPageDetailMain__title:after {
  background-color: #7E2D4F;
}
.ec-categoryPageDetailMain.chateau_de_beaute .ec-categoryPageDetailMain__titleSub {
  color: #7E2D4F;
}
.ec-categoryPageDetailMain.silk .ec-categoryPageDetailMain__title:after {
  background-color: #B29E97;
}
.ec-categoryPageDetailMain.silk .ec-categoryPageDetailMain__titleSub {
  color: #B29E97;
}
.ec-categoryPageDetailMain.bio_satin_serum .ec-categoryPageDetailMain__title:after {
  background-color: #686868;
}
.ec-categoryPageDetailMain.bio_satin_serum .ec-categoryPageDetailMain__titleSub {
  color: #686868;
}
.ec-categoryPageDetailMain.clinical .ec-categoryPageDetailMain__title:after {
  background-color: #488A9B;
}
.ec-categoryPageDetailMain.clinical .ec-categoryPageDetailMain__titleSub {
  color: #488A9B;
}
.ec-categoryPageDetailMain__bigText {
  text-align: left;
  font-size: 20px;
  margin-top: 32px;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMain__bigText {
    font-size: 26px;
    text-align: center;
    margin-top: 63px;
    line-height: 1.5em;
  }
}
.ec-categoryPageDetailMain__text {
  text-align: left;
  margin-top: 32px;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMain__text {
    text-align: center;
    margin-top: 48px;
  }
}
.ec-categoryPageDetailMain__subText {
  vertical-align: super;
  font-size: smaller;
}
.ec-categoryPageDetailMain .sp_none {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMain .sp_none {
    display: inline;
  }
}
.ec-categoryPageDetailMain__smallText {
  text-align: left;
  font-size: 12px;
  margin-top: 25px;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailMain__smallText {
    text-align: center;
    margin-top: 40px;
  }
}

/*
カテゴリ詳細ページのLINE UP

カテゴリ詳細ページのLINE UP部分で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailLineup {
  margin: 0 auto;
}
.ec-categoryPageDetailLineup__title {
  font-size: 28px;
  font-family: "BonvenoCF-Light", sans-serif;
  font-weight: bold;
  text-align: center;
  margin-top: 90px;
}
.ec-categoryPageDetailLineup__list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding-left: 0;
  margin: -2px 0 0 -2%;
}
.ec-categoryPageDetailLineup__element {
  color: #000000;
  width: 48%;
  min-height: 387px;
  margin: 25px 0 0 2%;
  overflow: hidden;
  padding-bottom: 25px;
  background-color: #EDEDED;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailLineup__element {
    width: 23%;
    margin: 40px 0 0 2%;
  }
}
.ec-categoryPageDetailLineup__element:hover {
  opacity: 0.8;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-categoryPageDetailLineup__imgBox {
  height: 195px;
  background-color: #FFFFFF;
}
.ec-categoryPageDetailLineup__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.ec-categoryPageDetailLineup__productTitle {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-weight: bold;
  color: #5C5C67;
  margin: 20px 0 0;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5em;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailLineup__productTitle {
    margin: 30px 0 0;
  }
}
.ec-categoryPageDetailLineup__productTitleSmall {
  font-family: "BonvenoCF-Light", sans-serif;
  color: #5C5C67;
  margin-top: 12px;
  font-size: 13px;
  letter-spacing: 0.12em;
}
.ec-categoryPageDetailLineup__text {
  font-size: 16px;
  font-family: "Fahkwang", "Koburina-Gothic-w3", sans-serif;
  color: #5C5C67;
  padding: 0 20px;
  margin-top: 35px;
  letter-spacing: -0.01em;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailLineup__text {
    font-size: 19px;
  }
}
.ec-categoryPageDetailLineup__textSmall {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailLineup__textSmall {
    font-size: 15px;
  }
}

/*
カテゴリ詳細ページのFEATURES

カテゴリ詳細ページのFEATURES部分で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailFeatures {
  margin: 80px auto 0;
  font-size: 14px;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 2em;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailFeatures {
    margin-top: 120px;
  }
}
.ec-categoryPageDetailFeatures__wrapper {
  background-color: #FFFFFF;
  padding: 55px 7vw;
}
.ec-categoryPageDetailFeatures__title {
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 22px;
  text-align: left;
  padding-bottom: 25px;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailFeatures__title {
    font-size: 28px;
    text-align: center;
    padding-bottom: 35px;
  }
}
.ec-categoryPageDetailFeatures__detailTitle {
  font-size: 20px;
  font-weight: 400;
  font-family: "Koburina-Gothic-w3", sans-serif;
  line-height: 1.5em;
  margin: 35px 0;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailFeatures__detailTitle {
    font-size: 24px;
  }
}
.ec-categoryPageDetailFeatures__detailImg {
  width: auto !important;
  max-width: 100%;
  display: block;
  padding-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .ec-categoryPageDetailFeatures__detailImg {
    padding-bottom: 25px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
プライバシーポリシー

プライバシーポリシーのコンポーネントを定義します。
*/
.ec-privacyRole {
  font-family: "Koburina-Gothic-w3", sans-serif;
  letter-spacing: 0.1em;
}
.ec-privacyRole__main {
  padding: 32px 0 20px;
}
.ec-privacyRole__p {
  font-size: 14px;
  line-height: 1.9em;
}
@media only screen and (min-width: 768px) {
  .ec-privacyRole__p {
    font-size: 16px;
  }
}
.ec-privacyRole__bold {
  margin: 32px 0;
}
@media only screen and (min-width: 768px) {
  .ec-privacyRole__bold {
    margin: 38px 0;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
会社概要

会社概要のコンポーネントを定義します。
*/
@media (max-width: 767px) {
  .profile_page .ec-headerNaviRole {
    z-index: 11;
  }
}
@media (max-width: 767px) {
  .profile_page .ec-widthRole.ec-headerRole {
    z-index: 11;
  }
}
.profile_page .ec-layoutRole__contents {
  margin: 0;
  padding: 0;
  line-height: 1.8em;
  letter-spacing: 2px;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  color: #5e6770;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
.profile_page .ec-layoutRole__contents a {
  outline: 0;
  text-decoration: none;
}
.profile_page .ec-layoutRole__contents ul {
  list-style: none;
}
.profile_page .ec-layoutRole__contents li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.profile_page .ec-pageHeader {
  position: relative;
  margin: 0 80px 80px;
  padding: 0;
  height: 140px;
  background-color: #c8dade;
}
@media (max-width: 1180px) {
  .profile_page .ec-pageHeader {
    margin: 0 50px 50px;
  }
}
@media (max-width: 767px) {
  .profile_page .ec-pageHeader {
    margin: 0 0 50px;
  }
}
.profile_page .ec-pageHeader .ec-pageHeader__h1 {
  position: absolute;
  top: 0;
  right: 112px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  color: #ffffff;
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 34px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 1;
  border-bottom: none;
}
@media (max-width: 1180px) {
  .profile_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 50px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .profile_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 30px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}
.profile_page .ec-layoutRole__footer {
  margin-top: 0;
}
.profile_page .ec-blockTopBtn.pagetop {
  z-index: 99;
}
.profile_page .ec-counselingBtn {
  z-index: 999;
}

#ec-company {
  margin: 0;
  padding: 0;
}
#ec-company h2 {
  font-weight: 400;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
@media (max-width: 1180px) {
  #ec-company h2 {
    text-align: center;
  }
}

.ec-companyHero {
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 0;
}
.ec-companyHero img {
  width: 100%;
  height: auto;
}
.ec-companyHero .ec-companyHero__h1 {
  position: absolute;
  top: 0;
  right: 112px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  color: #ffffff;
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 34px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 1;
}
@media (max-width: 1180px) {
  .ec-companyHero .ec-companyHero__h1 {
    right: 50px;
  }
}
@media (max-width: 960px) {
  .ec-companyHero .ec-companyHero__h1 {
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .ec-companyHero .ec-companyHero__h1 {
    right: 30px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}

.ec-companyMessage {
  margin: 0;
  padding: 150px 80px 0;
}
@media (max-width: 1380px) {
  .ec-companyMessage {
    padding: 100px 80px 0;
  }
}
@media (max-width: 1180px) {
  .ec-companyMessage {
    padding: 100px 50px 0;
  }
}
@media (max-width: 960px) {
  .ec-companyMessage {
    padding: 80px 50px 0;
  }
}
@media (max-width: 767px) {
  .ec-companyMessage {
    padding: 50px 30px 0;
  }
}
.ec-companyMessage .ec-companyMessage__inneer {
  margin: 0 auto;
  padding: 0;
  max-width: 980px;
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__h2 {
  margin: 0 0 19px;
  padding: 0;
  text-align: left;
  letter-spacing: 10px;
  font-size: 34px;
}
@media (max-width: 1180px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__h2 {
    margin: 0 0 50px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media (max-width: 960px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__h2 {
    margin: 0 0 30px;
  }
}
@media (max-width: 767px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__h2 {
    margin: 0 0 20px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex {
    display: block;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo {
  margin: 0;
  padding: 30px 0 0;
  width: 392px;
}
@media (max-width: 960px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo {
    width: 326px;
  }
}
@media (max-width: 767px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo {
    margin: 0 auto 20px;
    max-width: 300px;
    width: 60%;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__photoImg {
  width: 100%;
  line-height: 0;
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__photoImg img {
  width: 100%;
  height: auto;
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__ceo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1180px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__ceo {
    display: block;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__ceo .ec-companyMessage__ceoName {
  padding: 0;
  text-align: left;
  letter-spacing: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: Ryumin;
  line-height: 2.4em;
}
@media (max-width: 960px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__ceo .ec-companyMessage__ceoName {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__ceo .ec-companyMessage__ceoName {
    text-align: center;
    font-size: 12px;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__ceo .ec-companyMessage__ceoName span {
  display: inline-block;
  padding: 0 0 0 10px;
  font-size: 28px;
}
@media (max-width: 767px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__photo .ec-companyMessage__ceo .ec-companyMessage__ceoName span {
    font-size: 20px;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message {
  margin: 0;
  padding: 0;
  width: calc(100% - 424px);
}
@media (max-width: 960px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message {
    width: calc(100% - 346px);
  }
}
@media (max-width: 767px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message {
    width: auto;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__copy {
  margin: 0;
  padding: 0;
  letter-spacing: 5px;
  font-weight: 500;
  font-size: 28px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 1.6em;
}
@media (max-width: 1180px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__copy {
    margin: 0;
    letter-spacing: 2px;
    font-size: 24px;
  }
}
@media (max-width: 960px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__copy {
    letter-spacing: normal;
    font-size: 20px;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__read {
  margin: 0 0 30px;
  letter-spacing: 3px;
  font-weight: 500;
  font-size: 22px;
  font-family: Ryumin;
  line-height: 2em;
}
@media (max-width: 1180px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__read {
    margin: 0 0 20px;
    font-size: 18px;
  }
}
@media (max-width: 960px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__read {
    font-size: 16px;
  }
}
.ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__description {
  margin: 0 0 20px;
  padding: 0;
  text-align: justify;
  font-size: 14px;
  line-height: 1.7em;
}
@media (max-width: 960px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__description br {
    display: none;
  }
}
@media (max-width: 767px) {
  .ec-companyMessage .ec-companyMessage__inneer .ec-companyMessage__flex .ec-companyMessage__message .ec-companyMessage__description {
    margin: 0 0 20px;
  }
}

.ec-companyInterview {
  margin: 0;
  padding: 150px 80px 0;
}
@media (max-width: 1380px) {
  .ec-companyInterview {
    padding: 100px 80px 0;
  }
}
@media (max-width: 1180px) {
  .ec-companyInterview {
    padding: 100px 50px 0;
  }
}
@media (max-width: 767px) {
  .ec-companyInterview {
    padding: 80px 30px 0;
  }
}
.ec-companyInterview .ec-companyInterview__inneer {
  margin: 0 auto;
  padding: 0;
  max-width: 980px;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__h2 {
  margin: 0 0 40px;
  padding: 0;
  text-align: left;
  letter-spacing: 10px;
  font-size: 34px;
}
@media (max-width: 1180px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__h2 {
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__h2 {
    margin: 0 0 20px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li {
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 80px;
  width: 33.333%;
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li {
    padding: 0 0 50px;
    width: 50%;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterview__photo {
  margin: 0;
  padding: 0;
  line-height: 0;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterview__photo img {
  width: 100%;
  height: auto;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterview__caption {
  padding: 38px 20px 0;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterview__caption {
    padding: 20px 10px 0;
    letter-spacing: normal;
    font-size: 14px;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterview__caption .popup__btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 20px auto 0;
  padding: 0 16px;
  width: 122px;
  height: 30px;
  border: #5e6770 solid 1px;
  background: url(/html/template/default/assets/img/common/btn_arrow_right.svg) no-repeat top 50% right 16px;
  text-align: left;
  letter-spacing: normal;
  font-size: 12px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterview__caption .popup__btn {
    width: 102px;
    background: url(/html/template/default/assets/img/common/btn_arrow_right.svg) no-repeat top 50% right 10px;
    font-size: 11px;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  pointer-events: none;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail.is-show {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  pointer-events: all;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail.is-show .ec-companyInterviewDetail__inneer {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
  pointer-events: all;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 0 40px;
  max-width: 980px;
  height: 416px;
  background-color: #c8dade;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  pointer-events: none;
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer {
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    overflow: auto;
    padding: 50px 0 0;
    height: auto;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .popup__close::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 45px;
  height: 1px;
  background-color: #5e6770;
  content: "";
  transform: rotate(45deg);
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .popup__close::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 45px;
  height: 1px;
  background-color: #5e6770;
  content: "";
  transform: rotate(-45deg);
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .popup__close {
    top: 10px;
    right: 10px;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__photo {
  position: absolute;
  right: 40px;
  bottom: 0;
  z-index: 3;
  width: 342px;
  line-height: 0;
}
@media (max-width: 960px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__photo {
    right: 12px;
    width: 282px;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__photo img {
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__photo {
    position: static;
    margin: 0 auto 20px;
    padding: 0;
    max-width: 300px;
    width: 60%;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info {
  position: relative;
  z-index: 4;
  overflow: auto;
  box-sizing: border-box;
  margin: 0;
  padding: 40px 382px 0 0;
  height: 416px;
}
@media (max-width: 960px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info {
    padding: 40px 290px 0 0;
  }
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info {
    overflow: hidden;
    padding: 0 20px 20px;
    height: auto;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info .ec-companyInterviewDetail__name {
  margin: 0 0 20px;
  padding: 0;
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 24px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
@media (max-width: 960px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info .ec-companyInterviewDetail__name {
    font-size: 20px;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info .ec-companyInterviewDetail__name span {
  padding: 0 0 0 5px;
  color: #5e6770;
  letter-spacing: normal;
  font-size: 18px;
}
@media (max-width: 960px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info .ec-companyInterviewDetail__name span {
    display: block;
    padding: 0;
    font-size: 15px;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0 0 40px;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dt {
  margin: 0 0 10px;
  width: 100px;
  color: #ffffff;
  letter-spacing: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 1.8em;
}
@media (max-width: 960px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dt {
    margin: 0 0 5px;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dt:nth-of-type(2) {
  width: 50px;
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dt:nth-of-type(2) {
    width: 100px;
  }
}
@media (max-width: 960px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dt:nth-of-type(4) {
    margin: 0 0 5px;
    width: 100%;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dd {
  margin: 0 0 10px;
  padding: 0;
  width: calc(100% - 100px);
  text-align: left;
  text-align: justify;
  font-size: 14px;
  line-height: 1.8em;
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dd:first-of-type {
  width: 100px;
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dd:first-of-type {
    width: calc(100% - 100px);
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dd:nth-of-type(2) {
  width: calc(100% - 250px);
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dd:nth-of-type(2) {
    width: calc(100% - 100px);
  }
}
@media (max-width: 960px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dd {
    margin: 0 0 5px;
  }
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__staff li .ec-companyInterviewDetail .ec-companyInterviewDetail__inneer .ec-companyInterviewDetail__info dl dd:nth-of-type(4) {
    margin: 0;
    width: 100%;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__btn {
  margin: -22px 0 0;
  padding: 0;
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__btn {
    margin: 0;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 245px;
  height: 68px;
  background-color: #5e6770;
  color: #ffffff;
  font-size: 15px;
  cursor: pointer;
}
@media (max-width: 1180px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__btn a {
    height: 52px;
  }
}
@media (max-width: 767px) {
  .ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__btn a {
    width: 100%;
  }
}
.ec-companyInterview .ec-companyInterview__inneer .ec-companyInterview__btn a span {
  display: inline-block;
  padding: 0 24px 0 0;
  background: url(/html/template/default/assets/img/common/icon_link.svg) no-repeat right;
  letter-spacing: normal;
}

.ec-companyInformation {
  margin: 0;
  padding: 80px 80px 0;
}
@media (max-width: 1180px) {
  .ec-companyInformation {
    padding: 80px 50px 0;
  }
}
@media (max-width: 767px) {
  .ec-companyInformation {
    padding: 80px 30px 0;
  }
}
.ec-companyInformation .ec-companyInformation__inneer {
  margin: 0 auto;
  max-width: 980px;
}
.ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__h2 {
  margin: 0 0 30px;
  padding: 0;
  letter-spacing: 5px;
  font-weight: 500 !important;
  font-size: 28px;
  font-family: "Ryumin", sans-serif !important;
}
@media (max-width: 767px) {
  .ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__h2 {
    margin: 0 0 20px;
    letter-spacing: 3px;
    font-size: 20px;
  }
}
.ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__detail {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__detail {
    display: block;
    letter-spacing: normal;
    font-size: 14px;
  }
}
.ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__detail dt {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 0 5px;
  padding: 13px;
  width: 225px;
  background-color: #d7e1e4;
}
@media (max-width: 767px) {
  .ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__detail dt {
    display: block;
    padding: 0;
    width: auto;
    background-color: transparent;
    text-align: left;
    font-weight: 700;
  }
}
.ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__detail dd {
  box-sizing: border-box;
  margin: 0 0 5px;
  padding: 10px;
  width: calc(100% - 255px);
}
@media (max-width: 767px) {
  .ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__detail dd {
    margin: 0 0 20px;
    padding: 0 0 20px;
    width: auto;
    border-bottom: #e4e4e4 solid 1px;
  }
}
.ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__detail dd span {
  display: inline-block;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 12px;
}
.ec-companyInformation .ec-companyInformation__inneer .ec-companyInformation__detail dd span:nth-of-type(2) {
  padding: 13px 0 0;
}

.ec-companyAccess {
  margin: 0;
  padding: 150px 0 0;
}
@media (max-width: 1180px) {
  .ec-companyAccess {
    padding: 100px 0 0;
  }
}
@media (max-width: 767px) {
  .ec-companyAccess {
    padding: 50px 0 0;
  }
}
.ec-companyAccess .ec-companyAccess__inneer {
  margin: 0;
  padding: 0;
  line-height: 0;
}
.ec-companyAccess .ec-companyAccess__inneer .ec-companyAccess__h2 {
  margin: 0 0 70px;
  padding: 0;
  text-align: center;
  letter-spacing: 10px;
  font-size: 34px;
  line-height: 1;
}
@media (max-width: 1180px) {
  .ec-companyAccess .ec-companyAccess__inneer .ec-companyAccess__h2 {
    margin: 0 0 40px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .ec-companyAccess .ec-companyAccess__inneer .ec-companyAccess__h2 {
    margin: 0 0 40px;
    letter-spacing: 3px;
    font-size: 20px;
  }
}
.ec-companyAccess .ec-companyAccess__inneer .ec-companyAccess_map {
  background: rgba(94, 103, 112, 0.15);
}
.ec-companyAccess .ec-companyAccess__inneer .ec-companyAccess_map iframe {
  width: 100%;
  height: 500px;
  opacity: 0.7;
  filter: grayscale(100%);
}
@media (max-width: 960px) {
  .ec-companyAccess .ec-companyAccess__inneer .ec-companyAccess_map iframe {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .ec-companyAccess .ec-companyAccess__inneer .ec-companyAccess_map iframe {
    height: 300px;
  }
}

.ec-companySocial {
  margin: 0;
  padding: 150px 80px;
  background-color: #c8dade;
}
@media (max-width: 1180px) {
  .ec-companySocial {
    padding: 100px 50px;
  }
}
@media (max-width: 960px) {
  .ec-companySocial {
    padding: 80px 50px;
  }
}
@media (max-width: 767px) {
  .ec-companySocial {
    padding: 50px 30px;
  }
}
.ec-companySocial .ec-companySocial__inneer {
  margin: 0 auto;
  max-width: 980px;
}
.ec-companySocial .ec-companySocial__inneer .ec-companySocial__h2 {
  margin: 0 0 50px;
  padding: 0;
  letter-spacing: 3px;
  font-size: 30px;
  line-height: 1;
}
@media (max-width: 960px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__h2 {
    margin: 0 0 30px;
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__h2 {
    margin: 0 0 24px;
    letter-spacing: 1px;
    font-size: 20px;
    line-height: 1.6em;
  }
}
.ec-companySocial .ec-companySocial__inneer .ec-companySocial__content {
  padding: 80px;
  background-color: #a4c0c4;
  color: #ffffff;
  text-align: center;
}
@media (max-width: 1180px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__content {
    padding: 80px 50px;
  }
}
@media (max-width: 960px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__content {
    padding: 60px 50px;
  }
}
@media (max-width: 767px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__content {
    padding: 50px 30px;
  }
}
.ec-companySocial .ec-companySocial__inneer .ec-companySocial__content .ec-companySocial__h3 {
  margin: 0;
  padding: 0;
  letter-spacing: 5px;
  font-weight: 500 !important;
  font-size: 28px;
  font-family: "Ryumin", sans-serif !important;
}
@media (max-width: 1180px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__content .ec-companySocial__h3 {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__content .ec-companySocial__h3 {
    letter-spacing: 2px;
    font-size: 20px;
  }
}
.ec-companySocial .ec-companySocial__inneer .ec-companySocial__content .ec-companySocial__read {
  margin: 5px 0 40px;
  padding: 0;
  font-weight: 400;
  font-size: 15px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
@media (max-width: 1180px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__content .ec-companySocial__read {
    letter-spacing: 1px;
  }
}
.ec-companySocial .ec-companySocial__inneer .ec-companySocial__content .ec-companySocial__text {
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 2em;
}
@media (max-width: 767px) {
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__content .ec-companySocial__text {
    text-align: justify;
  }
  .ec-companySocial .ec-companySocial__inneer .ec-companySocial__content .ec-companySocial__text br {
    display: none;
  }
}

.ec-profileRole {
  font-family: "Koburina-Gothic-w3", sans-serif;
  letter-spacing: 0.1em;
}
.ec-profileRole__main {
  padding: 32px 0 20px;
  font-size: 14px;
  line-height: 1.9em;
}
@media only screen and (min-width: 768px) {
  .ec-profileRole__main {
    font-size: 16px;
  }
}
.ec-profileRole__table, .ec-profileRole__tr, .ec-profileRole__th, .ec-profileRole__td {
  border-collapse: collapse;
}
.ec-profileRole__th {
  min-width: 125px;
  padding: 15px 20px;
  background: #eee;
  text-align: left;
  border-bottom: 1px solid #fff;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-profileRole__th {
    min-width: 200px;
    padding: 25px 30px;
  }
}
.ec-profileRole__td {
  padding: 15px 20px;
}
@media only screen and (min-width: 768px) {
  .ec-profileRole__td {
    padding: 25px 30px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ABOUT

ABOUTページのコンポーネントを定義します。
*/
.ec-aboutRole {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  letter-spacing: 0.1em;
  font-size: 22px;
  line-height: 1.9em;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole {
    font-size: 20px;
  }
}
.ec-aboutRole__pc {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__pc {
    display: block;
  }
}
.ec-aboutRole__pcInline {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__pcInline {
    display: inline;
  }
}
.ec-aboutRole__sp {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__sp {
    display: none;
  }
}
.ec-aboutRole__inner {
  max-width: 1247px;
  text-align: center;
}
.ec-aboutRole__asterisk {
  position: relative;
  top: -3.99px;
}
.ec-aboutRole__mainImgWrapper {
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: 1247px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__mainImgWrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.ec-aboutRole__mainImg {
  max-width: 100%;
  height: auto;
}
.ec-aboutRole__pageTitle {
  font-family: "BonvenoCF-Light", sans-serif;
  letter-spacing: 0.2em;
  font-size: 5.3vw;
  line-height: 1.9em;
  color: #FFFFFF;
  position: absolute;
  top: 39%;
  right: 8.5%;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__pageTitle {
    font-size: 34px;
    top: 35%;
    right: 7%;
  }
}
.ec-aboutRole__infoWrapper {
  background-color: #F3F1F1;
}
@media (max-width: 934px) {
  .ec-aboutRole__infoWrapper {
    background-color: unset;
  }
}
.ec-aboutRole__info {
  position: relative;
  padding-bottom: 547px;
  margin-top: 5vw;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__info {
    padding-bottom: 0;
    max-width: 934px;
    width: 100%;
    height: 709px;
    margin: 40px auto 0;
  }
}
.ec-aboutRole__infoImg {
  max-width: 100%;
  height: auto;
}
.ec-aboutRole__infoTextWrapper {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__infoTextWrapper {
    width: 48.5%;
    top: 42px;
    right: 26px;
    padding-left: 0;
    padding-right: 0;
  }
}
.ec-aboutRole__infoTitle {
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 5.7vw;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  padding: 8vw 0 0 47vw;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__infoTitle {
    font-size: 34px;
    line-height: 1.7em;
    padding: 0;
    margin-top: 0;
  }
}
@media (max-width: 800px) {
  .ec-aboutRole__infoTitle {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .ec-aboutRole__infoTitle {
    font-size: 5.7vw;
  }
}
.ec-aboutRole__infotext {
  font-size: 14px;
  line-height: 1.6em;
  letter-spacing: 0.1em;
  margin-top: 53vw;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__infotext {
    text-align: left;
    margin-top: 4vw;
  }
}
@media (min-width: 1280px) {
  .ec-aboutRole__infotext {
    margin-top: 47px;
  }
}
.ec-aboutRole__infotextSmall {
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 1.9em;
  margin-top: 7.5vw;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__infotextSmall {
    margin-top: 3vw;
    text-align: left;
  }
}
@media (min-width: 1280px) {
  .ec-aboutRole__infotextSmall {
    margin-top: 38px;
  }
}
.ec-aboutRole__concept {
  padding-top: 12vw;
  position: relative;
  padding-bottom: 63.5vw;
  max-width: 1280px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__concept {
    padding-top: 50px;
    padding-bottom: 58.5vw;
  }
}
@media (min-width: 1280px) {
  .ec-aboutRole__concept {
    padding-bottom: 750px;
  }
}
.ec-aboutRole__contentTitle {
  font-size: 24px;
  margin: 20px 0 0;
  letter-spacing: 0.2em;
  font-family: "BonvenoCF-Light", sans-serif;
  text-align: center;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__contentTitle {
    font-size: 34px;
  }
}
.ec-aboutRole__textSpaceWide {
  font-size: 14px;
  line-height: 2.3em;
  padding-top: 40px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__textSpaceWide {
    padding-top: 82px;
    line-height: 2.4em;
  }
}
.ec-aboutRole__conceptImg1 {
  width: 37%;
  position: absolute;
  left: 0;
  transform: none;
  bottom: 0;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__conceptImg1 {
    width: 33%;
    left: 28%;
    transform: translate(-50%, 0);
  }
}
@media (min-width: 1280px) {
  .ec-aboutRole__conceptImg1 {
    width: 425.5px;
    left: calc(50% - 287.5px);
  }
}
.ec-aboutRole__conceptImg2 {
  width: 49.5%;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__conceptImg2 {
    width: 44%;
  }
}
@media (min-width: 1280px) {
  .ec-aboutRole__conceptImg2 {
    width: 565.5px;
  }
}
.ec-aboutRole__israel {
  padding-top: 24.5vw;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__israel {
    padding-top: 157px;
  }
}
.ec-aboutRole__israel .ec-aboutRole__textSpaceWide {
  padding-top: 8.5vw;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__israel .ec-aboutRole__textSpaceWide {
    padding-top: 81px;
  }
}
.ec-aboutRole__israelImg {
  width: 27px !important;
  height: 19.5px;
  margin-top: 10vw;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__israelImg {
    width: 54px !important;
    height: 39px;
    margin-top: 69px;
  }
}
.ec-aboutRole__bottomImgWrapper {
  margin: 25vw auto 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 16vw;
  max-width: 1247px;
}
@media only screen and (min-width: 768px) {
  .ec-aboutRole__bottomImgWrapper {
    margin-top: 126px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
  }
}
.ec-aboutRole__bottomImg {
  max-width: 100%;
  height: auto;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ニュース詳細

ニュース詳細のコンポーネントを定義します。
*/
.ec-newsDetailRole {
  font-family: "Koburina-Gothic-w3", sans-serif;
  letter-spacing: 0.1em;
}
.ec-newsDetailRole .ec-pageHeader {
  position: relative;
  margin: 0 80px 80px;
  padding: 0;
  height: 140px;
  background-color: #c8dade;
  border-bottom: none;
}
@media (max-width: 1180px) {
  .ec-newsDetailRole .ec-pageHeader {
    margin: 0 50px 50px;
  }
}
@media (max-width: 767px) {
  .ec-newsDetailRole .ec-pageHeader {
    margin: 0 0 50px;
  }
}
.ec-newsDetailRole .ec-pageHeader h1 {
  position: absolute;
  top: 0;
  right: 112px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  color: #ffffff;
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 34px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 1;
  border-bottom: none;
}
@media (max-width: 1180px) {
  .ec-newsDetailRole .ec-pageHeader h1 {
    right: 50px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .ec-newsDetailRole .ec-pageHeader h1 {
    right: 30px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}
.ec-newsDetailRole .ec-viewmore {
  margin-top: 32px;
  margin-bottom: 48px;
}
.ec-newsDetailRole .ec-viewmore.ec-back {
  margin-top: 48px;
  width: 120px;
}
.ec-newsDetailRole .ec-newsDetail {
  margin: 0;
  padding: 0 80px 86px;
  color: #5e6770;
  letter-spacing: 2px;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
@media (max-width: 1180px) {
  .ec-newsDetailRole .ec-newsDetail {
    padding: 0 50px 86px;
  }
}
@media (max-width: 767px) {
  .ec-newsDetailRole .ec-newsDetail {
    padding: 0 30px 16px;
  }
}
.ec-newsDetailRole .ec-newsDetail__inner {
  margin: 0 auto;
  padding: 0;
  max-width: 998px;
}
.ec-newsDetailRole .ec-newsDetail__h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0 20px;
  height: 50px;
  background-color: rgba(200, 218, 222, 0.36);
  letter-spacing: 2px;
  font-weight: 500 !important;
  font-size: 20px;
  font-family: "Ryumin", sans-serif !important;
}
@media (max-width: 767px) {
  .ec-newsDetailRole .ec-newsDetail__h3 {
    letter-spacing: 1px;
    font-size: 15px;
    padding: 0 10px;
  }
}
.ec-newsDetailRole .ec-newsDetail__date {
  font-family: "Fahkwang", sans-serif;
  font-size: 16px;
  margin-right: 16px;
}
@media (max-width: 767px) {
  .ec-newsDetailRole .ec-newsDetail__date {
    font-size: 12px;
    margin-right: 12px;
  }
}
.ec-newsDetailRole .ec-newsDetail__detail {
  margin: 0;
  padding-top: 32px;
  font-size: 15px;
  line-height: 1.8em;
}
@media (max-width: 767px) {
  .ec-newsDetailRole .ec-newsDetail__detail {
    font-size: 14px;
  }
}
.ec-newsDetailRole .ec-newsDetail__link {
  padding-top: 16px;
  display: block;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
新着情報一覧

新着情報一覧のコンポーネントを定義します。
*/
.ec-newsListRole {
  font-family: "Koburina-Gothic-w3", sans-serif;
  letter-spacing: 0.1em;
}
.ec-newsListRole .ec-pageHeader {
  position: relative;
  margin: 0 80px 80px;
  padding: 0;
  height: 140px;
  background-color: #c8dade;
  border-bottom: none;
}
@media (max-width: 1180px) {
  .ec-newsListRole .ec-pageHeader {
    margin: 0 50px 50px;
  }
}
@media (max-width: 767px) {
  .ec-newsListRole .ec-pageHeader {
    margin: 0 0 50px;
  }
}
.ec-newsListRole .ec-pageHeader h1 {
  position: absolute;
  top: 0;
  right: 112px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  color: #ffffff;
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 34px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 1;
  border-bottom: none;
}
@media (max-width: 1180px) {
  .ec-newsListRole .ec-pageHeader h1 {
    right: 50px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .ec-newsListRole .ec-pageHeader h1 {
    right: 30px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}
.ec-newsListRole .ec-viewmore {
  margin-top: 32px;
  margin-bottom: 48px;
}
.ec-newsListRole .ec-newsList {
  margin: 0;
  padding: 0 80px 86px;
  color: #5e6770;
  letter-spacing: 2px;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
@media (max-width: 1180px) {
  .ec-newsListRole .ec-newsList {
    padding: 0 50px 86px;
  }
}
@media (max-width: 767px) {
  .ec-newsListRole .ec-newsList {
    padding: 0 30px 16px;
  }
}
.ec-newsListRole .ec-newsList__inner {
  margin: 0 auto;
  padding: 0;
  max-width: 998px;
}
.ec-newsListRole .ec-newsList__listWrap {
  list-style-type: none;
  padding-left: 0;
}
.ec-newsListRole .ec-newsList__list {
  margin-bottom: 64px;
  clear: both;
  content: "";
}
.ec-newsListRole .ec-newsList__list:last-child {
  margin-bottom: 0;
}
.ec-newsListRole .ec-newsList__h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0 20px;
  height: 50px;
  background-color: rgba(200, 218, 222, 0.36);
  letter-spacing: 2px;
  font-weight: 500 !important;
  font-size: 20px;
  font-family: "Ryumin", sans-serif !important;
}
@media (max-width: 767px) {
  .ec-newsListRole .ec-newsList__h3 {
    letter-spacing: 1px;
    font-size: 15px;
    padding: 0 10px;
  }
}
.ec-newsListRole .ec-newsList__date {
  font-family: "Fahkwang", sans-serif;
  font-size: 16px;
  margin-right: 16px;
}
@media (max-width: 767px) {
  .ec-newsListRole .ec-newsList__date {
    font-size: 12px;
    margin-right: 12px;
  }
}
.ec-newsListRole .ec-newsList__detail {
  margin: 0;
  padding-top: 32px;
  font-size: 15px;
  line-height: 1.8em;
}
@media (max-width: 767px) {
  .ec-newsListRole .ec-newsList__detail {
    font-size: 14px;
  }
}
.ec-newsListRole .ec-newsList__link {
  padding-top: 16px;
  display: block;
}

/*
店舗一覧

*/
#agency_list.ec-categoryRole {
  background: #fff;
}
@media only screen and (min-width: 768px) {
  #agency_list.ec-categoryRole {
    padding-left: 0;
    padding-right: 0;
    max-width: initial;
  }
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole {
    padding: 0;
  }
}
#agency_list.ec-categoryRole .ec-role {
  max-width: initial;
  padding-left: 0;
  padding-right: 0;
}
#agency_list.ec-categoryRole .ec-agency__heading {
  margin-top: 56px;
}
@media (max-width: 980px) and (min-width: 768px) {
  #agency_list.ec-categoryRole .ec-agency__heading {
    padding-left: 20px;
    padding-right: 20px;
  }
}
#agency_list.ec-categoryRole .ec-widthRole {
  margin-left: auto;
  margin-right: auto;
}
#agency_list.ec-categoryRole .ec-secHeading {
  display: flex;
  flex-direction: column;
  margin-bottom: 58px;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-secHeading {
    padding: 0 20px;
  }
}
#agency_list.ec-categoryRole .ec-secHeading__en {
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 28px;
  color: #5C5C67;
  margin-bottom: 20px;
}
#agency_list.ec-categoryRole .ec-secHeading__ja {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 12px;
  color: #5C5C67;
}
#agency_list.ec-categoryRole .ec-lineRole {
  width: 100%;
  height: 1px;
  background-color: #000;
  border: none;
  margin: 0 auto 32px;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-lineRole__wrap {
    padding: 0 20px;
  }
}
#agency_list.ec-categoryRole .ec-agency__bg {
  background: #F2F2F2;
  padding: 54px 0 58px 0;
  margin: 48px auto 0;
}
@media (max-width: 980px) and (min-width: 768px) {
  #agency_list.ec-categoryRole .ec-agency__bg {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-agency__bg {
    padding: 44px 20px 48px 20px;
  }
}
#agency_list.ec-categoryRole .ec-agency__form {
  margin-bottom: 84px;
}
#agency_list.ec-categoryRole .ec-agency__prefWrap {
  width: 30%;
  border: 1px solid #9B9B9B;
  border-radius: 0;
  position: relative;
}
@media (max-width: 900px) {
  #agency_list.ec-categoryRole .ec-agency__prefWrap {
    width: 40%;
  }
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-agency__prefWrap {
    width: 100%;
  }
}
#agency_list.ec-categoryRole .ec-agency__prefWrap::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-top: solid 2px #5C5C67;
  border-right: solid 2px #5C5C67;
  right: 17px;
  top: calc(50% - 6px);
  transform: rotate(135deg);
}
#agency_list.ec-categoryRole #search_agency_pref {
  appearance: none;
  width: 100%;
}
#agency_list.ec-categoryRole #search_agency_Category {
  display: flex;
  flex-wrap: wrap;
}
#agency_list.ec-categoryRole .form-check {
  width: 19%;
}
#agency_list.ec-categoryRole .form-check:nth-of-type(5n) {
  width: 23%;
}
@media (max-width: 900px) {
  #agency_list.ec-categoryRole .form-check {
    width: 25%;
  }
  #agency_list.ec-categoryRole .form-check:nth-of-type(5n) {
    width: 25%;
  }
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .form-check {
    width: 50%;
  }
  #agency_list.ec-categoryRole .form-check:nth-of-type(5n) {
    width: 50%;
  }
}
#agency_list.ec-categoryRole input[type=checkbox] {
  display: none;
}
#agency_list.ec-categoryRole .form-check-label {
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 14px;
  color: #5C5C67;
  cursor: pointer;
  position: relative;
  padding: 5px 0 5px 24px;
}
@media (max-width: 900px) {
  #agency_list.ec-categoryRole .form-check-label {
    font-size: 12px;
  }
}
#agency_list.ec-categoryRole .form-check-label::before, #agency_list.ec-categoryRole .form-check-label::after {
  position: absolute;
  content: "";
  top: calc(50% - 8px);
}
#agency_list.ec-categoryRole .form-check-label::before {
  border: 1px solid #9B9B9B;
  background-color: #fff;
  width: 16px;
  height: 16px;
  left: 2px;
  margin-right: -8px;
}
#agency_list.ec-categoryRole .form-check-label::after {
  border-right: 2px solid #9B9B9B;
  border-bottom: 2px solid #9B9B9B;
  width: 5px;
  height: 9px;
  top: 53%;
  left: 8px;
  margin-top: -7px;
  opacity: 0;
  transform: rotate(45deg);
}
#agency_list.ec-categoryRole input[type=checkbox]:checked + .form-check-label::after {
  opacity: 1;
}
#agency_list.ec-categoryRole .ec-agency__formFlex {
  display: flex;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-agency__formFlex {
    flex-direction: column;
  }
}
#agency_list.ec-categoryRole .ec-agency__formFlex p {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 14px;
  color: #5C5C67;
  margin-right: 25px;
  white-space: nowrap;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-agency__formFlex p {
    margin-bottom: 20px;
  }
}
#agency_list.ec-categoryRole .ec-agency__formFlex .ec-agency__formCategory {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
#agency_list.ec-categoryRole .ec-agency__formFlex .ec-agency__formCategory li {
  width: 20%;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 14px;
  color: #000;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-agency__formFlex .ec-agency__formCategory li {
    width: 50%;
    font-size: 13px;
  }
}
#agency_list.ec-categoryRole .ec-agency__selectPref {
  align-items: center;
  margin-bottom: 38px;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-agency__selectPref {
    align-items: baseline;
    margin-bottom: 36px;
  }
}
#agency_list.ec-categoryRole .ec-agency__selectCategory {
  margin-bottom: 34px;
}
#agency_list.ec-categoryRole .ec-agency__searchBtn {
  border: none;
  background: #5C5C67;
  color: #fff;
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 15px;
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#agency_list.ec-categoryRole .ec-agency__searchBtn img {
  width: 16px;
  height: 16px;
  margin-right: 11px;
}
#agency_list.ec-categoryRole .ec-agency__searchBtn:hover {
  opacity: 0.8;
  color: #fff;
  text-decoration: none;
}
#agency_list.ec-categoryRole .ec-agency__description {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 15px;
  line-height: 20px;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-agency__description {
    padding: 0 20px;
  }
}
#agency_list.ec-categoryRole .ec-agency__searchResult {
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 25px;
}
#agency_list.ec-categoryRole .ec-productRole__flex {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#agency_list.ec-categoryRole .ec-categoryRole__list {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-categoryRole__list {
    gap: 24px 11px;
  }
}
#agency_list.ec-categoryRole .ec-categoryRole__list::before {
  content: "";
  display: block;
  width: calc(25% - 24px);
  height: 0;
  order: 1;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-categoryRole__list::before {
    width: calc(50% - 7.5px);
  }
}
#agency_list.ec-categoryRole .ec-categoryRole__list::after {
  content: "";
  display: block;
  width: calc(25% - 24px);
  height: 0;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-categoryRole__list::after {
    width: calc(50% - 7.5px);
  }
}
#agency_list.ec-categoryRole .ec-categoryRole__listItem {
  box-sizing: border-box;
  display: flex;
}
@media only screen and (min-width: 768px) {
  #agency_list.ec-categoryRole .ec-categoryRole__listItem {
    width: calc(25% - 24px);
  }
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-categoryRole__listItem {
    width: calc(50% - 7.5px);
  }
}
#agency_list.ec-categoryRole .ec-categoryRole__listItem:not(:last-of-type) {
  margin-right: 0;
}
#agency_list.ec-categoryRole .ec-agencyRole__imgWrap {
  width: 100%;
  height: auto;
  position: relative;
}
#agency_list.ec-categoryRole .ec-agencyRole__img {
  display: block;
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 767px) {
  #agency_list.ec-categoryRole .ec-agencyRole__img {
    height: 125px;
  }
}
#agency_list.ec-categoryRole .ec-productRole__wrap {
  background: #fff;
  font-family: "Koburina-Gothic-w3", sans-serif;
  font-size: 13px;
  padding: 20px;
  flex-grow: 1;
}
#agency_list.ec-categoryRole .ec-productRole__code {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
#agency_list.ec-categoryRole .ec-productRole__title {
  font-family: "Koburina-Gothic-w3", "Fahkwang-bold", sans-serif;
  padding-bottom: 30px;
  font-weight: bold;
}
#agency_list.ec-categoryRole #shop_guide.ec-shopGuide {
  padding: 0;
}

#page_agency_list .ec-layoutRole__footer {
  margin-top: 0;
}

/*
代理店詳細

Styleguide 18.0
*/
#agency_detail {
  background: #fff;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767px) {
  #agency_detail {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.ec-layoutRole__main #agency_detail {
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}
#agency_detail .ec-productRole__profile {
  margin-top: 80px;
}
@media only screen and (min-width: 768px) {
  #agency_detail .ec-productRole__profile {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  #agency_detail .ec-productRole__profile {
    margin-top: 40px;
    margin-bottom: 80px;
  }
}
#agency_detail .ec-productRole__profFlex {
  display: flex;
}
@media (max-width: 767px) {
  #agency_detail .ec-productRole__profFlex {
    flex-direction: column;
  }
}
#agency_detail .ec-agency__imgWrap {
  padding: 0;
  width: 46%;
}
@media (max-width: 767px) {
  #agency_detail .ec-agency__imgWrap {
    width: 100%;
  }
}
#agency_detail .ec-agency__imgWrap .ec-productRole__code {
  padding: 0;
}
#agency_detail .ec-agency__imgWrap img {
  width: 100%;
  object-fit: cover;
}
#agency_detail .ec-productRole__code {
  padding: 0;
  border: none;
}
#agency_detail .ec-productRole__profDetail {
  font-family: "Koburina-Gothic-w3", sans-serif;
  color: #000;
  display: flex;
  flex-direction: column;
  margin-left: 72px;
}
@media (max-width: 767px) {
  #agency_detail .ec-productRole__profDetail {
    margin-left: 0;
    margin-top: 32px;
  }
}
#agency_detail .ec-headingTitle {
  font-size: 22px;
  margin-bottom: 12px;
}
#agency_detail .ec-productRole__code.ec-agency__kana {
  margin-bottom: 28px;
}
#agency_detail .product-code-default {
  font-size: 12px;
  color: #6F6F6F;
}
#agency_detail .ec-profDetail__access {
  display: flex;
  margin-bottom: 28px;
}
#agency_detail .ec-prof__accessIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
#agency_detail .ec-prof__accessIcon + div {
  line-height: 24px;
}
#agency_detail .ec-prof__postCode {
  margin-bottom: 9px;
  font-size: 14px;
  font-family: "Fahkwang", sans-serif;
  color: #5C5C67;
}
#agency_detail .ec-prof__address {
  margin-bottom: 13px;
  font-size: 14px;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  color: #5C5C67;
}
#agency_detail .ec-prof__mapLink {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
  display: block;
  font-family: "Koburina-Gothic-w3", sans-serif;
  color: #5C5C67;
}
#agency_detail .ec-prof__mapLink:hover {
  opacity: 0.8;
}
#agency_detail .ec-profDetail__tel {
  display: flex;
  margin-bottom: 25px;
  font-size: 14px;
}
#agency_detail .ec-prof__telIcon {
  width: 14px;
  height: 17px;
  margin: 4px 13px 4px 5px;
}
#agency_detail .ec-prof__telIcon + div {
  font-family: "Fahkwang", sans-serif;
  line-height: 24px;
}
#agency_detail .ec-profDetail__hp {
  display: flex;
  align-items: center;
  margin-bottom: 29px;
}
#agency_detail .ec-prof__salonHp {
  margin-right: 32px;
}
#agency_detail .ec-prof__salonHp a {
  text-decoration: underline;
  color: #5C5C67;
  font-weight: bold;
  font-size: 14px;
  font-family: "Koburina-Gothic-w3", sans-serif;
}
#agency_detail .ec-prof__salonHp a:hover {
  opacity: 0.8;
}
#agency_detail .ec-prof__hpIcon {
  width: 16px;
  height: 13px;
  margin: 6px 12px 5px 4px;
}
#agency_detail .ec-prof__instaIcon {
  margin-right: 24px;
}
#agency_detail .ec-prof__instaIcon img {
  width: 20px;
  height: 20px;
}
#agency_detail .ec-prof__lineIcon img {
  width: 21px;
  height: 20px;
}
#agency_detail .ec-prof__recommended {
  font-size: 14px;
}
#agency_detail .ec-prof__recomIcon {
  width: 14px;
  height: 12px;
  margin: 5px 6px;
}
#agency_detail .ec-prof__recomTitle {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
#agency_detail .ec-prof__recomContent {
  margin-left: 24px;
  line-height: 22px;
}
#agency_detail .ec-category2Role .ec-role {
  padding-left: 0;
  padding-right: 0;
}
#agency_detail .ec-prof__introSeries {
  margin-top: 63px;
  margin-bottom: 56px;
  font-family: "Koburina-Gothic-w3", sans-serif;
  color: #000;
}
#agency_detail .ec-prof__introSeries > p {
  margin-bottom: 19px;
  font-size: 16px;
}
@media (max-width: 767px) {
  #agency_detail .ec-prof__introSeries {
    margin-top: 43px;
    margin-bottom: 48px;
  }
}
#agency_detail .ec-prof__introFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}
@media (max-width: 767px) {
  #agency_detail .ec-prof__introFlex {
    gap: 11px 8px;
  }
}
#agency_detail .ec-prof__introFlex::before {
  content: "";
  display: block;
  width: calc(25% - 24px);
  /* .boxに指定したwidthと同じ幅を指定する */
  height: 0;
  order: 1;
  /* 疑似要素beforeの順番を必ず指定する*/
}
@media (max-width: 767px) {
  #agency_detail .ec-prof__introFlex::before {
    width: calc(50% - 7.5px);
  }
}
#agency_detail .ec-prof__introFlex::after {
  content: "";
  display: block;
  width: calc(25% - 24px);
  /* .boxに指定したwidthと同じ幅を指定する */
  height: 0;
}
@media (max-width: 767px) {
  #agency_detail .ec-prof__introFlex::after {
    width: calc(50% - 7.5px);
  }
}
#agency_detail .ec-prof__introDetail {
  width: calc(25% - 24px);
}
@media (max-width: 767px) {
  #agency_detail .ec-prof__introDetail {
    width: calc(50% - 7.5px);
  }
}
#agency_detail .ec-prof__introLink {
  display: inline-block;
  width: 100%;
}
#agency_detail .ec-prof__introLink > img {
  width: 100%;
  object-fit: cover;
}
#agency_detail .ec-prof__backBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 62px;
  color: #fff;
  background: #000;
  font-size: 15px;
  font-family: "Koburina-Gothic-w3", sans-serif;
  margin-bottom: 64px;
}
#agency_detail .ec-prof__backBtn:hover {
  opacity: 0.8;
  text-decoration: none;
}
#agency_detail .ec-prof__backBtn img {
  width: 5px;
  height: 10px;
  margin-right: 30px;
}
@media (min-width: 768px) {
  #agency_detail #shop_guide.ec-shopGuide {
    padding: 120px 0 0 0;
  }
}

@media (min-width: 768px) {
  #agency_detail + #instagram.ec-instagram {
    display: none;
  }
}
@media (max-width: 767px) {
  #agency_detail + #instagram.ec-instagram {
    display: block;
    margin-bottom: 64px;
  }
}

#page_agency_detail .ec-layoutRole__footer {
  margin-top: 0;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
よくあるご質問

よくあるご質問のコンポーネントを定義します。
*/
.faq_page .ec-layoutRole__contents {
  margin: 0;
  padding: 0;
  line-height: 1.8em;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  color: #5e6770;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
.faq_page .ec-layoutRole__contents a {
  outline: 0;
  text-decoration: none;
}
.faq_page .ec-layoutRole__contents ul {
  list-style: none;
}
.faq_page .ec-layoutRole__contents li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.faq_page .ec-pageHeader {
  position: relative;
  margin: 0 80px 80px;
  padding: 0;
  height: 140px;
  background-color: #c8dade;
}
@media print, screen and (max-width: 1180px) {
  .faq_page .ec-pageHeader {
    margin: 0 50px 50px;
  }
}
@media print, screen and (max-width: 767px) {
  .faq_page .ec-pageHeader {
    margin: 0 0 50px;
  }
}
.faq_page .ec-pageHeader .ec-pageHeader__h1 {
  position: absolute;
  top: 0;
  right: 112px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  color: #ffffff;
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 34px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 1;
  border-bottom: none;
}
@media print, screen and (max-width: 1180px) {
  .faq_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 50px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media print, screen and (max-width: 767px) {
  .faq_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 30px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}

#ec-faq {
  margin: 0;
  padding: 0;
}

.ec-faq {
  margin: 0;
  padding: 0 80px 86px;
  color: #5e6770;
  letter-spacing: 2px;
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
@media print, screen and (max-width: 1180px) {
  .ec-faq {
    padding: 0 50px 86px;
  }
}
@media print, screen and (max-width: 767px) {
  .ec-faq {
    padding: 0 30px 16px;
  }
}
.ec-faq .ec-faq__inneer {
  margin: 0 auto;
  padding: 0;
  max-width: 998px;
}
.ec-faq .ec-faq__inneer .ec-faq__h2 {
  margin: 0 0 30px;
  padding: 0;
  font-weight: 500 !important;
  font-size: 28px;
  font-family: "Ryumin", sans-serif !important;
}
@media print, screen and (max-width: 1180px) {
  .ec-faq .ec-faq__inneer .ec-faq__h2 {
    text-align: center;
    letter-spacing: 3px;
    font-size: 24px;
  }
}
.ec-faq .ec-faq__inneer .ec-faq__menu {
  margin: 0;
  padding: 0;
}
.ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 0 0 -30px;
  padding: 0;
}
@media print, screen and (max-width: 1180px) {
  .ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi {
    margin: 0 0 0 -10px;
  }
}
@media print, screen and (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi {
    margin: 0;
  }
}
.ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi li {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 0 0 30px;
  width: 25%;
}
@media (max-width: 1180px) {
  .ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi li {
    padding: 0 0 0 10px;
  }
}
@media (max-width: 960px) {
  .ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi li {
    padding: 0 0 10px 10px;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi li {
    padding: 0 0 1px;
    width: 100%;
  }
}
.ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  height: 50px;
  background: url(/html/template/default/assets/img/common/btn_arrow_bottom.svg) no-repeat rgba(200, 218, 222, 0.36) right 20px top 50%;
  color: #5e6770;
  font-size: 14px;
}
@media print, screen and (max-width: 1180px) {
  .ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi li a {
    letter-spacing: 1px;
  }
}
@media print, screen and (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__menu .ec-faq__navi li a {
    font-size: 13px;
  }
}
.ec-faq .ec-faq__inneer .ec-faq__list {
  margin: 0;
  padding: 65px 0 0;
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 30px;
  padding: 0 20px;
  height: 50px;
  background-color: rgba(200, 218, 222, 0.36);
  letter-spacing: 2px;
  font-weight: 500 !important;
  font-size: 20px;
  font-family: "Ryumin", sans-serif !important;
}
@media print, screen and (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__h3 {
    letter-spacing: 1px;
    font-size: 16px;
  }
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail {
  margin: 0;
  padding: 20px 0;
  border-bottom: #c8dade solid 1px;
  font-size: 15px;
}
@media print, screen and (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail {
    font-size: 14px;
  }
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail i {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 23px 0 0;
  padding: 0;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  line-height: 1;
}
@media print, screen and (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail i {
    margin: 0 20px 0 0;
  }
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
@media print, screen and (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt {
    align-items: center;
  }
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt i {
  background-color: rgba(200, 218, 222, 0.36);
  text-indent: 3px;
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt .ec-faq__question {
  position: relative;
  flex-grow: 1;
  margin: 0;
  padding: 5px 50px 0 0;
}
@media print, screen and (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt .ec-faq__question {
    box-sizing: border-box;
    padding: 0 50px 0 0;
    width: calc(100% - 56px);
  }
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt .ec-faq__question::before {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 15px;
  height: 1px;
  background-color: #5e6770;
  content: "";
  transition: transform 0.7s cubic-bezier(0.26, 0.63, 0, 0.99) 0.2s;
  transform: rotate(0deg);
  transform-origin: center center;
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt .ec-faq__question::after {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 15px;
  height: 1px;
  background-color: #5e6770;
  content: "";
  transition: transform 0.7s cubic-bezier(0.26, 0.63, 0, 0.99) 0.2s;
  transform: rotate(90deg);
  transform-origin: center center;
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt.open .ec-faq__question::before {
  transform: rotate(360deg);
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dt.open .ec-faq__question::after {
  transform: rotate(360deg);
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dd {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
  padding: 20px 0 0;
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dd i {
  border: #c8dade solid 1px;
  text-indent: 3px;
}
.ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dd .ec-faq__answer {
  position: relative;
  flex-grow: 1;
  margin: 0;
  padding: 5px 0 0 0;
  width: calc(100% - 59px);
  font-size: 15px;
  line-height: 1.8em;
}
@media print, screen and (max-width: 767px) {
  .ec-faq .ec-faq__inneer .ec-faq__list .ec-faq__detail dd .ec-faq__answer {
    box-sizing: border-box;
    width: calc(100% - 56px);
    font-size: 14px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
採用情報

採用情報のコンポーネントを定義します。
*/
.recruit_page .ec-layoutRole__contents {
  margin: 0;
  padding: 0;
  line-height: 1.8em;
  letter-spacing: 2px;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  color: #5e6770;
  font-family: KoburiGothic2, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
}
.recruit_page .ec-layoutRole__contents a {
  outline: 0;
  text-decoration: none;
}
.recruit_page .ec-layoutRole__contents ul {
  list-style: none;
}
.recruit_page .ec-layoutRole__contents li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.recruit_page .ec-pageHeader {
  position: relative;
  margin: 0 80px 80px;
  padding: 0;
  height: 140px;
  background-color: #c8dade;
}
@media (max-width: 1180px) {
  .recruit_page .ec-pageHeader {
    margin: 0 50px 50px;
  }
}
@media (max-width: 767px) {
  .recruit_page .ec-pageHeader {
    margin: 0 0 50px;
  }
}
.recruit_page .ec-pageHeader .ec-pageHeader__h1 {
  position: absolute;
  top: 0;
  right: 112px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  color: #ffffff;
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 34px;
  font-family: BonvenoCF, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1;
  border-bottom: none;
}
@media (max-width: 1180px) {
  .recruit_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 50px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .recruit_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 30px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}

#ec-recruit {
  margin: 0;
  padding: 0;
}
#ec-recruit h3 {
  font-weight: 400;
  font-family: BonvenoCF, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
}
@media (max-width: 1180px) {
  #ec-recruit h3 {
    text-align: center;
  }
}

.ec-recruit__position {
  margin: 0 auto;
  padding: 0 50px 34px;
  max-width: 996px;
}
@media (max-width: 767px) {
  .ec-recruit__position {
    padding: 0 30px 16px;
  }
}
.ec-recruit__position .ec-recruit__h2 {
  margin: 0 0 60px;
  padding: 0;
  letter-spacing: 5px;
  font-weight: 400;
  font-size: 34px;
  font-family: "BonvenoCF", "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__h2 {
    margin: 0 0 30px;
    text-align: center;
    letter-spacing: 3px;
    font-size: 28px;
  }
}
.ec-recruit__position .ec-recruit__nav {
  margin: 0;
  padding: 0;
}
.ec-recruit__position .ec-recruit__nav ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 0 -30px;
  padding: 0;
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__nav ul {
    flex-wrap: wrap;
    margin: 0;
  }
}
.ec-recruit__position .ec-recruit__nav ul li {
  box-sizing: border-box;
  padding: 0 0 0 30px;
  width: 25%;
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__nav ul li {
    padding: 0 0 1px;
    width: 100%;
  }
}
.ec-recruit__position .ec-recruit__nav ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  height: 50px;
  background: url(/html/template/default/assets/img/common/btn_arrow_bottom.svg) no-repeat rgba(200, 218, 222, 0.36) right 20px top 50%;
  color: #5e6770;
  font-size: 14px;
}
.ec-recruit__position .ec-recruit__detail {
  margin: 0;
  padding: 80px 0 0;
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__detail {
    padding: 65px 0 0;
  }
}
.ec-recruit__position .ec-recruit__detail .ec-recruit__h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px;
  padding: 0 20px;
  height: 50px;
  background-color: rgba(200, 218, 222, 0.36);
  letter-spacing: 3px;
  font-weight: 500 !important;
  font-size: 20px;
  font-family: "Ryumin2", "Ryumin", sans-serif !important;
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__detail .ec-recruit__h3 {
    margin: 0;
    letter-spacing: 1px;
    font-size: 16px;
  }
}
.ec-recruit__position .ec-recruit__detail dl {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.8em;
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__detail dl {
    display: block;
    padding: 20px 0 0;
    border-right: #efefef solid 1px;
    border-bottom: #efefef solid 1px;
    border-left: #efefef solid 1px;
    font-size: 14px;
  }
}
.ec-recruit__position .ec-recruit__detail dl dt {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 0 10px;
  padding: 10px 20px;
  width: 225px;
  background-color: rgba(200, 218, 222, 0.36);
}
@media (max-width: 960px) {
  .ec-recruit__position .ec-recruit__detail dl dt {
    width: 170px;
  }
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__detail dl dt {
    display: block;
    margin: 0 0 2px;
    padding: 0 20px;
    width: auto;
    background-color: transparent;
    font-weight: 700;
  }
  .ec-recruit__position .ec-recruit__detail dl dt::before {
    content: "■ ";
  }
}
.ec-recruit__position .ec-recruit__detail dl dd {
  box-sizing: border-box;
  margin: 0 0 10px;
  padding: 10px 0 10px 34px;
  width: calc(100% - 225px);
  line-height: 1.8em;
  letter-spacing: 2px;
}
@media (max-width: 960px) {
  .ec-recruit__position .ec-recruit__detail dl dd {
    width: calc(100% - 170px);
  }
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__detail dl dd {
    display: block;
    margin: 0 0 20px;
    padding: 0 20px 20px;
    width: auto;
    border-bottom: #efefef solid 1px;
  }
  .ec-recruit__position .ec-recruit__detail dl dd:last-of-type {
    margin: 0;
    border-bottom: none;
  }
}
.ec-recruit__position .ec-recruit__detail .ec-recruit__btn {
  margin: 50px 0 0;
  padding: 0;
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__detail .ec-recruit__btn {
    margin: 20px 0 0;
  }
}
.ec-recruit__position .ec-recruit__detail .ec-recruit__btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 245px;
  height: 68px;
  background-color: #c8dade;
  color: #5e6770;
  font-size: 15px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .ec-recruit__position .ec-recruit__detail .ec-recruit__btn a {
    width: 100%;
  }
}
.ec-recruit__position .ec-recruit__detail .ec-recruit__btn a span {
  display: inline-block;
  padding: 0 24px 0 0;
  background: url(/html/template/default/assets/img/common/icon_link.svg) no-repeat right;
  letter-spacing: normal;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
採用エントリーフォーム

採用エントリーフォームのコンポーネントを定義します。
*/
.recruitForm_page .ec-layoutRole__contents {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.8em;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  color: #5e6770;
  font-family: KoburiGothic, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
}
@media (max-width: 767px) {
  .recruitForm_page .ec-layoutRole__contents {
    font-size: 14px;
  }
}
.recruitForm_page .ec-layoutRole__contents a {
  outline: 0;
  text-decoration: none;
}
.recruitForm_page .ec-layoutRole__contents ul {
  list-style: none;
}
.recruitForm_page .ec-layoutRole__contents li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.recruitForm_page .ec-pageHeader {
  position: relative;
  margin: 0 80px 80px;
  padding: 0;
  height: 140px;
  background-color: #c8dade;
}
@media print, screen and (max-width: 1180px) {
  .recruitForm_page .ec-pageHeader {
    margin: 0 50px 50px;
  }
}
@media print, screen and (max-width: 767px) {
  .recruitForm_page .ec-pageHeader {
    margin: 0 0 50px;
  }
}
.recruitForm_page .ec-pageHeader .ec-pageHeader__h1 {
  position: absolute;
  top: 0;
  right: 112px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  color: #ffffff;
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 34px;
  font-family: BonvenoCF, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1;
  border-bottom: none;
}
@media print, screen and (max-width: 1180px) {
  .recruitForm_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 50px;
    letter-spacing: 3px;
    font-size: 30px;
  }
}
@media print, screen and (max-width: 767px) {
  .recruitForm_page .ec-pageHeader .ec-pageHeader__h1 {
    right: 30px;
    letter-spacing: 2px;
    font-size: 20px;
  }
}

.ec-recruitFormCommonRole__sp {
  display: none;
}
@media (max-width: 767px) {
  .ec-recruitFormCommonRole__sp {
    display: block;
  }
}
.ec-recruitFormCommonRole .ec-recruitFormCommonRole__dNone {
  display: none;
}
.ec-recruitFormCommonRole__main {
  margin: 0;
  padding: 0 80px;
}
@media print, screen and (max-width: 1180px) {
  .ec-recruitFormCommonRole__main {
    padding: 0 50px;
  }
}
@media print, screen and (max-width: 767px) {
  .ec-recruitFormCommonRole__main {
    padding: 0 30px;
  }
}
.ec-recruitFormCommonRole__textCenter {
  text-align: center;
}
.ec-recruitFormCommonRole .ec-off1Grid .ec-off1Grid__cell {
  width: 100%;
  max-width: 986px;
  margin: 0 auto;
}
.ec-recruitFormCommonRole__flow {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  font-weight: bold;
  margin: 55px 0 0;
  padding: 0 0 24px;
  border-bottom: 1px solid #c8dade;
}
.ec-recruitFormCommonRole__flowElement {
  width: 30%;
  height: auto;
  padding: 0;
  color: #a4c0c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
}
.ec-recruitFormCommonRole__flowElement.active {
  color: #66a6af;
}
.ec-recruitFormCommonRole__flowElement.active .ec-recruitFormCommonRole__flowNumber {
  color: #fff;
  background: #66a6af;
}
.ec-recruitFormCommonRole__flowNumber {
  background: rgba(200, 218, 222, 0.36);
  width: 26px;
  height: 26px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a4c0c4;
  margin-bottom: 4px;
  font-size: 15px;
}
.ec-recruitFormCommonRole .ec-borderedDefs {
  border-top: unset;
  margin-top: 40px;
}
.ec-recruitFormCommonRole .ec-borderedDefs dt {
  padding: 9px 0 11px;
}
.ec-recruitFormCommonRole .ec-label {
  font-family: KoburiGothic, sans-serif;
  font-size: 18px;
  display: inline;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .ec-recruitFormCommonRole .ec-label {
    font-size: 17px;
  }
}
.ec-recruitFormCommonRole dl {
  flex-wrap: unset !important;
  display: block;
  border-bottom: 1px dashed #a4c0c4;
  padding: 15px 20px 14px !important;
}
@media (max-width: 767px) {
  .ec-recruitFormCommonRole dl {
    padding: 10px 20px 12px !important;
  }
}
.ec-recruitFormCommonRole dt, .ec-recruitFormCommonRole dd {
  width: 100% !important;
}
.ec-recruitFormCommonRole__borderBottomNone {
  border-bottom: unset !important;
}
.ec-recruitFormCommonRole .ec-required, .ec-recruitFormCommonRole__any {
  font-size: 12px;
  color: #ffffff;
  padding: 2px 10px;
  font-style: normal;
  margin-left: 20px;
  display: inline-block;
  vertical-align: 2px;
}
.ec-recruitFormCommonRole .ec-required {
  background: #66a6af;
}
.ec-recruitFormCommonRole__any {
  background: rgba(200, 218, 222, 0.36);
  color: #a4c0c4;
}
.ec-recruitFormCommonRole .ec-blockBtn--action,
.ec-recruitFormCommonRole .ec-blockBtn--cancel {
  font-family: KoburiGothic, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  color: #5e6770;
  font-size: 18px;
  letter-spacing: 0.1em;
  line-height: 2;
  cursor: pointer;
  border: none;
  max-width: 235px;
  height: 63px;
  margin: 0 auto;
  font-weight: normal;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
  position: relative;
}
.ec-recruitFormCommonRole .ec-blockBtn--action:before,
.ec-recruitFormCommonRole .ec-blockBtn--cancel:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid;
  display: block;
  position: absolute;
}
.ec-recruitFormCommonRole .ec-blockBtn--action:before {
  border-color: #5e6770 #5e6770 transparent transparent;
  transform: translate(0, -50%) rotate(45deg);
  -ms-transform: translate(0, -50%) rotate(45deg);
  -webkit-transform: translate(0, -50%) rotate(45deg);
  top: 50%;
  right: 40px;
}
.ec-recruitFormCommonRole .ec-blockBtn--cancel:before {
  border-color: #FFF #FFF transparent transparent;
  transform: translate(0, -50%) rotate(225deg);
  -ms-transform: translate(0, -50%) rotate(225deg);
  -webkit-transform: translate(0, -50%) rotate(225deg);
  top: 50%;
  left: 40px;
}
.ec-recruitFormCommonRole .ec-blockBtn--action {
  background-color: #c8dade;
}
.ec-recruitFormCommonRole .ec-blockBtn--action:hover {
  opacity: 0.7;
}
.ec-recruitFormCommonRole .ec-blockBtn--cancel {
  background-color: #5e6770;
  color: #FFF;
}
.ec-recruitFormCommonRole .ec-blockBtn--cancel:hover {
  opacity: 0.7;
}

.ec-recruitFormRole .ec-recruitFormRole__actions {
  padding-top: 20px;
}
.ec-recruitFormRole p {
  margin: 16px 0;
}
.ec-recruitFormRole .ec-recruitFormRole__explanation {
  margin: 0 0 30px;
  padding: 0;
  letter-spacing: 5px;
  font-weight: 500;
  font-size: 28px;
  font-family: KoburiGothic, sans-serif;
  text-align: center;
}
@media (max-width: 1180px) {
  .ec-recruitFormRole .ec-recruitFormRole__explanation {
    letter-spacing: 3px;
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .ec-recruitFormRole .ec-recruitFormRole__explanation {
    line-height: 1.8em;
  }
}
.ec-recruitFormRole .ec-radio input[type=radio],
.ec-recruitFormRole .ec-input [type=file],
.ec-recruitFormRole .ec-halfInput [type=file],
.ec-recruitFormRole .ec-numberInput [type=file],
.ec-recruitFormRole .ec-zipInput [type=file],
.ec-recruitFormRole .ec-telInput [type=file],
.ec-recruitFormRole .ec-select [type=file],
.ec-recruitFormRole .ec-birth [type=file],
.ec-recruitFormRole .ec-radio label,
.ec-recruitFormRole .ec-checkbox input[type=checkbox],
.ec-recruitFormRole .ec-checkbox label {
  cursor: pointer;
}
.ec-recruitFormRole .ec-checkbox label,
.ec-recruitFormRole .ec-radio label,
.ec-recruitFormRole .ec-input [type=text],
.ec-recruitFormRole .ec-halfInput [type=text],
.ec-recruitFormRole .ec-numberInput [type=text],
.ec-recruitFormRole .ec-zipInput [type=text],
.ec-recruitFormRole .ec-telInput [type=text],
.ec-recruitFormRole .ec-select [type=text],
.ec-recruitFormRole .ec-birth [type=text],
.ec-recruitFormRole .ec-input [type=tel],
.ec-recruitFormRole .ec-halfInput [type=tel],
.ec-recruitFormRole .ec-numberInput [type=tel],
.ec-recruitFormRole .ec-zipInput [type=tel],
.ec-recruitFormRole .ec-telInput [type=tel],
.ec-recruitFormRole .ec-select [type=tel],
.ec-recruitFormRole .ec-birth [type=tel],
.ec-recruitFormRole .ec-input [type=email],
.ec-recruitFormRole .ec-halfInput [type=email],
.ec-recruitFormRole .ec-numberInput [type=email],
.ec-recruitFormRole .ec-zipInput [type=email],
.ec-recruitFormRole .ec-telInput [type=email],
.ec-recruitFormRole .ec-select [type=email],
.ec-recruitFormRole .ec-birth [type=email] {
  height: 50px;
}
.ec-recruitFormRole .ec-checkbox label,
.ec-recruitFormRole .ec-input [type=text],
.ec-recruitFormRole .ec-halfInput [type=text],
.ec-recruitFormRole .ec-numberInput [type=text],
.ec-recruitFormRole .ec-zipInput [type=text],
.ec-recruitFormRole .ec-telInput [type=text],
.ec-recruitFormRole .ec-select [type=text],
.ec-recruitFormRole .ec-birth [type=text],
.ec-recruitFormRole .ec-input [type=tel],
.ec-recruitFormRole .ec-halfInput [type=tel],
.ec-recruitFormRole .ec-numberInput [type=tel],
.ec-recruitFormRole .ec-zipInput [type=tel],
.ec-recruitFormRole .ec-telInput [type=tel],
.ec-recruitFormRole .ec-select [type=tel],
.ec-recruitFormRole .ec-birth [type=tel],
.ec-recruitFormRole .ec-input [type=email],
.ec-recruitFormRole .ec-halfInput [type=email],
.ec-recruitFormRole .ec-numberInput [type=email],
.ec-recruitFormRole .ec-zipInput [type=email],
.ec-recruitFormRole .ec-telInput [type=email],
.ec-recruitFormRole .ec-select [type=email],
.ec-recruitFormRole .ec-birth [type=email],
.ec-recruitFormRole .ec-input [type=file],
.ec-recruitFormRole .ec-halfInput [type=file],
.ec-recruitFormRole .ec-numberInput [type=file],
.ec-recruitFormRole .ec-zipInput [type=file],
.ec-recruitFormRole .ec-telInput [type=file],
.ec-recruitFormRole .ec-select [type=file],
.ec-recruitFormRole .ec-birth [type=file],
.ec-recruitFormRole .ec-radio label,
.ec-recruitFormRole .ec-select select,
.ec-recruitFormRole .ec-input textarea,
.ec-recruitFormRole .ec-halfInput textarea,
.ec-recruitFormRole .ec-numberInput textarea,
.ec-recruitFormRole .ec-zipInput textarea,
.ec-recruitFormRole .ec-telInput textarea,
.ec-recruitFormRole .ec-select textarea,
.ec-recruitFormRole .ec-birth textarea {
  border: 1px solid #5e6770 !important;
  border-radius: unset;
}
.ec-recruitFormRole .ec-input [type=text], .ec-recruitFormRole .ec-halfInput [type=text], .ec-recruitFormRole .ec-numberInput [type=text], .ec-recruitFormRole .ec-zipInput [type=text], .ec-recruitFormRole .ec-telInput [type=text], .ec-recruitFormRole .ec-select [type=text], .ec-recruitFormRole .ec-birth [type=text],
.ec-recruitFormRole .ec-input [type=tel],
.ec-recruitFormRole .ec-halfInput [type=tel],
.ec-recruitFormRole .ec-numberInput [type=tel],
.ec-recruitFormRole .ec-zipInput [type=tel],
.ec-recruitFormRole .ec-telInput [type=tel],
.ec-recruitFormRole .ec-select [type=tel],
.ec-recruitFormRole .ec-birth [type=tel],
.ec-recruitFormRole .ec-input [type=email],
.ec-recruitFormRole .ec-halfInput [type=email],
.ec-recruitFormRole .ec-numberInput [type=email],
.ec-recruitFormRole .ec-zipInput [type=email],
.ec-recruitFormRole .ec-telInput [type=email],
.ec-recruitFormRole .ec-select [type=email],
.ec-recruitFormRole .ec-birth [type=email],
.ec-recruitFormRole .ec-input textarea,
.ec-recruitFormRole .ec-halfInput textarea,
.ec-recruitFormRole .ec-numberInput textarea,
.ec-recruitFormRole .ec-zipInput textarea,
.ec-recruitFormRole .ec-telInput textarea,
.ec-recruitFormRole .ec-select textarea,
.ec-recruitFormRole .ec-birth textarea {
  max-width: 680px;
  width: 100%;
  padding: 15px 20px;
  font-size: 15px;
  line-height: 1.8em;
}
@media (max-width: 767px) {
  .ec-recruitFormRole .ec-input [type=text], .ec-recruitFormRole .ec-halfInput [type=text], .ec-recruitFormRole .ec-numberInput [type=text], .ec-recruitFormRole .ec-zipInput [type=text], .ec-recruitFormRole .ec-telInput [type=text], .ec-recruitFormRole .ec-select [type=text], .ec-recruitFormRole .ec-birth [type=text],
.ec-recruitFormRole .ec-input [type=tel],
.ec-recruitFormRole .ec-halfInput [type=tel],
.ec-recruitFormRole .ec-numberInput [type=tel],
.ec-recruitFormRole .ec-zipInput [type=tel],
.ec-recruitFormRole .ec-telInput [type=tel],
.ec-recruitFormRole .ec-select [type=tel],
.ec-recruitFormRole .ec-birth [type=tel],
.ec-recruitFormRole .ec-input [type=email],
.ec-recruitFormRole .ec-halfInput [type=email],
.ec-recruitFormRole .ec-numberInput [type=email],
.ec-recruitFormRole .ec-zipInput [type=email],
.ec-recruitFormRole .ec-telInput [type=email],
.ec-recruitFormRole .ec-select [type=email],
.ec-recruitFormRole .ec-birth [type=email],
.ec-recruitFormRole .ec-input textarea,
.ec-recruitFormRole .ec-halfInput textarea,
.ec-recruitFormRole .ec-numberInput textarea,
.ec-recruitFormRole .ec-zipInput textarea,
.ec-recruitFormRole .ec-telInput textarea,
.ec-recruitFormRole .ec-select textarea,
.ec-recruitFormRole .ec-birth textarea {
    font-size: 14px;
  }
}
.ec-recruitFormRole .ec-radio .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: bold;
  color: #DE5D50;
}
@media (max-width: 767px) {
  .ec-recruitFormRole .ec-radio .ec-errorMessage {
    font-size: 14px;
  }
}
.ec-recruitFormRole .ec-checkbox span,
.ec-recruitFormRole .ec-radio span {
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-recruitFormRole .ec-checkbox span,
.ec-recruitFormRole .ec-radio span {
    font-size: 14px;
  }
}
.ec-recruitFormRole .ec-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/html/template/default/assets/img/contact/contact_select_arrow01.png");
  background-repeat: no-repeat;
  background-color: unset;
  background-size: 12px 6px;
  background-position: right 20px center;
  max-width: 325px;
  width: 100%;
  height: 50px;
  padding: 13px 45px 13px 20px;
  font-size: 15px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .ec-recruitFormRole .ec-select select {
    font-size: 14px;
  }
}
.ec-recruitFormRole .ec-select.error select {
  background-color: #FDF1F0;
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide {
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide div {
  margin-left: 10px;
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide input[type=radio],
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide input[type=checkbox] {
  display: none;
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide input[type=radio]:checked + label,
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide input[type=checkbox]:checked + label {
  color: #5e6770;
  border: 1px solid #5e6770;
  background-color: #a4c0c4;
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide input[type=radio]:checked + label::after,
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide input[type=checkbox]:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  right: 24px;
  width: 7px;
  height: 14px;
  transform: rotate(40deg);
  border-bottom: 2px solid #5e6770;
  border-right: 2px solid #5e6770;
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide label {
  position: relative;
  border: 1px solid #c8dade;
  padding: 4px 60px 4px 20px;
  margin: 0 0 10px 0;
  min-width: 300px;
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide label {
    font-size: 14px;
    padding: 8px 60px 8px 20px;
  }
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide label:hover {
  background-color: #c8dade;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide label:hover span {
  color: #5e6770;
}
.ec-recruitFormRole__radioAndCheckBox .ec-recruitFormRole__sideBySide label:before {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  right: 18px;
  width: 20px;
  height: 20px;
  background: #fff;
  border: solid 1px #5e6770;
}
.ec-recruitFormRole__radioAndCheckBox.error label {
  border: 1px solid #CF3F34;
  background-color: #FDF1F0;
}
.ec-recruitFormRole__postalCode input[type=text] {
  width: 100px !important;
  display: inline;
}
.ec-recruitFormRole__fileBox input[type=file] {
  display: none;
}
.ec-recruitFormRole__fileBox .custom-file-label {
  padding: 2px 60px 2px 20px;
  border: 1px solid #5e6770 !important;
  cursor: pointer;
  min-width: 300px;
  height: 50px;
}
@media (max-width: 767px) {
  .ec-recruitFormRole__fileBox .custom-file-label {
    padding: 8px 60px 8px 20px;
  }
}
.ec-recruitFormRole__fileBox .custom-file-label:before {
  content: "ファイルを選択";
}
.ec-recruitFormRole__fileBox .custom-file-label:hover {
  background-color: #c8dade;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-recruitFormRole__fileBox.error label {
  border: 1px solid #CF3F34;
  background-color: #FDF1F0;
}
.ec-recruitFormRole__consultation {
  padding-bottom: 10px;
}
.ec-recruitFormRole__consultation textarea {
  height: 450px;
}
@media (max-width: 767px) {
  .ec-recruitFormRole__consultation textarea {
    height: 200px;
  }
}
.ec-recruitFormRole .ec-recruitFormRole__thinText {
  margin: 0 0 16px;
  color: #5e6770;
}
@media (max-width: 767px) {
  .ec-recruitFormRole .ec-recruitFormRole__thinText {
    margin: 10px 0 6px;
  }
}
.ec-recruitFormRole__contactCaption {
  margin: 30px auto 60px;
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-recruitFormRole__contactCaption {
    font-size: 14px;
  }
}
.ec-recruitFormRole .ec-errorMessage {
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-recruitFormRole .ec-errorMessage {
    font-size: 14px;
  }
}

.ec-recruitFormConfirmRole .ec-recruitFormConfirmRole__actions {
  padding-top: 20px;
}
.ec-recruitFormConfirmRole .ec-recruitFormConfirmRole__flow {
  margin: 0;
}
.ec-recruitFormConfirmRole .ec-blockBtn--action {
  margin-bottom: 16px;
}
.ec-recruitFormConfirmRole .ec-recruitFormConfirmRole__explanation {
  margin-bottom: 24px;
  line-height: 1.9em;
}
.ec-recruitFormConfirmRole dl {
  padding: 15px 20px 15px !important;
}
@media (max-width: 767px) {
  .ec-recruitFormConfirmRole dl {
    padding: 10px 20px 10px !important;
  }
}
.ec-recruitFormConfirmRole .ec-recruitFormConfirmRole__dt {
  padding-top: 3px;
  line-height: 1.6em;
}
.ec-recruitFormConfirmRole .ec-recruitFormConfirmRole__dt .ec-required,
.ec-recruitFormConfirmRole .ec-recruitFormConfirmRole__dt .ec-recruitFormConfirmRole__any {
  line-height: 1.42857143;
}
.ec-recruitFormConfirmRole .ec-recruitFormConfirmRole__dd {
  line-height: 1.9em;
  padding-bottom: 5px;
  font-size: 15px;
}
@media (max-width: 767px) {
  .ec-recruitFormConfirmRole .ec-recruitFormConfirmRole__dd {
    font-size: 14px;
  }
}
.ec-recruitFormConfirmRole .ec-borderedDefs {
  margin-bottom: 62px;
}

.ec-recruitFormCompleteRole .ec-recruitFormCompleteRole__flow {
  margin: 0;
}
.ec-recruitFormCompleteRole .ec-recruitFormCompleteRole__h2 {
  line-height: 1.6em;
  font-size: 28px;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .ec-recruitFormCompleteRole .ec-recruitFormCompleteRole__h2 {
    font-size: 22px;
  }
}
.ec-recruitFormCompleteRole .ec-reportHeading {
  margin: 0 0 30px;
  border-top: none;
}
.ec-recruitFormCompleteRole .ec-reportDescription {
  font-size: 15px;
  line-height: 1.9em;
  margin-bottom: 62px;
}
@media (max-width: 767px) {
  .ec-recruitFormCompleteRole .ec-reportDescription {
    font-size: 14px;
  }
}
.ec-recruitFormCompleteRole .ec-blockBtn--action {
  padding: 13px 0;
}
.ec-recruitFormCompleteRole .ec-blockBtn--action:before {
  right: 34px;
}

.howto_page {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
.howto_page .ec-layoutRole .ec-layoutRole__contents {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  display: initial;
}

#ec-howto {
  margin: 0;
  padding: 0;
}
#ec-howto h3 {
  font-weight: 400;
  font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}
#ec-howto .swiper-wrapper {
  z-index: 0;
}
#ec-howto ul {
  list-style-type: none;
}
#ec-howto #special {
  display: none;
}
#ec-howto .ec-symbolFont {
  font-family: "Fahkwang", sans-serif;
}

.ec-howToUse {
  margin: 0;
  padding: 80px 0 150px;
}
@media (max-width: 960px) {
  .ec-howToUse {
    padding: 40px 0 100px;
  }
}
@media (max-width: 767px) {
  .ec-howToUse {
    padding: 30px 0 70px;
  }
}
.ec-howToUse .ec-howToUse__inneer {
  margin: 0 auto;
  padding: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__tab {
  margin: 0 0 53px;
  padding: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__tab {
    margin: 0 0 36px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__tab ul {
  display: inline-flex;
  margin: 0;
  padding: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__tab ul li {
  margin: 0 25px;
  padding: 0;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__tab ul li {
    margin: 0 16px;
    padding: 0;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__tab ul li a {
  font-family: "Koburina-Gothic-w3", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  color: #5c5c67;
  text-align: center;
  text-decoration: none;
  letter-spacing: normal;
  font-weight: 500;
  font-size: 16px;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__tab ul li a {
    width: 110px;
    font-size: 14px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__tab ul li a.active::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #5c5c67;
  content: "";
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow {
  margin: 0 auto;
  padding: 0 80px;
  max-width: 1286px;
}
@media (max-width: 1280px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow {
    padding: 0 50px;
  }
}
@media (max-width: 1180px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow {
    display: block;
  }
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow {
    display: none;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 0 25px -10px;
  padding: 0;
}
@media (max-width: 1280px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type {
    margin: 0 0 25px;
  }
}
@media (max-width: 1180px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type {
    margin: 0 0 25px -10px;
    width: 100%;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li {
  box-sizing: border-box;
  padding: 0 0 10px 10px;
  width: 25%;
}
@media (max-width: 1280px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li {
    padding: 0 0 10px 10px;
  }
}
@media (max-width: 960px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li {
    width: 33.333%;
  }
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li {
    width: 50%;
  }
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li:first-child {
    text-align: center;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li .ec-howToUse__do {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 20px;
  height: 58px;
  background-color: #5c5c67;
  color: #ffffff;
  text-align: center;
  letter-spacing: normal;
  font-size: 14px;
  font-family: "BonvenoCF", sans-serif;
  line-height: 1.2;
}
@media (max-width: 1280px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li .ec-howToUse__do {
    padding: 0 20px;
    background-color: #5c5c67;
    background-size: 6px auto;
    font-size: 14px;
  }
}
@media (max-width: 1100px) and (min-width: 768px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li .ec-howToUse__do {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow .ec-howToUse__type li .ec-howToUse__do {
    height: 50px;
    background-color: #5c5c67;
    background-size: 4px auto;
    font-size: 13px;
    line-height: 1.4em;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__flow:last-of-type {
  margin: 0 auto 110px;
}
.ec-howToUse .ec-howToUse__inneer .ec-howTOUse__noWrap {
  white-space: nowrap;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow {
  position: relative;
  display: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow {
    display: block;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow ul {
  margin: 0;
  padding: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow ul li {
  margin: 0;
  padding: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 50px;
  background-color: #5c5c67;
  background-size: 4px auto;
  color: #ffffff;
  text-align: center;
  letter-spacing: normal;
  font-size: 14px;
  font-family: "BonvenoCF", sans-serif;
  line-height: 1;
  opacity: 0.2;
  transition: opacity 0.7s cubic-bezier(0.26, 0.63, 0, 0.99);
}
@media (max-width: 420px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow ul li a {
    font-size: 12px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow ul li.swiper-slide-active a {
  opacity: 1;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow .link_btn {
  position: absolute;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow .link_btn span {
  display: block;
  width: 24px;
  height: 24px;
  border: #bebec1 solid 1px;
  border-radius: 100%;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow .link_btn.link-prev {
  left: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow .link_btn.link-prev span {
  background: url(/html/template/default/assets/img/howtouse/slider_prev.svg) no-repeat 50% 50% #ffffff;
  background-size: 5px auto;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow .link_btn.link-next {
  right: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUseSp__flow .link_btn.link-next span {
  background: url(/html/template/default/assets/img/howtouse/slider_next.svg) no-repeat 50% 50% #ffffff;
  background-size: 5px auto;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content {
  position: relative;
  margin: 0;
  padding: 80px 0 80px;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content {
    padding: 50px 0 40px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content:last-of-type {
  margin: 0;
  padding: 100px 0 0;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content:last-of-type {
    padding: 80px 0 0;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content:last-of-type::after {
  display: none;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content::after {
  position: absolute;
  right: 80px;
  bottom: 0;
  left: 80px;
  height: 1px;
  background-color: #e4e4e4;
  content: "";
}
@media (max-width: 1280px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content::after {
    right: 50px;
    left: 50px;
  }
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content::after {
    right: 30px;
    left: 30px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 80px;
}
@media (max-width: 1280px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex {
    padding: 0 0 0 50px;
  }
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex {
    display: block;
    padding: 0;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType {
  box-sizing: border-box;
  margin: 0;
  padding: 0 50px 0 0;
  width: 24%;
}
@media (max-width: 1600px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType {
    width: 27%;
  }
}
@media (max-width: 1400px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType {
    width: 36%;
  }
}
@media (max-width: 960px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType {
    padding: 0 40px 0 0;
    width: 45%;
  }
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType {
    padding: 0 30px;
    width: auto;
  }
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUse__img {
    padding: 0 40px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUse__h3 {
  margin: 0 auto 20px 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 25px;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUse__h3 {
    margin: 0 0 20px;
    padding: 0 30px;
    text-align: center;
    font-size: 20px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod {
  margin: 0;
  padding: 0;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod {
    margin: 0 0 30px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__img {
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__img img {
  width: auto;
  height: 210px;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__img img {
    width: auto;
    height: 240px;
  }
}
@media (max-width: 500px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__img img {
    width: auto;
    height: 167px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__caption {
  margin: 0;
  padding: 20px 0 0;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__caption {
    padding: 8px 0 0;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__caption .ec-howToUseMethod__text {
  margin: 0;
  padding: 0;
  text-align: justify;
  letter-spacing: normal;
  font-size: 16px;
  line-height: 1.8em;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__caption .ec-howToUseMethod__text {
    font-size: 14px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__caption .ec-howToUseMethod__text b {
  display: inline-block;
  font-weight: 500;
  font-family: "Ryumin", sans-serif;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__caption .ec-howToUseMethod__text b:last-of-type {
  padding: 20px 0 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__caption .ec-howToUseMethod__note {
  margin: 0;
  padding: 5px 0 0;
  text-align: justify;
  font-size: 13px;
  line-height: 1.8em;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .ec-howToUse__careType .ec-howToUseMethod .ec-howToUseMethod__caption .ec-howToUseMethod__note {
    font-size: 12px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 40px 80px 0 0;
  width: 76%;
}
@media (max-width: 1600px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container {
    width: 73%;
  }
}
@media (max-width: 1400px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container {
    width: 64%;
  }
}
@media (max-width: 1280px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container {
    padding: 40px 50px 0 0;
  }
}
@media (max-width: 960px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container {
    width: 55%;
  }
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container {
    padding: 0 10px;
    width: auto;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-wrapper {
  margin: 0;
  padding: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-wrapper .swiper-slide .ec-howToUse__detail .ec-howToUse__img {
  margin: 0;
  padding: 0;
  line-height: 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-wrapper .swiper-slide .ec-howToUse__detail .ec-howToUse__img img {
  width: 100%;
  height: auto;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 40px 0 0;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control {
    justify-content: center;
    padding: 25px 0 0;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .swiper-pagination-fraction {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: auto;
  font-weight: 300;
  font-size: 20px;
  font-family: "Fahkwang", sans-serif;
  line-height: 1;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .swiper-pagination-fraction .swiper-pagination-current {
  padding: 0 5px 0 0;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .swiper-pagination-fraction .swiper-pagination-total {
  padding: 0 0 0 5px;
  font-size: 24px;
  line-height: 20px;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .swiper-pagination-fraction.swiper-pagination-lock {
  display: none;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .arrow {
  display: block;
  width: 28px;
  height: 28px;
  border: #5c5c67 solid 1px;
  border-radius: 100%;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .swiper-button-disabled {
  transform: scale(0.6);
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .button-prev {
  margin: 0 20px 0 0;
  padding: 0;
  opacity: 0.6;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .button-prev {
    margin: 0 20px 0 0;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .button-prev .arrow {
  background: url(/html/template/default/assets/img/howtouse/slider_prev.svg) no-repeat 50% 50%;
  background-size: 7px auto;
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .button-next {
  margin: 0 0 0 20px;
  padding: 0;
  opacity: 0.6;
}
@media (max-width: 767px) {
  .ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .button-next {
    margin: 0 0 0 20px;
  }
}
.ec-howToUse .ec-howToUse__inneer .ec-howToUse__content .ec-howToUse__flex .swiper-container .swiper-control .button-next .arrow {
  background: url(/html/template/default/assets/img/howtouse/slider_next.svg) no-repeat 50% 50%;
  background-size: 7px auto;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
特商法

特商法のコンポーネントを定義します。
*/
body#page_help_tradelaw {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
}

dd, dt {
  letter-spacing: 0.12em;
}

.ec-label {
  font-family: "Koburina-Gothic-w3";
}