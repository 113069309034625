@import "../mixins/media";
/*
ショップガイドのスタイルです。
対応twig: app/template/default/Block/bottomInfo.twig

*/

#shop_guide.ec-shopGuide {
  padding: 120px 0;
  background: #D7E1E4;
  @media (max-width: 767px) {
    padding: 80px 0 64px 0;
  }
  .ec-widthRole {
    margin: 0 auto;
  }
  .ec-section__titleEn {
    color: #fff;
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 48px;
    }
    @media (max-width: 375px) {
      white-space: nowrap;
    }
  }
  .ec-shopGuide__list {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      justify-content: space-evenly;
    }
    .ec-shopGuide__detail {
      padding-bottom: 20px;
      width: 33.33%;
      @media (max-width: 767px) {
        padding-bottom: 16px;
      }
      @media (max-width: 600px) {
        padding-bottom: 14px;
      }
      @media (max-width: 428px) {
        padding-bottom: 10px;
      }
    }
    .ec-shopGuide__secTitleEn {
      font-family: "BonvenoCF-Light", sans-serif;
      color: #5C5C67;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 0.12em;
      text-align: center;
      margin-bottom: 16px;
      @media (max-width: 767px) {
        margin-bottom: 12px;
        font-size: 9px;
      }
      @media (max-width: 600px) {
        margin-bottom: 10px;
        font-size: 7px;
        line-height: 1;
      }
      @media (max-width: 428px) {
        // display: none;
      }
    }
    .ec-shopGuide__secTitleJa {
      font-family: "Koburina-Gothic-w3", sans-serif;
      color: #5C5C67;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.12em;
      text-align: center;
      margin-bottom: 16px;
      @media (max-width: 600px) {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.5;
      }
    }
    .ec-icon__delivery {
      width: 57px;
      height: auto;
      margin: 0 auto;
      display: block;
      @media (max-width: 767px) {
        width: 47px;
      }
      @media (max-width: 600px) {
        width: 37px;
        height: 25px;
      }
    }
    .ec-icon__payment {
      width: 51px;
      height: auto;
      margin: 0 auto;
      display: block;
      @media (max-width: 767px) {
        width: 41px;
      }
      @media (max-width: 600px) {
        width: 31px;
        height: 25px;
      }
    }
    .ec-icon__returns {
      width: 53px;
      height: auto;
      margin: 0 auto;
      display: block;
      @media (max-width: 767px) {
        width: 43px;
      }
      @media (max-width: 600px) {
        width: 33px;
        height: 25px;
        transform: scale(1.2);
      }
    }
  }
  .ec-viewmore {
    width: 120px;
    text-align: center;
    float: none;
    margin: 0 auto;
    display: block;
    padding-bottom: 8px;
    @media (max-width: 600px) {
      padding-bottom: 6px;
    }
    @media (max-width: 428px) {
      width: 110px;
      padding-bottom: 4px;
    }
    &__wrap {
      margin-top: 24px;
      @media (max-width: 767px) {
        margin-top: 16px;
      }
    }
    a {
      font-size: 16px;
      letter-spacing: 0.1em;
      line-height: 28px;
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
}
