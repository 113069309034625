@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: #fff;
  & &__contentTop {
    padding: 0;
  }

  & &__contents {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 1150px;
    display: flex;
    flex-wrap: nowrap;

  }
  & &__main {
    width: 100%;
  }
  & &__mainWithColumn {
    width: 100%;
    @include media_desktop() {
      width: 75%;
    }
  }
  & &__mainBetweenColumn {
    width: 100%;
    @include media_desktop() {
      width: 50%;
    }
  }
  & &__left,
  & &__right {
    display: none;
    @include media_desktop() {
      display: block;
      width: 25%;
    }
  }
  & &__header {
    background-color: #fff;
    overflow: visible;
    @media (max-width: 767px) {
      height: 15%;
      min-height: 52px
    }
  }
}


.ec-headerRole {
  @include container;
  padding-top: 15px;
  position: relative;
  &:after {
    display: none;
  }
  @include media_desktop {
    @include clearfix;
  }
  &::before {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  @include media_desktop {
    width: 100%;
    @include clearfix;
  }
  & &__title {
    width: 100%;
  }
  & &__navSP {
    display: block;
    position: absolute;
    top: 15px;
    width: 27%;
    right: 0;
    text-align: right;
    @include media_desktop {
      display: none;
    }
    @media (max-width: 767px) {
      display: block;
      position: absolute;
      top: -19px;
    }
  }
}

.ec-widthRole.ec-headerRole {
  display: none;
  @media (max-width: 767px) {
    display: block;
    position: absolute;
    left: calc(50% - 35%/2);
    top: 10px;
    width: 35%;
    padding-top: 0;
    z-index: 3;
  }
}

.ec-headerNaviRole {
  @include container;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 97%;
  padding-top: 3.6%;
  min-height: 52px;
  z-index: 2;
  @media (max-width: 980px) {
    width: 100%;
  }

  & &__search {
    margin-top: 0px!important;
    margin-left: 18px;
    @media (max-width: 1000px) {
      margin-left: 14px;
    }
    @media (max-width: 800px) {
      margin-left: 10px;
    }
  }

  .fa-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & &__inner {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 52px;
    @media (max-width: 767px) {
      justify-content: space-between;
      position: absolute;
    }
  }

  & &__left {
    // width: calc(100% / 3);
    width: auto;
  }

  & &__search {
    display: none;
    @include media_desktop() {
      display: inline-block;
      margin-top: 10px;
      @include reset_link;
      position: relative;
    }
  }

  &__searchIcon {
    display: inline-block;
    width: 16px;
    height: 16px;
    @media (max-width: 1000px) {
      width: 14px;
      height: 14px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  & &__navSP {
    display: block;
    @include media_desktop() {
      display: none;
      @include reset_link;
    }
  }

  & &__right {
    // width: calc(100% * 2 / 3);
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 767px) {
      margin-right: 20px;
    }
  }

  & &__nav {
    display: inline-block;
    z-index: 1;
    margin-right: 0;
    @include reset_link;
  }
  & &__cart {
    display: inline-block;
    @include reset_link;
  }
}

.ec-headerNavSP {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  top: 5px;
  padding: 6px 10px 10px 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: transparent;
  position: fixed;
  top: 7px;
  left: 10px;
  z-index: 1000;

  .fas {
    vertical-align: top;
  }

  @include media_desktop {
    display: none;
  }
}
.ec-headerNavSP.is-active {
  display: none;
}

#fixed_nav {
  z-index: 1;
  .ec-headerNav {
    display: none;
  }
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  @include commonStyle();
  & &__title {
    text-align: center;
    h1 {
      margin: 0;
      padding: 0;
    }
    a {
      display: inline-block;
      text-decoration: none;
      font-size: 20px;

      @include media_desktop() {
        font-size: 40px;
      }
      font-weight: bold;
      color: black;

      &:hover {
        opacity: .8;
      }
    }
  }
  & &__img {
    @media (max-width: 767px) {
      font-size: inherit!important;
    }
    img {
      @media (max-width: 980px) {
        width: 171px;
        height: 30px;
      }
      @media (max-width: 550px) {
        width: 140px;
        height: 25px;
      }
    }
  }
  & &__subtitle {
    font-size: 10px;
    text-align: center;
    @include media_desktop() {
      font-size: 16px;
      margin-bottom: 10px;
    }
    a {
      display: inline-block;
      color: #0092C4;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  display: flex;
  align-items: center;
  text-align: right;
  line-height: 1.2;
  & &__item {
    margin-left: 0;
    display: inline-block;
    font-size: 28px;
  }
  & &__itemIcon {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
    color: black;
    @include media_desktop {
      margin-right: 0;
      font-size: 20px;
    }
  }
  & &__itemLink {
    display: none;
    margin-right: 5px;
    font-size: 14px;
    vertical-align: middle;
    color: black;
    @include media_desktop {
      display: inline-block;
    }
  }
}

.ec-originalNav__item {
  margin-right: 18px;
  @media (max-width: 1000px) {
    margin-right: 14px;
  }
  @media (max-width: 800px) {
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    margin-right: 12px;
  }
  &>a {
    &> .ec-headerNavIcom__item {
      width: 16px;
      height: 16px;
      object-fit: contain;
      @media (max-width: 1000px) {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.ec-cartNaviWrap .ec-cartNavi {
  justify-content: normal;
  border-radius: 0;
  background: transparent;
  padding: 0;
  min-width: initial;
  height: auto;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    transform: scale(1.1);
    object-fit: contain;
    @media (max-width: 1000px) {
      width: 14px;
      height: 14px;
    }
    @media (max-width: 767px) {
      margin-bottom: 3px;
    }
  }
}

.ec-headerNav {
  &> .ec-headerNav__item.ec-originalNav__item {
    font-size: initial;
  }
}

.ec-headerNav__item.ec-originalNav__item {
  cursor: pointer;
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch {
  display: none;
  @include clearfix;
  @media (max-width: 767px) {
    display: block;
  }
  & &__category {
    float: none;
    @include media_desktop {
      float: left;
      width: 43%;
    }
    .ec-select {
      overflow: hidden;
      width: 100%;
      margin: 0;
      text-align: center;

      select {
        width: 100%;
        cursor: pointer;
        padding: 8px 24px 8px 8px;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        appearance: none;
        color: #fff;

        @include media_desktop {
          max-width: 165px;
          height: 36px;
        }

        option {
          color: #000;
        }

        &::-ms-expand {
          display: none;
        }
      }

      &.ec-select_search {
        position: relative;
        border: 0;
        background: #000;
        color: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        @include media_desktop {
          border-top-right-radius: inherit;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }

        &::before {
          position: absolute;
          top: 0.8em;
          right: 0.4em;
          width: 0;
          height: 0;
          padding: 0;
          content: '';
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
          pointer-events: none;
        }
      }
    }
  }
  & &__keyword{
    position: relative;
    color: $clrDarkGray;
    border: 1px solid #ccc;
    background-color: #f6f6f6;
    border-radius: 50px;

    @include media_desktop {
      float: none;
      width: 100%;
    }
    input[type="search"]{
      width: 100%;
      height: 34px;
      font-size: 16px;
      border: 0 none;
      padding: 0.5em 50px 0.5em 1em;
      box-shadow: none;
      background: none;
      box-sizing: border-box;
      margin-bottom: 0;
      @include media_desktop {
        font-size: 12px;
      }
      &::placeholder {
        color: #5C5C67;
        font-family: 'Koburina-Gothic-w3', sans-serif;
        font-size: 14px;
      }
    }
    .ec-icon {
      width: 22px;
      height: 22px;
    }
    .ec-headerNavIcom__item {
      width: 16px;
      height: 16px;
      margin-top: 4px;
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
  & &__keywordBtn{
    border: 0;
    background: none;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-55%);
    display: block;
    white-space: nowrap;
    z-index: 1;
    @media (max-width: 767px) {
      top: calc(50% + 3px);
    }
  }
}
.ec-headerSearch.is-active {
  display: block;
  position: absolute;
  right: -16px;
  width: 240px;
  margin-top: 10px;
  @media (max-width: 767px) {
    position: initial;
    margin-top: 0;
  }
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  background-color: #fff;
  @include container;
  display: none;
  @include media_desktop() {
    display: block;
    width: 100%;
    @include reset_link;
  }
}

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  &__nav {
    display: flex;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    text-align: center;
    vertical-align: bottom;
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.ec-itemNav__nav li {
  margin: 0;
  padding: 0;
  width: auto;
  text-align: center;
  position: relative;
  &:hover {
    background: transparent;
  }
}
.ec-itemNav__nav li:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #5C5C67;
  position: absolute;
  top: 4px;
  right: 0;
}
.ec-itemNav__nav li a {
  margin: 0;
  padding: 14px 12px;
  height: auto;
  color: #5C5C67;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  text-align: center;
  background: #fff;
  white-space: nowrap;
  font-family: 'BonvenoCF-Light', sans-serif;
  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
  @media (max-width: 1200px) {
    padding: 14px 10px;
    font-size: 14px;
  }
  @media (max-width: 1000px) {
    padding: 14px 8px;
    font-size: 12px;
  }
}

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 100%;
  left: 0;
  @include media_desktop {
    display: block;
    z-index: 100;
    position: absolute;
  }
}

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: .3s;
  @include media_desktop {
    overflow: hidden;
    height: 0;
  }
}

.ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #E8E8E8;
  padding: 16px 22px 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  background: black;
}

.ec-itemNav__nav > li:hover > a {
  background: #fff;
}

.ec-itemNav__nav > li:hover li:hover > a {
  background: #333;
}

.ec-itemNav__nav > li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: auto;

  }
}

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto;
}

.ec-itemNav__nav li ul li ul:before {
  @include media_desktop {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px;
  }
}

.ec-itemNav__nav li ul li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: auto;
    width: auto;
  }
}

.ec-itemNav__nav li ul li ul li a {
  background: #7D7D7D
}

.ec-itemNav__nav li:hover ul li ul li a:hover {
  background: #333;
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/

.ec-drawerRole {
  overflow-y: scroll;
  background: black;
  width: 260px;
  height: 100vh;
  transform: translateX(-300px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: z-index 0ms 1ms;
  @include media_desktop() {
    display: none;
  }


  .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #F8F8F8;
  }

  .ec-headerSearch{
    padding: 16px 8px 26px;
    background: #EBEBEB;
    color: #636378;
    select{
      width: 100% !important;
    }
  }

  .ec-headerCategoryArea{
    .ec-headerCategoryArea__heading {
      border-top: 1px solid #CCCCCC;
      border-bottom: 1px solid #CCCCCC;
      padding: 1em 10px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      background: #F8F8F8;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ec-itemNav__nav li a {
      border-bottom: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      color: #5C5C67;
      font-weight: normal;
      background: #f8f8f8;
    }

    .ec-itemNav__nav li ul li a {
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      font-weight: normal;
      background: white;
    }

    .ec-itemNav__nav > li:hover > a {
      background: #f8f8f8;
    }

    .ec-itemNav__nav > li:hover li:hover > a {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li a {
      padding-left: 40px;
      color: black;
      background: white;
    }

    .ec-itemNav__nav li:hover ul li ul li a:hover {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li ul li a{
      padding-left: 60px;
      font-weight: normal;
    }
  }
}

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 270px;
  z-index: 1000;

  .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media_desktop {
    display: none;
  }

}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all .3s;
  z-index: 100000;

  @include media_desktop() {
    display: none;
  }
}
.ec-drawerRoleClose.is_active  {
  display: inline-block;
  transition: all .3s;

  @include media_desktop {
    display: none;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all .3s;
  visibility: hidden;

  @include media_desktop {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;

  @include media_desktop {
    display: none;
  }
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/

.ec-itemNavAccordion {
  display: none;
}

.ec-maintenanceAlert {
  background: steelblue;
  height: 5rem;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  z-index: 9999;
  display: flex;
  font-weight: bold;
  & > * {
    margin: auto;
  }
  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & +* {
    margin-top: 5rem;
  }
}
