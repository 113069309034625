@import "../mixins/media";
/*
バナー

トップページで使用されているバナー（サロンインフォメーションの箇所）のスタイルです。

*/
#banner.ec-banner {
  background: #fff;
  padding: 160px 0 200px 0;
  @media (max-width: 767px) {
    padding: 64px 0;
  }
  .ec-widthRole {
    margin: 0 auto;
  }
  .ec-titleWrap {
    margin-bottom: 50px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .ec-bannerWrap {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .ec-banner {
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .ec-banner__img {
    position: relative;
  }
  .ec-banner__title {
    position: absolute;
    color: #fff;
    font-family: 'BonvenoCF-Light', sans-serif;
    writing-mode: vertical-lr;
    font-size: 25px;
    letter-spacing: 0.12em;
    margin: 16px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .ec-banner__margin {
    margin-right: 80px;
    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
  .ec-banner__pcImg {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .ec-banner__spImg {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .ec-viewmore__white {
    border-bottom: 1px solid #fff;
    width: 189px;
    padding-bottom: 17px;
    text-align: right;
    float: right;
    position: absolute;
    right: 32px;
    bottom: 34px;
    @media (max-width: 767px) {
      width: 130px;
      right: 16px;
      bottom: 18px;
      padding-bottom: 8px;
    }
    a {
      font-family: 'BonvenoCF-Light', sans-serif;
      font-size: 20px;
      color: #fff;
      text-decoration: none;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    &:hover {
      color: #fff;
      opacity: 0.8;
      text-decoration: none;
    }
  }
  .ec-banner__caption {
    margin-top: 32px;
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 18px;
    letter-spacing: 0.12em;
    color: #5C5C67;
    @media (max-width: 767px) {
      margin-top: 26px;
      font-size: 13px;
    }
  }
}
