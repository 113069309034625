/*
共通コンポーネント

Styleguide 11.0
*/
// 全体の幅
.ec-widthRole {
  max-width: 1000px!important;
}

.ec-widthRole.ec-headerNaviRole,
.ec-widthRole.ec-headerRole,
.ec-widthRole.ec-categoryNaviRole {
  padding-left: 0;
  padding-right: 0;
}

.ec-section__title {
  color: #000;
  margin-bottom: 36px;
}
.ec-section__titleJa {
  font-family: 'Koburina-Gothic-w3', sans-serif;
  font-size: 13px;
  line-height: 28px;
}

.ec-product__unstress {
  color: #54BEB3;
}
.ec-product__illustrious {
  color: #A7A8A9;
}
.ec-product__biophyto {
  color: #618F2E;
}
.ec-product__foreveryoung {
  color: #F7B05B;
}
.ec-product__rosedemer {
  color: #162C7D;
}
.ec-product__muse {
  color: #D76EA8;
}
.ec-product__comodex {
  color: #842331;
}
.ec-product__theraskin {
  color: #38829B;
}
.ec-product__chateu {
  color: #7E2D4F;
}
.ec-product__silk {
  color: #B29E97;
}
.ec-product__satin {
  color: #686868;
}
.ec-product__clinical {
  color: #488A9B;
}

// パンくずリスト
.ec-breadcrumbsRole {
  background: #000;
  color: #fff;
  width: 100%;
  height: 33px;
  font-family: 'Koburina-Gothic-w3', sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  .ec-widthRole {
    margin: 0 auto;
    width: 100%;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }
  .ec-separatorRole {
    margin: 0 16px;
  }
  a {
    color: #fff;
  }
}

// category2.twigのスタイル
.ec-category2Role__categoryTitle {
  width: 100%!important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1.8vw;
  font-weight: 300;
  margin: 0;
  color: #FFFFFF;
  font-family: "BonvenoCF-Light", sans-serif;
  position: absolute;
  top: 0!important;
  left: 0!important;
  right: 0!important;
  bottom: 0!important;
  background: rgba(0, 0, 0, .4);
  transform: none!important;
}

// カートのスタイル
.ec-cartRole__progress {
  padding-top: 48px;
  @media (max-width: 767px) {
    padding-top: 24px;
  }
}

// チェックボックス
input[type="checkbox"].ec-checkboxRole {
  display: none;
}

label.ec-labelRole {
  font-weight: 400;
  position: relative;
  padding: 2px 0 2px 25px;
  cursor: pointer;
  white-space: nowrap;
  &::before,
  &::after {
    position:absolute;
    content:"";
    top:50%
  }
  &::before {
    width: 16px;
    height: 16px;
    left: 0;
    border: 1px solid #9B9B9B;
    background: #fff;
    margin-top: -8px;
  }
  &::after {
    position: absolute;
    width: 5px;
    height: 9px;
    top: 50%;
    left: 10px;
    border-right: 2px solid #9B9B9B;
    border-bottom: 2px solid #9B9B9B;
    content: '';
    margin-top: -6px;
    opacity: 0;
    transform: rotate(45deg);
  }
}

input[type=checkbox].ec-checkboxRole:checked + .ec-labelRole::after {
  opacity: 1;
}

// セレクトボックス
select.ec-selectRole {
  font-family: 'Koburina-Gothic-w3', sans-serif;
  font-size: 16px;
  color: #000;
  border: 1px solid #9B9B9B;
  width: 320px;
  height: 46px;
  padding-left: 24px;
  padding-right: 17px;
  -webkit-appearance: none;
  appearance: none;
  background: #fff url('/html/template/default/assets/img/common/select_arrow.svg') no-repeat right 17px center;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 100%;
  }
}

img.slide-arrow {
  position: absolute;
  width: 5px;
  height: 10px;
}

.ec-blockTopBtn.pagetop {
  background: transparent;
  width: 60px;
  height: 60px;
  @media only screen and (min-width: 768px) {
    bottom: 128px;
  }
  @media (max-width: 767px) {
    width: 38px;
    height: 38px;
    margin-right: 8px;
    bottom: 93px;
  }
  .ec-gotop {
    width: 100%;
    height: 100%;
    &:hover {
      opacity: 0.8;
    }
  }
}

/*
トップページ

Styleguide 12.0
*/

// お知らせ
// 旧デザインのスタイル
// .ec-topNotice {
//   background: #EDEDED;
//   font-family: 'Koburina-Gothic-w3', sans-serif;
//   font-size: 15px;
//   & &__toggle {
//     align-items: center;
//     justify-content: center;
//     padding: 16px 0;
//     border-bottom: 2px solid #000;
//     cursor: pointer;
//   }
//   & &__date {
//     font-size: 12px;
//     margin-bottom: 20px;
//   }
//   & &__title {
//     margin-bottom: 26px;
//     font-size: 15px;
//   }
//   & &__text {
//     font-size: 14px;
//     line-height: 30px;
//     @media (max-width: 767px) {
//       font-size: 13px;
//       line-height: 28px;
//     }
//   }

//   .ec-role {
//     padding-left: 0;
//     padding-right: 0;
//     max-width: initial;
//   }

//   .ec-newsRole {
//     padding: 0;
//   }

//   .ec-newsRole .ec-newsRole__news {
//     border: none;
//     padding: 0;
//   }

//   .ec-newsRole__newsItem {
//     @media only screen and (min-width: 768px) {
//       padding: 0!important;
//     }
//   }

//   .ec-newsRole__newsItem .is_active {
//     padding: 0;
//   }

//   .ec-widthRole {
//     margin: 0 auto;
//   }

//   .ec-newsRole .ec-newsRole__newsColumn {
//     min-width: initial;
//     align-items: center;
//     justify-content: center;
//   }

//   .ec-newsRole .ec-newsRole__newsTitle {
//     white-space: nowrap;
//     margin-right: 5px;
//     font-family: 'Koburina-Gothic-w3', sans-serif;
//     font-size: 15px;
//     color: #000;
//     font-weight: 400;
//     &:hover {
//       opacity: 0.8;
//     }
//     @media (max-width: 767px) {
//       width: initial!important;
//       margin-bottom: 0!important;
//     }
//   }

//   .ec-newsRole__newsDescription {
//     .ec-widthRole {
//       margin: 0 auto;
//     }
//   }

//   .ec-newsRole__newsItem.is_active .ec-newsRole__newsDescription {
//     @media only screen and (min-width: 768px) {
//       margin: 45px 0!important;
//     }
//     @media (max-width: 767px) {
//       padding: 37px 20px 46px!important;
//     }
//   }

//   .ec-newsRole .ec-newsRole__newsClose {
//     position: initial;
//   }

//   .ec-newsRole .ec-newsRole__newsCloseBtn {
//     position: initial;
//     right: 0;
//   }

//   .ec-newsRole .ec-newsRole__newsItem:last-of-type {
//     margin-bottom: 0;
//   }
// }

/*
商品詳細

Styleguide 14.0
*/

/*
レコメンド

Styleguide 14.0
*/
#recommend.ec-ranking {
  padding: 56px 0 56px;
  background: #DEDEDE;
}

/*
フォーム

Styleguide 19.0
*/

/*
404ページ

Styleguide 20.0
*/

/*
退会手続き

Styleguide 21.0
*/

/*
会員情報編集完了

Styleguide 22.0
*/
