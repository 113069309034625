@import "../mixins/media";
/*
サロン

トップページで使用されている「サロントリートメントについて」のスタイルです。

*/
.ec-care {
  background: #fff;
  .ec-role {
    .ec-widthRole {
      margin: 0 auto;
    }
  }
  .ec-salon__visual {
    position: relative;
    background: url('/html/template/default/assets/img/top/salon/salon_img.png') no-repeat center;
    background-size: cover;
    height: 708px;
    margin-bottom: 120px;
    @media (max-width: 767px) {
      background: url('/html/template/default/assets/img/top/salon/salon_img_sp.png') no-repeat left;
      min-height: 400px;
      margin-bottom: 60px;
    }
    @media (max-width: 415px) {
      background-size: cover;
      height: 400px;
    }
    @media (max-width: 376px) {
      background-size: contain;
    }
  }
  .ec-salon__visualTitle {
    position: absolute;
    right: 87px;
    bottom: calc(80px + 92px + 32px);
    color: #fff;
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 35px;
    line-height: 61px;
    letter-spacing: 0.12em;
    text-align: right;
    @media (max-width: 767px) {
      bottom: 90px;
      right: 30px;
      font-size: 24px;
      line-height: 42px;
      letter-spacing: 0.25em;
    }
    @media (max-width: 428px) {
      bottom: 85px;
      letter-spacing: 0.12em;
    }
  }
  .ec-infoBtn {
    position: absolute;
    right: 87px;
    bottom: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 980px) {
      padding: 0 30px;
    }
    @media (max-width: 767px) {
      right: 30px;
      bottom: 23px;
      height: 56px;
      padding: 0 16px;
    }
    @media (max-width: 428px) {
      height: 46px;
      padding: 0 20px;
    }
  }
  .ec-infoBtn__textSm {
    font-family: 'RyuminPr5-Light', sans-serif;
    font-size: 16px;
    margin-bottom: 4px;
    letter-spacing: 0.05em;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
    @media (max-width: 980px) {
      font-size: 13px;
    }
    @media (max-width: 767px) {
      font-size: 11px;
      margin-bottom: 2px;
      letter-spacing: 0.1em;
    }
    @media (max-width: 428px) {
      font-size: 8px;
      margin-bottom: 0;
      letter-spacing: 0;
    }
  }
  .ec-infoBtn__textLg {
    font-family: 'RyuminPr5-Medium', sans-serif;
    font-size: 28px;
    @media (max-width: 1200px) {
      font-size: 27px;
    }
    @media (max-width: 980px) {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  .ec-salon {
    font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
    color: #5C5C67;
    letter-spacing: 0.12em;
    @media (max-width: 767px) {
    }
  }
  .ec-salon__titleWrap {
    display: flex;
    align-items: center;
    margin-bottom: 72px;
    @media (max-width: 767px) {
      margin-bottom: 36px;
    }
  }
  .ec-salon__title {
    font-size: 30px;
    line-height: 46px;
    @media (max-width: 767px) {
      font-size: 19px;
      line-height: 26px;
    }
    @media (max-width: 376px) {
      font-size: 17px;
      line-height: 23px;
    }
  }

  .ec-salon__line {
    border-bottom: 1px solid #5C5C67;
    flex: 1;
    margin-left: 25px;
    @media (max-width: 767px) {
      margin-left: 16px;
    }
  }

  .ec-salon__content {
    font-size: 14px;
    line-height: 2.6em;
    width: 90%;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 30px;
      width: 100%;
    }
    @media (max-width: 376px) {
      font-size: 12px;
      line-height: 28px;
    }
  }
  .ec-salon__text {
    margin-bottom: 20px;
  }
}
