@import "../mixins/media";
@import "../mixins/projects";

/*
よくあるご質問

よくあるご質問のコンポーネントを定義します。
*/
.faq_page {
  .ec-layoutRole__contents {
    margin:0;
    padding:0;
    line-height:1.8em;
    font-feature-settings:"palt";
    -webkit-text-size-adjust:100%;
    color:#5e6770;
    font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
    a {
      outline:0;
      text-decoration:none;
    }
    ul {
      list-style:none;
    }
    li {
      margin:0;
      padding:0;
      list-style:none;
    }
  }
  .ec-pageHeader {
    position:relative;
    margin:0 80px 80px;
    padding:0;
    height:140px;
    background-color:#c8dade;
    @media print, screen and (max-width: 1180px) {
      margin:0 50px 50px;
    }
    @media print, screen and (max-width: 767px) {
      margin:0 0 50px;
    }

    .ec-pageHeader__h1 {
      position:absolute;
      top:0;
      right:112px;
      bottom:0;
      display:flex;
      align-items:center;
      justify-content:flex-end;
      margin:0;
      padding:0;
      color:#ffffff;
      letter-spacing:10px;
      font-weight:400;
      font-size:34px;
      font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
      line-height:1;
      border-bottom:none;
      @media print, screen and (max-width: 1180px) {
        right:50px;
        letter-spacing:3px;
        font-size:30px;
      }
      @media print, screen and (max-width: 767px) {
        right:30px;
        letter-spacing:2px;
        font-size:20px;
      }
    }
  }
}
#ec-faq {
	margin:0;
	padding:0;
}
.ec-faq {
	margin:0;
	padding:0 80px 86px;
  color: #5e6770;
  letter-spacing: 2px;
  font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
	@media print, screen and (max-width: 1180px) {
		padding:0 50px 86px;
	}
	@media print, screen and (max-width: 767px) {
		padding:0 30px 16px;
	}
	.ec-faq__inneer {
		margin:0 auto;
		padding:0;
		max-width:998px;
		.ec-faq__h2 {
			margin:0 0 30px;
			padding:0;
			font-weight:500!important;
			font-size:28px;
			font-family:"Ryumin",sans-serif!important;
			@media print, screen and (max-width: 1180px) {
				text-align:center;
				letter-spacing:3px;
				font-size:24px;
			}
		}

		.ec-faq__menu {
			margin:0 ;
			padding:0;
			.ec-faq__navi {
				display:flex;
				align-items:center;
				flex-wrap:wrap;
				justify-content:flex-start;
				margin:0 0 0 -30px;
				padding:0;
				@media print, screen and (max-width: 1180px) {
					margin:0 0 0 -10px;
				}
				@media print, screen and (max-width: 767px) {
					margin:0;
				}
				li {
					box-sizing:border-box;
					margin:0 auto;
					padding:0 0 0 30px;
					width:25%;
					@media (max-width: 1180px) {
						padding:0 0 0 10px;
					}

					@media (max-width: 960px) {
						padding:0 0 10px 10px;
						width:50%;
					}
					@media (max-width: 767px) {
						padding:0 0 1px;
						width:100%;
					}
					a {
						display:flex;
						align-items:center;
						justify-content:flex-start;
						padding:0 20px;
						height:50px;
						background:url(/html/template/default/assets/img/common/btn_arrow_bottom.svg) no-repeat rgb(200 218 222 / 36%) right 20px top 50%;
						color:#5e6770;
						font-size:14px;
						@media print, screen and (max-width: 1180px) {
							letter-spacing:1px;
						}
						@media print, screen and (max-width: 767px) {
							font-size:13px;
						}
					}
				}
			}
		}
		.ec-faq__list {
			margin:0;
			padding:65px 0 0;
			.ec-faq__h3 {
				display:flex;
				align-items:center;
				justify-content:flex-start;
				margin:0 0 30px;
				padding:0 20px;
				height:50px;
				background-color:rgb(200 218 222 / 36%);
				letter-spacing:2px;
				font-weight:500!important;
				font-size:20px;
				font-family:"Ryumin",sans-serif!important;
				@media print, screen and (max-width: 767px) {
					letter-spacing:1px;
					font-size:16px;
				}
			}
			.ec-faq__detail {
				margin:0;
				padding:20px 0;
				border-bottom:#c8dade solid 1px;
				font-size:15px;
				@media print, screen and (max-width: 767px) {
					font-size:14px;
				}
				i {
					display:flex;
					align-items:center;
					justify-content:center;
					box-sizing:border-box;
					margin:0 23px 0 0;
					padding:0;
					width:36px;
					height:36px;
					border-radius:36px;
					font-weight:400;
					font-style:normal;
					font-size:22px;
          font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
					line-height:1;
					@media print, screen and (max-width: 767px) {
						margin:0 20px 0 0;
					}
				}
				dt {
					display:flex;
					align-items:stretch;
					justify-content:flex-start;
					margin:0;
					padding:0;
					cursor:pointer;
					@media print, screen and (max-width: 767px) {
						align-items:center;
					}

					i {
						background-color:rgb(200 218 222 / 36%);
						text-indent:3px;
					}
					.ec-faq__question {
						position:relative;
						flex-grow:1;
						margin:0;
						padding:5px 50px 0 0;
						@media print, screen and (max-width: 767px) {
							box-sizing:border-box;
							padding:0 50px 0 0;
							width:calc(100% - 56px);
						}
						&::before {
							position:absolute;
							top:50%;
							right:20px;
							width:15px;
							height:1px;
							background-color:#5e6770;
							content:"";
							transition:transform 0.7s cubic-bezier(0.26, 0.63, 0, 0.99) 0.2s;
							transform:rotate(0deg);
							transform-origin:center center;
						}
						&::after {
							position:absolute;
							top:50%;
							right:20px;
							width:15px;
							height:1px;
							background-color:#5e6770;
							content:"";
							transition:transform 0.7s cubic-bezier(0.26,0.63,0,0.99) 0.2s;
							transform:rotate(
							90deg
							);
							transform-origin:center center;
						}
					}
					&.open {
						.ec-faq__question {
							&::before {
								transform:rotate(360deg);
							}
							&::after {
								transform:rotate(360deg);
							}
						}
					}
				}
				dd {
					display:flex;
					align-items:stretch;
					justify-content:flex-start;
					margin:0;
					padding:20px 0 0 ;

					i {
						border:#c8dade solid 1px;
						text-indent:3px;
					}
					.ec-faq__answer {
						position:relative;
						flex-grow:1;
						margin:0;
						padding:5px 0 0 0;
						width:calc(100% - 59px);
						font-size:15px;
						line-height:1.8em;
						@media print, screen and (max-width: 767px) {
							box-sizing:border-box;
							width:calc(100% - 56px);
							font-size:14px;
						}
					}
				}
			}
		}
	}
}
