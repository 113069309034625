// SP版サイドメニュー
#spmenu.ec-drawerRole {
  font-family: Lato, sans-serif;
  letter-spacing: 0.84px;
  color: #000;
  background: #fff;

  .ec-headerSearch {
    background: #fff;
    width: calc(100% - 40px);
  }

  .ec-headerCategoryArea {
    &__heading {
      border-top: none;
      border-bottom: none;
      background: #fff;
      padding: 0;
      position: relative;
      .ec-drawerRoleClose {
        position: absolute;
        top: -68px;
        right: 6px;
        left: initial;
        display: block;
      }
    }
  }

  .ec-itemNav {
    margin-bottom: 13px;
    &__title {
      font-size: 15px;
      font-weight: bold;
      font-family: "BonvenoCF-Light", sans-serif;
      color: #5C5C67;
      background: #fff;
      text-align: left;
      padding: 0 0 13px 20px;
      a {
        color: #5C5C67;
        &:hover {
          color: rgba(92, 92, 103, 0.8);
        }
      }
    }
    &__nav {
      display: block;
      letter-spacing: 0.84px;
      margin: auto;
      text-align: left;
      li {
        padding: 0 0 13px 20px;
        text-align: left;
        position: initial;
        a {
          border-bottom: none;
          background: #fff;
          font-family: 'RyuminPr5-Light', sans-serif;
          font-size: 12px;
          font-weight: normal;
          color: #5C5C67;
          padding: 0;
          text-align: left;
          &:hover {
            color: rgba(92, 92, 103, 0.8);
          }
        }
      }
    }
    &__products {
      li a {
        font-family: "BonvenoCF-Light", sans-serif;
      }
    }
  }

  .ec-itemNav__nav li:not(:last-child)::after {
    content: none;
  }

  .ec-headerLink {
    &__terms {
      background-color: #fff;
      display: block;
      margin-bottom: 31px;
      div {
        padding: 0 0 11px 20px;
        a {
          font-family: 'RyuminPr5-Light', sans-serif;
          font-size: 12px;
          color: #9B9B9B;
        }
      }
    }
    &__list {
      border-top: none;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    &__item {
      font-family: "BonvenoCF-Light", sans-serif;
      font-size: 9px;
      line-height: 1.8em;
      letter-spacing: 0.05em;
      font-weight: normal;
      color: #5C5C67;
      display: flex;
      align-items: center;
      padding: 0 0 14px 20px;
      border-bottom: none;
      background: #fff;
      text-align: left;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
    &__icon {
      margin-right: 10px;
      img {
        width: 22px;
        height: 23px;
      }
    }
    &__cart {
      order: 1;
    }
    &__favorite {
      order: 2;
    }
    &__mypage {
      order: 3;
    }
    &__forSalon {
      order: 4;
    }
    &__instagram {
      order: 5;
    }
    &__storeInstagram {
      order: 6;
    }
  }

  .ec-btnRole__salon {
    margin-bottom: 35px;
    a {
      color: #5C5C67;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'RyuminPr5-Light', sans-serif;
      font-size: 12px;
      background-color: #DEDEDE;
      width: 88%;
      height: 38px;
      margin: 0 auto;
      border: none;
      padding: 0 16px;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
}
