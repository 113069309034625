@import "../mixins/media";
/*
TOPページ　ファーストビューのスタイルです。

*/
// ファーストビュー
#firstview {
  margin-left: 0;
  margin-right: 0;
  max-width: initial;
}
.ec-firstview {
  position: relative;
  & &__imgPc {
    @media (min-width: 768px) {
      width: 100%;
      display: block;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  & &__imgSp {
    @media (min-width: 768px) {
      display: none;
    }
    @media (max-width: 767px) {
      display: block;
      width: 100%;
      height: 90%;
      object-fit: cover;
      min-height: 340px;
    }
  }
  & &__captions {
    position: absolute;
    top: 10vh;
    left: 6vw;
    @media (max-width: 1280px) {
      top: 8vh;
    }
    @media (max-width: 1000px) {
      top: 6vh;
    }
    @media (max-width: 767px) {
      top: 7vh;
    }
  }
  & &__subCaption {
    color: #fff;
    font-family: 'RyuminPr5-Light', sans-serif;
    font-size: 1.5vw;
    letter-spacing: 0.12em;
    padding: 6px 14px;
    border: 1px solid #fff;
    margin-bottom: 2vw;
    display: inline-block;
    @media (max-width: 767px) {
      padding: 4px 10px;
      font-size: 2.6vw;
      margin-bottom: 4vh;
    }
    @media (max-width: 600px) {
      margin-bottom: 2.8vh;
    }
  }
  & &__captionXs {
    color: #fff;
    font-family: 'RyuminPr5-Light', sans-serif;
    font-size: 15px;
    margin-bottom: 40px;
    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 25px;
    }
  }
  & &__captionLg {
    color: #fff;
    font-family: 'RyuminPr5-Light', sans-serif;
    font-size: 2.7vw;
    line-height: 1.4em;
    letter-spacing: 0.12em;
    margin-bottom: 12px;
    @media (max-width: 767px) {
      font-size: 3.7vw;
      margin-bottom: 1.2vh;
    }
  }
}
