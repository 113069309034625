@import "../mixins/media";
@import "../mixins/projects";

/*
会社概要

会社概要のコンポーネントを定義します。
*/
.profile_page {
  .ec-headerNaviRole {
    @media (max-width: 767px) {
      z-index: 11;
    }
  }
  .ec-widthRole.ec-headerRole {
    @media (max-width: 767px) {
      z-index: 11;
    }
  }
  .ec-layoutRole__contents {
    margin:0;
    padding:0;
    line-height:1.8em;
    letter-spacing: 2px;
    font-feature-settings:"palt";
    -webkit-text-size-adjust:100%;
    color:#5e6770;
    font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
    a {
      outline:0;
      text-decoration:none;
    }
    ul {
      list-style:none;
    }
    li {
      margin:0;
      padding:0;
      list-style:none;
    }
  }
  .ec-pageHeader {
    position:relative;
    margin:0 80px 80px;
    padding:0;
    height:140px;
    background-color:#c8dade;
    @media (max-width: 1180px) {
      margin:0 50px 50px;
    }
    @media (max-width: 767px) {
      margin:0 0 50px;
    }

    .ec-pageHeader__h1 {
      position:absolute;
      top:0;
      right:112px;
      bottom:0;
      display:flex;
      align-items:center;
      justify-content:flex-end;
      margin:0;
      padding:0;
      color:#ffffff;
      letter-spacing:10px;
      font-weight:400;
      font-size:34px;
      font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
      line-height:1;
      border-bottom:none;
      @media (max-width: 1180px) {
        right:50px;
        letter-spacing:3px;
        font-size:30px;
      }
      @media (max-width: 767px) {
        right:30px;
        letter-spacing:2px;
        font-size:20px;
      }
    }
  }
  .ec-layoutRole__footer {
    margin-top: 0;
  }
  .ec-blockTopBtn.pagetop {
    z-index: 99;
  }
  .ec-counselingBtn {
    z-index: 999;
  }
}

#ec-company {
	margin:0;
	padding:0;
	h2 {
		font-weight:400;
    font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
		@media (max-width: 1180px) {
			text-align:center;
		}
	}
}

.ec-companyHero {
	position:relative;
	margin:0 ;
	padding:0;
	line-height:0;
	img {
		width:100%;
		height:auto;
	}
	.ec-companyHero__h1 {
		position:absolute;
		top:0;
		right:112px;
		bottom:0;
		display:flex;
		align-items:center;
		justify-content:flex-end;
		margin:0;
		padding:0;
		color:#ffffff;
		letter-spacing:10px;
		font-weight:400;
		font-size:34px;
    font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
		line-height:1;
		@media (max-width: 1180px) {
			right:50px;
		}
		@media (max-width: 960px) {
			letter-spacing:3px;
			font-size:30px;
		}
		@media (max-width: 767px) {
			right:30px;
			letter-spacing:2px;
			font-size:20px;
		}
	}
}

.ec-companyMessage {
	margin:0;
	padding:150px 80px 0;
	@media (max-width: 1380px) {
		padding:100px 80px 0;
	}
	@media (max-width: 1180px) {
		padding:100px 50px 0;
	}
	@media (max-width: 960px) {
		padding:80px 50px 0;
	}
	@media (max-width: 767px) {
		padding:50px 30px 0;
	}
	.ec-companyMessage__inneer {
		margin:0 auto;
		padding:0;
		max-width:980px;
		.ec-companyMessage__h2 {
			margin:0 0 19px;
			padding:0;
			text-align:left;
			letter-spacing:10px;
			font-size:34px;
			@media (max-width: 1180px) {
				margin:0 0 50px;
				letter-spacing:3px;
				font-size:30px;
			}
			@media (max-width: 960px) {
				margin:0 0 30px;
			}
			@media (max-width: 767px) {
				margin:0 0 20px;
				letter-spacing:2px;
				font-size:20px;
			}
		}
		.ec-companyMessage__flex {
			display:flex;
			align-items:flex-start;
			justify-content:space-between;
			@media (max-width: 767px) {
				display:block;
			}
			.ec-companyMessage__photo {
				margin:0;
				padding:30px 0 0;
				width:392px;
				@media (max-width: 960px) {
					width:326px;
				}
				@media (max-width: 767px) {
					margin:0 auto 20px;
					max-width:300px;
					width:60%;
				}
				.ec-companyMessage__photoImg {
					width:100%;
					line-height:0;
					img {
						width:100%;
						height:auto;
					}
				}
				.ec-companyMessage__ceo {
					display:flex;
					align-items:center;
					justify-content:flex-start;
					@media (max-width: 1180px) {
						display:block;
					}
					.ec-companyMessage__ceoName {
						padding:0;
						text-align:left;
						letter-spacing:normal;
						font-weight:500;
						font-size:14px;
						font-family:Ryumin;
						line-height:2.4em;
						@media (max-width: 960px) {
							text-align:left;
						}
						@media (max-width: 767px) {
							text-align:center;
							font-size:12px;
						}
						span {
							display:inline-block;
							padding:0 0 0 10px;
							font-size:28px;
							@media (max-width: 767px) {
								font-size:20px;
							}
						}
					}
				}
			}
			.ec-companyMessage__message {
				margin:0;
				padding:0;
				width:calc(100% - 424px);
				@media (max-width: 960px) {
					width:calc(100% - 346px);
				}
				@media (max-width: 767px) {
					width:auto;
				}
				.ec-companyMessage__copy {
					margin:0 ;
					padding:0;
					letter-spacing:5px;
					font-weight:500;
					font-size:28px;
          font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
					line-height:1.6em;
					@media (max-width: 1180px) {
						margin:0 ;
						letter-spacing:2px;
						font-size:24px;
					}
					@media (max-width: 960px) {
						letter-spacing:normal;
						font-size:20px;
					}
				}
				.ec-companyMessage__read {
					margin:0 0 30px;
					letter-spacing:3px;
					font-weight:500;
					font-size:22px;
					font-family:Ryumin;
					line-height:2.0em;
					@media (max-width: 1180px) {
						margin:0 0 20px;
						font-size:18px;
					}
					@media (max-width: 960px) {
						font-size:16px;
					}
				}
				.ec-companyMessage__description {
					margin:0 0 20px;
					padding:0;
					text-align:justify;
					font-size:14px;
					line-height:1.7em;

					@media (max-width: 960px) {
						br {
							display:none;
						}
					}
					@media (max-width: 767px) {
						margin:0 0 20px;
					}
				}
			}
		}
	}
}

.ec-companyInterview {
	margin:0;
	padding:150px 80px 0;
	@media (max-width: 1380px) {
		padding:100px 80px 0;
	}
	@media (max-width: 1180px) {
		padding:100px 50px 0;
	}
	@media (max-width: 767px) {
		padding:80px 30px 0;
	}
	.ec-companyInterview__inneer {
		margin:0 auto;
		padding:0;
		max-width:980px;
		.ec-companyInterview__h2 {
			margin:0 0 40px;
			padding:0;
			text-align:left;
			letter-spacing:10px;
			font-size:34px;
			@media (max-width: 1180px) {
				letter-spacing:3px;
				font-size:30px;
			}
			@media (max-width: 767px) {
				margin:0 0 20px;
				letter-spacing:2px;
				font-size:20px;
			}
		}
		.ec-companyInterview__staff {
			display:flex;
			align-items:stretch;
			flex-wrap:wrap;
			justify-content:flex-start;
			margin:0;
			padding:0;
			li {
				box-sizing:border-box;
				margin:0;
				padding:0 0 80px ;
				width:33.333%;
				@media (max-width: 767px) {
					padding:0 0 50px;
					width:50%;
				}
				.ec-companyInterview__photo {
					margin:0;
					padding:0;
					line-height:0;
					img {
						width:100%;
						height:auto;
					}
				}
				.ec-companyInterview__caption {
					padding:38px 20px 0;
					text-align:center;
					font-weight:400;
					font-size:18px;
          font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
					@media (max-width: 767px) {
						padding:20px 10px 0;
						letter-spacing:normal;
						font-size:14px;
					}
					.popup__btn {
						display:flex;
						align-items:center;
						justify-content:flex-start;
						box-sizing:border-box;
						margin:20px auto 0;
						padding:0 16px;
						width:122px;
						height:30px;
						border:#5e6770 solid 1px;
						background:url(/html/template/default/assets/img/common/btn_arrow_right.svg) no-repeat top 50% right 16px;
						text-align:left;
						letter-spacing:normal;
						font-size:12px;
						cursor:pointer;
						@media (max-width: 767px) {
							width:102px;
							background:url(/html/template/default/assets/img/common/btn_arrow_right.svg) no-repeat top 50% right 10px;
							font-size:11px;
						}
					}
				}
				.ec-companyInterviewDetail {
					position:fixed;
					top:0;
					right:0;
					bottom:0;
					left:0;
					z-index:10;
					display:flex;
					align-items:center;
					justify-content:center;
					padding:50px;
					background-color:rgba(255,255,255,0.8);
					opacity:0;
					transition:opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
					pointer-events:none;
					&.is-show {
						opacity:1;
						transition:opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
						pointer-events:all;
						.ec-companyInterviewDetail__inneer {
							opacity:1;
							transition:opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
							pointer-events:all;
						}
					}

					.ec-companyInterviewDetail__inneer {
						position:relative;
						overflow:hidden;
						box-sizing:border-box;
						margin:0;
						padding:0 0 0 40px;
						max-width:980px;
						height:416px;
						background-color:#c8dade;
						opacity:0;
						transition:opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
						pointer-events:none;

						@media (max-width: 767px) {
							position:absolute;
							top:10px;
							right:10px;
							bottom:10px;
							left:10px;
							overflow:auto;
							padding:50px 0 0;
							height:auto;
						}


						.popup__close {
							position:absolute;
							top:20px;
							right:20px;
							z-index:5;
							width:45px;
							height:45px;
							cursor:pointer;
							&::before {
								position:absolute;
								top:50%;
								left:0;
								width:45px;
								height:1px;
								background-color:#5e6770;
								content:"";
								transform:rotate(45deg);
							}
							&::after {
								position:absolute;
								top:50%;
								left:0;
								width:45px;
								height:1px;
								background-color:#5e6770;
								content:"";
								transform:rotate(-45deg);
							}
							@media (max-width: 767px) {
								top:10px;
								right:10px;
							}
						}
						.ec-companyInterviewDetail__photo {
							position:absolute;
							right:40px;
							bottom:0;
							z-index:3;
							width:342px;
							line-height:0;
							@media (max-width: 960px) {
								right:12px;
								width:282px;
							}
							img {
								width:100%;
								height:auto;
							}
							@media (max-width: 767px) {
								position:static;
								margin:0 auto 20px;
								padding:0;
								max-width:300px;
								width:60%;
							}
						}
						.ec-companyInterviewDetail__info {
							position:relative;
							z-index:4;
							overflow:auto;
							box-sizing:border-box;
							margin:0;
							padding:40px 382px 0 0;
							height:416px;

							@media (max-width: 960px) {
								padding:40px 290px 0 0;
							}
							@media (max-width: 767px) {
								overflow:hidden;
								padding:0 20px 20px;
								height:auto;
							}

							.ec-companyInterviewDetail__name {
								margin:0 0 20px;
								padding:0;
								color:#ffffff;
								letter-spacing:1px;
								font-weight:400;
								font-size:24px;
                font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
								@media (max-width: 960px) {
									font-size:20px;
								}
								span {
									padding:0 0 0 5px;
									color:#5e6770;
									letter-spacing:normal;
									font-size:18px;
									@media (max-width: 960px) {
										display:block;
										padding:0;
										font-size:15px;
									}
								}
							}
							dl {
								display:flex;
								align-items:stretch;
								flex-wrap:wrap;
								justify-content:flex-start;
								margin:0;
								padding:0 0 40px;
								dt {
									margin:0 0 10px;
									width:100px;
									color:#ffffff;
									letter-spacing:normal;
									font-weight:400;
									font-size:14px;
                  font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
									line-height:1.8em;
									@media (max-width: 960px) {
										margin:0 0 5px;
									}
									&:nth-of-type(2) {
										width:50px;
										@media (max-width: 767px) {
											width:100px;
										}
									}
									@media (max-width: 960px) {
										&:nth-of-type(4) {
											margin:0 0 5px;
											width:100%;
										}
									}
								}
								dd {
									margin:0 0 10px;
									padding:0;
									width:calc(100% - 100px);
									text-align:left;
									text-align:justify;
									font-size:14px;
									line-height:1.8em;
									&:first-of-type {
										width:100px;
										@media (max-width: 767px) {
											width:calc(100% - 100px);
										}
									}
									&:nth-of-type(2) {
										width:calc(100% - 250px);
										@media (max-width: 767px) {
											width:calc(100% - 100px);
										}
									}
									@media (max-width: 960px) {
										margin:0 0 5px;
										&:nth-of-type(4) {
											margin:0;
											width:100%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.ec-companyInterview__btn {
			margin:-22px 0 0;
			padding:0;
			@media (max-width: 767px) {
				margin:0;
			}
			a {
				display:flex;
				align-items:center;
				justify-content:center;
				margin:0 auto;
				padding:0;
				width:245px;
				height:68px;
				background-color:#5e6770;
				color:#ffffff;
				font-size:15px;
				cursor:pointer;
				@media (max-width: 1180px) {
					height:52px;
				}
				@media (max-width: 767px) {
					width:100%;
				}
				span {
					display:inline-block;
					padding:0 24px 0 0;
					background:url(/html/template/default/assets/img/common/icon_link.svg) no-repeat right;
					letter-spacing:normal;
				}
			}
		}
	}
}
.ec-companyInformation {
	margin:0;
	padding:80px 80px 0;
	@media (max-width: 1180px) {
		padding:80px 50px 0;
	}
	@media (max-width: 767px) {
		padding:80px 30px 0;
	}
	.ec-companyInformation__inneer {
		margin:0 auto;
		max-width:980px;
		.ec-companyInformation__h2 {
			margin:0 0 30px;
			padding:0;
			letter-spacing:5px;
			font-weight:500!important;
			font-size:28px;
			font-family:"Ryumin",sans-serif!important;
			@media (max-width: 767px) {
				margin:0 0 20px;
				letter-spacing:3px;
				font-size:20px;
			}
		}
		.ec-companyInformation__detail {
			display:flex;
			align-items:stretch;
			flex-wrap:wrap;
			justify-content:space-between;
			margin:0;
			padding:0;
			font-size:15px;
			@media (max-width: 767px) {
				display:block;
				letter-spacing:normal;
				font-size:14px;
			}
			dt {
				display:flex;
				align-items:center;
				justify-content:center;
				box-sizing:border-box;
				margin:0 0 5px;
				padding:13px;
				width:225px;
				background-color:#d7e1e4;
				@media (max-width: 767px) {
					display:block;
					padding:0;
					width:auto;
					background-color:transparent;
					text-align:left;
					font-weight:700;
				}
			}
			dd {
				box-sizing:border-box;
				margin:0 0 5px;
				padding:10px;
				width:calc(100% - 255px);
				@media (max-width: 767px) {
					margin:0 0 20px;
					padding:0 0 20px;
					width:auto;
					border-bottom:#e4e4e4 solid 1px;
				}
				span {
					display:inline-block;
					letter-spacing:normal;
					font-weight:700;
					font-size:12px;
					&:nth-of-type(2) {
						padding:13px 0 0;
					}
				}
			}
		}
	}
}

.ec-companyAccess {
	margin:0;
	padding:150px 0 0;
	@media (max-width: 1180px) {
		padding:100px 0 0;
	}
	@media (max-width: 767px) {
		padding:50px 0 0;
	}
	.ec-companyAccess__inneer {
		margin:0;
		padding:0;
		line-height:0;
		.ec-companyAccess__h2 {
			margin:0 0 70px;
			padding:0;
			text-align:center;
			letter-spacing:10px;
			font-size:34px;
			line-height:1;
			@media (max-width: 1180px) {
				margin:0 0 40px;
				letter-spacing:3px;
				font-size:30px;
			}
			@media (max-width: 767px) {
				margin:0 0 40px;
				letter-spacing:3px;
				font-size:20px;
			}
		}
		.ec-companyAccess_map {
			background:rgba(94, 103, 112, 0.15);
			iframe {
				width:100%;
				height:500px;
				opacity:0.7;
				filter:grayscale(100%);
				@media (max-width: 960px) {
					height:400px;
				}
				@media (max-width: 767px) {
					height:300px;
				}
			}
		}
	}
}

.ec-companySocial {
	margin:0;
	padding:150px 80px ;
	background-color:#c8dade;
	@media (max-width: 1180px) {
		padding:100px 50px;
	}
	@media (max-width: 960px) {
		padding:80px 50px;
	}
	@media (max-width: 767px) {
		padding:50px 30px;
	}
	.ec-companySocial__inneer {
		margin:0 auto;
		max-width:980px;

		.ec-companySocial__h2 {
			margin:0 0 50px;
			padding:0;
			letter-spacing:3px;
			font-size:30px;
			line-height:1;
			@media (max-width: 960px) {
				margin:0 0 30px;
				font-size:24px;
			}
			@media (max-width: 767px) {
				margin:0 0 24px;
				letter-spacing:1px;
				font-size:20px;
				line-height:1.6em;
			}
		}
		.ec-companySocial__content {
			padding:80px;
			background-color:#a4c0c4;
			color:#ffffff;
			text-align:center;
			@media (max-width: 1180px) {
				padding:80px 50px;
			}
			@media (max-width: 960px) {
				padding:60px 50px;
			}
			@media (max-width: 767px) {
				padding:50px 30px;
			}
			.ec-companySocial__h3 {
				margin:0;
				padding:0;
				letter-spacing:5px;
				font-weight:500 !important;
				font-size:28px;
				font-family:"Ryumin", sans-serif !important;
				@media (max-width: 1180px) {
					font-size:24px;
				}
				@media (max-width: 767px) {
					letter-spacing:2px;
					font-size:20px;
				}
			}
			.ec-companySocial__read {
				margin:5px 0 40px;
				padding:0;
				font-weight:400;
				font-size:15px;
        font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
				@media (max-width: 1180px) {
					letter-spacing:1px;
				}
			}
			.ec-companySocial__text {
				margin:0;
				padding:0;
				letter-spacing:normal;
				font-weight:400;
				font-size:14px;
				line-height:2.0em;
				@media (max-width: 767px) {
					text-align:justify;
					br {
						display:none;
					}
				}
			}
		}
	}
}




.ec-profileRole {
  font-family: 'Koburina-Gothic-w3', sans-serif;
  letter-spacing: 0.1em;
  &__main {
    padding: 32px 0 20px;
    font-size: 14px;
    line-height: 1.9em;
    @include media_desktop {
      font-size: 16px;
    }
  }
  &__table,
  &__tr,
  &__th,
  &__td {
    border-collapse: collapse;
  }
  &__th {
    min-width: 125px;
    padding: 15px 20px;
    background: #eee;
    text-align: left;
    border-bottom: 1px solid #fff;
    font-weight: normal;
    @include media_desktop {
      min-width: 200px;
      padding: 25px 30px;
    }
  }
  &__td {
    padding: 15px 20px;
    @include media_desktop {
      padding: 25px 30px;
    }
  }
}
