/*
テキスト

Styleguide 1.0
*/
/*
ボタン

Styleguide 2.0
*/
// 背景：グラデーションの角丸ボタン
.ec-infoBtn {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #E5E5E5 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF;
  opacity: 1;
  color: #5C5C67;
  border-radius: 47px;
  font-size: 16px;
  height: 92px;
  box-sizing: border-box;
  padding: 0 36px;
  &:hover {
    color: #5C5C67;
    opacity: 0.8;
    text-decoration: none;
  }
}
.ec-infoBtn__text {
  text-align: center;
  letter-spacing: 0.2em;
  .ec-infoBtn__textSm {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 16px;
  }
  .ec-infoBtn__textLg {
    font-family: 'RyuminPr5-Light', sans-serif;
    font-size: 24px;
  }
}

// カウンセリングボタン
.ec-counselingBtn {
  width: 416px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  right: 30px;
  bottom: 15px;
  padding: 0 36px;
  .ec-counselingText {
    text-align: center;
    letter-spacing: 0.2em;
    @media (max-width: 600px) {
      margin-right: 20px;
    }
    span {
      font-family: 'Koburina-Gothic-w3', sans-serif;
      font-size: 16px;
    }
    p {
      font-family: 'RyuminPr5-Light', sans-serif;
      font-size: 24px;
    }
  }
  &:hover {
    opacity: 0.8;
    text-decoration: none;
    .ec-counselingText {
      text-decoration: none;
    }
  }
  .ec-counselingPlus {
    width: 11px;
    height: 22px;
  }
  @media (max-width: 767px) {
    bottom: 10px;
    height: 68px;
    font-size: 14px;
  }
  @media (max-width: 600px) {
    left: 0;
    width: 100%;
    justify-content: center;
  }
}

// view moreのリンク
.ec-viewmore {
  border-bottom: 1px solid #5C5C67;
  width: 189px;
  padding-bottom: 17px;
  text-align: right;
  float: right;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 767px) {
    width: 130px;
    right: 16px;
    bottom: 18px;
    padding-bottom: 8px;
  }
  a, div {
    color: #5C5C67;
    font-family: 'BonvenoCF-Light', sans-serif;
    display: block;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 0.12em;
    @media (max-width: 767px) {
      font-size: 16px;
    }
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
}

/*
テキスト

*/
.ec-section__titleEn {
  color: #5C5C67;
  font-family: "BonvenoCF-Light", sans-serif;
  font-size: 34px;
  letter-spacing: 0.12em;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}

/*
フォーム

Styleguide 3.0
*/

/*
アイコン

Styleguide 4.0
*/

/*
グリッド

Styleguide 5.0
*/

/*
ログイン

Styleguide 6.0
*/

/*
商品

Styleguide 7.0
*/

/*
お知らせ

Styleguide 8.0
*/

/*
マイページ

Styleguide 9.0
*/
