@import "../mixins/media";
@import "../mixins/projects";

/*
特商法

特商法のコンポーネントを定義します。
*/

body#page_help_tradelaw {
  font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
}

dd, dt {
  letter-spacing: 0.12em;
}

.ec-label {
  font-family: 'Koburina-Gothic-w3';
}
