@import "../mixins/media";
@import "../mixins/projects";

/*
ABOUT

ABOUTページのコンポーネントを定義します。
*/
.ec-aboutRole {
  font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
  letter-spacing: 0.1em;
  font-size: 22px;
  line-height: 1.9em;
  @include media_desktop {
    font-size: 20px;
  }
  &__pc {
    display: none;
    @include media_desktop {
      display: block;
    }
  }
  &__pcInline {
    display: none;
    @include media_desktop {
      display: inline;
    }
  }
  &__sp {
    display: block;
    @include media_desktop {
      display: none;
    }
  }
  &__inner {
    max-width: 1247px;
    text-align: center;
  }
  &__asterisk {
    position: relative;
    top: -3.99px;
  }
  &__mainImgWrapper {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 1247px;
    position: relative;
    @include media_desktop {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &__mainImg {
    max-width: 100%;
    height: auto;
  }
  &__pageTitle {
    font-family: 'BonvenoCF-Light', sans-serif;
    letter-spacing: 0.2em;
    font-size: 5.3vw;
    line-height: 1.9em;
    color: #FFFFFF;
    position: absolute;
    top: 39%;
    right: 8.5%;
    @include media_desktop {
      font-size: 34px;
      top: 35%;
      right: 7%;
    }
  }
  &__infoWrapper {
    background-color: #F3F1F1;
    @media (max-width: 934px) {
      background-color: unset;
    }
  }
  &__info {
    position: relative;
    padding-bottom: 547px;
    margin-top: 5vw;
    @include media_desktop {
      padding-bottom: 0;
      max-width: 934px;
      width: 100%;
      height: 709px;
      margin: 40px auto 0;
    }
  }
  &__infoImg {
    max-width: 100%;
    height: auto;
  }
  &__infoTextWrapper{
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 20px;
    padding-right: 20px;
    @include media_desktop {
      width: 48.5%;
      top: 42px;
      right: 26px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__infoTitle {
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 5.7vw;
    font-weight: bold;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    padding: 8vw 0 0 47vw;
    @include media_desktop {
      font-size: 34px;
      line-height: 1.7em;
      padding: 0;
      margin-top: 0;
    }
    @media (max-width: 800px) {
      font-size: 32px;
    }
    @media (max-width: 767px) {
      font-size: 5.7vw;
    }
  }
  &__infotext {
    font-size: 14px;
    line-height: 1.6em;
    letter-spacing: 0.1em;
    margin-top: 53vw;
    text-align: center;
    @include media_desktop {
      text-align: left;
      margin-top: 4vw;
    }
    @media (min-width: 1280px) {
      margin-top: 47px;
    }
  }
  &__infotextSmall {
    font-size: 12px;
    letter-spacing: 0.1em;
    line-height: 1.9em;
    margin-top: 7.5vw;
    text-align: center;
    @include media_desktop {
      margin-top: 3vw;
      text-align: left;
    }
    @media (min-width: 1280px) {
      margin-top: 38px;
    }
  }
  &__concept {
    padding-top: 12vw;
    position: relative;
    padding-bottom: 63.5vw;
    max-width: 1280px;
    margin: 0 auto;
    @include media_desktop {
      padding-top: 50px;
      padding-bottom: 58.5vw;
    }
    @media (min-width: 1280px) {
      padding-bottom: 750px;
    }
  }
  &__contentTitle {
    font-size: 24px;
    margin: 20px 0 0;
    letter-spacing: 0.2em;
    font-family: 'BonvenoCF-Light', sans-serif;
    text-align: center;
    font-weight: bold;
    @include media_desktop {
      font-size: 34px;
    }
  }
  &__textSpaceWide {
    font-size: 14px;
    line-height: 2.3em;
    padding-top: 40px;
    text-align: center;
    @include media_desktop {
      padding-top: 82px;
      line-height: 2.4em;
    }
  }
  &__conceptImg1 {
    width: 37%;
    position: absolute;
    left: 0;
    transform: none;
    bottom: 0;
    @include media_desktop {
      width: 33%;
      left: 28%;
      transform: translate(-50%, 0);
    }
    @media (min-width: 1280px) {
      width: 425.5px;
      left: calc(50% - 287.5px);
    }
  }
  &__conceptImg2 {
    width: 49.5%;
    position: absolute;
    right: 0;
    bottom: 0;
    @include media_desktop {
      width: 44%;
    }
    @media (min-width: 1280px) {
      width: 565.5px;
    }
  }
  &__israel {
    padding-top: 24.5vw;
    @include media_desktop {
      padding-top: 157px;
    }
    & .ec-aboutRole__textSpaceWide {
      padding-top: 8.5vw;
      @include media_desktop {
        padding-top: 81px;
      }
    }
  }
  &__israelImg {
    width: 27px!important;
    height: 19.5px;
    margin-top: 10vw;
    @include media_desktop {
      width: 54px!important;
      height: 39px;
      margin-top: 69px;
    }
  }
  &__bottomImgWrapper {
    margin: 25vw auto 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16vw;
    max-width: 1247px;
    @include media_desktop {
      margin-top: 126px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 80px;
    }
  }
  &__bottomImg {
    max-width: 100%;
    height: auto;
  }
}
