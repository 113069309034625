/*
代理店詳細

Styleguide 18.0
*/
#agency_detail {
  background: #fff;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ec-layoutRole__main & {
    background: #fff;
    margin-left: auto;
    margin-right: auto;
  }
  .ec-productRole__profile {
    margin-top: 80px;
    @media only screen and (min-width: 768px) {
      margin-left: 0;
    }
    @media (max-width: 767px) {
      margin-top: 40px;
      margin-bottom: 80px;
    }
  }
  .ec-productRole__profFlex {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .ec-agency__imgWrap {
    padding: 0;
    width: 46%;
    @media (max-width: 767px) {
      width: 100%;
    }
    .ec-productRole__code {
      padding: 0;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .ec-productRole__code {
    padding: 0;
    border: none;
  }

  .ec-productRole__profDetail {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    color: #000;
    display: flex;
    flex-direction: column;
    margin-left: 72px;
    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 32px;
    }
  }

  .ec-headingTitle {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .ec-productRole__code.ec-agency__kana {
    margin-bottom: 28px;
  }

  .product-code-default {
    font-size: 12px;
    color: #6F6F6F;
  }

  .ec-profDetail__access {
    display: flex;
    margin-bottom: 28px;
  }

  .ec-prof__accessIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    &+div {
      line-height: 24px;
    }
  }

  .ec-prof__postCode {
    margin-bottom: 9px;
    font-size: 14px;
    font-family: "Fahkwang", sans-serif;
    color: #5C5C67;
  }

  .ec-prof__address {
    margin-bottom: 13px;
    font-size: 14px;
    font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
    color: #5C5C67;
  }

  .ec-prof__mapLink {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
    display: block;
    font-family: "Koburina-Gothic-w3", sans-serif;
    color: #5C5C67;
    &:hover {
      opacity: 0.8;
    }
  }

  .ec-profDetail__tel {
    display: flex;
    margin-bottom: 25px;
    font-size: 14px;
  }

  .ec-prof__telIcon {
    width: 14px;
    height: 17px;
    margin: 4px 13px 4px 5px;
    &+div {
      font-family: "Fahkwang", sans-serif;
      line-height: 24px;
    }
  }

  .ec-profDetail__hp {
    display: flex;
    align-items: center;
    margin-bottom: 29px;
  }

  .ec-prof__salonHp {
    margin-right: 32px;
    a {
      text-decoration: underline;
      color: #5C5C67;
      font-weight: bold;
      font-size: 14px;
      font-family: "Koburina-Gothic-w3", sans-serif;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .ec-prof__hpIcon {
    width: 16px;
    height: 13px;
    margin: 6px 12px 5px 4px;
  }

  .ec-prof__instaIcon {
    margin-right: 24px;
    img {
      width: 20px;
      height: 20px;
    }
  }

  .ec-prof__lineIcon {
    img {
      width: 21px;
      height: 20px;
    }
  }

  .ec-prof__recommended {
    font-size: 14px;
  }

  .ec-prof__recomIcon {
    width: 14px;
    height: 12px;
    margin: 5px 6px;
  }

  .ec-prof__recomTitle {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .ec-prof__recomContent {
    margin-left: 24px;
    line-height: 22px;
  }

  .ec-category2Role {
    .ec-role {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ec-prof__introSeries {
    margin-top: 63px;
    margin-bottom: 56px;
    font-family: 'Koburina-Gothic-w3', sans-serif;
    color: #000;
    &>p {
      margin-bottom: 19px;
      font-size: 16px;
    }
    @media (max-width: 767px) {
      margin-top: 43px;
      margin-bottom: 48px;
    }
  }

  .ec-prof__introFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 767px) {
      gap: 11px 8px;
    }
    &::before {
      content: "";
      display: block;
      width: calc(25% - 24px);  /* .boxに指定したwidthと同じ幅を指定する */
      height: 0;
      order: 1;  /* 疑似要素beforeの順番を必ず指定する*/
      @media (max-width: 767px) {
        width: calc(50% - 7.5px);
      }
    }
    &::after {
      content: "";
      display: block;
      width: calc(25% - 24px);  /* .boxに指定したwidthと同じ幅を指定する */
      height: 0;
      @media (max-width: 767px) {
        width: calc(50% - 7.5px);
      }
    }
  }

  .ec-prof__introDetail {
    width: calc(25% - 24px);
    @media (max-width: 767px) {
      width: calc(50% - 7.5px);
    }
  }

  .ec-prof__introLink {
    display: inline-block;
    width: 100%;
    &> img {
      width: 100%;
      object-fit: cover;
    }
  }

  .ec-prof__backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 62px;
    color: #fff;
    background: #000;
    font-size: 15px;
    font-family: 'Koburina-Gothic-w3', sans-serif;
    margin-bottom: 64px;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
    img {
      width: 5px;
      height: 10px;
      margin-right: 30px;
    }
  }
  #shop_guide.ec-shopGuide {
    @media (min-width: 768px) {
      padding: 120px 0 0 0;
    }
  }
}
#agency_detail + #instagram.ec-instagram {
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 64px;
  }
}

#page_agency_detail {
  .ec-layoutRole__footer {
    margin-top: 0;
  }
}
