@import "../mixins/media";
/*
ABOUT

トップページで使用されているABOUTのスタイルです。

*/
/*
トピック

Styleguide 13.0
*/

#topic.ec-topicRole {
  background: #fff;
  @media (min-width: 768px) {
    padding: 120px 0 200px 0;
  }
  @media (max-width: 767px) {
    padding: 60px 0 100px 0;
  }
  .ec-widthRole {
    margin: 0 auto;
    width: 100%;
  }
  .ec-role {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: initial;
  }
  .ec-headingCaption__en {
    color: #5C5C67;
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 32px;
    line-height: 54px;
    letter-spacing: 0.18em;
    margin-bottom: 82px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 37px;
      white-space: nowrap;
      letter-spacing: 0.15em;
      margin-bottom: 40px;
    }
  }
  .ec-headingCaption__en.fz23 {
    @media (max-width: 767px) {
      font-size: 24px;
      letter-spacing: 0.05em;
    }
    @media (max-width: 400px) {
      font-size: 23px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
      letter-spacing: 0.04em;
    }
  }
  // topic.scssに移動？
  .ec-topicRole__listItem {
    margin-bottom: 120px;
    @media (max-width: 767px) {
      margin-bottom: 48px;
    }
    &:not(:last-of-type) {
      margin-right: 24px;
      @media (max-width: 767px) {
        margin-right: 6px;
      }
    }
    @media only screen and (min-width: 768px) {
      width: calc((100% - 24px)/2);
      img {
        width: 100%;
        margin-left: 0;
      }
    }
    @media (max-width: 767px) {
      width: calc((100% - 6px)/2);
      img {
        width: 100%;
        margin-left: 0;
      }
    }
  }
  // 移動？ここまで
  .ec-about {
    color: #000;
    height: auto;
    margin: 0 auto;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .ec-about__img {
    width: 52%;
    @media (max-width: 767px) {
      width: 100%;
    }
    img {
      margin-left: -20px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  .ec-about__description {
    @media (max-width: 767px) {
      margin: 0 auto 40px auto;
      width: 100%;
    }
  }
  .ec-about__content {
    color: #5C5C67;
    font-size: 14px;
    font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
    margin-bottom: 36px;
    text-align: center;
    p {
      line-height: 2.4em;
      letter-spacing: 0.12em;
      @media (max-width: 767px) {
        line-height: 2.3em;
      }
    }
  }
  .ec-annotation {
    color: #5C5C67;
    font-size: 12px;
    font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
    line-height: 2em;
    letter-spacing: 0.12em;
    text-align: center;
    margin-bottom: 68px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
}

.ec-movieWrapper {
  display: flex;
  text-align: center;
  margin: 0 auto 129px auto;
  width: 81%;
  @media (max-width: 767px) {
    margin-bottom: 68px;
    width: 100%;
  }
  .ec-ytWrap {
    width: 100%;
    margin: auto;
    flex-shrink: 0;
    @media (max-width: 690px) {
      height: 59.4vw;
    }
  }
  .ec-ytBlock {
    position: relative;
  }
  .ec-videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 0;
    height: 0;
    width: 100%;
  }
  .ec-videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .ec-videoWrapper::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("/html/template/default/assets/img/top/movie_new.png");
    background-size: cover;
  }
  .ec-videoWrapper.pv::after {
    display: none;
  }
  #play {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    width: 12%;
  }
}
.ec-movieText {
  margin-left: 9px;
  @media (max-width: 767px) {
    margin-left: 4px;
  }
}
.ec-movieLine {
  height: 93px;
  margin-left: 0;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    height: 36px;
    margin-bottom: 4px;
  }
}
.ec-movieCaption {
  writing-mode: vertical-rl;
  font-size: 14px;
  font-family: 'BonvenoCF-Light', sans-serif;
  letter-spacing: 0.12em;
  color: #5C5C67;
  @media (max-width: 767px) {
    font-size: 7px;
  }
}
