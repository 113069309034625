@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  font-family: 'Koburina-Gothic-w3', sans-serif;
  letter-spacing: 0.1em;
  .ec-productTop {
    display: block;
    margin: 0 auto;
    padding-top: 55px;
    @include media_desktop {
      padding-top: 80px;
    }
  }
  .ec-grid2__cell.ec-productCellImg {
    @include media_desktop {
      width: 416px;
      float: left;
    }
    @media (max-width: 950px) {
      width: 46%;
    }
    @media (max-width: 767px) {
      width: 100%;
      float: unset;
    }
  }
  .ec-grid2__cell.ec-productCellDetail {
    @include media_desktop {
      width: 464px;
      float: right;
    }
    @media (max-width: 950px) {
      width: 51%;
    }
    @media (max-width: 767px) {
      width: 100%;
      float: unset;
    }
  }
  .ec-sliderItemRole .slideThumb {
    text-align: left;
  }
  .ec-sliderItemRole .slideThumb:nth-of-type(3n+2) {
    text-align: center;
  }
  .ec-sliderItemRole .slideThumb:nth-of-type(3n) {
    text-align: right;
  }
  .ec-sliderItemRole .slideThumb img {
    width: 88%;
  }
  .ec-productImgBox {
    padding: 0;
    margin-bottom: 0;
    width: 100%;
    position: relative;
  }
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    @include media_desktop {
      margin-left: 16px;
    }
  }
  & &__title {
    .ec-headingTitle {
      font-size: 18px;
      margin-top: 8px;
      @include media_desktop {
        font-size: 20px;
        margin-top: 18px;
      }
      .ec-headingTitleSmall {
        font-size: 14px;
        margin-top: 17px;
        font-family: 'BonvenoCF-Light', sans-serif;
      }
    }
  }
  & &__tags {
    margin-top: 16px;
    padding: 0;
    padding-bottom: 16px;
  }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #D7DADD;
    border-radius: 3px;
    background-color: #F5F7F8;
  }
  & &__priceRegular {
    padding-top: 14px
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    color: #000000;
    font-size: 28px;
    padding: 0 0 10px 0;
    border-bottom: 0;
    font-weight: normal;
    @include media_desktop {
      padding: 14px 0;
    }
  }
  & .ec-price .ec-price__price {
    font-size: 22px;
    padding: 0;
    font-family: 'Fahkwang', sans-serif;
    font-weight: normal;
    color: #5C5C67;
  }
  & .ec-price .ec-price__tax {
    color: #5C5C67;
    font-size: 15px;
  }
  & &__code {
    padding: 14px 0;
    .product-code-default {
      font-family: 'Fahkwang', sans-serif;
    }
  }
  & &__category {
    padding: 0;
    a {
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      font-size: 15px;
      &:hover {
        opacity: .6;
        text-decoration: none;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        -ms-transition: 0.2s;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  & &__actions {
    padding: 14px 0;
    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;
        @include media_desktop {
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
  }
  & &__text {
    font-size: 14px;
    line-height: 1.6em;
    border: 1px solid rgba(92, 92, 103, 0.5);
    padding: 10px;
  }
  & &__btnWrapper {
    margin-top: 30px;
    position: relative;
  }
  & &__btn {
    width: 100%;
    margin-bottom: 10px;
    @include media_desktop {
      margin-bottom: 16px;
    }
  }
  & &__btnMain {
    float: unset;
    width: 100%;
    @include media_desktop {
      float: left;
      width: calc(100% - 80px);
    }
  }
  & &__btnMain .ec-blockBtn--action {
    height: 62px;
  }
  & &__btnFav {
    float: unset;
    width: 62px;
    @include media_desktop {
      float: right;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  & &__btnFav.pcFav {
    display: none;
    @include media_desktop {
      display: block;
    }
  }
  & &__btnFav.spFav {
    display: block;
    position: absolute;
    left: 0;
    bottom: 20px;
    @include media_desktop {
      display: none;
    }
  }
  & &__btn .ec-blockBtn--action {
    background-color: #000000;
    border-color: unset;
    font-size: 15px;
    font-weight: normal;
    @media (max-width: 950px) {
      font-size: 1.6vw;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  & &__btn .ec-blockBtn--cancel {
    width: 62px;
    height: 62px;
    background-color: #EDEDED;
    border: unset;
  }
  & &__btn .ec-blockBtn--action:hover,
  & &__btn .ec-blockBtn--cancel:hover {
    opacity: .6;
    text-decoration: none;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
  & &__btnFavImg {
    width: 22px;
    height: 18px;
  }
  & &__contact {
    width: 210px;
    display: block;
    padding: 0 0 16px 0;
    margin-top: 16px;
    font-size: 13px;
    color: #000000;
    border-bottom: 1px solid #000000;
    position: relative;

  }
  & &__contact:hover {
    opacity: .6;
    text-decoration: none;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
  & &__contactImg {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    top: 3px;
  }
  & &__description {
    margin-bottom: 16px;
  }
}

/*
商品詳細ページの商品特徴部分(白枠で囲まれている部分)

商品特徴部分で使用されているクラスの定義です。

Styleguide 14.1
*/
.ec-productDetailBottom {
  margin: 40px auto 0;
  background-color: #F8F8F8;
  padding: 56px 0;
  @include media_desktop {
    margin-top: 80px;
  }
  &__wrapper {
    padding: 60px 7vw;
    margin: 0 auto;
    background-color: #FFFFFF;
    @include media_desktop {
      padding: 84px 7vw;
    }
  }
  &__detail {
    font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0.1em;
  }
  &__title {
    text-align: left;
    font-weight: bold;
    padding-bottom: 25px;
    margin: 0;
    font-size: 14px;
    padding-bottom: 15px;
  }
  &__title:nth-of-type(n+2) {
    margin-top: 38px
  }
  &__detailTitle {
    font-weight: 400;
    line-height: 1.5em;
    margin: 15px 0 0 0;
    font-size: 16px;
  }
  &__detailImg {
    width: auto!important;
    max-width: 100%;
    display: block;
    padding-bottom: 15px;
    @include media_desktop {
      padding-bottom: 25px;
    }
  }
}
