@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム4列）

カテゴリ部分で使用されている共通スタイルです。

Styleguide 12.7.1
*/

.ec-categoryRole {
  .ec-widthRole {
    margin: 0 auto;
  }
  &__headlineTop {
    display: none;
  }
  &__exTextTop {
    display: none;
  }
  .ec-section__titleEn {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
      letter-spacing: 0.18em;
    }
  }
  &__title h2 {
    font-size: 22px;
    font-family: 'BonvenoCF-Light', sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin: 0;
    @include media_desktop {
      font-size: 28px;
    }
  }
  &__title p {
    margin-top: 10px;
    font-size: 12px;
    font-weight: normal;
    font-family: 'Koburina-Gothic-w3', sans-serif;
    letter-spacing: 0.1em;
    @include media_desktop {
      font-size: 13px;
      margin-top: 20px;
    }
  }
  &__caption {
    display: none;
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding-left: 0;
    margin: -2px 0 0 -2%;
  }
  &__btnWrap {
    text-align: center;
    margin: 189px auto 200px;
    @media (max-width: 767px) {
      margin: 40px auto 100px;
    }
  }
  &__btn {
    display: inline-block;
    color: #fff;
    background: #5C5C67;
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 15px;
    padding: 21px 31px;
    letter-spacing: 0.12em;
    &:hover {
      opacity: 0.8;
      color: #fff;
      text-decoration: none;
    }
    @media (max-width: 767px) {
      padding: 14px 21px;
    }
    span {
      font-family: 'BonvenoCF-Light', sans-serif;
    }
  }
  &__element {
    color: #000000;
    width: 48%;
    margin: 25px 0 0 2%;
    overflow: hidden;
    padding-bottom: 25px;
    background-color: #F8F8F8;
    @include media_desktop {
      width: 23%;
      margin: 40px 0 0 2%;
    }
    .ec-viewmore {
      display: none;
    }
  }
  &__element:hover {
    opacity: .8;
    text-decoration: none;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
  &__imgBox {
    width: 100%;
    height: 120px;
    overflow: hidden;
    @include media_desktop {
      height: 165px;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__categoryTitle {
    font-size: 16px;
    margin: 25px 0 0 0;
    margin-top: 25px;
    padding: 0 16px;
    font-family: 'BonvenoCF-Light', sans-serif;
  }
  &__categoryTitleSmall {
    font-size: 0.8em;
    letter-spacing: 0.01em;
  }
  &__categoryTitleTail {
    margin-top: 8px;
    font-size: 13px;
    font-family: 'Koburina-Gothic-w3', sans-serif;
  }
  &__headlineTop {
    display: none;
  }
  &__exTextTop {
    display: none;
  }
  &__asterisk {
    position: relative;
    top: 0.01em;
    padding: 0 0.1em;
    font-size: 0.9em;
    span {
      font-size: 10px;
      vertical-align: bottom;
    }
  }
  .unstress {
    color: #54BEB3;
  }
  .illustrious {
    color: #A7A8A9;
  }
  .biophyto {
    color: #618F2E;
  }
  .forever_young {
    color: #F7B05B;
  }
  .rose_de_mer {
    color: #162C7D;
  }
  .muse {
    color: #D76EA8;
  }
  .comodex {
    color: #842331;
  }
  .theraskin {
    color: #38829B;
  }
  .chateau_de_beaute {
    color: #7E2D4F;
  }
  .silk {
    color: #B29E97;
  }
  .bio_satin_serum {
    color: #686868;
  }
  .clinical {
    color: #488A9B;
  }
}

/*
カテゴリ（アイテム4列・シンプルver.）

カテゴリ(シンプルver.)部分で使用されている共通スタイルです。

Styleguide 12.7.1
*/

.ec-category2Role {
  padding: 64px 0;
  @include media_desktop {
    padding: 80px 0;
  }
  &__wrapper {
    margin: 0 auto;
  }
  &__title h2 {
    font-size: 22px;
    font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin: 0;
    @include media_desktop {
      font-size: 18px;
    }
  }
  &__caption {
    display: none;
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding-left: 0;
    margin: 2px 0 0 -2%;
  }
  &__element {
    color: #000000;
    width: 48%;
    height: 26.9vw;
    margin: 10px 0 0 2%;
    overflow: hidden;
    position: relative;
    @include media_desktop {
      width: 23%;
      height: 10.2vw;
      margin: 25px 0 0 2%;
    }
    .ec-viewmore {
      display: none;
    }
  }
  &__element:hover {
    opacity: .8;
    text-decoration: none;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__categoryTitle {
    width: 95%;
    text-align: center;
    font-size: 3.5vw;
    font-weight: 300;
    white-space: nowrap;
    margin: 0;
    color: #FFFFFF;
    font-family: 'BonvenoCF-Light', sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (min-width: 1100px) {
      font-size: 18px;
    }
    @media only screen and (min-width: 880px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
  &__headlineTop {
    display: none;
  }
  &__exTextTop {
    display: none;
  }
}

/*
カテゴリ TOPのスタイル

*/
#products.ec-productsTop {
  .ec-categoryRole{
    &__wrap {
      width: 89%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    &__caption {
      display: block;
      font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
      text-align: center;
      margin-bottom: 80px;
      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
      p {
        font-size: 25px;
        line-height: 47px;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 32px;
        }
      }
    }
    &__title h2 {
      color: #5C5C67;
      font-family: "BonvenoCF-Light", sans-serif;
      font-size: 34px;
      letter-spacing: 0.12em;
      margin: 0 0 80px 0;
      text-align: center;
      @include media_desktop {
        font-size: 34px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        margin: 0 0 40px 0;
        letter-spacing: 0.18em;
      }
    }
    &__categoryTitle {
      color: #5C5C67;
      font-family: "BonvenoCF-Light", sans-serif;
      font-size: 34px;
      padding: 0;
      margin: 40px 0 56px 0;
      letter-spacing: 0.12em;
      @media (max-width: 767px) {
        font-size: 24px;
        margin: 20px 0 28px 0;
      }
    }
    &__categoryTitleTail {
      display: none;
    }
    &__list {
      justify-content: space-between;
      margin: 0;
      @media (max-width: 767px) {
        flex-direction: column
      }
    }
    &__element {
      color: #5C5C67;
      background: #fff;
      overflow: visible;
      display: flex;
      flex-direction: column;
      &:not(:first-child) {
        @media (max-width: 767px) {
          padding-top: 35px;
        }
      }
      &:nth-child(odd) {
        .ec-viewmore {
          @media (max-width: 767px) {
          }
        }
      }
      &:nth-child(even) {
        padding-top: 115px;
        align-items: flex-end;
        .ec-categoryRole__imgBox {
          align-self: flex-end;
        }
      }
      @include media_desktop {
        width: 50%;
        margin: 40px 0 0 0;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .ec-viewmore {
      display: block;
      align-self: flex-end;
      margin-top: 32px;
      @media (max-width: 767px) {
        margin-top: 17px;
      }
    }
    &__headlineTop {
      display: block;
      color: #5C5C67;
      font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 29px;
        margin-bottom: 8px;
      }
    }
    &__exTextTop {
      display: block;
      color: #5C5C67;
      font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
      font-size: 12px;
      line-height: 24px;
    }
    &__imgBox {
      @include media_desktop {
        height: 388px;
        width: 100%;
      }
      @media (max-width: 767px) {
        height: 194px;
      }
    }
  }
}
