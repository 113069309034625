@import "../mixins/media";
/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole{
  @include container;
  & &__intro {
    font-size: 16px;
  }
  & &__form {
    margin: 48px auto 16px;
  }
  .ec-borderedDefs {
    margin: 32px auto;
  }
}
.ec-forgetCompleteRole {
  margin: 48px auto 0;
  @media (max-width: 767px) {
    width: 90%;
  }
}
.ec-resetPassForm {
  margin-top: 48px;
}
