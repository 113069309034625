@import "../mixins/media";
/*
MEDIA

トップページで使用されているMEDIAのスタイルです。

*/
/*
メディア

Styleguide 15.0
*/
#media.ec-media {
  .ec-role {
    @media (max-width: 1100px) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  .ec-widthRole {
    margin: 0 auto;
  }
  .ec-section__titleEn {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
  .ec-media__scroll {
    display: flex;
    flex-direction: column;
    .ec-scroll__wrap {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 16px;
      @media (max-width: 767px) {
        order: 2;
        justify-content: center;
        margin-top: 25px;
        margin-bottom: 0;
      }
    }
    .ec-scroll__arrow {
      width: 5px;
      height: 10px;
      cursor: pointer;
      white-space:nowrap;
      overflow-x:hidden;
      scroll-behavior:smooth;
      scroll-snap-type:inline;
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
  .ec-media__list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    .slick-slide {
      margin: 0 12px;
      @media (max-width: 767px) {
        width: calc(50% - 7.5px);
      }
    }
    .slick-list {
      margin: 0 -12px;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: 8px;
    }
    img.slide-arrow {
      position: absolute;
      height: 36px;
      width: 36px;
      top: calc(50% - 60px);
      cursor: pointer;
      margin-bottom: 0;
      @media (max-width: 1100px) {
        height: 30px;
        width: 30px;
      }
      @media (max-width: 870px) {
        top: calc(50% - 70px);
      }
      @media (max-width: 767px) {
        top: calc(50% - 55px);
      }
      @media (max-width: 600px) {
        height: 24px;
        width: 24px;
        top: calc(50% - 70px);
      }
      @media (max-width: 390px) {
        top: calc(50% - 85px);
      }
      @media (max-width: 375px) {
        top: calc(50% - 95px);
      }
    }
    img.prev-arrow {
      left: -50px;
      @media (max-width: 1100px) {
        left: -36px;
      }
      @media (max-width: 390px) {
        left: -30px;
      }
    }
    img.next-arrow {
      right: -50px;
      @media (max-width: 1100px) {
        right: -36px;
      }
      @media (max-width: 390px) {
        right: -30px;
      }
    }
  }
  .ec-media__name {
    font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
    font-size: 13px;
    line-height: 28px;
    color: #5C5C67;
  }
}
