@import "../mixins/media";
@import "../mixins/projects";

/*
ニュース詳細

ニュース詳細のコンポーネントを定義します。
*/
.ec-newsDetailRole {
  font-family: 'Koburina-Gothic-w3', sans-serif;
  letter-spacing: 0.1em;
  .ec-pageHeader {
    position: relative;
    margin: 0 80px 80px;
    padding: 0;
    height: 140px;
    background-color: #c8dade;
    border-bottom: none;
    @media (max-width: 1180px) {
      margin: 0 50px 50px;
    }
    @media (max-width: 767px) {
      margin:0 0 50px;
    }
    h1 {
      position: absolute;
      top: 0;
      right: 112px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
      color: #ffffff;
      letter-spacing: 10px;
      font-weight: 400;
      font-size: 34px;
      font-family: "BonvenoCF-Light", "Koburina-Gothic-w3", "Fahkwang", sans-serif;
      line-height: 1;
      border-bottom: none;
      @media (max-width: 1180px) {
        right: 50px;
        letter-spacing: 3px;
        font-size: 30px;
      }
      @media (max-width: 767px) {
        right: 30px;
        letter-spacing: 2px;
        font-size: 20px;
      }
    }
  }
  .ec-viewmore {
    margin-top: 32px;
    margin-bottom: 48px;
  }
  .ec-viewmore.ec-back {
    margin-top: 48px;
    width: 120px;
  }
  .ec-newsDetail {
    margin: 0;
    padding: 0 80px 86px;
    color: #5e6770;
    letter-spacing: 2px;
    font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
    @media (max-width: 1180px) {
      padding: 0 50px 86px;
    }
    @media (max-width: 767px) {
      padding: 0 30px 16px;
    }
    &__inner {
      margin: 0 auto;
      padding: 0;
      max-width: 998px;
    }
    &__h3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      padding: 0 20px;
      height: 50px;
      background-color: rgb(200 218 222 / 36%);
      letter-spacing: 2px;
      font-weight: 500!important;
      font-size: 20px;
      font-family: "Ryumin",sans-serif!important;
      @media (max-width: 767px) {
        letter-spacing: 1px;
        font-size: 15px;
        padding: 0 10px;
      }
    }
    &__date {
      font-family: "Fahkwang", sans-serif;
      font-size: 16px;
      margin-right: 16px;
      @media (max-width: 767px) {
        font-size: 12px;
        margin-right: 12px;
      }
    }
    &__detail {
      margin: 0;
      padding-top: 32px;
      font-size: 15px;
      line-height: 1.8em;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    &__link {
      padding-top: 16px;
      display: block;
    }
  }
}
