@import "../mixins/media";
@import "../mixins/projects";

/* フォント */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  background: rgba(94, 103, 112, 0.15);
  font-family: 'RyuminPr5-Light', sans-serif;
  letter-spacing: 0.1em;
  position: relative;
  @media (max-width: 1120px) {
    padding-top: 60px;
  }
  @media (max-width: 767px) {
   padding-bottom: 55px;
  }
  @include media_desktop(){
    padding-top: 104px;
    padding-bottom: 30px;
    margin-bottom: 0;
  }
  &__pcInline {
    display: none;
    @include media_desktop() {
      display: inline;
    }
  }
  &__spInline {
    display: inline;
    @include media_desktop() {
      display: none;
    }
  }
  & &__innerTop,
  & &__innerBottom{
    position: relative;
    @include media_desktop {
      @include container;
    }
  }
  & &__innerTop {
    min-height: 344px;
    margin-top: 80px!important;
    @media (max-width: 1120px) {
      margin-top: 40px!important;
    }
    @media (max-width: 767px) {
      margin-top: 18px!important;
    }
  }
}

/*
フッターメイン

フッタープロジェクトで使用するメイン部分(ロゴなど)です。
*/
.ec-footerMain {
  width: 162px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  right: 20px;
  bottom: 0;
  @include media_desktop {
    width: 228px;
  }
  @media (max-width: 1120px) {
    margin: 80px auto 0;
    position: unset;
  }
  @media (max-width: 767px) {
    margin: 40px auto 0;
  }
  &__element:nth-of-type(2) {
    margin: 10px 0 0 0;
    @include media_desktop {
      margin: 7px 0 0 0;
    }
  }
  &__element:nth-of-type(3) {
    margin: 15px 0 0 19px;
    @include media_desktop {
      margin: 20px 0 0 0;
    }
  }
  &__h1 {
    margin: 0;
    text-align: center;
    @include media_desktop {
      text-align: left;
    }
  }
  &__titleImg {
    width: 228px;
    height: 40px;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    @include media_desktop {
      width: 100%;
      height: 4.2vw;
    }
  }
  &__titleImg:hover {
    opacity: .6;
  }
  &__link {
    position: relative;
    color: #5C5C67;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
  &__link,
  &__link:hover {
    text-decoration: none;
    color: #5C5C67;
  }
  &__link:hover {
    opacity: .6;
  }
  &__tel:before {
    top: 4px;
  }
  &__telImg,
  &__contactImg {
    position: absolute;
  }
  &__telImg {
    width: 25px!important;
    height: 25px;
    left: 0;
    top: 9px;
    @include media_desktop {
      width: 32px!important;
      height: 32px;
    }
  }
  &__tel,
  &__time,
  &__contact {
    padding-left: 35px;
    @include media_desktop {
      padding-left: 48px;
    }
  }
  &__tel {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 20px;
    font-weight: bold;
    @include media_desktop {
      font-size: 26px;
    }
  }
  &__time {
    font-size: 7px;
    position: relative;
    top: -5px;
    @include media_desktop {
      font-size: 8px;
    }
  }
  &__contact {
    font-size: 11px;
    padding-top: 5px;
    @include media_desktop {
      font-size: 15px;
      padding-top: 7px;
    }
  }
  &__contactImg {
    width: 25px!important;
    height: 25px;
    left: 0;
    top: 0;
    @include media_desktop {
      width: 32px!important;
      height: 32px;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNaviWrapper {
  @media (max-width: 1120px) {
    margin-top: 60px;
    padding: 0;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    margin-top: 40px;
  }
  @include media_desktop {
    padding: 0 0 0 300px;
  }
}
.ec-footerNavi1,
.ec-footerNavi2 {
  color: #5C5C67;
  list-style: none;
  text-align: center;
}
.ec-footerNavi1__link,
.ec-footerNavi2__link {
  display: block;
}
.ec-footerNavi1__link a,
.ec-footerNavi2__link a {
  display: inline;
  text-decoration: none;
  color: #5C5C67;
}
.ec-footerNavi1__link a:hover,
.ec-footerNavi2__link a:hover {
  opacity: .6;
  text-decoration: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
}
.ec-footerNavi1 {
  list-style: none;
  padding-left: 0;
  margin: 0;
  @media (max-width: 1120px) {
    max-width: 765px;
    margin: 80px auto 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 40px auto 0;
  }
  &__element {
    text-align: left;
    position: relative;
    float: left;
    @media (max-width: 1120px) {
      width: 25%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    @include media_desktop {
      width: 190px;
      position: static;
      padding-right: 10px;
    }
  }
  &__element:nth-last-of-type(1) {
    padding-right: 0;
  }
  &__index {
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 12px 0;
    border-bottom: 1px solid #5C5C67;
    @include media_desktop {
      border-bottom: unset;
      letter-spacing: 0.2em;
      padding: 0;
    }
  }
  &__linkList {
    list-style: none;
    padding: 18px 0;
    border-bottom: 1px solid #5C5C67;
    @include media_desktop {
        border-bottom: unset;
        display: block!important;
        padding: 28px 0 0 0;
    }
  }
  &__link {
    font-size: 12px;
    line-height: 2em;
    &.products {
      font-family: 'BonvenoCF-Light', sans-serif;
    }
  }
  &__introducedInfo {
    color: #5C5C67;
    font-size: 12px;
    display: block;
    width: 100%;
    border: unset;
    background-color: #DEDEDE;
    padding: 11px 10px;
    text-decoration: none;
    @include media_desktop {
      border: 1px solid #5C5C67;
      background-color: unset;
      padding: 8px 10px;
    }
    &:hover {
      color: #5C5C67;
      background-color: #b4b4b4;
      opacity: .6;
      transition: 0.2s;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      -o-transition: 0.2s;
      -ms-transition: 0.2s;
      @include media_desktop {
        background-color: #CCCCCC;
      }
    }
    &,
    &:hover {
      text-decoration: none;
    }
  }
  &__plusImgSp {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0;
    top: 15px;
    display: block;
    @include media_desktop {
      display: none;
      position: static;
    }
  }
  &__plusImg {
    width: 12px!important;
    height: 12px;
    margin: 0 0 2px 5px;
    position: absolute;
    top: 13px;
    right: 16px;
    @include media_desktop {
      position: static;
    }
  }
}
.ec-footerNavi2 {
  list-style: none;
  padding-left: 0;
  text-align: left;
  margin-top: 35px;
  @media (max-width: 1120px) {
    position: unset;
    width: 100%;
    max-width: 765px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
  }
  @media (max-width: 767px) {
    margin: 40px auto 0;
  }
  @include media_desktop {
    width: 200px;
    display: block;
    flex-flow: unset;
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 20px;
  }
  &__link {
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 9px;
    line-height: 1.8em;
    letter-spacing: 0.05em;
    position: relative;
    margin-top: 22px;
    @media (max-width: 1120px) {
      width: 48%;
    }
    @media (max-width: 767px) {
      padding-left: 32px;
    }
    @include media_desktop {
      width: auto;
      font-size: 12px;
      line-height: 2.5em;
      letter-spacing: 0.1em;
      margin-top: 13px;
      padding-left: 40px;
    }
  }
  &__link:nth-of-type(-n+1) {
    margin-top: 0;
  }
  &__link:nth-of-type(2) {
    @media (max-width: 1120px) {
      margin-top: 0;
    }
  }
  &__link:nth-of-type(2n) {
    margin-left: 4%;
    @include media_desktop {
      margin-left: 0;
    }
  }
  &__linkImg {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  .mypage {
    width: 23px!important;
    height: 23px;
    @include media_desktop {
      width: 27px!important;
      height: 27px;
    }
  }
  .registration {
    width: 13px!important;
    height: 16px;
    left: 3px;
    @include media_desktop {
      width: 15px!important;
      height: 18px;
      left: 0;
    }
  }
  .memberPage {
    width: 14px!important;
    height: 22px;
    left: 5px;
    @include media_desktop {
      width: 17px!important;
      height: 26px;
      left: 4px;
    }
  }
  .onlineShop {
    width: 23px!important;
    height: 22px;
    @include media_desktop {
      width: 28px!important;
      height: 26px;
    }
  }
  .favorite {
    width: 20px!important;
    height: 18px;
    left: 2px;
    @include media_desktop {
      width: 24px!important;
      height: 21px;
    }
  }
  .instagram {
    width: 18px!important;
    height: 18px;
    left: 3px;
    @include media_desktop {
      width: 22px!important;
      height: 22px;
      left: 2px;
    }
  }
  /*
  &__element {
    width: 100%;
    margin-top: 65px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    letter-spacing: 0.05em;
    @include media_desktop {
      width: auto;
      margin-top: 25px;
      display: block;
      justify-content: unset;
      flex-wrap: unset;
      letter-spacing: 0.1em;
    }
  }
  &__linkList {
    list-style: none;
    padding-left: 0;
  }
  &__linkIcon {
    float: left;
  }
  &__linkIcon:hover {
    opacity: .6;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
  &__linkIcon:not(:nth-last-of-type(1)) {
    padding-right: 15px;
  }
  .instagram {
    width: 20px;
    height: 20px;
  }
  .twitter {
    width: 20px;
    height: 16px;
  }
  .line {
    width: 20px;
    height: 19px;
  }
  .youtube {
    width: 22px;
    height: 16px;
  }
  */
}

/*
ミニメニュー＆フッタータイトル

フッタープロジェクトで使用するミニメニュー＆タイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerRole__innerBottom {
  margin-top: 30px!important;
  padding-bottom: 40px;
  @include media_desktop {
    margin-top: 55px!important;
    padding-bottom: 25px;
  }
}
.ec-footerNaviSmall {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  float: unset;
  @include media_desktop {
    float: right;
  }
  &__link {
    float: unset;
    padding: 0;
    position: relative;
    text-align: center;
    margin-top: 5px;
    @include media_desktop {
      float: left;
      padding: 0 16px;
      text-align: left;
      margin-top: 0;
    }
  }
  &__link:nth-of-type(1) {
    padding-left: 0;
    margin-top: 0;
  }
  &__link:nth-last-of-type(1) {
    padding-right: 0;
  }
  &__link:not(:last-of-type):after {
    content: "";
    width: 1px;
    height: 12px;
    background-color: #9B9B9B;
    position: unset;
    right: 0;
    top: 4px;
    @include media_desktop {
      position: absolute;
    }
  }
  &__link a {
    display: inline;
    font-size: 12px;
    text-decoration: none;
    color: #9B9B9B;
    @include media_desktop {
      display: block;
    }
  }
  &__link a:hover {
    opacity: .6;
    text-decoration: none;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
}
.ec-footerTitle{
  float: unset;
  margin-top: 50px;
  @include media_desktop {
    float: left;
    margin-top: 0;
  }
  & &__copyright{
    font-family: 'Lato', sans-serif;
    font-size: 9px;
    color: #3B4043;
    text-align: center;
    @include media_desktop {
      text-align: left;
    }
  }
}
