@import "../mixins/media";
@import "../mixins/projects";

/*
採用情報

採用情報のコンポーネントを定義します。
*/
.recruit_page {
  .ec-layoutRole__contents {
    margin:0;
    padding:0;
    line-height:1.8em;
    letter-spacing: 2px;
    font-feature-settings:"palt";
    -webkit-text-size-adjust:100%;
    color:#5e6770;
    font-family: KoburiGothic2, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
    a {
      outline:0;
      text-decoration:none;
    }
    ul {
      list-style:none;
    }
    li {
      margin:0;
      padding:0;
      list-style:none;
    }
  }
  .ec-pageHeader {
    position:relative;
    margin:0 80px 80px;
    padding:0;
    height:140px;
    background-color:#c8dade;
    @media (max-width: 1180px) {
      margin:0 50px 50px;
    }
    @media (max-width: 767px) {
      margin:0 0 50px;
    }

    .ec-pageHeader__h1 {
      position:absolute;
      top:0;
      right:112px;
      bottom:0;
      display:flex;
      align-items:center;
      justify-content:flex-end;
      margin:0;
      padding:0;
      color:#ffffff;
      letter-spacing:10px;
      font-weight:400;
      font-size:34px;
      font-family: BonvenoCF, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
      line-height:1;
      border-bottom:none;
      @media (max-width: 1180px) {
        right:50px;
        letter-spacing:3px;
        font-size:30px;
      }
      @media (max-width: 767px) {
        right:30px;
        letter-spacing:2px;
        font-size:20px;
      }
    }
  }
}
#ec-recruit {
	margin:0;
	padding:0;
	h3 {
		font-weight:400;
    font-family: BonvenoCF, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
		@media (max-width: 1180px) {
			text-align:center;
		}
	}
}

.ec-recruit__position {
	margin:0 auto;
	padding:0 50px 34px;
	max-width:996px;
	@media (max-width: 767px) {
		padding:0 30px 16px;
	}
	.ec-recruit__h2 {
		margin:0 0 60px;
		padding:0;
		letter-spacing:5px;
		font-weight:400;
		font-size:34px;
    font-family: "BonvenoCF", "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
		@media (max-width: 767px) {
			margin:0 0 30px;
			text-align:center;
			letter-spacing:3px;
			font-size:28px;
		}
	}
	.ec-recruit__nav {
		margin:0;
		padding:0;
		ul {
			display:flex;
			align-items:flex-start;
			justify-content:flex-start;
			margin:0 0 0 -30px;
			padding:0;
			@media (max-width: 767px) {
				flex-wrap:wrap;
				margin:0;
			}
			li {
				box-sizing:border-box;
				padding:0 0 0 30px;
				width:25%;
				@media (max-width: 767px) {
					padding:0 0 1px;
					width:100%;
				}
				a {
					display:flex;
					align-items:center;
					justify-content:flex-start;
					padding:0 20px;
					height:50px;
					background:url(/html/template/default/assets/img/common/btn_arrow_bottom.svg) no-repeat rgba(200, 218, 222, 0.36) right 20px top 50%;
					color:#5e6770;
					font-size:14px;
				}
			}
		}
	}
	.ec-recruit__detail {
		margin:0;
		padding:80px 0 0;
		@media (max-width: 767px) {
			padding:65px 0 0;
		}
		.ec-recruit__h3 {
			display:flex;
			align-items:center;
			justify-content:flex-start;
			margin:0 0 10px;
			padding:0 20px;
			height:50px;
			background-color:rgba(200, 218, 222, 0.36);
			letter-spacing:3px;
			font-weight:500 !important;
			font-size:20px;
			font-family:"Ryumin2", "Ryumin", sans-serif !important;
			@media (max-width: 767px) {
				margin:0;
				letter-spacing:1px;
				font-size:16px;
			}
		}
		dl {
			display:flex;
			align-items:stretch;
			flex-wrap:wrap;
			justify-content:space-between;
			margin:0;
			padding:0;
			font-weight:500;
			font-size:15px;
			line-height:1.8em;
			@media (max-width: 767px) {
				display:block;
				padding:20px 0 0;
				border-right:#efefef solid 1px;
				border-bottom:#efefef solid 1px;
				border-left:#efefef solid 1px;
				font-size:14px;
			}
			dt {
				display:flex;
				align-items:center;
				justify-content:center;
				box-sizing:border-box;
				margin:0 0 10px;
				padding:10px 20px;
				width:225px;
				background-color:rgba(200, 218, 222, 0.36);
				@media (max-width: 960px) {
					width:170px;
				}
				@media (max-width: 767px) {
					display:block;
					margin:0 0 2px;
					padding:0 20px;
					width:auto;
					background-color:transparent;
					font-weight:700;
					&::before {
						content:"■ ";
					}
				}
			}
			dd {
				box-sizing:border-box;
				margin:0 0 10px;
				padding:10px 0 10px 34px;
				width:calc(100% - 225px);
				line-height:1.8em;
        letter-spacing: 2px;
				@media (max-width: 960px) {
					width:calc(100% - 170px);
				}
				@media (max-width: 767px) {
					display:block;
					margin:0 0 20px;
					padding:0 20px 20px;
					width:auto;
					border-bottom:#efefef solid 1px;
					&:last-of-type {
						margin:0 ;
						border-bottom:none;
					}
				}
			}
		}
		.ec-recruit__btn {
			margin:50px 0 0;
			padding:0;
			@media (max-width: 767px) {
				margin:20px 0 0;
			}
			a {
				display:flex;
				align-items:center;
				justify-content:center;
				margin:0 auto;
				padding:0;
				width:245px;
				height:68px;
				background-color:#c8dade;
				color:#5e6770;
				font-size:15px;
				cursor:pointer;
				@media (max-width: 767px) {
					width:100%;
				}
				span {
					display:inline-block;
					padding:0 24px 0 0;
					background:url(/html/template/default/assets/img/common/icon_link.svg) no-repeat right;
					letter-spacing:normal;
				}
			}
		}
	}
}
