@import "../mixins/media";
@import "../mixins/projects";

/* フォント */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/*
カテゴリページ

カテゴリページのコンポーネントを定義します。

Styleguide 11.3
*/
.ec-categoryPageRole {
  font-family: 'Koburina-Gothic-w3', sans-serif;
  letter-spacing: 0.1em;
}

/*
カテゴリ（アイテム4列）
カテゴリページver.

カテゴリページでのみ使用されているクラスの定義です。
(本体は_12.7.category.scss)

Styleguide 12.7.1
*/
.ec-categoryPageRole {
  .ec-categoryRole {
    padding: 41px 0 65px;
    @include media_desktop {
      padding: 64px 0 80px;
    }
  }
  .ec-categoryRole__listWrapper {
    margin: 0 auto;
  }
  .ec-categoryRole__text {
    min-height: 66px;
    font-size: 13px;
    padding: 0 16px;
    margin-top: 20px;
    @include media_desktop {
      margin-top: 25px;
    }
  }
}
