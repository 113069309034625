@import "../mixins/media";
@import "../mixins/projects";

/*
プライバシーポリシー

プライバシーポリシーのコンポーネントを定義します。
*/
.ec-privacyRole {
  font-family: 'Koburina-Gothic-w3', sans-serif;
  letter-spacing: 0.1em;
  &__main {
    padding: 32px 0 20px;
  }
  &__p {
    font-size: 14px;
    line-height: 1.9em;
    @include media_desktop {
      font-size: 16px;
    }
  }
  &__bold {
    margin: 32px 0;
    @include media_desktop {
      margin: 38px 0;
    }
  }
}
