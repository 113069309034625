/*
店舗一覧

*/
#agency_list.ec-categoryRole {
  background: #fff;
  @media only screen and (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    max-width: initial;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
  .ec-role {
    max-width: initial;
    padding-left: 0;
    padding-right: 0;
  }

  .ec-agency__heading {
    margin-top: 56px;
    @media (max-width: 980px) and (min-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .ec-widthRole {
    margin-left: auto;
    margin-right: auto;
  }

  .ec-secHeading {
    display: flex;
    flex-direction: column;
    margin-bottom: 58px;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .ec-secHeading__en {
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 28px;
    color: #5C5C67;
    margin-bottom: 20px;
  }

  .ec-secHeading__ja {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 12px;
    color: #5C5C67;
  }

  .ec-lineRole {
    width: 100%;
    height: 1px;
    background-color: #000;
    border: none;
    margin: 0 auto 32px;
  }

  .ec-lineRole__wrap {
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .ec-agency__bg {
    background: #F2F2F2;
    padding: 54px 0 58px 0;
    margin: 48px auto 0;
    @media (max-width: 980px) and (min-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: 767px) {
      padding: 44px 20px 48px 20px;
    }
  }

  .ec-agency__form {
    margin-bottom: 84px;
  }

  .ec-agency__prefWrap {
    width: 30%;
    border: 1px solid #9B9B9B;
    border-radius: 0;
    position: relative;
    @media (max-width: 900px) {
      width: 40%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    &::before {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      border-top: solid 2px #5C5C67;
      border-right: solid 2px #5C5C67;
      right: 17px;
      top: calc(50% - 6px);
      transform: rotate(135deg);
    }
  }

  #search_agency_pref {
    appearance: none;
    width: 100%;
  }

  #search_agency_Category {
    display: flex;
    flex-wrap: wrap;
  }

  .form-check {
    width: 19%;
    &:nth-of-type(5n) {
      width: 23%;
    }
    @media (max-width: 900px) {
      width: 25%;
      &:nth-of-type(5n) {
        width: 25%;
      }
    }
    @media (max-width: 767px) {
      width: 50%;
      &:nth-of-type(5n) {
        width: 50%;
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  .form-check-label {
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 14px;
    color: #5C5C67;
    cursor: pointer;
    position: relative;
    padding: 5px 0 5px 24px;
    @media (max-width: 900px) {
      font-size: 12px;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      top: calc(50% - 16px/2);
    }
    &::before {
      border: 1px solid #9B9B9B;
      background-color: #fff;
      width: 16px;
      height: 16px;
      left: 2px;
      margin-right: -8px;
    }
    &::after {
      border-right: 2px solid #9B9B9B;
      border-bottom: 2px solid #9B9B9B;
      width: 5px;
      height: 9px;
      top: 53%;
      left: 8px;
      margin-top: -7px;
      opacity: 0;
      transform: rotate(45deg);
    }
  }

  input[type=checkbox]:checked + .form-check-label::after {
    opacity: 1;
  }

  .ec-agency__formFlex {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    p {
      font-family: 'Koburina-Gothic-w3', sans-serif;
      font-size: 14px;
      color: #5C5C67;
      margin-right: 25px;
      white-space: nowrap;
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
    .ec-agency__formCategory {
      list-style-type: none;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 20%;
        font-family: 'BonvenoCF-Light', sans-serif;
        font-size: 14px;
        color: #000;
        @media (max-width: 767px) {
          width: 50%;
          font-size: 13px;
        }
      }
    }
  }

  .ec-agency__selectPref {
    align-items: center;
    margin-bottom: 38px;
    @media (max-width: 767px) {
      align-items: baseline;
      margin-bottom: 36px;
    }
  }

  .ec-agency__selectCategory {
    margin-bottom: 34px;
  }

  .ec-agency__searchBtn {
    border: none;
    background: #5C5C67;
    color: #fff;
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 15px;
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 16px;
      height: 16px;
      margin-right: 11px;
    }
    &:hover {
      opacity: 0.8;
      color: #fff;
      text-decoration: none;
    }
  }

  .ec-agency__description {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 15px;
    line-height: 20px;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .ec-agency__searchResult {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 25px;
  }

  .ec-productRole__flex {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ec-categoryRole__list {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 767px) {
      gap: 24px 11px;
    }
  }

  // 要素が12個に満たない場合、最後の要素を左寄せにさせる
  .ec-categoryRole__list::before {
    content: "";
    display: block;
    width: calc(25% - 24px);
    height: 0;
    order: 1;
    @media (max-width: 767px) {
      width: calc(50% - 7.5px);
    }
  }

  .ec-categoryRole__list::after {
    content: "";
    display: block;
    width: calc(25% - 24px);
    height: 0;
    @media (max-width: 767px) {
      width: calc(50% - 7.5px);
    }
  }

  .ec-categoryRole__listItem {
    box-sizing: border-box;
    display: flex;
    @media only screen and (min-width: 768px) {
      width: calc(25% - 24px);
    }
    @media (max-width: 767px) {
      width: calc(50% - 7.5px);
    }
  }

  .ec-categoryRole__listItem:not(:last-of-type) {
    margin-right: 0;
  }

  .ec-agencyRole__imgWrap {
    width: 100%;
    height: auto;
    position: relative;
  }

  .ec-agencyRole__img {
    display: block;
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: center;
    @media (max-width: 767px) {
      height: 125px;
    }
  }

  .ec-productRole__wrap {
    background: #fff;
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 13px;
    padding: 20px;
    flex-grow: 1;
  }

  .ec-productRole__code {
    font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  }

  .ec-productRole__title {
    font-family: "Koburina-Gothic-w3", 'Fahkwang-bold', sans-serif;
    padding-bottom: 30px;
    font-weight: bold;
  }

  #shop_guide.ec-shopGuide {
    padding: 0;
  }
}

#page_agency_list {
  .ec-layoutRole__footer {
    margin-top: 0;
  }
}
