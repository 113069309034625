@import "../mixins/media";
/*
SNSのスタイルです。

*/

// TOPページ instagram欄
#instagram.ec-instagram {
  padding: 200px 0;
  @media (max-width: 767px) {
    padding: 100px 0;
  }
  .ec-widthRole {
    margin: 0 auto;
  }
  .ec-section__titleEn {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
  .ec-instagram__list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
    li {
      width: 19%;
      @media (max-width: 767px) {
        width: calc(50% - 7.5px);
        margin-bottom: 8px
      }
      &:nth-of-type(odd) {
        @media (max-width: 767px) {
          margin-right: 11px;
        }
      }
      &:last-child {
        @media (min-width: 768px) {
          display: none;
        }
        @media (max-width: 767px) {
          display: block;
        }
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .ec-instagram__linkWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ec-instagram__link {
    float: right;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    padding-bottom: 15px;
    a {
      color: #5C5C67;
      font-family: 'BonvenoCF-Light', sans-serif;
      font-size: 13px;
      white-space: nowrap;
      @media (max-width: 767px) {
        font-size: 11px;
      }
      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
    }
    img {
      width: 93px;
      height: 1px;
      margin-right: 8px;
      @media (max-width: 767px) {
        width: 46px;
      }
    }
  }
}
