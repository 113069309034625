@import "../mixins/media";
@import "../mixins/projects";

/*
カテゴリ詳細ページ

カテゴリ詳細ページのコンポーネントを定義します。

Styleguide 11.3
*/
.ec-categoryPageDetailRole {
  line-height: 2.1em;
  letter-spacing: 0.14em;
}

/*
カテゴリ詳細ページのWrapper部分(背景色が付いている部分)
カテゴリ詳細ページのWrapper部分で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailWrapper {
  margin: 0 calc(50% - 50vw);
  background-color: #F8F8F8;
  padding: 80px 0;
}

/*
カテゴリ詳細ページのメイン画像

カテゴリ詳細ページのメイン画像で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailMainImg {
  height: 39vw;
  margin: 0 calc(50% - 50vw);
  position: relative;
  &__img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  &__imgText {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 32px;
    font-weight: 300;
    @include media_desktop {
      font-size: 40px;
    }
  }
}

/*
カテゴリ詳細ページのメイン部分

カテゴリ詳細ページのメイン部分で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailMain {
  font-family: 'Koburina-Gothic-w3',"Fahkwang", sans-serif;
  margin: 0 auto;
  text-align: left;
  line-height: 1.8em;
  @include media_desktop {
    text-align: center;
  }
  &__title {
    margin: 0;
    font-family: 'BonvenoCF-Light', sans-serif;
    text-align: left;
    font-size: 26px;
    letter-spacing: 0.1em;
    padding-bottom: 25px;
    position: relative;
    @include media_desktop {
      text-align: center;
      font-size: 32px;
      padding-bottom: 40px;
    }
  }
  &__title:after {
    content: "";
    position: absolute;
    width: 32px;
    height: 4px;
    left: 0;
    bottom: 0;
    transform: unset;
    @include media_desktop {
      left: calc(50% - 16px);
      bottom: 0;
      transform: translate(0, -50%);
    }
  }
  &__titleSub {
    font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
    font-size: 13px;
    margin-top: 12px;
    font-weight: 300;
    @include media_desktop {
      font-size: 15px;
      margin-top: 20px;
      font-weight: 400;
    }
  }
  &.unstress {
    .ec-categoryPageDetailMain__title:after {
      background-color: #54BEB3;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #54BEB3;
    }
  }
  &.illustrious {
    .ec-categoryPageDetailMain__title:after {
      background-color: #A7A8A9;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #A7A8A9;
    }
  }
  &.biophyto {
    .ec-categoryPageDetailMain__title:after {
      background-color: #618F2E;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #618F2E;
    }
  }
  &.forever_young {
    .ec-categoryPageDetailMain__title:after {
      background-color: #F7B05B;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #F7B05B;
    }
  }
  &.rose_de_mer {
    .ec-categoryPageDetailMain__title:after {
      background-color: #162C7D;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #162C7D;
    }
  }
  &.muse {
    .ec-categoryPageDetailMain__title:after {
      background-color: #D76EA8;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #D76EA8;
    }
  }
  &.comodex {
    .ec-categoryPageDetailMain__title:after {
      background-color: #842331;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #842331;
    }
  }
  &.theraskin {
    .ec-categoryPageDetailMain__title:after {
      background-color: #38829B;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #38829B;
    }
  }
  &.chateau_de_beaute {
    .ec-categoryPageDetailMain__title:after {
      background-color: #7E2D4F;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #7E2D4F;
    }
  }
  &.silk {
    .ec-categoryPageDetailMain__title:after {
      background-color: #B29E97;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #B29E97;
    }
  }
  &.bio_satin_serum {
    .ec-categoryPageDetailMain__title:after {
      background-color: #686868;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #686868;
    }
  }
  &.clinical {
    .ec-categoryPageDetailMain__title:after {
      background-color: #488A9B;
    }
    .ec-categoryPageDetailMain__titleSub {
      color: #488A9B;
    }
  }
  &__bigText {
    text-align: left;
    font-size: 20px;
    margin-top: 32px;
    @include media_desktop {
      font-size: 26px;
      text-align: center;
      margin-top: 63px;
      line-height: 1.5em;
    }
  }
  &__text {
    text-align: left;
    margin-top: 32px;
    @include media_desktop {
      text-align: center;
      margin-top: 48px;
    }
  }
  &__subText {
    vertical-align: super;
    font-size: smaller;
  }
  .sp_none {
    display: none;
    @include media_desktop {
      display: inline;
    }
  }
  &__smallText {
    text-align: left;
    font-size: 12px;
    margin-top: 25px;
    @include media_desktop {
      text-align: center;
      margin-top: 40px;
    }
  }
}

/*
カテゴリ詳細ページのLINE UP

カテゴリ詳細ページのLINE UP部分で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailLineup {
  margin: 0 auto;
  &__title {
    font-size: 28px;
    font-family: 'BonvenoCF-Light', sans-serif;
    font-weight: bold;
    text-align: center;
    margin-top: 90px;
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding-left: 0;
    margin: -2px 0 0 -2%;
  }
  &__element {
    color: #000000;
    width: 48%;
    min-height: 387px;
    margin: 25px 0 0 2%;
    overflow: hidden;
    padding-bottom: 25px;
    background-color: #EDEDED;
    @include media_desktop {
      width: 23%;
      margin: 40px 0 0 2%;
    }
  }
  &__element:hover {
    opacity: .8;
    text-decoration: none;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
  }
  &__imgBox {
    height: 195px;
    background-color: #FFFFFF;
  }
  &__img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  &__productTitle {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-weight: bold;
    color: #5C5C67;
    margin: 20px 0 0;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5em;
    @include media_desktop {
      margin: 30px 0 0;
    }
  }
  &__productTitleSmall {
    font-family: 'BonvenoCF-Light', sans-serif;
    color: #5C5C67;
    margin-top: 12px;
    font-size: 13px;
    letter-spacing: 0.12em;
  }
  &__text {
    font-size: 16px;
    font-family: 'Fahkwang', 'Koburina-Gothic-w3', sans-serif;
    color: #5C5C67;
    padding: 0 20px;
    margin-top: 35px;
    letter-spacing: -0.01em;
    @include media_desktop {
      font-size: 19px;
    }
  }
  &__textSmall {
    font-family: "Koburina-Gothic-w3", sans-serif;
    font-size: 12px;
    @include media_desktop {
      font-size: 15px;
    }
  }
}

/*
カテゴリ詳細ページのFEATURES

カテゴリ詳細ページのFEATURES部分で使用されているクラスの定義です。

Styleguide 12.7.1
*/
.ec-categoryPageDetailFeatures {
  margin: 80px auto 0;
  font-size: 14px;
  font-family: 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
  line-height: 2em;
  @include media_desktop {
    margin-top: 120px;
  }
  &__wrapper {
    background-color: #FFFFFF;
    padding: 55px 7vw;
  }
  &__title {
    font-family: 'BonvenoCF-Light', sans-serif;
    font-size: 22px;
    text-align: left;
    padding-bottom: 25px;
    margin: 0;
    @include media_desktop {
      font-size: 28px;
      text-align: center;
      padding-bottom: 35px;
    }
  }
  &__detailTitle {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Koburina-Gothic-w3', sans-serif;
    line-height: 1.5em;
    margin: 35px 0;
    @include media_desktop {
      font-size: 24px;
    }
  }
  &__detailImg {
    width: auto!important;
    max-width: 100%;
    display: block;
    padding-bottom: 15px;
    @include media_desktop {
      padding-bottom: 25px;
    }
  }
}
