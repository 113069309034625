@import "../mixins/media";
@import url('https://fonts.googleapis.com/css2?family=Fahkwang:wght@300&display=swap');

/*
TOPページ　ランキングのスタイルです。

*/
.ec-ranking {
  margin-bottom: 200px;
  .ec-widthRole {
    margin: 0 auto;
  }
  .ec-section__titleEn {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
      letter-spacing: 0.2em;
    }
  }
  & &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .slick-slide {
      margin: 0 6px;
      @media (max-width: 767px) {
        width: calc(50% - 7.5px);
      }
    }
    .slick-list {
      margin: 0 -6px;
    }
    img.slide-arrow {
      top: -20px;
      width: 5px;
      height: 10px;
      @media (max-width: 767px) {
        top: initial;
        bottom: -64px;
      }
    }
    img.prev-arrow {
      right: 37px;
      @media (max-width: 767px) {
        left: calc((100% - 37px)/2);
      }
    }
    img.next-arrow {
      right: 0;
      @media (max-width: 767px) {
        right: calc((100% - 37px)/2);
      }
    }
  }
  .ec-rank {
    &__listChild {
      flex: 1;
      margin-right: 3%;
      &:last-of-type {
        margin-right: 0;
      }
      @media (max-width: 1110px) {
        margin-right: 2%;
      }
    }
    &__numWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__num {
      font-family: "Fahkwang", sans-serif;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: #5C5C67;
      margin-bottom: 16px;
    }
    &__num1 {
      color: #A8975D;
    }
    &__num2 {
      color: #989898;
    }
    &__num3 {
      color: #BB9A86;
    }
    &__icon {
      width: 14px;
      height: auto;
    }
    &__itemInner {
      @media (max-width: 767px) {
        width: 100%;
        overflow: hidden;
      }
    }
    &__itemImg {
      margin: 32px auto 25px auto;
      width: auto;
      aspect-ratio: 1/1.3;
      display: block;
      object-fit: cover;
      @media (max-width: 767px) {
        margin: 16px auto 13px auto;
        // 画像を拡大する
        transform: scale(1.3);
      }
    }
    &__name {
      color: #5C5C67;
      font-family: "BonvenoCF-Light", 'Koburina-Gothic-w3', sans-serif;
      font-size: 14px;
      line-height: 24px;
      height: 24px;
      letter-spacing: 0.12em;
      text-align: center;
      margin-bottom: 32px;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 20px;
        height: 20px;
        letter-spacing: 0.1em;
        margin-top: 13px;
        margin-bottom: 27px;
      }
    }
    &__price {
      color: #5C5C67;
      font-family: 'Fahkwang', sans-serif;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.12em;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.1em;
        padding-top: 20px
      }
    }
  }
}

// 旧デザインのスタイル
/*
.slick-track {
  display: flex;
}

.slick-slide {
  height: auto!important;
}

.ec-ranking {
  background: #F2F2F2;
  padding: 56px 0 88px;
  .ec-widthRole {
    margin: 0 auto;
  }
  .ec-section__title {
    margin-bottom: 42px;
  }
  .ec-ranking__list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin: 0 0 0 -2%;
    @media (max-width: 767px) {
      flex-flow: unset;
      justify-content: space-between;
      margin: 0;
    }
    .ec-rank__listChild {
      width: 18%;
      margin: 0 0 0 2%;
      @media (max-width: 767px) {
        width: initial;
        margin: 0;
        padding: 0 7px;
      }
    }
    .ec-rank__item {
      background: #fff;
      width: 100%;
      height: 100%;
      padding: 8px 16px 32px 16px;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
      img {
        width: 82%;
        height: 136px;
        display: block;
        margin: 0 auto 32px;
        object-fit: contain;
        @media (max-width: 900px) and (min-width: 768px) {
          margin: 0 auto 20px;
        }
      }
    }
    .ec-rank__num {
      font-family: Lato, sans-serif;
      color: #000;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
  .ec-rank__name {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 13px;
  }
  .ec-product__brand {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .ec-rank__detail {
    display: flex;
    align-items: center;
  }
  .ec-rank__price {
    font-family: Lato, sans-serif;
    font-size: 15px;
    line-height: 28px;
  }
  .ec-rank__tax {
    font-family: 'Koburina-Gothic-w3', sans-serif;
    font-size: 12px;
    line-height: 28px;
  }
  .slick-list {
    @media (max-width: 767px) {
      margin: 0 -7.5px;
    }
  }
  .slick-slide {
    @media (max-width: 767px) {
    margin: 0 7.5px;
    }
  }
  img.slide-arrow {
    @media (max-width: 767px) {
      bottom: -24px;
      width: 5px;
      height: 10px;
    }
  }
  img.prev-arrow {
    @media (max-width: 767px) {
      left: calc((100% - 37px) / 2);
    }
  }
  img.next-arrow {
    @media (max-width: 767px) {
      right: calc((100% - 37px) / 2);
    }
  }
}
*/
