@import "../mixins/media";

/*
採用エントリーフォーム

採用エントリーフォームのコンポーネントを定義します。
*/
.recruitForm_page {
  .ec-layoutRole__contents {
    margin:0;
    padding:0;
    font-size:15px;
    line-height:1.8em;
    font-feature-settings:"palt";
    -webkit-text-size-adjust:100%;
    color:#5e6770;
    font-family: KoburiGothic, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
    @media (max-width: 767px) {
      font-size:14px;
    }
    a {
      outline:0;
      text-decoration:none;
    }
    ul {
      list-style:none;
    }
    li {
      margin:0;
      padding:0;
      list-style:none;
    }
  }
  .ec-pageHeader {
    position:relative;
    margin:0 80px 80px;
    padding:0;
    height:140px;
    background-color:#c8dade;
    @media print, screen and (max-width: 1180px) {
      margin:0 50px 50px;
    }
    @media print, screen and (max-width: 767px) {
      margin:0 0 50px;
    }
    .ec-pageHeader__h1 {
      position:absolute;
      top:0;
      right:112px;
      bottom:0;
      display:flex;
      align-items:center;
      justify-content:flex-end;
      margin:0;
      padding:0;
      color:#ffffff;
      letter-spacing:10px;
      font-weight:400;
      font-size:34px;
      font-family: BonvenoCF, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
      line-height:1;
      border-bottom:none;
      @media print, screen and (max-width: 1180px) {
        right:50px;
        letter-spacing:3px;
        font-size:30px;
      }
      @media print, screen and (max-width: 767px) {
        right:30px;
        letter-spacing:2px;
        font-size:20px;
      }
    }
  }
}
.ec-recruitFormCommonRole {
  &__sp {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
  & &__dNone {
    display: none;
  }
  &__main {
    margin:0;
    padding:0 80px;
    @media print, screen and (max-width: 1180px) {
      padding:0 50px;
    }
    @media print, screen and (max-width: 767px) {
      padding:0 30px;
    }
  }
  &__textCenter {
    text-align: center;
  }
  .ec-off1Grid {
    .ec-off1Grid__cell {
      width: 100%;
      max-width: 986px;
      margin: 0 auto;
    }
  }
  &__flow {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    font-weight: bold;
    margin: 55px 0 0;
    padding: 0 0 24px;
    border-bottom: 1px solid #c8dade;
  }
  &__flowElement {
    width: 30%;
    height: auto;
    padding: 0;
    color: #a4c0c4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 16px;
    &.active {
      color: #66a6af;
      .ec-recruitFormCommonRole__flowNumber {
        color: #fff;
        background: #66a6af;
      }
    }
  }
  &__flowNumber {
    background: rgba(200, 218, 222, 0.36);
    width: 26px;
    height: 26px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a4c0c4;
    margin-bottom: 4px;
    font-size: 15px;
  }
  .ec-borderedDefs {
    border-top: unset;
    margin-top: 40px;
    dt {
      padding: 9px 0 11px;
    }
  }
  .ec-label {
    font-family: KoburiGothic, sans-serif;
    font-size: 18px;
    display: inline;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 17px;
    }
  }
  dl {
    flex-wrap: unset!important;
    display: block;
    border-bottom: 1px dashed #a4c0c4;
    padding: 15px 20px 14px!important;
    @media (max-width: 767px) {
      padding: 10px 20px 12px!important;
    }
  }
  dt,dd {
    width: 100%!important;
  }
  &__borderBottomNone {
    border-bottom: unset!important;
  }
  .ec-required,
  &__any {
    font-size: 12px;
    color: #ffffff;
    padding: 2px 10px;
    font-style: normal;
    margin-left: 20px;
    display: inline-block;
    vertical-align: 2px;
  }
  .ec-required {
    background: #66a6af;
  }
  &__any {
    background: rgba(200, 218, 222, 0.36);
    color: #a4c0c4;
  }
  .ec-blockBtn--action,
  .ec-blockBtn--cancel {
    font-family: KoburiGothic, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
    color: #5e6770;
    font-size: 18px;
    letter-spacing: 0.1em;
    line-height: 2;
    cursor: pointer;
    border: none;
    max-width: 235px;
    height: 63px;
    margin: 0 auto;
    font-weight: normal;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    position: relative;
  }
  .ec-blockBtn--action:before,
  .ec-blockBtn--cancel:before {
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid;
    display: block;
    position: absolute;
  }
  .ec-blockBtn--action:before {
    border-color: #5e6770 #5e6770 transparent transparent;
    transform: translate(0, -50%) rotate(45deg);
    -ms-transform: translate(0, -50%) rotate(45deg);
    -webkit-transform: translate(0, -50%) rotate(45deg);
    top: 50%;
    right: 40px;
  }
  .ec-blockBtn--cancel:before {
    border-color: #FFF #FFF transparent transparent;
    transform: translate(0, -50%) rotate(225deg);
    -ms-transform: translate(0, -50%) rotate(225deg);
    -webkit-transform: translate(0, -50%) rotate(225deg);
    top: 50%;
    left: 40px;
  }
  .ec-blockBtn--action {
    background-color: #c8dade;
    &:hover {
      opacity: 0.7;
    }
  }
  .ec-blockBtn--cancel {
    background-color: #5e6770;
    color: #FFF;
    &:hover {
      opacity: 0.7;
    }
  }
}
.ec-recruitFormRole {
  & &__actions {
    padding-top:20px;
  }
  p {
    margin:16px 0;
  }
  & &__explanation {
    margin:0 0 30px;
    padding:0;
    letter-spacing:5px;
    font-weight:500;
    font-size:28px;
    font-family:KoburiGothic, sans-serif;
    text-align:center;
    @media (max-width: 1180px) {
      letter-spacing:3px;
      font-size:24px;
    }
    @media (max-width: 767px) {
      line-height: 1.8em;
    }
  }
  .ec-radio input[type="radio"],
  .ec-input [type="file"],
  .ec-radio label,
  .ec-checkbox input[type="checkbox"],
  .ec-checkbox label {
    cursor: pointer;
  }
  .ec-checkbox label,
  .ec-radio label,
  .ec-input [type="text"],
  .ec-input [type="tel"],
  .ec-input [type="email"] {
    height: 50px;
  }
  .ec-checkbox label,
  .ec-input [type="text"],
  .ec-input [type="tel"],
  .ec-input [type="email"],
  .ec-input [type="file"],
  .ec-radio label,
  .ec-select select,
  .ec-input textarea {
    border: 1px solid #5e6770!important;
    border-radius: unset;
  }
  .ec-input [type="text"],
  .ec-input [type="tel"],
  .ec-input [type="email"],
  .ec-input textarea {
    max-width: 680px;
    width: 100%;
    padding: 15px 20px;
    font-size: 15px;
    line-height: 1.8em;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .ec-radio .ec-errorMessage {
    margin-bottom: 25px;
    font-size: 15px;
    font-weight: bold;
    color: #DE5D50;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .ec-checkbox span,
  .ec-radio span {
    font-size: 15px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .ec-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('/html/template/default/assets/img/contact/contact_select_arrow01.png');
    background-repeat: no-repeat;
    background-color: unset;
    background-size: 12px 6px;
    background-position: right 20px center;
    max-width: 325px;
    width: 100%;
    height: 50px;
    padding: 13px 45px 13px 20px;
    font-size: 15px;
    cursor: pointer;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .ec-select.error select {
    background-color: #FDF1F0;
  }
  &__radioAndCheckBox {
    .ec-recruitFormRole__sideBySide {
      display: flex;
      flex-flow: row wrap;
      margin-left: -10px;
      div {
        margin-left: 10px;
      }
      input[type="radio"],
      input[type="checkbox"] {
        display: none;
        &:checked + label{
          color: #5e6770;
          border: 1px solid #5e6770;
          background-color: #a4c0c4;
        }
        &:checked + label::after{
          content: "";
          display: block;
          position: absolute;
          top: 13px;
          right: 24px;
          width: 7px;
          height: 14px;
          transform: rotate(40deg);
          border-bottom: 2px solid #5e6770;
          border-right: 2px solid #5e6770;
        }
      }
      label {
        position:relative;
        border: 1px solid #c8dade;
        padding: 4px 60px 4px 20px;
        margin: 0 0 10px 0;
        min-width: 300px;
        font-size: 15px;
        @media (max-width: 767px) {
          font-size: 14px;
          padding: 8px 60px 8px 20px;
        }
        &:hover {
          background-color: #c8dade;
          transition: 0.2s;
          -webkit-transition: 0.2s;
          -moz-transition: 0.2s;
          -o-transition: 0.2s;
          -ms-transition: 0.2s;
          span {
            color: #5e6770;
          }
        }
      }
      label:before {
        content: "";
        display: block;
        position: absolute;
        top: 13px;
        right: 18px;
        width: 20px;
        height: 20px;
        background: #fff;
        border: solid 1px #5e6770;
      }
    }
    &.error {
      label {
        border: 1px solid #CF3F34;
        background-color: #FDF1F0;
      }
    }
  }
  &__postalCode {
    input[type="text"] {
      width: 100px!important;
      display: inline;
    }
  }
  &__fileBox {
    input[type="file"] {
      display: none;
    }
    .custom-file-label {
      padding: 2px 60px 2px 20px;
      border: 1px solid #5e6770 !important;
      cursor: pointer;
      min-width: 300px;
      height: 50px;
      @media (max-width: 767px) {
        padding: 8px 60px 8px 20px;
      }
      &:before {
        content: "ファイルを選択";
      }
      &:hover {
        background-color: #c8dade;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        -ms-transition: 0.2s;
      }
    }
    &.error {
      label {
        border: 1px solid #CF3F34;
        background-color: #FDF1F0;
      }
    }
  }
  &__consultation {
    padding-bottom: 10px;
    textarea {
      height: 450px;
      @media (max-width: 767px) {
        height: 200px;
      }
    }
  }
  & &__thinText {
    margin: 0 0 16px;
    color: #5e6770;
    @media (max-width: 767px) {
      margin: 10px 0 6px;
    }
  }
  &__contactCaption {
    margin: 30px auto 60px;
    font-size: 15px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .ec-errorMessage {
    font-size: 15px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}
.ec-recruitFormConfirmRole {
  & &__actions {
    padding-top:20px;
  }
  & &__flow {
    margin: 0;
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
  }
  & &__explanation {
    margin-bottom: 24px;
    line-height: 1.9em;
  }
  dl {
    padding: 15px 20px 15px !important;
    @media (max-width: 767px) {
      padding: 10px 20px 10px !important;
    }
  }
  & &__dt {
    padding-top: 3px;
    line-height: 1.6em;
    .ec-required,
    .ec-recruitFormConfirmRole__any {
      line-height: 1.42857143;
    }
  }
  & &__dd {
    line-height: 1.9em;
    padding-bottom: 5px;
    font-size: 15px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .ec-borderedDefs {
    margin-bottom: 62px;
  }
}
.ec-recruitFormCompleteRole {
  & &__flow {
    margin: 0;
  }
  & &__h2 {
    line-height: 1.6em;
    font-size: 28px;
    margin-top: 40px;
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
  .ec-reportHeading {
    margin: 0 0 30px;
    border-top: none;
  }
  .ec-reportDescription {
    font-size: 15px;
    line-height: 1.9em;
    margin-bottom: 62px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .ec-blockBtn--action {
    padding: 13px 0;
  }
  .ec-blockBtn--action:before {
    right: 34px;
  }
}
