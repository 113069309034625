@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/btn";

/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.3
*/


/*
ページトップボタン

ページトップボタンを表示します

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
.ec-blockTopBtn

Styleguide 2.3.1
*/
.ec-blockTopBtn{
  display: none;
  position: fixed;
  width:120px;
  height: 40px;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  background-color: #9da3a9;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  @include media_desktop {
    right:30px;
    bottom: 30px;
  }
  &.pagetop .ec-gotop {
    position: relative;
    top: -3px;
    @include media_desktop {
      position: unset;
      top: unset;
    }
  }
}
