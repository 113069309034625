.howto_page {
  font-family: "Koburina-Gothic-w3", "Fahkwang", sans-serif;
  .ec-layoutRole .ec-layoutRole__contents {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    display: initial;
  }
}

#ec-howto {
  margin:0;
  padding:0;
  h3 {
    font-weight:400;
    font-family: 'BonvenoCF-Light', 'Koburina-Gothic-w3', "Fahkwang", sans-serif;
  }
  .swiper-wrapper {
    // 無料カウンセリングボタンの上に重なってしまうため以下を追加
    z-index: 0;
  }
  ul {
    list-style-type: none;
  }
  #special {
    display: none;
  }
  .ec-symbolFont {
    font-family: 'Fahkwang', sans-serif;
  }
}

.ec-howToUse {
  margin:0;
  padding:80px 0 150px;
  @media (max-width: 960px) {
    padding:40px 0 100px;
  }
  @media (max-width: 767px) {
    padding:30px 0 70px;
  }
  .ec-howToUse__inneer {
    margin:0 auto;
    padding:0;

    .ec-howToUse__tab {
      margin:0 0 53px;
      padding:0;
      text-align:center;
      @media (max-width: 767px) {
        margin:0 0 36px;
      }
      ul {
        display:inline-flex;
        margin:0;
        padding:0;
        li {
          margin:0 25px;
          padding:0;
          @media (max-width: 767px) {
            margin:0 16px;
            padding:0;
          }
          a {
            font-family: 'Koburina-Gothic-w3', sans-serif;
            position:relative;
            display:flex;
            align-items:center;
            justify-content:center;
            width:240px;
            height:50px;
            color:#5c5c67;
            text-align:center;
            text-decoration:none;
            letter-spacing:normal;
            font-weight:500;
            font-size:16px;

            @media (max-width: 767px) {
              width:110px;
              font-size: 14px;
            }

            &.active::after {
              position:absolute;
              right:0;
              bottom:0;
              left:0;
              height:2px;
              background-color:#5c5c67;
              content:"";
            }
          }
        }
      }
    }

    .ec-howToUse__flow {
      margin:0 auto;
      padding:0 80px;
      max-width:1286px;



      @media (max-width: 1280px) {
        padding:0 50px;
      }
      @media (max-width: 1180px) {
        display:block;
      }
      @media (max-width: 767px) {
        display:none;
      }



      .ec-howToUse__type {
        display:flex;
        align-items:stretch;
        flex-wrap:wrap;
        justify-content:flex-start;
        margin:0 0 25px -10px;
        padding:0;

        @media (max-width: 1280px) {
          margin:0 0 25px;
        }
        @media (max-width: 1180px) {
          margin:0 0 25px -10px;
          width:100%;
        }

        li {
          box-sizing:border-box;
          padding:0 0 10px 10px;
          width:25%;
          @media (max-width: 1280px) {
            padding:0 0 10px 10px;
          }
          @media (max-width: 960px) {
            width:33.333%;
          }
          @media (max-width: 767px) {
            width:50%;
            &:first-child {
              text-align:center;
            }
          }

          .ec-howToUse__do {
            display:flex;
            align-items:center;
            justify-content:center;
            box-sizing:border-box;
            padding:0 20px;
            height:58px;
            background-color:#5c5c67;
            color:#ffffff;
            text-align:center;
            letter-spacing:normal;
            font-size:14px;
            font-family:"BonvenoCF", sans-serif;
            line-height: 1.2;
            @media (max-width: 1280px) {
              padding:0 20px;
              background-color:#5c5c67;
              background-size:6px auto;
              font-size:14px;
            }
            @media (max-width: 1100px) and (min-width: 768px) {
              font-size: 12px;
            }
            @media (max-width: 767px) {
              height:50px;
              background-color:#5c5c67;
              background-size:4px auto;
              font-size:13px;
              line-height:1.4em;
            }
          }
        }
      }
      &:last-of-type {
        margin:0 auto 110px;
      }
    }
    .ec-howTOUse__noWrap {
      white-space: nowrap;
    }
    .ec-howToUseSp__flow {
      position:relative;
      display:none;
      overflow:hidden;
      margin:0;
      padding:0;
      @media (max-width: 767px) {
        display:block;
      }
      ul {
        margin:0;
        padding:0;
        li {
          margin:0;
          padding:0;
          a {
            display:flex;
            align-items:center;
            justify-content:center;
            padding:0 10px;
            height:50px;
            background-color:#5c5c67;
            background-size:4px auto;
            color:#ffffff;
            text-align:center;
            letter-spacing:normal;
            font-size:14px;
            font-family:"BonvenoCF", sans-serif;
            line-height:1;
            opacity:0.2;
            transition:opacity 0.7s cubic-bezier(0.26, 0.63, 0, 0.99);
            @media (max-width: 420px) {
              font-size: 12px;
            }
          }
          &.swiper-slide-active a {
            opacity:1;
          }
        }
      }
      .link_btn {
        position:absolute;
        top:0;
        z-index:999;
        display:flex;
        align-items:center;
        justify-content:center;
        width:50px;
        height:50px;


        span {
          display:block;
          width:24px;
          height:24px;
          border:#bebec1 solid 1px;
          border-radius:100%;
        }
        &.link-prev {
          left:0;
          span {
            background:url(/html/template/default/assets/img/howtouse/slider_prev.svg) no-repeat 50% 50% #ffffff;
            background-size:5px auto;
          }
        }
        &.link-next {
          right:0;
          span {
            background:url(/html/template/default/assets/img/howtouse/slider_next.svg) no-repeat 50% 50% #ffffff;
            background-size:5px auto;
          }
        }
      }
    }

    .ec-howToUse__content {
      position:relative;
      margin:0;
      padding:80px 0 80px;

      @media (max-width: 767px) {
        padding:50px 0 40px;
      }

      &:last-of-type {
        margin:0;
        padding:100px 0 0;
        @media (max-width: 767px) {
          padding:80px 0 0;
        }
        &::after {
          display:none;
        }
      }

      &::after {
        position:absolute;
        right:80px;
        bottom:0;
        left:80px;
        height:1px;
        background-color:#e4e4e4;
        content:"";
        @media (max-width: 1280px) {
          right:50px;
          left:50px;
        }
        @media (max-width: 767px) {
          right:30px;
          left:30px;
        }
      }
      .ec-howToUse__flex {
        display:flex;
        align-items:flex-start;
        justify-content:flex-start;
        padding:0 0 0 80px;
        @media (max-width: 1280px) {
          padding:0 0 0 50px;
        }
        @media (max-width: 767px) {
          display:block;
          padding:0;
        }
        .ec-howToUse__careType {
          box-sizing:border-box;
          margin:0;
          padding:0 50px 0 0;
          width:24%;
          @media (max-width: 1600px) {
            width: 27%;
          }
          @media (max-width: 1400px) {
            width: 36%;
          }
          @media (max-width: 960px) {
            padding: 0 40px 0 0;
            width: 45%;
          }
          @media (max-width: 767px) {
            padding: 0 30px;
            width: auto;
            .ec-howToUse__img {
              padding:0 40px;
            }
          }
          .ec-howToUse__h3 {
            margin:0 auto 20px 0;
            padding:0;
            text-align:center;
            font-weight:400;
            font-size:25px;

            @media (max-width: 767px) {
              margin:0 0 20px;
              padding:0 30px;
              text-align:center;
              font-size:20px;
            }
          }
          .ec-howToUseMethod {
            margin:0;
            padding:0;
            @media (max-width: 767px) {
              margin:0 0 30px;
            }
            .ec-howToUseMethod__img {
              margin:0;
              padding:0;
              text-align:center;
              line-height:0;


              img {
                width: auto;
                height: 210px;
                @media (max-width: 767px) {
                  width: auto;
                  height: 240px;
                }
                @media (max-width: 500px) {
                  width: auto;
                  height: 167px;
                }
              }
            }
            .ec-howToUseMethod__caption {
              margin:0;
              padding:20px 0 0;
              @media (max-width: 767px) {
                padding:8px 0 0;
              }
              .ec-howToUseMethod__text {
                margin:0;
                padding:0;
                text-align:justify;
                letter-spacing:normal;
                font-size:16px;
                line-height:1.8em;

                @media (max-width: 767px) {
                  font-size:14px;
                }
                b {
                  display:inline-block;
                  font-weight:500;
                  font-family:"Ryumin", sans-serif;
                  &:last-of-type {
                    padding:20px 0 0;
                  }
                }
              }
              .ec-howToUseMethod__note {
                margin:0;
                padding:5px 0 0;
                text-align:justify;
                font-size:13px;
                line-height:1.8em;
                @media (max-width: 767px) {
                  font-size:12px;
                }
              }
            }
          }
        }

        .swiper-container {
          position:relative;
          overflow:hidden;
          box-sizing:border-box;
          padding:40px 80px 0 0;
          width:76%;
          @media (max-width: 1600px) {
            width:73%;
          }
          @media (max-width: 1400px) {
            width:64%;
          }
          @media (max-width: 1280px) {
            padding:40px 50px 0 0;
          }
          @media (max-width: 960px) {
            width:55%;
          }
          @media (max-width: 767px) {
            padding:0 10px;
            width:auto;
          }

          .swiper-wrapper {
            margin:0;
            padding:0;
            .swiper-slide {
              .ec-howToUse__detail {
                .ec-howToUse__img {
                  margin:0;
                  padding:0;
                  line-height:0;

                  img {
                    width:100%;
                    height:auto;
                  }
                }
              }
            }
          }
          .swiper-control {
            display:flex;
            align-items:center;
            justify-content:flex-end;
            padding:40px 0 0;
            @media (max-width: 767px) {
              justify-content:center;
              padding:25px 0 0;
            }

            .swiper-pagination-fraction {
              display:flex;
              align-items:flex-end;
              justify-content:center;
              width:auto;
              font-weight:300;
              font-size:20px;
              font-family:"Fahkwang", sans-serif;
              line-height:1;
              .swiper-pagination-current {
                padding:0 5px 0 0;
              }
              .swiper-pagination-total {
                padding:0 0 0 5px;
                font-size: 24px;
                line-height: 20px;
              }
              &.swiper-pagination-lock {
                display:none;
              }
            }
            .arrow {
              display:block;
              width:28px;
              height:28px;
              border:#5c5c67 solid 1px;
              border-radius:100%;
            }
            .swiper-button-disabled {
              transform:scale(0.6);
            }
            .button-prev {
              margin:0 20px 0 0;
              padding:0;
              opacity:0.6;
              @media (max-width: 767px) {
                margin:0 20px 0 0;
              }
              .arrow {
                background:url(/html/template/default/assets/img/howtouse/slider_prev.svg) no-repeat 50% 50%;
                background-size: 7px auto;
              }
            }
            .button-next {
              margin:0 0 0 20px;
              padding:0;
              opacity:0.6;
              @media (max-width: 767px) {
                margin:0 0 0 20px;
              }
              .arrow {
                background:url(/html/template/default/assets/img/howtouse/slider_next.svg) no-repeat 50% 50%;
                background-size: 7px auto;
              }
            }
          }
        }
      }
    }
  }
}
